<template>
	<div>
		<b-container class="p-0">
			<b-navbar id="navigation" toggleable="lg" type="dark" variant="dark" class="mb-3" v-if="!session">
				<b-navbar-brand>
					<b-img src="/img/logo_hmc_bordered.svg" class="logo"></b-img>
					<span class="text-white">Pase Corporativo</span>
				</b-navbar-brand>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav class="ml-auto">
						<router-link to="/ingresar" custom v-slot="{ navigate }">
							<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
								<i class="fas fa-lock mr-2"></i>
								<span>Iniciar Sesión</span>
							</b-nav-item>
						</router-link>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>
			<b-overlay :show="loading" rounded="sm">
				<b-card no-body>
					<b-card-body>
						<div v-if="checking" class="text-center pt-3 pb-2">
							<b-spinner type="grow" variant="primary"></b-spinner>
							<h3 class="mt-3">Verificando Información</h3>
							<p class="text-size-15 mb-0 text-muted">Por favor, espere un momento.</p>
						</div>
						<div v-else-if="invalid" class="text-center pt-2 pb-2">
							<i class="fas fa-minus-circle text-size-3 text-danger"></i>
							<h3 class="mt-3">Solicitud Inválida</h3>
							<p class="text-size-15 mb-0 text-muted">{{error_message}}</p>
						</div>
						<div v-else-if="success" class="text-center pt-2 pb-2">
							<i class="fas fa-check-circle text-size-3 text-success"></i>
							<h3 class="mt-3">Pase Aprobado/Rechazado</h3>
							<p class="text-size-15 mb-0 text-muted">Ya puede cerrar esta ventana.</p>
						</div>
						<div v-else>
							<div v-if="pass.id">
								<b-row class="mb-4" v-if="pass.status_id === 1">
									<b-col>
										<b-button variant="success" block @click="approve_modal()" size="lg" class="py-3">
											<i class="fas fa-check mr-2"></i>
											<span>APROBAR PASE</span>
										</b-button>
									</b-col>
									<b-col>
										<b-button variant="danger" block @click="reject_modal()" size="lg" class="py-3">
											<i class="fas fa-times mr-2"></i>
											<span>RECHAZAR PASE</span>
										</b-button>
									</b-col>
									<!--
									<b-col>
										<b-button variant="dark" block @click="nullify_modal()" size="lg">
											<i class="fas fa-trash mr-1"></i>
											<span>ANULAR</span>
										</b-button>
									</b-col>
									-->
								</b-row>
								<b-card bg-variant="success" class="mb-4" v-if="pass.status_id === 2">
									<p class="mb-0 text-size-12 text-white">
										<i class="fas fa-check mr-2"></i>
										<strong>PASE APROBADO</strong>
									</p>
								</b-card>
								<b-card bg-variant="danger" class="mb-4" v-if="pass.status_id === 3">
									<p class="mb-0 text-size-12 text-white">
										<i class="fas fa-times mr-2"></i>
										<strong>PASE RECHAZADO</strong>
									</p>
								</b-card>
								<b-card bg-variant="dark" class="mb-4" v-if="pass.status_id === 0">
									<p class="mb-0 text-size-12 text-white">
										<i class="fas fa-trash mr-2"></i>
										<strong>PASE ANULADO</strong>
									</p>
								</b-card>
								<b-list-group class="mb-4">
									<b-list-group-item v-if="pass.status_id === 3 || pass.status_id === 0">
										<b-row>
											<b-col sm="3" class="text-danger">
												<strong v-if="pass.status_id === 3">Motivo de Rechazo</strong>
												<strong v-else-if="pass.status_id === 0">Motivo de Anulación</strong>
											</b-col>
											<b-col>
												{{pass.comment}}
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
											<b-col sm="3">
												<strong>N° de Pase</strong>
											</b-col>
											<b-col>
												{{pass.id}}
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item v-if="pass.status_id === 1">
										<b-row>
											<b-col sm="3">
												<strong>Estado de Solicitud</strong>
											</b-col>
											<b-col>
												<b-badge variant="warning">EN ESPERA DE REVISIÓN</b-badge>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
											<b-col sm="3">
												<strong>Fecha de Inicio</strong>
											</b-col>
											<b-col>
												{{pass.date_start}}
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
											<b-col sm="3">
												<strong>Fecha de Término</strong>
											</b-col>
											<b-col>
												{{pass.date_end}}
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
											<b-col sm="3">
												<strong>Motivo / Carga</strong>
											</b-col>
											<b-col>
												{{pass.motive}}
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
											<b-col sm="3">
												<strong>E-mail de Anfitrión</strong>
											</b-col>
											<b-col>
												<span class="text-megamuted mr-1"><i class="fas fa-envelope"></i></span>
												<span>{{pass.host_email}}</span>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
											<b-col sm="3">
												<strong>
													<span v-if="pass.on_behalf">Empresa Solicitante</span>
													<span v-else>Empresa</span>
												</strong>
											</b-col>
											<b-col>
												<span>
													<span>{{pass.contact_name}}</span>
													<span class="text-muted mx-2">&ndash;</span>
													<span class="text-muted mr-1">RUT</span>
													<span>{{parseIdentifier(pass.contact_identifier)}}</span>
												</span>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item v-if="pass.on_behalf">
										<b-row>
											<b-col sm="3">
												<strong>Empresa Transportista</strong>
											</b-col>
											<b-col>
												<span>
													<span>{{pass.on_behalf_name}}</span>
													<span class="text-muted mx-2">&ndash;</span>
													<span class="text-muted mr-1">RUT</span>
													<span>{{parseIdentifier(pass.on_behalf_identifier)}}</span>
												</span>
											</b-col>
										</b-row>
									</b-list-group-item>
									<b-list-group-item>
										<b-row>
											<b-col sm="3">
												<strong>Contacto</strong>
											</b-col>
											<b-col>
												<span>
													<span class="text-megamuted mr-1"><i class="fas fa-phone-alt"></i></span>
													<span class="mr-3">{{pass.contact_phone}}</span>
													<span class="text-megamuted mr-1"><i class="fas fa-envelope"></i></span>
													<span>{{pass.contact_email}}</span>
												</span>
											</b-col>
										</b-row>
									</b-list-group-item>
								</b-list-group>
								<b-card no-body class="mb-4">
									<b-card-header>
										<strong class="mr-2">Nómina de Personal</strong>
										<span class="text-muted">({{pass.members.length}} persona<span v-if="pass.members.length >1">s</span>)</span>
									</b-card-header>
									<b-table
										:items="pass.members"
										:fields="fields"
										hover
										responsive
									>
										<template v-slot:cell(identifier)="data">
											<div>
												{{parseIdentifier(data.value)}}
											</div>
										</template>
									</b-table>
								</b-card>
								<b-card no-body>
									<b-card-header>
										<strong class="mr-2">Documentación Adjunta</strong>
										<span class="text-muted">({{pass.files.length}} documento<span v-if="pass.files.length >1">s</span>)</span>
									</b-card-header>
									<b-table :items="pass.files" :fields="files_fields" show-empty responsive hover class="mb-0">
										<template #table-colgroup="scope">
											<col
												v-for="field in scope.fields"
												:key="field.key"
												:style="{ width: field.key === 'name' ? '70%' : 'auto' }"
											>
										</template>
										<template #empty>
											<div class="text-center py-2 text-size-11 text-muted">
												<span>Esta solicitud de pase no incluye documentos adjuntos.</span>
											</div>
										</template>
										<template #head(name)="data">
											<div class="text-left ml-2">
												<span>{{data.label}}</span>
											</div>
										</template>
										<template #head(size)="data">
											<div class="text-center">
												<span>{{data.label}}</span>
											</div>
										</template>
										<template #head(download)="data">
											<div class="text-center">
												<span>{{data.label}}</span>
											</div>
										</template>
										<template #cell(name)="data">
											<div class="text-left ml-2">
												<span>{{data.value}}</span>
											</div>
										</template>
										<template #cell(size)="data">
											<div class="text-center">
												<span>{{humanBytes(data.value)}}</span>
											</div>
										</template>
										<template #cell(download)="data">
											<div class="text-center" v-if="data && data.item && data.item.uuid">
												<b-button :href="api_path+'corporate_passes/files/'+data.item.uuid" style="padding: 0px 5px !important;">
													<i class="fas fa-arrow-down"></i>
												</b-button>
											</div>
										</template>
									</b-table>
								</b-card>
							</div>
						</div>
					</b-card-body>
				</b-card>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->
			</b-overlay>
			
		</b-container>

		<!-- APPROVE MODAL -->
		<b-modal id="approve-corporate-pass" title="Aprobar Pase Corporativo">
			<b-alert show variant="success" class="text-size-11 mb-0">
				<p class="mb-1">Al aprobar un Pase Corporativo, se notificará a:</p>
				<ul class="mb-1">
					<li>Solicitante del Pase</li>
					<li>Recursos Humanos</li>
					<li>Prevención de Riesgos</li>
					<li>Jefe de Protección Industrial</li>
					<li>Garita Costa</li>
					<li>Garita Faena</li>
				</ul>
			</b-alert>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2">Cancelar</b-button>
				<b-button @click="approve()" variant="success">
					<i class="fas fa-check mr-1"></i>
					<span>Confirmar Aprobación</span>
				</b-button>
			</template>
		</b-modal>
		<!-- APPROVE MODAL -->

		<!-- REJECTION MODAL -->
		<b-modal id="reject-corporate-pass" title="Rechazar Pase Corporativo">
			<b-alert show variant="danger" class="text-size-11">Al rechazar un Pase Corporativo, se notificará al solicitante el motivo de rechazo que escriba a continuación.</b-alert>
			<b-form-group label="Motivo de Rechazo">
				<b-input v-model="comment" :state="val_comment" size="lg"></b-input>
				<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
				<b-form-invalid-feedback>Debe ingresar un motivo para poder rechazar el pase</b-form-invalid-feedback>
			</b-form-group>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2">Cancelar</b-button>
				<b-button @click="reject()" variant="danger" :disabled="!val_comment">
					<i class="fas fa-times mr-1"></i>
					<span>Confirmar Rechazo</span>
				</b-button>
			</template>
		</b-modal>
		<!-- REJECTION MODAL -->

		<!-- NULLIFY MODAL -->
		<b-modal id="nullify-pass" title="Anular Pase Corporativo">
			<b-alert show variant="dark" class="text-size-11">Al anular un Pase Corporativo, no se emitirá ninguna notificación.</b-alert>
			<b-form-group label="Motivo de Anulación">
				<b-input v-model="comment" :state="val_comment"></b-input>
				<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
				<b-form-invalid-feedback>Debe ingresar un motivo para poder rechazar el pase</b-form-invalid-feedback>
			</b-form-group>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2">Cancelar</b-button>
				<b-button @click="nullify()" variant="dark" :disabled="!val_comment">
					<i class="fas fa-trash mr-1"></i>
					<span>Confirmar Anulación</span>
				</b-button>
			</template>
		</b-modal>
		<!-- NULLIFY MODAL -->

	</div>
</template>

<script>
export default {
	name: 'CorporatePassToken',
	components: {
	},
	data() {
		return {
			checking: true,
			invalid: false,
			loading: false,
			success: false,
			pass: {},
			error_message: 'El enlace de Pase Corporativo utilizado no es válido.',
			comment: '',
			fields: [
				{ key: 'identifier', label: 'RUT', sortable: true },
				{ key: 'names', label: 'Nombres', sortable: true },
				{ key: 'first_lastname', label: '1er Apellido', sortable: true },
				{ key: 'second_lastname', label: '2do Apellido', sortable: true }
			],
			files_fields: [
				{ key: 'name', label: 'Nombre', sortable: false },
				{ key: 'description', label: 'Tipo', sortable: false },
				{ key: 'size', label: 'Tamaño', sortable: false },
				{ key: 'download', label: 'Descargar', sortable: false },
			],
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (!this.loading && (modalId == 'reject-corporate-pass' || modalId == 'nullify-corporate-pass')) {
				this.comment = ''
			}
		})
	},
	created() {
		this.$store.dispatch('check_corporate_pass_token', this.token)
		.then(response => {
			this.checking = false
			this.pass = response.data
		})
		.catch(error => {
			this.checking = false
			this.invalid = true
			if (error.response.data.message) this.error_message = error.response.data.message
		})
	},
	computed: {
		session() {
			return this.$store.getters.session
		},
		token() {
			return this.$route.params.token
		},
		val_comment() {
			return this.comment.length > 0 ? true : false
		},
		api_path() {
			return this.$store.state.api_path
		}
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay,
				toaster: 'b-toaster-top-center'
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		approve_modal() {
			this.$bvModal.show('approve-corporate-pass')
		},
		approve() {
			this.loading = true
			this.$bvModal.hide('approve-corporate-pass')
			this.$api.put('corporate_pass/'+this.pass.id, { token: this.token, action: 'approve' })
			.then(response => {
				this.loading = false
				this.pass.status_id = 2
				this.toast(true, 'Se aprobó el Pase Corporativo N° '+response.data.id+' correctamente.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reject_modal() {
			this.$bvModal.show('reject-corporate-pass')
		},
		reject() {
			this.loading = true
			this.$bvModal.hide('reject-corporate-pass')
			this.$api.put('corporate_pass/'+this.pass.id, { token: this.token, action: 'reject', comment: this.comment })
			.then(response => {
				this.loading = false
				this.pass.status_id = 3
				this.pass.comment = this.comment
				this.toast(true, 'Se rechazó el Pase Corporativo N° '+response.data.id+' correctamente.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		nullify_modal() {
			this.$bvModal.show('nullify-corporate-pass')
		},
		nullify() {
			this.loading = true
			this.$bvModal.hide('nullify-corporate-pass')
			this.$api.put('corporate_pass/'+this.pass.id, { token: this.token, action: 'nullify', comment: this.comment })
			.then(response => {
				this.loading = false
				this.pass.status_id = 0
				this.pass.comment = this.comment
				this.toast(true, 'Se anuló el Pase Corporativo N° '+response.data.id+' correctamente.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		humanBytes(size) {
			if (size == 0) return 0
			if (size < (1024 * 1024)) return (size / (1024)).toFixed(2) + ' KB'
			return (size / (1024 * 1024)).toFixed(2) + ' MB'
		},
		downloadFile(file) {
			console.log(file)
		}
	}
}
</script>

<style scoped>
</style>
