<template>
	<div v-if="loading" class="text-center p-5 mb-5">
		<b-spinner type="grow" variant="light"></b-spinner>
	</div>
	<b-card body-class="p-5" v-else>
		<b-row>
			<b-col sm="auto">
				<i class="fas fa-minus-circle text-size-4 text-danger"></i>
			</b-col>
			<b-col>
				<h3>Acceso Denegado</h3>
				<p class="text-size-15 mb-0 text-muted">Su cuenta de usuario no se encuentra habilitada para acceder a este módulo.</p>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
export default {
	name: 'Unauthorized',
	computed: {
		loading() {
			return this.$store.state.user.data.privileges.loading
		}
	}
}
</script>