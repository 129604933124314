<template>
	<Unauthorized v-if="!getters.prv_subcontractors"></Unauthorized>
	<div v-else>

		<!-- COMPONENTS -->
		<Requirements @refresh="load()" :owner="owner"></Requirements>
		<Create @update="load()"></Create>
		<Update @update="load()"></Update>
		<Delete @update="load()" :owner="owner"></Delete>
		<!-- /COMPONENTS -->

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar una nueva empresa"
					v-if="getters.prv_subcontractors_create"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				<b-overlay
					:show="subcontractors.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de empresas">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2">
				<b-button-group>
					<b-button
						:variant="query.active=='any'?'primary':'light'"
						@click="active('any')"
						v-b-tooltip.hover.bottomleft
						title="Todas las empresas"
					>Todo</b-button>
					<b-button
						:variant="query.active=='yes'?'primary':'light'"
						@click="active('yes')"
						v-b-tooltip.hover.bottomleft
						title="Empresas con servicios vigentes"
					>Vigente</b-button>
					<b-button
						:variant="query.active=='no'?'primary':'light'"
						@click="active('no')"
						v-b-tooltip.hover.bottomleft
						title="Empresas sin servicios vigentes"
					>No Vigente</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->
		
		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!subcontractors.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="subcontractors.data.total"> {{subcontractors.data.total}} </span>
						<span v-else> 0 </span>
						<span>Empresa<span v-if="subcontractors.data.total > 1 || !subcontractors.data.total">s</span> subcontratista<span v-if="subcontractors.data.total > 1 || !subcontractors.data.total">s</span></span>
						<span v-if="query.active == 'yes'"> vigente<span v-if="subcontractors.data.total > 1 || !subcontractors.data.total">s</span></span>
						<span v-else-if="query.active == 'no'"> no vigente<span v-if="subcontractors.data.total > 1 || !subcontractors.data.total">s</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="subcontractors.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="subcontractors.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="subcontractors.data.data"
					:fields="options.fields"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
					class="table-with-dropdowns"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron empresas que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(identifier)="data">
						<div class="ml-2">
							{{parseIdentifier(data.value)}}
						</div>
					</template>
					<template v-slot:cell(short_name)="data">
						<div>
							<span v-b-tooltip.hover.right :title="data.item.full_name.toUpperCase()" class="cursor-pointer">{{data.value.toUpperCase()}}</span>
						</div>
					</template>
					<template v-slot:cell(contractor.short_name)="data">
						<div>
							<span v-b-tooltip.hover.right :title="data.item.contractor.full_name.toUpperCase()" class="cursor-pointer">{{data.value.toUpperCase()}}</span>
						</div>
					</template>
					<template v-slot:cell(requirements)="data">
						<div class="text-center">
							<b-dropdown size="sm" variant="outline-primary" right no-caret>
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_subcontractors_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_subcontractors_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Empresa</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_subcontractors_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_subcontractors_read && !getters.prv_subcontractors_update && !getters.prv_subcontractors_delete"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
						</div>
					</template>
					<template v-slot:cell(req_done)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="success">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_rejected)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_waiting)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_missing)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<!-- HELP -->
		<b-modal id="subcontractors-help" title="Información de Ayuda" size="lg">

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="secondary">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /HELP -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import Requirements from '@/components/Requirements.vue'
import Create from '@/components/CreateSubcontractor.vue'
import Update from '@/components/UpdateSubcontractor.vue'
import Delete from '@/components/DeleteSubcontractor.vue'

export default {
	name: 'Subcontractors',
	components: {
		Unauthorized,
		Create,
		Update,
		Delete,
		Requirements
	},
	data() {
		return {
			owner: {},
			filtered: '',
			query: {
				active: 'yes',
				limit: 9999,
				page: 1,
				filter: ''
			},
			options: {
				fields: [
					{ key: 'identifier', label: 'RUT', sortable: true },
					{ key: 'short_name', label: 'Empresa', sortable: true },
					{ key: 'contractor.short_name', label: 'Mandante', sortable: true },
					{ key: 'requirements', label: 'Acciones' },
					{ key: 'req_done', label: 'Aprobados', sortable: true },
					{ key: 'req_waiting', label: 'Cargados', sortable: true },
					{ key: 'req_rejected', label: 'Rechazados', sortable: true },
					{ key: 'req_missing', label: 'Pendientes', sortable: true }
				],
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 9999, text: 'Todas' }
				]
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		subcontractors() {
			return this.$store.state.subcontractors
		},
	},
	created() {
		this.load()
	},
	methods: {
		active(type) {
			this.query.active = type
			this.load()
		},
		create() {
			this.$store.dispatch('get_contractors')
			this.$bvModal.show('create-subcontractor')
		},
		update(subcontractor) {
			this.$store.dispatch('get_contractors')
			this.$store.dispatch('get_subcontractor', subcontractor.item.id)
			this.$bvModal.show('update-subcontractor')
		},
		destroy(subcontractor) {
			this.owner = subcontractor
			this.$bvModal.show('delete-subcontractor')
		},
		load() {
			this.$store.dispatch('get_subcontractors', this.query)
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un nombre de empresa o RUT para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.active = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
			this.filtered = ''
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.active = 'yes'
			this.query.filter = ''
			this.query.limit = 9999
			this.filtered = ''
			this.load()
		},
		help() {
			this.$bvModal.show('subcontractors-help')
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		requirements(subcontractor) {
			this.$store.dispatch('get_subcontractor_requirements', subcontractor.item.id)
			this.owner = subcontractor
			this.$bvModal.show('requirements')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
	}
}
</script>

<style>
</style>