<template>
	<Unauthorized v-if="false"></Unauthorized>
	<div v-else>

		<b-modal
			id="queue-add"
		>
			queue-add
		</b-modal>
		<b-modal
			id="queue-view"
			title="Revisar Documentos"
			hide-footer
		>
			<b-row class="mb-3">
				<b-col sm="auto">
					<p class="mb-0 text-size-08 text-uppercase">
						<strong>Empresa</strong>
					</p>
					<p class="mb-0 text-size-12">ENTEL S.A.</p>
				</b-col>
				<b-col sm="auto">
					<p class="mb-0 text-size-08 text-uppercase">
						<strong>Rut</strong>
					</p>
					<p class="mb-0 text-size-12">92.580.000-7</p>
				</b-col>
				<b-col></b-col>
				<b-col sm="auto">
					<p class="mb-0 text-size-08 text-uppercase">
						<strong>Documentos</strong>
					</p>
					<p class="mb-0 text-size-12">10</p>
				</b-col>
				<b-col sm="auto">
					<p class="mb-0 text-size-08 text-uppercase">
						<strong>Avance</strong>
					</p>
					<p class="mb-0 text-size-12">60%</p>
				</b-col>
			</b-row>
			<b-list-group>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Inicio de Actividades ante el SII</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="success" block>100%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Carpeta Tributaria Electrónica</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="danger" block>75%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Certificado de Vigencia de Sociedad</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="success" block>100%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>RUT Sociedad</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="danger" block>50%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Declaración Jurada Ley 20.393</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="danger" block>0%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Certificado de Deuda de la TGR</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="success" block>100%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Copia de Constitucion de Empresa</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="danger" block>25%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Acreditación de Cuentas Corrientes</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="success" block>100%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Formulario 29: Declaraciones Mensuales de Impuestos</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="success" block>100%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item class="py-2 px-3">
					<b-row>
						<b-col>
							<span>Formulario 22: Impuestos a la Renta</span>
						</b-col>
						<b-col sm="2">
							<b-button size="sm" variant="success" block>100%</b-button>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
		</b-modal>

		<!-- CONTENT -->
		<b-card no-body class="px-3 py-3">

			<b-row>


				<!-- ABASTECIMIENTO -->
				<b-col>
					<b-card bg-variant="light" no-body class="px-3 py-3">
						<b-row no-gutters>
							<b-col>
								<p class="text-size-12">
									<strong>Abastecimiento y Contratos</strong>
									<strong class="text-danger ml-2">98,45%</strong>
								</p>
							</b-col>
							<b-col sm="auto">
								<b-button size="sm" variant="light" id="popover-abastecimiento">
									<i class="fas fa-users"></i>
								</b-button>
								<b-popover target="popover-abastecimiento" triggers="hover" placement="bottomleft">
									<template #title>Aprobadores</template>
									<p class="mb-0">Manuel Ríos<span class="text-muted ml-2">mrios@minerahmc.cl</span></p>
									<p class="mb-0">Mario Araya<span class="text-muted ml-2">maraya@minerahmc.cl</span></p>
									<p class="mb-0">Fernanda Bravo<span class="text-muted ml-2">fbravo@minerahmc.cl</span></p>
								</b-popover>
							</b-col>
						</b-row>
						<b-row class="text-size-08 px-2 mb-1">
							<b-col sm="1" class="text-center">
								<span class="text-muted">#</span>
							</b-col>
							<b-col sm="2">
								<span class="text-muted">Tipo</span>
							</b-col>
							<b-col>
								<span class="text-muted">Propietario</span>
							</b-col>
							<b-col sm="2" class="text-center">
								<span class="text-muted">Documentos</span>
							</b-col>
							<b-col sm="2" class="text-center">
								<span class="text-muted">Avance</span>
							</b-col>
						</b-row>
						
						<b-card no-body class="text-size-09 px-2 py-1 mb-1" v-for="(item, index) in abastecimiento" :key="index">
							<b-row>
								<b-col sm="1" class="text-center">
									<b-dropdown variant="light" no-caret toggle-class="py-0 px-2">
										<template #button-content>
											{{ item.position }}
										</template>
										<b-dropdown-item>
											<i class="fas fa-up-to-line mr-2"></i>
											<span>Subir a la primera posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-caret-up mr-2"></i>
											<span>Subir 1 posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-caret-down mr-2"></i>
											<span>Bajar 1 posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-down-to-line mr-2"></i>
											<span>Bajar a la última posición</span>
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
								<b-col sm="2" @click="view()" class="cursor-pointer">
									{{ item.type }}
								</b-col>
								<b-col @click="view()" class="cursor-pointer">
									{{ item.owner }}
								</b-col>
								<b-col sm="2" class="text-center cursor-pointer" @click="view()">
									{{ item.documents }}
								</b-col>
								<b-col sm="2" class="text-center cursor-pointer" @click="view()">
									{{ item.progress }}%
								</b-col>
							</b-row>
						</b-card>

						<b-alert show variant="success" class="py-3 mt-2 mb-0">
							<p class="mb-0 text-center text-success">Fin de la cola.</p>
						</b-alert>

					</b-card>
				</b-col>
				<!-- /ABASTECIMIENTO -->


				<!-- PREVENCION DE RIESGOS -->
				<b-col>
					<b-card bg-variant="light">
						<b-row no-gutters>
							<b-col>
								<p class="text-size-12">
									<strong>Prevención de Riesgos y Recursos Humanos</strong>
									<strong class="text-danger ml-2">82,13%</strong>
								</p>
							</b-col>
							<b-col sm="auto">
								<b-button size="sm" variant="light" id="popover-prevencion">
									<i class="fas fa-users"></i>
								</b-button>
								<b-popover target="popover-prevencion" triggers="hover" placement="bottomleft">
									<template #title>Aprobadores</template>
									<p class="mb-0">Elizabeth Wallberg<span class="text-muted ml-2">ewallberg@minerahmc.cl</span></p>
									<p class="mb-0">Jorge Riquelme<span class="text-muted ml-2">jriquelme@minerahmc.cl</span></p>
									<p class="mb-0">Milton Martínez<span class="text-muted ml-2">mmartinez@minerahmc.cl</span></p>
									<p class="mb-0">Michael Guajardo<span class="text-muted ml-2">mguajardo@minerahmc.cl</span></p>
									<p class="mb-0">Claudia Olivares<span class="text-muted ml-2">colivares@minerahmc.cl</span></p>
									<p class="mb-0">Poulett Galleguillos<span class="text-muted ml-2">pgallegu@minerahmc.cl</span></p>
								</b-popover>
							</b-col>
						</b-row>
						<b-row class="text-size-08 px-2 mb-1">
							<b-col sm="1" class="text-center">
								<span class="text-muted">#</span>
							</b-col>
							<b-col sm="2">
								<span class="text-muted">Tipo</span>
							</b-col>
							<b-col>
								<span class="text-muted">Propietario</span>
							</b-col>
							<b-col sm="2" class="text-center">
								<span class="text-muted">Documentos</span>
							</b-col>
							<b-col sm="2" class="text-center">
								<span class="text-muted">Avance</span>
							</b-col>
						</b-row>
						
						<b-card no-body class="text-size-09 px-2 py-1 mb-1" v-for="(item, index) in prevencion" :key="index">
							<b-row>
								<b-col sm="1" class="text-center">
									<b-dropdown variant="light" no-caret toggle-class="py-0 px-2">
										<template #button-content>
											{{ item.position }}
										</template>
										<b-dropdown-item>
											<i class="fas fa-up-to-line mr-2"></i>
											<span>Subir a la primera posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-caret-up mr-2"></i>
											<span>Subir 1 posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-caret-down mr-2"></i>
											<span>Bajar 1 posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-down-to-line mr-2"></i>
											<span>Bajar a la última posición</span>
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
								<b-col sm="2">
									{{ item.type }}
								</b-col>
								<b-col class="text-uppercase">
									{{ item.owner }}
								</b-col>
								<b-col sm="2" class="text-center">
									{{ item.documents }}
								</b-col>
								<b-col sm="2" class="text-center">
									{{ item.progress }}%
								</b-col>
							</b-row>
						</b-card>

						<b-alert show variant="danger" class="py-3 mt-2 mb-0">
							<p class="mb-0 text-center text-danger">Quedan 943 documentos adicionales en esta cola.</p>
						</b-alert>

					</b-card>
				</b-col>
				<!-- /PREVENCION DE RIESGOS -->


				<!-- PROTECCION INDUSTRIAL -->
				<b-col>
					<b-card bg-variant="light">
						<b-row no-gutters>
							<b-col>
								<p class="text-size-12">
									<strong>Protección Industrial</strong>
									<strong class="text-danger ml-2">75,44%</strong>
								</p>
							</b-col>
							<b-col sm="auto">
								<b-button size="sm" variant="light" id="popover-proteccion">
									<i class="fas fa-users"></i>
								</b-button>
								<b-popover target="popover-proteccion" triggers="hover" placement="bottomleft">
									<template #title>Aprobadores</template>
									<p class="mb-0">Jorge Riquelme<span class="text-muted ml-2">jriquelme@minerahmc.cl</span></p>
									<p class="mb-0">José Campillay<span class="text-muted ml-2">jcampillay@minerahmc.cl</span></p>
								</b-popover>
							</b-col>
						</b-row>
						<b-row class="text-size-08 px-2 mb-1">
							<b-col sm="1" class="text-center">
								<span class="text-muted">#</span>
							</b-col>
							<b-col sm="2">
								<span class="text-muted">Tipo</span>
							</b-col>
							<b-col>
								<span class="text-muted">Propietario</span>
							</b-col>
							<b-col sm="2" class="text-center">
								<span class="text-muted">Documentos</span>
							</b-col>
							<b-col sm="2" class="text-center">
								<span class="text-muted">Avance</span>
							</b-col>
						</b-row>
						
						
						<b-card no-body class="text-size-09 px-2 py-1 mb-1" v-for="(item, index) in proteccion" :key="index">
							<b-row>
								<b-col sm="1" class="text-center">
									<b-dropdown variant="light" no-caret toggle-class="py-0 px-2">
										<template #button-content>
											{{ item.position }}
										</template>
										<b-dropdown-item>
											<i class="fas fa-up-to-line mr-2"></i>
											<span>Subir a la primera posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-caret-up mr-2"></i>
											<span>Subir 1 posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-caret-down mr-2"></i>
											<span>Bajar 1 posición</span>
										</b-dropdown-item>
										<b-dropdown-item>
											<i class="fas fa-down-to-line mr-2"></i>
											<span>Bajar a la última posición</span>
										</b-dropdown-item>
									</b-dropdown>
								</b-col>
								<b-col sm="2">
									{{ item.type }}
								</b-col>
								<b-col>
									{{ item.owner }}
								</b-col>
								<b-col sm="2" class="text-center">
									{{ item.documents }}
								</b-col>
								<b-col sm="2" class="text-center">
									{{ item.progress }}%
								</b-col>
							</b-row>
						</b-card>

						<b-alert show variant="danger" class="py-3 mt-2 mb-0">
							<p class="mb-0 text-center text-danger">Quedan 156 documentos adicionales en esta cola.</p>
						</b-alert>

					</b-card>
				</b-col>
				<!-- /PROTECCION INDUSTRIAL -->


			</b-row>

		</b-card>
		<!-- /CONTENT -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'

export default {
	name: 'Queues',
	components: {
		Unauthorized,
	},
    data(){
        return {
			abastecimiento: [
				{
					position: 1,
					type: 'Empresa',
					owner: 'CIPTEMIN LTDA',
					documents: 10,
					progress: 80,
				},
				{
					position: 2,
					type: 'Empresa',
					owner: 'ENTEL S.A.',
					documents: 10,
					progress: 60,
				},
				{
					position: 3,
					type: 'Empresa',
					owner: 'AUTOMAT CONTROL LTDA',
					documents: 10,
					progress: 50,
				},
				{
					position: 4,
					type: 'Empresa',
					owner: 'AUTEM INGENIEROS',
					documents: 10,
					progress: 60,
				},
				{
					position: 5,
					type: 'Empresa',
					owner: 'ATLAS COPCO CHILE SPA',
					documents: 10,
					progress: 20,
				},
				{
					position: 6,
					type: 'Empresa',
					owner: 'KOMATSU',
					documents: 10,
					progress: 0,
				},
				{
					position: 7,
					type: 'Empresa',
					owner: 'LAVEFULL',
					documents: 10,
					progress: 0,
				},
				{
					position: 8,
					type: 'Empresa',
					owner: 'MAUROTEC SPA',
					documents: 10,
					progress: 0,
				},
			],
			prevencion: [
				{
					position: 1,
					type: 'Servicio',
					owner: 'Orden de Servicio N°2412',
					documents: 7,
					progress: 32,
				},
				{
					position: 2,
					type: 'Servicio',
					owner: 'Orden de Servicio N°2411',
					documents: 7,
					progress: 71,
				},
				{
					position: 3,
					type: 'Empresa',
					owner: 'CIPTEMIN LTDA',
					documents: 9,
					progress: 88,
				},
				{
					position: 4,
					type: 'Trabajador',
					owner: 'DIEGO GUZMÁN SANTIBAÑEZ',
					documents: 9,
					progress: 33,
				},
				{
					position: 5,
					type: 'Trabajador',
					owner: 'FERMIN RIVERA VALENZUELA',
					documents: 9,
					progress: 0,
				},
				{
					position: 6,
					type: 'Trabajador',
					owner: 'JUAN PABLO OYARZUN MOYA',
					documents: 9,
					progress: 0,
				},
				{
					position: 7,
					type: 'Servicio',
					owner: 'Contrato Marco Entel',
					documents: 9,
					progress: 0,
				},
				{
					position: 8,
					type: 'Servicio',
					owner: 'Convenio Ciptemin',
					documents: 9,
					progress: 0,
				},
				{
					position: 9,
					type: 'Servicio',
					owner: 'Garantía CC102021',
					documents: 3,
					progress: 0,
				},
				{
					position: 10,
					type: 'Trabajador',
					owner: 'JUSTINO RODOLFO CAYO LOVERA',
					documents: 9,
					progress: 0,
				},
				{
					position: 11,
					type: 'Trabajador',
					owner: 'LUIS EDUARDO ARANEDA ROJAS',
					documents: 9,
					progress: 0,
				},
				{
					position: 12,
					type: 'Empresa',
					owner: 'ENTEL S.A.',
					documents: 7,
					progress: 0,
				},
				{
					position: 13,
					type: 'Empresa',
					owner: 'AUTOMAT CONTROL LTDA',
					documents: 9,
					progress: 0,
				},
				{
					position: 14,
					type: 'Empresa',
					owner: 'KOMATSU',
					documents: 9,
					progress: 0,
				},
				{
					position: 15,
					type: 'Empresa',
					owner: 'KOMATSU',
					documents: 9,
					progress: 0,
				},
			],
			proteccion: [
				{
					position: 1,
					type: 'Vehículo',
					owner: ' MINIBUS FT1311',
					documents: 6,
					progress: 88,
				},
				{
					position: 2,
					type: 'Conductor',
					owner: 'LESLY CORTES MORALES',
					documents: 6,
					progress: 88,
				},
				{
					position: 3,
					type: 'Vehículo',
					owner: 'CAMIONETA HA20PX',
					documents: 6,
					progress: 78,
				},
				{
					position: 4,
					type: 'Vehículo',
					owner: 'CAMIONETA BKH684',
					documents: 6,
					progress: 66,
				},
				{
					position: 5,
					type: 'Vehículo',
					owner: 'CAMIÓN GRÚA DHRC38',
					documents: 6,
					progress: 0,
				},
				{
					position: 6,
					type: 'Conductor',
					owner: 'JORGE ALEX PEÑA ARENAS',
					documents: 6,
					progress: 0,
				},
				{
					position: 7,
					type: 'Vehículo',
					owner: 'CAMIÓN ZJ7420',
					documents: 6,
					progress: 33,
				},
				{
					position: 8,
					type: 'Conductor',
					owner: 'BRAYAN ALEXIS ARANGO PERLAZA',
					documents: 6,
					progress: 0,
				},
				{
					position: 9,
					type: 'Vehículo',
					owner: 'CAMIÓN RKTF97',
					documents: 6,
					progress: 0,
				},
				{
					position: 10,
					type: 'Conductor',
					owner: 'LUIS GABRIEL JARA ZÚÑIGA',
					documents: 6,
					progress: 0,
				},
				{
					position: 11,
					type: 'Vehículo',
					owner: 'MAQUINARIA PESADA HSKJ28',
					documents: 6,
					progress: 0,
				},
				{
					position: 12,
					type: 'Conductor',
					owner: 'DANIEL MOISES GONZALEZ ALVIAL',
					documents: 6,
					progress: 0,
				},
				{
					position: 13,
					type: 'Vehículo',
					owner: 'CAMIONETA JDKC42',
					documents: 6,
					progress: 0,
				},
				{
					position: 14,
					type: 'Conductor',
					owner: 'JUAN ANTONIO ROJAS ANGEL',
					documents: 6,
					progress: 0,
				},
				{
					position: 15,
					type: 'Conductor',
					owner: 'JUAN PABLO EPUYAO MENA',
					documents: 6,
					progress: 0,
				},
			]
        }
    },
	computed: {
		getters() {
			return this.$store.getters
		},
	},
    created() {
		//this.load()
    },
    methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        refresh() {
            this.$store.dispatch('get_users')
        },
		load() {
			this.$store.dispatch('get_users', this.query)
		},
		type(type) {
			this.query.type = type
			this.load()
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
        shortName(name) {
            return name ? name.length > 40 ? name.substring(0,35) + '...' : name : ''
        },
		view() {
			this.$bvModal.show('queue-view')
		}
    }
}
</script>