<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="service-workers"
			title="Nómina de Personal"
			size="lg"
			body-class="p-3"
		>
			<!-- DATA -->

			<!-- SERVICE DETAILS -->
			<b-list-group v-if="owner.item">
				<b-list-group-item>
					<b-row>
						<b-col sm="2">
							<strong>Servicio</strong>
						</b-col>
						<b-col class="text-uppercase">
							{{owner.item.comments}}
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="2">
							<strong>Empresa</strong>
						</b-col>
						<b-col>
							{{owner.item.company.full_name}}
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="2">
							<strong>
								<span v-if="owner.item.administrator.person">Administrador</span>
								<span v-else>Mandante</span>
							</strong>
						</b-col>
						<b-col>
							<span v-if="owner.item.administrator.person">
								{{owner.item.administrator.person.names}} {{owner.item.administrator.person.primary_last_name}} {{owner.item.administrator.person.second_last_name}}
							</span>
							<span v-else>{{owner.item.administrator.full_name}}</span>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="2">
							<strong>Vigencia</strong>
						</b-col>
						<b-col>
							<span><span class="text-muted">Desde</span> {{owner.item.start_date}}</span>
							<span class="mx-2 text-muted">&ndash;</span>
							<span v-if="!owner.item.extensions.length"><span class="text-muted">Hasta </span>{{owner.item.end_date}}</span>
							<span v-else><span class="text-muted">Hasta </span>{{owner.item.extensions[owner.item.extensions.length-1].end_date}}</span>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="2">
							<strong>Turnos</strong>
						</b-col>
						<b-col>
							<b-badge variant="success" v-for="(shift, index) in owner.item.shifts" :key="index" class="badge-lg mb-1 mr-1">{{shift.name}}</b-badge>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="2">
							<strong>Dotación</strong>
						</b-col>
						<b-col>
							{{owner.item.workers_quantity}} trabajadores máximo
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
			<!-- /SERVICE DETAILS -->

			<div v-if="getters.prv_service_orders_workers_create || getters.prv_contracts_workers_create || getters.prv_warranties_workers_create || getters.prv_subcontracts_workers_create">
				<br>
				<!-- WORKER LINKING -->
				<b-card v-if="owner.item" body-class="p-0">
					<b-card-header><strong>Vincular Trabajadores</strong></b-card-header>
						
					<!-- FORM -->
					<b-overlay :show="loading" rounded="sm">
						
						<!-- UPLOAD PROGRESS -->
						<template #overlay>
							<div class="text-center" style="width: 300px">
								<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
								<b-progress :max="progress.total" height="5px" animated>
									<b-progress-bar :value="progress.current"></b-progress-bar>
								</b-progress>
							</div>
						</template>
						<!-- /UPLOAD PROGRESS -->

						<b-card-body class="p-3 pb-1">
							<b-row>
								<b-col sm="6" v-if="owner.item.type == 4">
									<b-form-group label="Seleccione Trabajador">
										<b-overlay :show="subcontractor_workers.loading" class="p-0">
											<template #overlay>
												<div class="d-flex align-items-center">
												<b-spinner type="grow" variant="secondary" small></b-spinner>
												</div>
											</template>
											<b-form-select v-model="service_worker.worker_id" :state="val_worker" :disabled="!subcontractor_workers.data.length">
												<template v-slot:first>
													<b-form-select-option :value="null" disabled>Seleccione Turno...</b-form-select-option>
												</template>
												<b-form-select-option v-for="(item, index) in subcontractor_workers.data" :key="index" :value="item.worker.id" class="text-uppercase">{{item.primary_last_name}} {{item.second_last_name}} {{item.names}}</b-form-select-option>
											</b-form-select>
										</b-overlay>
										<b-form-invalid-feedback :state="val_worker">Requerido</b-form-invalid-feedback>
										<b-form-valid-feedback :state="val_worker">OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col sm="6" v-else>
									<b-form-group label="Seleccione Trabajador">
										<b-overlay :show="contractor_workers.loading" class="p-0">
											<template #overlay>
												<div class="d-flex align-items-center">
												<b-spinner type="grow" variant="secondary" small></b-spinner>
												</div>
											</template>
											<b-form-select v-model="service_worker.worker_id" :state="val_worker" :disabled="!contractor_workers.data.length">
												<template v-slot:first>
													<b-form-select-option :value="null" disabled>Seleccione Trabajador...</b-form-select-option>
												</template>
												<b-form-select-option v-for="(item, index) in contractor_workers.data" :key="index" :value="item.worker.id" class="text-uppercase">{{item.primary_last_name}} {{item.second_last_name}} {{item.names}}</b-form-select-option>
											</b-form-select>
										</b-overlay>
										<b-form-invalid-feedback :state="val_worker">Requerido</b-form-invalid-feedback>
										<b-form-valid-feedback :state="val_worker">OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col sm="6">
									<b-form-group label="Seleccione Anexo de Contrato">
										<b-form-file v-model="service_worker.file" :state="val_file" placeholder="Presione aquí para seleccionar un archivo"
										>
										</b-form-file>
										<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
										<b-form-valid-feedback>OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col sm="6">
									<b-form-group label="Seleccione Turno">
										<b-form-select v-model="service_worker.shift_id" :state="val_shift">
											<template v-slot:first>
												<b-form-select-option :value="null" disabled>Seleccione Turno...</b-form-select-option>
											</template>
											<b-form-select-option v-for="(item, index) in owner.item.shifts" :key="index" :value="item.id">{{item.name}}</b-form-select-option>
										</b-form-select>
										<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
										<b-form-valid-feedback>OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col sm="6">
									<b-form-group label="Presione para Vincular">
										<b-button variant="primary" block :disabled="!val_full" @click="create()">VINCULAR</b-button>
									</b-form-group>
								</b-col>
							</b-row>

							<!-- MESSAGE -->
							<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
								<span v-if="val_full">¡Todo bien! Para vincular al trabajador, presione el botón VINCULAR.</span>
								<span v-else-if="!contractor_workers.loading && !subcontractor_workers.loading && !contractor_workers.data.length && !subcontractor_workers.data.length">
									La empresa no registra trabajadores en el sistema.
								</span>
								<span v-else>Por favor, complete todos los campos requeridos.</span>
							</b-alert>
							<!-- /MESSAGE -->

						</b-card-body>

					</b-overlay>
					<!-- /FORM -->

				</b-card>
				<!-- /WORKER LINKING -->
			</div>

			<br>
			
			<!-- WORKER LINKING -->
			<b-card v-if="owner.item" body-class="p-0">
				<b-card-header><strong>Trabajadores en Nómina</strong></b-card-header>
				
				
				<b-skeleton-wrapper :loading="service_workers.loading">

					<!-- LOADING -->
					<template #loading>
						<b-skeleton-table
							animation="fade"
							:table-props="{ small: true }"
							:rows="5"
							:columns="4"
						></b-skeleton-table>
					</template>
					<!-- /LOADING -->

					<b-table
						:items="service_workers.data"
						:fields="fields"
						hover
						small
						show-empty
					>
						<template v-slot:empty>
							<div class="text-center py-4">
								<span class="text-transform-none text-size-13 text-muted">No se han vinculado trabajadores a este servicio.</span>
							</div>
						</template>
						<template v-slot:cell(person.primary_last_name)="data">
							<div>
								<i class="far fa-clock mx-2 text-secondary" v-if="data.item.status == 1"></i>
								<i class="fas fa-check mx-2 text-success" v-else-if="data.item.status == 2"></i>
								<span>{{data.item.person.primary_last_name.toUpperCase()}} {{data.item.person.second_last_name.toUpperCase()}} {{data.item.person.names.toUpperCase()}}</span>
							</div>
						</template>
						<template v-slot:cell(person.identifier)="data">
							<span class="nowrap">{{parseIdentifier(data.value)}}</span>
						</template>
						<template v-slot:cell(shift.name)="data">
							<div class="text-center">
								<span>{{data.value}}</span>
							</div>
						</template>
						<template v-slot:cell(file)="data">
							<b-button size="sm" variant="outline-secondary" @click="file(data)">VER PDF</b-button>
						</template>
						<template v-slot:cell(actions)="data">
							<div class="text-center" v-if="current_task == data.index">
								<b-skeleton variant="primary" width="100%"></b-skeleton>
							</div>
							<div class="text-center" v-else>
								<div v-if="getters.prv_service_orders_workers_review || getters.prv_contracts_workers_review || getters.prv_warranties_workers_review || getters.prv_subcontracts_workers_review">
									<b-button variant="outline-success" size="sm" v-if="data.item.status == 1" @click="approve(data)">
										<i class="fas fa-check mr-1"></i>
										<span>APROBAR</span>
									</b-button>
									<b-button variant="outline-danger" size="sm" v-else-if="data.item.status == 2" @click="disapprove(data)">
										<i class="fas fa-times mr-1"></i>
										<span>DESAPROBAR</span>
									</b-button>
								</div>
								<b-button variant="outline-danger" size="sm" @click="unlink(data)" v-if="getters.prv_service_orders_workers_create || getters.prv_contracts_workers_create || getters.prv_warranties_workers_create || getters.prv_subcontracts_workers_create">
									<i class="fas fa-times mr-1"></i>
									<span>LIBERAR</span>
								</b-button>
							</div>
						</template>
					</b-table>
				</b-skeleton-wrapper>

			</b-card>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">Cerrar</b-button>
			</template>

			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

		<!-- DOCUMENT VIEWER -->
		<b-modal id="service-worker-document" title="Anexo de Contrato" size="lg" body-class="p-4">
			<b-card no-body class="mb-3">
				<iframe :src="api_root + url" class="document-viewer"></iframe>
			</b-card>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
		<!-- /DOCUMENT VIEWER -->

	</div>
</template>

<script>
export default {
	name: 'ServiceWorkers',
	components: {
	},
	data() {
		return {
			loading: false,
			current_task: null,
			url: '',
			service_worker: {
				worker_id: null,
				file: null,
				shift_id: null
			},
			fields: [
				{ key: 'person.primary_last_name', label: 'Nombre', sortable: true },
				{ key: 'person.identifier', label: 'RUT', sortable: true },
				{ key: 'shift.name', label: 'Turno', sortable: true },
				{ key: 'file', label: 'Anexo' },
				{ key: 'actions', label: 'Acciones' }
			],
			progress: {
				current: 0,
				total: 0
			}
		}
	},
	props: {
		owner: Object
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		val_full() {
			return this.val_worker && this.val_file && this.val_shift ? true : false
		},
		val_worker() {
			return this.service_worker.worker_id != null ? true : false
		},
		val_file() {
			return this.service_worker.file ? true : false
		},
		val_shift() {
			return this.service_worker.shift_id != null ? true : false
		},
		subcontractor_workers() {
			return this.$store.state.subcontractor_workers
		},
		contractor_workers() {
			return this.$store.state.contractor_workers
		},
		service_workers() {
			return this.$store.state.service_workers
		},
		api_root() {
			return this.$store.state.api_root
			//return 'https://api.sca.michilla.cl'
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'service-workers') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.$store.commit('reset_contractor_workers')
			this.$store.commit('reset_subcontractor_workers')
			this.service_worker = {
				worker_id: null,
				file: null,
				shift_id: null
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		clearTask() {
			this.current_task = null
		},
		runTask(index) {
			this.current_task = index
		},
		file(data) {
			this.url = data.item.file
			this.$bvModal.show('service-worker-document')
		},
		create() {
			this.loading = true
			var self = this
			this.progress = { total: 0, current: 0 }
			const config = {
				onUploadProgress: function(progressEvent) {
					self.progress.total = progressEvent.total
					self.progress.current = progressEvent.loaded
				},
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			let data = new FormData();
			data.append('file', this.service_worker.file)
			data.append('worker_id', this.service_worker.worker_id)
			data.append('shift_id', this.service_worker.shift_id)
			data.append('contract_id', this.owner.item.id)
			this.$api.post('services/'+this.owner.item.id+'/workers', data, config)
			.then(() => {
				this.loading = false
				this.service_worker = {
					worker_id: null,
					file: null,
					shift_id: null
				}
				this.$store.dispatch('get_service_workers', this.owner.item.id)
				this.toast(true, 'El trabajador fue correctamente vinculado y se encuentra en la tabla "Trabajadores en Nómina". Cuando su vinculación sea aprobada, verá una insignia verde al lado de su nombre.', 10000)
			})
			.catch(error => {
				this.loading = false
				if (error.response.status == 409) {
					this.service_worker = {
						worker_id: null,
						file: null,
						shift_id: null
					}
				}
				this.toast(false, error.response.data.message, 10000)
			})
		},
		approve(data) {
			this.$bvModal.msgBoxConfirm('¿Desea aprobar a "'+data.item.person.names+' '+data.item.person.primary_last_name+' '+data.item.person.second_last_name+'" en la Nómina de Personal?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'success',
				okTitle: 'Sí, Aprobar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(data.index)
					this.$api.put('services/'+data.item.contract_id+'/workers/'+data.item.worker_id, {
						action: 'approve'
					})
					.then(() => {
						this.clearTask()
						const index = this.service_workers.data.findIndex(worker => worker.id === data.item.id)
						this.service_workers.data[index].status = 2
						this.toast(true, 'Se aprobó a "'+data.item.person.names+' '+data.item.person.primary_last_name+' '+data.item.person.second_last_name+'" en la Nómina de Personal.', 10000)
					})
					.catch(error => {
						this.clearTask()
						this.toast(false, error.response.data.message, 5000)
					})
				}
			})
		},
		disapprove(data) {
			this.$bvModal.msgBoxConfirm('¿Desea desaprobar a "'+data.item.person.names+' '+data.item.person.primary_last_name+' '+data.item.person.second_last_name+'" de la Nómina de Personal?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Desaprobar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(data.index)
					this.$api.put('services/'+data.item.contract_id+'/workers/'+data.item.worker_id, {
						action: 'disapprove'
					})
					.then(() => {
						this.clearTask()
						const index = this.service_workers.data.findIndex(worker => worker.id === data.item.id)
						this.service_workers.data[index].status = 1
						this.toast(true, 'Se desaprobó a "'+data.item.person.names+' '+data.item.person.primary_last_name+' '+data.item.person.second_last_name+'" de la Nómina de Personal.', 10000)
					})
					.catch(error => {
						this.clearTask()
						this.toast(false, error.response.data.message, 5000)
					})
				}
			})
		},
		unlink(data) {
			this.$bvModal.msgBoxConfirm('¿Desea quitar a "'+data.item.person.names+' '+data.item.person.primary_last_name+' '+data.item.person.second_last_name+'" de la Nómina de Personal?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Quitar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(data.index)
					this.$api.delete('services/'+data.item.contract_id+'/workers/'+data.item.worker_id)
					.then(() => {
						this.clearTask()
						const index = this.service_workers.data.findIndex(worker => worker.id === data.item.id)
						this.service_workers.data.splice(index, 1)
						this.toast(true, 'Se quitó a "'+data.item.person.names+' '+data.item.person.primary_last_name+' '+data.item.person.second_last_name+'" de la Nómina de Personal.', 10000)
					})
					.catch(error => {
						this.clearTask()
						this.toast(false, error.response.data.message, 5000)
					})
				}
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
	}
}
</script>

<style scoped>
.document-viewer {
	width: 100%;
	height: 400px;
	border: 0px;
}
</style>
