<template>
	<div>
		<b-row>
			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('Reports')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Reportes">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->
		</b-row>

		<b-row>
			<b-col>
				<b-card class="mb-3" no-body>
					<b-card-header>
						<strong class="text-uppercase">Consultar Trabajadores en Faena</strong>
					</b-card-header>
					<b-card-body class="pb-2 pt-3">
						<b-row>
							<b-col>
								<b-form-group label="Fecha:">
									<b-datepicker v-model="date"></b-datepicker>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="Hora de Corte:">
									<b-input v-model="time" type="time" class="text-center pr-1"></b-input>
								</b-form-group>
							</b-col>
						</b-row>
						<b-form-group label="Empresa:">
							<b-row>
								<b-col>
									<b-form-select v-model="group" :options="companies" :disabled="companies_ready"></b-form-select>
								</b-col>
								<b-col>
									<b-button block variant="outline-danger" @click="getData()">Cargar</b-button>
								</b-col>
							</b-row>
						</b-form-group>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col>
				<b-card no-body v-if="loading || data.summary">
					<b-card-header>
						<strong class="text-uppercase">Resumen de Consulta</strong>
					</b-card-header>
					<b-card-body v-if="loading" class="pb-2 pt-3">
						<p class="text-center">
							<b-spinner class="mt-2" style="font-size:1em;width:100px;height:100px"></b-spinner>
						</p>
					</b-card-body>
					<table class="table table-bordered text-center" v-else-if="data.summary && !loading">
						<thead>
							<tr>
								<th class="text-left">
									<span>Total</span>
									<b-badge variant="dark" class="float-right">{{data.summary.mandante.total+data.summary.contratista.total}}</b-badge>
								</th>
								<th class="text-left">
									<span>Mandante</span>
									<b-badge variant="dark" class="float-right">{{data.summary.mandante.total}}</b-badge>
								</th>
								<th class="text-left">
									<span>Contratista</span>
									<b-badge variant="dark" class="float-right">{{data.summary.contratista.total}}</b-badge>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr class="text-danger">
								<th class="text-left">
									<span>En Faena</span>
									<b-badge variant="danger" class="float-right">{{data.summary.mandante.active+data.summary.contratista.active}}</b-badge>
								</th>
								<td v-if="data.summary.mandante.active">
									<strong>{{data.summary.mandante.active}}</strong>
								</td>
								<td v-else>-</td>
								<td v-if="data.summary.contratista.active">{{data.summary.contratista.active}}</td>
								<td v-else>-</td>
							</tr>
							<tr class="text-muted">
								<th class="text-left">
									<span>Inactivos</span>
									<b-badge variant="secondary" class="float-right">{{data.summary.mandante.inactive+data.summary.contratista.inactive}}</b-badge>
								</th>
								<td v-if="data.summary.mandante.inactive">{{data.summary.mandante.inactive}}</td>
								<td v-else>-</td>
								<td v-if="data.summary.contratista.inactive">{{data.summary.contratista.inactive}}</td>
								<td v-else>-</td>
							</tr>
							<tr>
								<td colspan="3" style="font-size:0.75em" class="text-muted text-center">
									<span>Fecha y Hora de Corte: {{data.meta.timestamp}}</span>
								</td>
							</tr>
						</tbody>
					</table>
				</b-card>
			</b-col>
		</b-row>
		<b-card no-body v-if="data.workers && data.workers.length && !loading" class="mt-3">
			<b-card-header>
				<strong class="text-uppercase">Detalle de Consulta</strong>
			</b-card-header>
			<table class="table">
				<tr>
					<th>Empresa</th>
					<th>RUT</th>
					<th>Nombre</th>
					<th class="text-center">En Faena</th>
					<th class="text-center">Último Control</th>
					<th class="text-center">Turno</th>
				</tr>
				<tr v-for="worker, index in data.workers" :key="index">
					<td>{{worker.company_name}}</td>
					<td>{{formatIdentifier(worker.identifier)}}</td>
					<td>{{worker.primary_last_name}} {{worker.second_last_name}} {{worker.names}}</td>
					<td class="text-center">
						<i class="fas fa-circle text-danger" v-if="worker.active" title="Activo en Faena"></i>
						<i class="far fa-circle text-secondary" v-else title="No activo en Faena"></i>
					</td>
					<td class="text-center">
						<span v-if="worker.active && worker.latest_in" title="Entrada">{{worker.latest_in}}</span>
						<span v-else-if="!worker.active && worker.latest_out" title="Salida">{{worker.latest_out}}</span>
						<span v-else class="text-muted">-</span>
					</td>
					<td class="text-center">
						<span v-if="worker.shift_name">{{worker.shift_name}}</span>
						<span v-else class="text-muted">-</span>
					</td>
				</tr>
			</table>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'OnSite',
	components: {
	},
	data() {
		return {
            data: {},
            summary: {},
            date: {},
            time: '',
            group: 'internal',
            loading: false,
            companies_ready: true,
            companies: [],
            options: {
                singleDatePicker: true,
                "locale": {
                    "separator": " - ",
                    "applyLabel": "Aplicar",
                    "cancelLabel": "Cancelar",
                    "fromLabel": "Desde",
                    "toLabel": "Hasta",
                    "customRangeLabel": "Custom",
                    "weekLabel": "W",
                    "daysOfWeek": [
                        "Do",
                        "Lu",
                        "Ma",
                        "Mi",
                        "Ju",
                        "Vi",
                        "Sa"
                    ],
                    "monthNames": [
                        "Enero",
                        "Febrero",
                        "Marzo",
                        "Abril",
                        "Mayo",
                        "Junio",
                        "Julio",
                        "Augosto",
                        "Septiembre",
                        "Octubre",
                        "Noviembre",
                        "Diciembre"
                    ],
                    "firstDay": 1
                }
            }
		}
	},
	created() {
        this.setDefaults();
        this.getCompanies();
        this.getData();
		this.time = this.current_time
	},
	computed: {
        current_time: function() {
            var now = new Date()
            var date = [ this.zeroPad(now.getHours()), this.zeroPad(now.getMinutes()), this.zeroPad(now.getSeconds()) ]
            return date.join(':')
        },
		/*
        time_std: function() {
            if (this.time == '') {
				this.time = this.current_time
				return this.current_time
			} else {
				return this.time
			}
        },
		*/
        time_std: function() {
            return this.current_time
        },
        date_std: function() {
            return this.date.split('/').reverse().join('-')
        }
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        zeroPad(d) {
                return ("0" + d).slice(-2)
        },
        setDefaults()
        {
            var date = new Date()
            this.today = [date.getUTCFullYear(),
            this.zeroPad(date.getMonth() + 1),
            this.zeroPad(date.getDate())].join("-")
            this.date = this.today
            this.month = date.getMonth()
            this.year = date.getUTCFullYear()
        },
        getData()
        {
            this.loading = true
            const self = this
            this.$api.get('temporary/reports/active',
            {
                params:
                {
                    date: this.date_std,
                    time: this.time_std,
                    group: this.group
                }
            })
            .then(res=>
            {
                self.data = res.data
                this.loading = false
            })
        },
        getCompanies()
        {
            this.$api.get('temporary/reports/active/companies')
            .then(res=>
            {
                this.companies = res.data
                this.companies_ready = false
            })
        },
        formatIdentifier(rut) {
            var actual = rut.replace(/^0+/, "");
            if (actual != '' && actual.length > 1) {
                var sinPuntos = actual.replace(/\./g, "");
                var actualLimpio = sinPuntos.replace(/-/g, "");
                var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
                var rutPuntos = "";
                var i = 0;
                var j = 1;
                for (i = inicio.length - 1; i >= 0; i--) {
                    var letra = inicio.charAt(i);
                    rutPuntos = letra + rutPuntos;
                    if (j % 3 == 0 && j <= inicio.length - 1) {
                        rutPuntos = "." + rutPuntos;
                    }
                    j++;
                }
                var dv = actualLimpio.substring(actualLimpio.length - 1);
                rutPuntos = rutPuntos + "-" + dv;
            }
            return rutPuntos;
        }
	}
}
</script>

<style scoped>
.badge {
	cursor: text !important;
}
.label-fix label {
	padding-top: 13px;
}
</style>
