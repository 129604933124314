<template>
	<Unauthorized v-if="false"></Unauthorized>
	<div v-else>

		<!-- CONTENT -->
		<b-card>

			<b-row class="mb-4">
				<b-col sm="auto">
					<b-button size="lg" variant="danger">
						<span class="text-size-15">93,16%</span>
					</b-button>
				</b-col>
				<b-col>
					<p class="mb-0 text-uppercase text-danger">
						<strong>Minera HMC S.A.</strong>
					</p>
					<p class="mb-0 text-size-2 text-danger">Acreditación Global</p>
				</b-col>
			</b-row>

			<div v-for="(item, index) in data" :key="index">
				<b-card no-body class="py-2 px-2 mb-2">
					<b-row no-gutters>
						<b-col sm="1" class="pr-3">
							<b-button block :variant="level && index == 2 ? 'danger' : item.req_done == 17 ? 'success' : 'danger'" class="px-0 py-1" @click="toggle()">
								<span>{{ item.req_done == 17 ? '100' : calc(17, item.req_done) }}%</span>
							</b-button>
						</b-col>
						<b-col>
							<p class="text-uppercase text-size-12 mt-1 mb-0">{{ item.full_name }}</p>
						</b-col>
					</b-row>

					<b-collapse :visible="level && index == 2">
						<b-row no-gutters class="level1 middle">
							<b-col sm="1" class="pr-3">
								<b-button block variant="success" class="px-0 py-0">
									<span>100%</span>
								</b-button>
							</b-col>
							<b-col>
								<p class="mb-0 text-size-11">Acreditación de Empresa</p>
							</b-col>
						</b-row>
						<b-row no-gutters class="leveln" v-bind:class="{
							'one' : level && !level2 && !level3,
							'two' : level && level2 && !level3,
							'three' : level && level2 && level3,
						}">
							<b-col sm="1" class="pr-3">
								<b-button block :variant="level2 && index == 2 ? 'danger' : 'danger'" class="px-0 py-0" @click="toggle2()">
									<span>82%</span>
								</b-button>
							</b-col>
							<b-col>
								<p class="mb-0 text-size-11">Orden de Servicio N°2561</p>
							</b-col>
						</b-row>
						
						
						<b-collapse :visible="level2 && index == 2">
							<b-row no-gutters class="level2 middle">
								<b-col sm="1" class="pr-3">
									<b-button block variant="success" class="px-0 py-0">
										<span>100%</span>
									</b-button>
								</b-col>
								<b-col>
									<p class="mb-0 text-size-11">Acreditación de Servicio</p>
								</b-col>
							</b-row>
							<b-row no-gutters class="level2 last">
								<b-col sm="1" class="pr-3">
									<b-button block variant="danger" class="px-0 py-0" @click="toggle3()">
										<span>76%</span>
									</b-button>
								</b-col>
								<b-col>
									<p class="mb-0 text-size-11">Acreditación de Trabajadores</p>
								</b-col>
							</b-row>
							<b-collapse :visible="level3 && index == 2">
								<b-row no-gutters class="level3 middle">
									<b-col sm="1" class="pr-3">
										<b-button block variant="danger" class="px-0 py-0">
											<span>0%</span>
										</b-button>
									</b-col>
									<b-col>
										<p class="mb-0 text-size-11">Eduardo Andrés Olmos Olivares</p>
									</b-col>
								</b-row>
								<b-row no-gutters class="level3 last">
									<b-col sm="1" class="pr-3">
										<b-button block variant="success" class="px-0 py-0">
											<span>100%</span>
										</b-button>
									</b-col>
									<b-col>
										<p class="mb-0 text-size-11">Felipe Andrés Solís Albanese</p>
									</b-col>
								</b-row>
							</b-collapse>
						</b-collapse>

						<b-row no-gutters class="level1 middle">
							<b-col sm="1" class="pr-3">
								<b-button block variant="success" class="px-0 py-0">
									<span>100%</span>
								</b-button>
							</b-col>
							<b-col>
								<p class="mb-0 text-size-11">Orden de Servicio N°2446</p>
							</b-col>
						</b-row>
						<b-row no-gutters class="level1 last">
							<b-col sm="1" class="pr-3">
								<b-button block variant="success" class="px-0 py-0">
									<span>100%</span>
								</b-button>
							</b-col>
							<b-col>
								<p class="mb-0 text-size-11">Acreditación de Vehículos</p>
							</b-col>
						</b-row>
					</b-collapse>
				</b-card>
			</div>

		</b-card>
		<!-- /CONTENT -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'

export default {
	name: 'Accreditation',
	components: {
		Unauthorized,
	},
    data(){
        return {
			data: [],
			level: false,
			level2: false,
			level3: false,
        }
    },
	computed: {
		getters() {
			return this.$store.getters
		},
	},
    created() {
		this.load()
    },
    methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        refresh() {
            this.$store.dispatch('get_users')
        },
		load() {
			this.$api.get('dashboard')
			.then(response => {
				this.data = response.data
			})
			//this.$store.dispatch('get_users', this.query)
		},
		type(type) {
			this.query.type = type
			this.load()
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
        shortName(name) {
            return name ? name.length > 40 ? name.substring(0,35) + '...' : name : ''
        },
		toggle() {
			this.level = !this.level
		},
		toggle2() {
			this.level2 = !this.level2
		},
		toggle3() {
			this.level3 = !this.level3
		},
		calc(total, done) {
			if (total && done) {
				return Math.trunc(done / total * 100);
			} else {
				return 0
			}
		}
    }
}
</script>

<style>
.level1.middle, .level1.last, .leveln {
	padding-top: 10px;
	padding-left: 40px;
	transition: 0.15s;
}
.leveln.one::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 40px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.leveln.two::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 100px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.leveln.three::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 170px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.leveln::before {
	position: absolute;
	display: block;
	width: 20px;
	height: 2px;
	margin-left: -10px;
	margin-top: 10px;
	background: #999;
	content: ''
}
.level1.middle::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 40px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.level1.last::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 20px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.level1::before {
	position: absolute;
	display: block;
	width: 20px;
	height: 2px;
	margin-left: -10px;
	margin-top: 10px;
	background: #999;
	content: ''
}

.level2.middle, .level2.last {
	padding-top: 10px;
	padding-left: 80px;
	transition: 0.15s;
}
.level2.middle::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 40px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.level2.last::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 20px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.level2::before {
	position: absolute;
	display: block;
	width: 20px;
	height: 2px;
	margin-left: -10px;
	margin-top: 10px;
	background: #999;
	content: ''
}

.level3.middle, .level3.last {
	padding-top: 10px;
	padding-left: 120px;
	transition: 0.15s;
}
.level3.middle::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 40px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.level3.last::after {
	position: absolute;
	display: block;
	width: 2px;
	height: 20px;
	margin-left: -10px;
	margin-top: -10px;
	background: #999;
	content: ''
}
.level3::before {
	position: absolute;
	display: block;
	width: 20px;
	height: 2px;
	margin-left: -10px;
	margin-top: 10px;
	background: #999;
	content: ''
}
</style>