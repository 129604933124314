<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="manage-user"
			title="Administrar Privilegios"
			size="lg"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="privileges.loading || updating" rounded="sm" class="p-3">

				<b-card class="text-size-11">
					<b-row v-if="privileges.data.user.owner">
						<b-col sm="auto">
							<p class="mb-0">
								<strong>RUT</strong>
							</p>
							<p class="mb-0">
								<span>{{parseIdentifier(privileges.data.user.rut)}}</span>
							</p>
						</b-col>
						<b-col>
							<p class="mb-0">
								<strong>Nombre</strong>
							</p>
							<p class="mb-0 text-uppercase">
								<span v-if="privileges.data.user.owner.person">{{privileges.data.user.owner.person.names}} {{privileges.data.user.owner.person.primary_last_name}} {{privileges.data.user.owner.person.second_last_name}}</span>
								<span v-else>{{privileges.data.user.owner.full_name}}</span>
							</p>
						</b-col>
						<b-col sm="auto" v-if="privileges.data.user.owner.full_name">
							<span v-if="privileges.data.user.subcontracts_enabled" class="text-primary">
								<i class="fas fa-check mr-1"></i>
								<span>Subcontratos Activados</span>
							</span>
							<b-button @click="enableSubcontracts()" variant="outline-primary" v-else>
								<span>Activar Subcontratos</span>
							</b-button>
						</b-col>
					</b-row>
				</b-card>
				<b-card no-body v-if="!privileges.loading || updating" class="mt-3">
					<b-tabs pills card vertical>
						<b-tab v-for="(item, index) in privileges.data.available" :key="index" no-body>
							<template #title>
								{{item.name}}
							</template>
							<h4 class="px-3 pt-3 mb-1">{{item.description}}</h4>
							<p class="px-3 text-muted text-size-11">Seleccione los privilegios que desee modificar y presione ACTUALIZAR.</p>
							<b-table
								:items="item.privileges"
								:fields="privilege_fields"
								small
								hover
								class="mb-0"
							>
								<template v-slot:head(enabled)="data">
									<div class="text-left ml-2">
										<span>{{data.label}}</span>
									</div>
								</template>
								<template v-slot:cell(enabled)="data">
									<div class="text-left ml-2">
										<b-form-checkbox v-model="privileges.data.enabled" :value="data.item.id">
											<span :class="data.item.id < 199 ? 'text-danger':''">{{data.item.description}}</span>
										</b-form-checkbox>
									</div>
								</template>
							</b-table>
						</b-tab>
					</b-tabs>
				</b-card>


				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center py-5">
						<b-spinner type="grow" variant="primary"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="privileges.loading || updating">Cancelar</b-button>
				<b-button @click="update()" variant="success" :disabled="privileges.loading || updating">ACTUALIZAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'ManageUser',
	components: {
	},
	data() {
		return {
			updating: false,
			selected: [],
            privilege_fields: [
                { key: 'enabled', label: 'Privilegio', sortable: true }
            ],
		}
	},
	props: {
		user: Object
	},
	computed: {
		loading() {
			return false
		},
		privileges() {
			return this.$store.state.privileges
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-worker') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.$store.commit('reset_worker')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		update() {
			this.updating = true
			this.$api.put('users/'+this.privileges.data.user.id+'/privileges', { privileges: this.privileges.data.enabled })
			.then(() => {
				this.updating = false
				this.$bvModal.hide('manage-user')
				this.toast(true, 'Se actualizaron los privilegios correctamente y serán aplicados al usuario la próxima vez que ingrese al sistema.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		enableSubcontracts() {
			this.updating = true
			this.$api.get('users/'+this.privileges.data.user.id+'/subcontracts')
			.then(() => {
				this.updating = false
				this.$store.dispatch('get_privileges', this.privileges.data.user.id)
				this.toast(true, 'Se activaron las funciones de subcontratos para el usuario exitosamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
	}
}
</script>

<style scoped>
</style>
