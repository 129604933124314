<template>
	<div>
		<b-row>
			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2" sm="auto">
				<b-button variant="outline-warning" @click="goTo('Reports')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Reportes">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-overlay
					:show="loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
                    <b-form-datepicker
                        v-model="date"
                        :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
                        placeholder="Seleccionar fecha..."
                        @input="reload()"
                        min="2022-12-21"
                    ></b-form-datepicker>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
            <b-col sm="auto" class="pr-1 pl-1">
				<b-overlay
					:show="loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar controles realizados">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
            </b-col>
            <b-col sm="auto" class="pl-1" v-if="getters.prv_reports_testing_setting">
				<b-overlay
					:show="loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="setting()" v-b-tooltip.hover.bottomright title="Configurar porcentaje de sorteo">
						<i class="fas fa-cog"></i>
						<span> Configuración</span>
					</b-button>
				</b-overlay>
            </b-col>
            <b-col>
            </b-col>
			<!-- /ACTIONS -->
		</b-row>
        <b-card no-body>
            <b-table
                :items="data"
                :busy="loading"
                :fields="fields"
                responsive
                hover
                show-empty
                small
                class="m-0 p-0"
            >
                <template v-slot:empty>
                    <div class="text-center p-4">
                        <span class="text-size-15">Seleccione una fecha para ver trabajadores sorteados.</span>
                    </div>
                </template>
                <template v-slot:cell(created_at)="data">
                    <div class="text-secondary">
                        <strong>{{data.value}}</strong>
                    </div>
                </template>
                <template v-slot:cell(message)="data">
                    <div v-if="data.item.unknown">
                        RUT: {{parseIdentifier(data.item.identifier)}}
                    </div>
                    <div v-else>
                        {{data.value}}
                    </div>
                </template>
                <template v-slot:cell(name)="data">
                    <div class="text-uppercase">
                        {{data.value}}
                    </div>
                </template>
                <template v-slot:cell(company)="data">
                    <div class="text-uppercase">
                        {{data.value}}
                    </div>
                </template>
                <template v-slot:cell(enter)="data">
                    <div v-if="data.value">
                        <span :class="data.item.success && data.item.code == 2050 ? 'text-warning' : data.item.success && data.item.code != 2050 ? 'text-success':'text-danger'">
                            <span class="fas fa-arrow-right mr-1"></span>
                        </span>
                        <b-badge :variant="data.item.success && data.item.code == 2050 ? 'warning' : data.item.success && data.item.code != 2050 ? 'success':'danger'">INGRESO</b-badge>
                    </div>
                    <div v-else>
                        <span class="fas fa-arrow-left text-secondary mr-1"></span>
                        <b-badge variant="secondary">SALIDA</b-badge>
                    </div>
                </template>
            </b-table>
        </b-card>
        <b-modal
            id="random-testing-setting"
            title="Configuración"
            size="sm"
            hide-footer
        >
            <b-alert show variant="info">
                <p class="mb-0 text-justify">El número aquí configurado será el porcentaje de selección aleatoria sobre el total diario de ingresos de trabajadores acreditados.</p>
            </b-alert>
            <b-form-group>
                <b-input-group append="%" size="lg">
                    <b-input
                        v-model="random_testing_percentage"
                        :state="val_percentage"
                        :formatter="cleanPercentage"
                        :maxlength="2"
                        :disabled="random_testing_loading"
                    ></b-input>
                </b-input-group>
                <b-form-invalid-feedback :state="val_percentage" class="text-size-11">
                    <span v-if="random_testing_loading">Cargando...</span>
                    <span v-else>Ingrese un número entre 1 y 10</span>
                </b-form-invalid-feedback>
                <b-form-valid-feedback :state="val_percentage" class="text-size-11">Correcto</b-form-valid-feedback>
            </b-form-group>
            <b-button
                block
                variant="primary"
                @click="updateSetting()"
                size="lg"
                :disabled="!val_percentage || random_testing_loading"
            >
                <span>Actualizar</span>
            </b-button>
        </b-modal>
	</div>
</template>

<script>
export default {
	name: 'RandomTesting',
	components: {
	},
	data() {
		return {
            data: [],
            date: '',
            online: false,
            loading: false,
            controls: [],
            random_testing_loading: false,
            random_testing_percentage: null,
            fields: [
                {
                    key: 'id',
                    label: 'N° Control',
                    sortable: true
                },
                {
                    key: 'timestamp',
                    label: 'Fecha/Hora',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Trabajador',
                    sortable: true
                },
                {
                    key: 'company',
                    label: 'Empresa',
                    sortable: true
                },
            ]
		}
	},
	mounted() {
	},
	created() {
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		val_percentage() {
			return this.random_testing_percentage >= 1 && this.random_testing_percentage <= 10 ? true : false
		},
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
        load() {
            this.data = []
            this.loading = true
            this.$api.post('random_tests', {
                date: this.date
            })
            .then(response => {
                this.loading = false
                this.data = response.data
            })
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
        },
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
        reload() {
            console.log(this.date)
            this.load()
        },
        setting() {
            this.$bvModal.show('random-testing-setting')
            this.random_testing_loading = true
            this.random_testing_percentage = null
            this.$api.get('offline/random_setting')
            .then(response => {
                this.random_testing_percentage = response.data
                this.random_testing_loading = false
            })
			.catch(() => {
                this.random_testing_loading = false
				this.toast(false, 'Ocurrió un error interno. Por favor, contacte al Administrador.', 10000)
			})
        },
        updateSetting() {
            this.random_testing_loading = true
            this.$api.post('random_tests/setting', {
                value: parseInt(this.random_testing_percentage)
            })
            .then(() => {
                this.random_testing_loading = false
                this.$bvModal.hide('random-testing-setting')
            })
			.catch(error => {
                this.random_testing_loading = false
				this.toast(false, error.response.data.message, 10000)
			})
        },
        cleanPercentage(value) {
			return value.replace(/[^0-9]/g,'');
        }
	}
}
</script>

<style scoped>
</style>
