<template>
	<Unauthorized v-if="getters.internal || getters.session_loading"></Unauthorized>
	<div v-else>

		<!-- COMPONENTS -->
		<RequirementView :requirement="requirement"></RequirementView>
		<RequirementUpload :requirement="requirement" @run_task="runTask" @clear_task="clearTask"></RequirementUpload>
		<!-- /COMPONENTS -->
		
		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-overlay
					:show="loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de empresas">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

		</b-row>
		<!-- /TOP BAR -->

		<!-- CONTENT -->
		<b-card no-body>

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="loading">

				<!-- LOADING -->
				<template #loading>
					<div class="pt-3 px-3">
						<b-skeleton></b-skeleton>
						<b-skeleton></b-skeleton>
					</div>
					<hr>
					<div class="px-3 pb-3">
						<b-skeleton></b-skeleton>
					</div>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="15"
						:columns="3"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-row class="pt-3 px-4">
					<b-col>
						<h1 class="text-uppercase">{{contractor.full_name}}</h1>
						<h3 class="text-muted">{{parseIdentifier(contractor.identifier)}}</h3>
					</b-col>
					<b-col sm="auto">
						<b-row>
							<b-col sm="auto pt-1 pr-0">
								<div v-if="validation(contractor, 'validated')">
									<i class="fas fa-check-circle text-success text-size-2"></i>
								</div>
								<div v-else>
									<i class="fas fa-times-circle text-danger text-size-2"></i>
								</div>
							</b-col>
							<b-col>
								<div v-if="validation(contractor, 'validated')">
									<p class="text-size-15 mb-0 text-success">
										<strong>Empresa Acreditada</strong>
									</p>
								</div>
								<div v-else>
									<p class="text-size-15 mb-0 text-danger">
										<strong>Empresa No Acreditada</strong>
									</p>
								</div>
								<p class="text-size-10 mb-0 text-muted">{{validation(contractor, 'detail')}}</p>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<hr>
				<div class="pb-3 px-4">
					<h4>Documentos de la Empresa</h4>
					<p class="text-size-13 mb-0">Haga clic en el botón Cargar Documento para subirlo al sistema.</p>
				</div>
				

				<!-- REQUIREMENTS INFO -->
				<b-list-group class="mx-4 mb-4">
					<b-list-group-item v-for="(item, index) in requirements" :key="index" class="xs-lg requirements">
						<b-row>

							<!-- STATUS -->
							<b-col sm="2">
								
								<!-- MISSING REQUIREMENT -->
								<div v-if="status(item) == 'missing'">
									<span class="status-dot secondary"></span> <span class="text-muted">Pendiente</span>
								</div>
								<!-- /MISSING REQUIREMENT -->
								
								<!-- DISABLED REQUIREMENT -->
								<div v-else-if="status(item) == 'disabled'">
									<span class="status-dot light"></span> <span class="text-muted">No Aplica</span>
								</div>
								<!-- /DISABLED REQUIREMENT -->
								
								<!-- DONE REQUIREMENT -->
								<div v-else-if="status(item) == 'done'">
									<span class="status-dot success"></span> <span class="text-muted">Aprobado</span>
								</div>
								<!-- /DONE REQUIREMENT -->
								
								<!-- REJECTED REQUIREMENT -->
								<div v-else-if="status(item) == 'rejected'">
									<span class="status-dot danger"></span> <span class="text-muted">Rechazado</span>
								</div>
								<!-- /REJECTED REQUIREMENT -->
								
								<!-- WAITING REQUIREMENT -->
								<div v-else-if="status(item) == 'waiting'">
									<span class="status-dot warning"></span> <span class="text-muted">Enviado</span>
								</div>
								<!-- /WAITING REQUIREMENT -->

							</b-col>
							<!-- /STATUS -->

							<!-- INFORMATION -->
							<b-col>
								<p class="m-0">
									<span>{{item.document_type.name}}</span>
								</p>
								<p class="m-0 text-danger text-uppercase" v-if="status(item) == 'rejected'">
									<strong>{{item.document.observation}}</strong>
								</p>
							</b-col>
							<!-- /INFORMATION -->

							<!-- ACTIONS -->
							<b-col sm="2" class="text-right pt-1" v-if="current_task == index">
								<b-skeleton variant="primary" width="100%"></b-skeleton>
							</b-col>
							<b-col sm="auto" class="text-right" v-else>
								
								<!-- MISSING REQUIREMENT -->
								<div v-if="status(item) == 'missing'">
									<b-button
										variant="outline-secondary xs-btn"
										@click="upload(item, index)"
										v-if="checkPrivilege(item, 'upload')"
									>Cargar Documento</b-button>
								</div>
								<!-- /MISSING REQUIREMENT -->

								<!-- WAITING REQUIREMENT -->
								<div v-if="status(item) == 'waiting'">
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="destroy(item, index)"
										v-if="checkPrivilege(item, 'destroy')"
									>Eliminar Carga</b-button>
								</div>
								<!-- /WAITING REQUIREMENT -->

								<!-- DONE REQUIREMENT -->
								<div v-if="status(item) == 'done'">
									<b-button
										variant="outline-secondary xs-btn"
										@click="view(item, index)"
										v-if="checkPrivilege(item, 'view')"
									>Ver Documento</b-button>
								</div>
								<!-- /DONE REQUIREMENT -->

								<!-- REJECTED REQUIREMENT -->
								<div v-if="status(item) == 'rejected'">
									<b-button
										variant="outline-secondary xs-btn"
										@click="view(item, index)"
										v-if="checkPrivilege(item, 'view')"
									>Ver Documento</b-button>
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="reupload(item, index)"
										v-if="checkPrivilege(item, 'reupload')"
									>Reemplazar Documento</b-button>
								</div>
								<!-- /REJECTED REQUIREMENT -->

							</b-col>
							<!-- /ACTIONS -->

						</b-row>
					</b-list-group-item>
				</b-list-group>
				<!-- REQUIREMENTS INFO -->



				<!-- /DATA -->

			</b-skeleton-wrapper>

		</b-card>
		<!-- /CONTENT -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import RequirementView from '@/components/RequirementView.vue'
import RequirementUpload from '@/components/RequirementUploadContractor.vue'
export default {
	name: 'Contractor',
	components: {
		Unauthorized,
		RequirementView,
		RequirementUpload
	},
	data() {
		return {
			requirement: {
				index: null,
				data: {}
			},
			current_task: null,
			folder_task_running: false
		}
	},
	mounted() {
		if (this.owner_id != null) {
			this.load()
		}
	},
	watch: {
		owner_id(value) {
			if (value != null) {
				this.load()
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		owner_id() {
			return this.$store.state.user.data.account.data.owner_id
		},
		account() {
			return this.$store.state.user.data.account
		},
		loading() {
			return this.$store.state.contractor.loading || this.$store.state.contractor_requirements.loading
		},
		contractor() {
			return this.$store.state.contractor.data
		},
		requirements() {
			return this.$store.state.contractor_requirements.data
		}
	},
	methods: {
		load() {
			this.$store.dispatch('get_contractor', this.owner_id)
			this.$store.dispatch('get_contractor_requirements', this.owner_id)
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				autoHideDelay: delay
			})
		},
		validation(contractor, type) {
			const done = contractor.req_done
			const rejected = contractor.req_rejected
			const waiting = contractor.req_waiting
			const missing = contractor.req_missing
			const total = done + rejected + waiting + missing
			const validated = done == total ? true : false
			const completed = Math.ceil(done / total * 100)
			if (type == 'total') return total
			if (type == 'completed') return completed
			if (type == 'validated') return validated
			if (type == 'done') return done
			if (type == 'detail') return 'Cumple con ' + done + ' de ' + total + ' requisitos ('+ completed + '%)'
			return null
		},
		status(requirement) {
			if (requirement) {
				if (requirement.document == null) {
					return 'missing'
				} else if (requirement.document != null && requirement.document.action != null && requirement.document.action.result == 1 && requirement.document.status == 2) {
					return 'disabled'
				} else if (requirement.document != null && requirement.document.action != null && requirement.document.action.result == 1 && requirement.document.status == 1) {
					return 'done'
				} else if (requirement.document != null && requirement.document.action != null && requirement.document.action.result == -2) {
					return 'rejected'
				} else if (requirement.document != null && requirement.document.action == null) {
					return 'waiting'
				}
			} else {
				return null
			}
		},
		clearTask() {
			this.current_task = null
		},
		runTask(index) {
			this.current_task = index
		},
		checkTask() {
			if (this.current_task !== null) {
				this.toast(false, 'Por favor, espere un momento mientras se procesa su última solicitud.', 5000)
				return true
			} else {
				return false
			}
		},
		upload(requirement, index) {
			this.requirement = { index: index, data: requirement }
			this.$bvModal.show('requirement-upload-contractor')
		},
		reupload(requirement, index) {
			this.requirement = { index: index, data: requirement }
			this.$bvModal.show('requirement-upload-contractor')
		},
		view(requirement, index) {
			this.requirement = { index: index, data: requirement }
			this.$bvModal.show('requirement-view')
		},
		destroy(requirement, index) {
			if (this.checkTask()) return
			this.$bvModal.msgBoxConfirm('¿Desea eliminar el documento "'+requirement.document_type.name+'" enviado por la empresa?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Eliminar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(index)
					this.$api.post('requirements/destroy', {
						class: requirement.class,
						document_type_id: requirement.document_type_id,
						owner_id: requirement.owner_id
					})
					.then(() => {
						this.requirements[index].document = null
						this.clearTask()
						this.toast(true, 'El documento "'+requirement.document_type.name+'" fue eliminado del sistema y debe ser cargado nuevamente.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.clearTask()
					})
				}
			})
		},
		checkPrivilege(privilege, action) {
			let requirements = {
				contractors: [ 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ],
				contractors_risk: [6, 12, 13, 14, 15, 16, 17],
				subcontractors: [ 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ],
				subcontractors_risk: [6, 12, 13, 14, 15, 16, 17],
				service_orders: [30, 31, 32, 33, 34, 35, 36, 29, 37],
				contracts: [30, 31, 32, 33, 34, 35, 36, 29, 37],
				warranties: [63, 64, 65],
				subcontracts: [72, 73, 74, 75, 76, 77, 78, 79, 80],
				contractor_workers: [46, 48, 49, 50, 51, 53, 54, 55, 56],
				contractor_workers_driver: [59, 60, 62, 57, 58, 61],
				subcontractor_workers: [46, 48, 49, 50, 51, 53, 54, 55, 56],
				subcontractor_workers_driver: [59, 60, 62, 57, 58, 61],
				contractor_vehicles: [66, 67, 68, 69, 70, 71],
				subcontractor_vehicles: [66, 67, 68, 69, 70, 71]
			}
			let current_class = privilege.class
			let current_requirement = privilege.document_type_id
			switch (current_class) {

				case 'contractor':
					if (requirements.contractors.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractors_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractors_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractors_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_contractors_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_contractors_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractors_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractors_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractors_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractors_requirements_disable) return true
						else { return false }
					}
					else if (requirements.contractors_risk.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractors_risk_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractors_risk_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractors_risk_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_contractors_risk_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_contractors_risk_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractors_risk_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractors_risk_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractors_risk_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractors_risk_requirements_disable) return true
						else { return false }
					}
					break

				case 'subcontractor':
					if (requirements.subcontractors.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractors_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractors_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractors_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_subcontractors_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_subcontractors_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractors_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractors_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractors_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractors_requirements_disable) return true
						else { return false }
					}
					else if (requirements.subcontractors_risk.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractors_risk_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractors_risk_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractors_risk_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_subcontractors_risk_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_subcontractors_risk_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractors_risk_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractors_risk_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractors_risk_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractors_risk_requirements_disable) return true
						else { return false }
					}
					break
				
				case 'service':
					if (requirements.service_orders.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_service_orders_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_service_orders_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_service_orders_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_service_orders_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_service_orders_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_service_orders_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_service_orders_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_service_orders_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_service_orders_requirements_disable) return true
						else { return false }
					}
					else if (requirements.contracts.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contracts_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contracts_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contracts_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_contracts_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_contracts_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contracts_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contracts_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contracts_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contracts_requirements_disable) return true
						else { return false }
					}
					else if (requirements.warranties.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_warranties_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_warranties_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_warranties_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_warranties_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_warranties_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_warranties_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_warranties_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_warranties_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_warranties_requirements_disable) return true
						else { return false }
					}
					else if (requirements.subcontracts.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontracts_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontracts_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontracts_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_subcontracts_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_subcontracts_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontracts_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontracts_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontracts_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontracts_requirements_disable) return true
						else { return false }
					}
					break
				
				case 'worker':
					if (requirements.contractor_workers.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractor_workers_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractor_workers_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractor_workers_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_contractor_workers_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_contractor_workers_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractor_workers_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractor_workers_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractor_workers_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractor_workers_requirements_disable) return true
						else { return false }
					}
					else if (requirements.contractor_workers_driver.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractor_workers_driver_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractor_workers_driver_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractor_workers_driver_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_contractor_workers_driver_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_contractor_workers_driver_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractor_workers_driver_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractor_workers_driver_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractor_workers_driver_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractor_workers_driver_requirements_disable) return true
						else { return false }
					}
					if (requirements.subcontractor_workers.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractor_workers_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractor_workers_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractor_workers_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_subcontractor_workers_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_subcontractor_workers_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractor_workers_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractor_workers_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractor_workers_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractor_workers_requirements_disable) return true
						else { return false }
					}
					else if (requirements.subcontractor_workers_driver.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractor_workers_driver_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractor_workers_driver_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractor_workers_driver_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_subcontractor_workers_driver_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_subcontractor_workers_driver_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractor_workers_driver_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractor_workers_driver_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractor_workers_driver_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractor_workers_driver_requirements_disable) return true
						else { return false }
					}
					break
				
				case 'vehicle':
					if (requirements.contractor_vehicles.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractor_vehicles_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractor_vehicles_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractor_vehicles_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_contractor_vehicles_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_contractor_vehicles_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractor_vehicles_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractor_vehicles_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractor_vehicles_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractor_vehicles_requirements_disable) return true
						else { return false }
					}
					if (requirements.subcontractor_vehicles.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractor_vehicles_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractor_vehicles_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractor_vehicles_requirements_upload) return true
						else if (action == 'destroy' && this.getters.prv_subcontractor_vehicles_requirements_upload) return true
						else if (action == 'review' && this.getters.prv_subcontractor_vehicles_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractor_vehicles_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractor_vehicles_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractor_vehicles_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractor_vehicles_requirements_disable) return true
						else { return false }
					}
					break
			}
		},
	}
}
</script>

<style scoped>
.requirements .xs-lg {
	padding: 5px 15px;
}
.requirements .xs-btn {
	padding: 3px 5px;
	margin: 0px;
	font-size: 0.85em;
}
.requirements .status-dot {
	display: inline-block;
	height: 8px;
	width: 8px;
	border-radius: 10px;
	margin-right: 15px;
}
.requirements .status-dot.success {
	background-color: #28A745;
}
.requirements .status-dot.danger {
	background-color:#dc3545;
}
.requirements .status-dot.warning {
	background-color:#fd7e14;
}
.requirements .status-dot.secondary {
	background-color: #343a40;
}
.requirements .status-dot.light {
	background-color:#999999;
}
</style>
