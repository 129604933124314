<template>
	<b-modal
		id="view-date-expirations"
		:title="title"
		size="xl"
		body-class="p-0"
	>
		<b-overlay :show="expirations.loading" rounded="sm" class="p-3">
			<b-row>
				<b-col>
					<b-table
						:items="expirations.data"
						:fields="fields"
						responsive
						hover
						bordered
						class="mb-0 text-xs"
						small
					>
						<template v-slot:table-busy>
							<div class="text-center my-2 p-3">
								<span>...</span>
							</div>
						</template>
						<template v-slot:cell(owner_company)="data">
							<div>
								<span>{{data.value.short_name}}</span>
							</div>
						</template>
						<template v-slot:cell(owner_data)="data">
							<div>
								<span v-if="data.item.owner_type == 'CONTRATISTA'">{{data.value.short_name}}</span>
								<span v-else-if="data.item.owner_type == 'SUBCONTRATISTA'">{{data.value.short_name}}</span>
								<span v-else-if="data.item.owner_type == 'SERVICIO'">
									<span v-if="data.value.type == 1">Orden de Servicio N°</span>
									<span v-else-if="data.value.type == 2">Contrato N°</span>
									<span v-else-if="data.value.type == 3">Garantía N°</span>
									<span v-else-if="data.value.type == 4">Subcontrato N°</span>
									<span> {{data.value.cod}}</span>
								</span>
								<span v-else-if="data.item.owner_type == 'TRABAJADOR'">
									<span v-if="data.value.status">{{data.value.names}} {{data.value.primary_last_name}} {{data.value.second_last_name}}</span>
									<span v-else class="text-muted">
										<span class="deleted">{{data.value.names}} {{data.value.primary_last_name}} {{data.value.second_last_name}}</span>
										<span> (ELIMINADO)</span>
									</span>
								</span>
								<span v-else-if="data.item.owner_type == 'VEHÍCULO'">{{data.value.identifier}}</span>
								<span v-else>
									<span v-if="data.value">{{data.value}}</span>
									<span>ELIMINADO</span>
								</span>
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</b-overlay>
		<template v-slot:modal-footer>
			<b-button @click="close()">Cerrar</b-button>
		</template>
	</b-modal>
</template>

<script>
export default {
	name: 'ViewDateExpirations',
	components: {
	},
	data() {
		return {
			current_document: {},
			loading: false,
			fields: [
				{ key: 'owner_company', label: 'Empresa', sortable: true },
				{ key: 'type.name', label: 'Tipo de Documento', sortable: true },
				{ key: 'owner_type', label: 'Asociado a', sortable: true },
				{ key: 'owner_data', label: 'Detalle', sortable: true },
				{ key: 'uploaded', label: 'Fecha de Carga', sortable: true }
			]
		}
	},
	computed: {
		expirations() {
			return this.$store.state.current_date_expirations
		},
		title() {
			return this.expirations.date != null ? 'Expiraciones Diarias: ' + this.expirations.date : 'Expiraciones Diarias'
		}
	},
	methods: {
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : '...' : i
        },
		close() {
			this.$bvModal.hide('view-date-expirations')
		}
	}
}
</script>

<style scoped>
.deleted {
	text-decoration: line-through;
}
.text-xs {
	font-size: 0.9em;
}
</style>
