<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="update-service"
			:title="'Actualizar ' + type_name"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading || updating" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="12" v-if="type === 4">
						<b-form-group label="Empresa Subcontratista">
							<b-overlay :show="subcontractors.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="service.company_id" :state="val_subcontractor" :disabled="subcontractors.loading" v-if="!subcontractors.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Subcontratista...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in subcontractors.data" :key="index" :value="item.id">{{item.full_name}} // {{item.contractor.short_name}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12" v-else>
						<b-form-group label="Empresa Contratista">
							<b-overlay :show="contractors.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="service.company_id" :state="val_contractor" :disabled="contractors.loading" v-if="!contractors.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Contratista...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in contractors.data" :key="index" :value="item.id">{{item.full_name}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="type !== 4">
						<b-form-group label="Código / Folio / Número">
							<b-input v-model="service.cod" :state="val_cod"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Máximo de Trabajadores">
							<b-input type="number" v-model="service.workers_quantity" :state="val_workers_quantity"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Título">
							<b-textarea v-model="service.comments" :state="val_comments"></b-textarea>
							<b-form-invalid-feedback>Ingrese una descripción breve del servicio</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Fecha de Inicio">
							<b-form-datepicker
								v-model="service.start_date"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								:state="val_start_date"
								@input="service.end_date = ''"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Fecha de Término">
							<b-form-datepicker
								v-model="service.end_date"
								:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
								:min="service.start_date"
								:disabled="!val_start_date"
								:state="val_end_date"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12" v-if="type != 4">
						<b-form-group label="Administrador de Contrato">
							<b-overlay :show="administrators.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="service.service_administrator" :state="val_service_administrator" :disabled="administrators.loading">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Administrador...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in administrators.data" :key="index" :value="item.id">{{item.primary_last_name}} {{item.second_last_name}} {{item.names}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Turnos Habilitados">
							<p v-if="!val_shifts" class="mb-2 text-danger">0 turnos seleccionados</p>
							<div v-else class="mb-1">
								<b-badge variant="success" v-for="(shift, index) in selected_shifts" :key="index" class="badge-lg mb-1 mr-1">
									<span v-if="shift">{{shift.name}}</span>
								</b-badge>
							</div>
							<b-button v-b-modal.shifts-update :variant="val_shifts ? 'outline-success' : 'outline-danger'">
								<span v-if="val_shifts">Modificar Turnos</span>
								<span v-else>Seleccionar Turnos</span>
							</b-button>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón ACTUALIZAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading || updating">Cerrar</b-button>
				<b-button @click="update()" variant="success" :disabled="!val_full || loading || updating">ACTUALIZAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->
		
		<!-- SHIFTS SELECTOR -->
		<b-modal id="shifts-update" title="Seleccione Turnos">

			<!-- SHIFTS -->
			<b-row class="mb-3">
				<b-col sm="6" v-for="(shift, index) in shifts.data" :key="index">
					<b-form-checkbox v-model="service.shift_ids" :value="shift.id">
						<span>{{ shift.name }}</span>
					</b-form-checkbox>
				</b-col>
			</b-row>
			<!-- /SHIFTS -->

			<!-- MESSAGE -->
			<b-alert show :variant="val_shifts?'success':'danger'" class="text-size-11">
					<span v-if="val_shifts">¡Todo bien! Para volver, presione el botón GUARDAR.</span>
					<span v-else>Seleccione como mínimo 1 turno y luego presione GUARDAR.</span>
			</b-alert>
			<!-- /MESSAGE -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="!val_shifts" variant="success">Guardar</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /SHIFTS SELECTOR -->

	</div>
</template>

<script>
export default {
	name: 'UpdateService',
	components: {
	},
	data() {
		return {
			updating: false
		}
	},
	props: {
		type: Number
	},
	computed: {
		service() {
			return this.$store.state.service.data
		},
		loading() {
			return this.$store.state.service.loading
		},
		contractors() {
			return this.$store.state.contractors
		},
		subcontractors() {
			return this.$store.state.subcontractors
		},
		administrators() {
			return this.$store.state.service_administrators
		},
		shifts() {
			return this.$store.state.shifts
		},
		selected_shifts() {
			let shifts = []
			for (let i = 0; i < this.service.shift_ids.length; i++) {
				let index = this.shifts.data.findIndex(shift => shift.id === this.service.shift_ids[i])
				shifts.push(this.shifts.data[index])
			}
			return shifts
		},
		val_full() {
			return ( (this.val_contractor && this.val_service_administrator && this.val_cod) || this.val_subcontractor ) &&
					this.val_comments &&
					this.val_start_date &&
					this.val_end_date &&
					this.val_workers_quantity &&
					this.val_shifts ? true : false
		},
		val_contractor() {
			return this.service.company_id != null ? true : false
		},
		val_subcontractor() {
			return this.service.company_id != null ? true : false
		},
		val_comments() {
			return this.service.comments.length > 0 ? true : false
		},
		val_start_date() {
			return this.service.start_date.length > 0 ? true : false
		},
		val_end_date() {
			return this.service.end_date.length > 0 ? true : false
		},
		val_cod() {
			return this.service.cod.length > 0 ? true : false
		},
		val_workers_quantity() {
			return this.service.workers_quantity > 0 ? true : false
		},
		val_service_administrator() {
			return this.service.service_administrator != null ? true : false
		},
		val_shifts() {
			return this.selected_shifts.length > 0 ? true : false
		},
		type_name() {
			if (this.type) {
				switch (this.type) {
					case 1:
						return 'Nueva Orden de Servicio'
					case 2:
						return 'Nuevo Contrato'
					case 3:
						return 'Nueva Garantía'
					case 4:
						return 'Nuevo Subcontrato'
					default:
						return 'Nuevo Servicio'
				}
			} else {
				return 'Nuevo Servicio'
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-service') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.$store.commit('reset_service')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		update() {
			this.updating = true
			this.service.type = this.type
			this.$api.put('services/'+this.service.id, this.service)
			.then(response => {
				this.updating = false
				this.$emit('update')
				this.$bvModal.hide('update-service')
				this.toast(true, 'Se guardaron los cambios en '+this.type_name_pronoun()+' "'+response.data.cod+'" correctamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				if (error.response.status == 409) this.service.cod = '' // duplicated
				this.toast(false, error.response.data.message, 10000)
			})
		},
		type_name_pronoun() {
			if (this.type) {
				switch (this.type) {
					case 1:
						return 'la Orden de Servicio'
					case 2:
						return 'el Contrato'
					case 3:
						return 'el Garantía'
					case 4:
						return 'la Subcontrato'
					default:
						return 'el Servicio'
				}
			} else {
				return 'el Servicio'
			}
		},
	}
}
</script>

<style scoped>
</style>
