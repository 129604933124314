<template>
	<div>
		<b-row>
			<b-col>
				<b-overlay :show="current_user.loading" rounded="sm">
					<b-card no-body>
						<b-card-header>
							<strong>Información de Usuario</strong>
						</b-card-header>
						<b-list-group v-if="!current_user.data.id">
							<b-list-group-item class="text-center">
								<b-spinner type="grow" variant="light"></b-spinner>
							</b-list-group-item>
						</b-list-group>
						<b-list-group flush v-else>
							<b-list-group-item>
								<b-row>
									<b-col sm="4">
										<strong>Tipo de Usuario</strong>
									</b-col>
									<b-col>
										<span v-if="current_user.data.type == 1 && current_user.data.owner.owner">SUPERADMINISTRADOR</span>
										<span v-else-if="current_user.data.type == 1 && !current_user.data.owner.owner">EMPRESA CONTRATISTA</span>
										<span v-else-if="current_user.data.type == 0 && current_user.data.owner.company.owner">TRABAJADOR HMC</span>
										<span v-else-if="current_user.data.type == 0 && !current_user.data.owner.company.owner">TRABAJADOR EXTERNO</span>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row>
									<b-col sm="4">
										<strong v-if="current_user.data.type == 1">Razón Social</strong>
										<strong v-else>Nombre</strong>
									</b-col>
									<b-col class="text-uppercase">
										<span v-if="current_user.data.type == 1">{{current_user.data.owner.full_name}}</span>
										<span v-else-if="current_user.data.owner && current_user.data.owner.person">{{current_user.data.owner.person.names}} {{current_user.data.owner.person.primary_last_name}} {{current_user.data.owner.person.second_last_name}}</span>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row>
									<b-col sm="4">
										<strong>RUT</strong>
									</b-col>
									<b-col>
										<span>{{parseIdentifier(current_user.data.rut)}}</span>
									</b-col>
								</b-row>
							</b-list-group-item>
							<b-list-group-item>
								<b-row>
									<b-col sm="4">
										<strong>Correo electrónico</strong>
									</b-col>
									<b-col class="text-lowercase">
										<span v-if="current_user.data.owner && current_user.data.owner.email">{{current_user.data.owner.email}}</span>
										<span v-else-if="current_user.data.owner && current_user.data.owner.person">{{current_user.data.owner.person.email}}</span>
									</b-col>
								</b-row>
							</b-list-group-item>
						</b-list-group>
					</b-card>
				</b-overlay>
			</b-col>
			<b-col sm="4">
				<b-overlay :show="updating_password" rounded="sm">
					<b-card no-body>
						<b-card-header>
							<strong>Cambiar Contraseña</strong>
						</b-card-header>
						<b-card-body>
							<b-form action="#" @submit.prevent="update_password">
								<b-form-group label="Ingrese su nueva contraseña">
									<b-input type="password" v-model="new_password" size="lg" :state="val_new_password"></b-input>
									<b-form-invalid-feedback>
										<span>Mínimo 5 letras o números</span>
									</b-form-invalid-feedback>
									<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
								</b-form-group>
								<b-form-group label="Repita su nueva contraseña">
									<b-input type="password" v-model="repeat_password" size="lg" :state="val_repeat_password"></b-input>
									<b-form-invalid-feedback>
										<span>La contraseña debe ser idéntica</span>
									</b-form-invalid-feedback>
									<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
								</b-form-group>
								<b-button type="submit" variant="primary" size="lg" block :disabled="!val_full">
									<span>Cambiar Contraseña</span>
								</b-button>
							</b-form>
						</b-card-body>
					</b-card>
					<!-- LOADING PROGRESS -->
					<template #overlay>
						<div class="text-center">
							<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
						</div>
					</template>
					<!-- /LOADING PROGRESS -->
				</b-overlay>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: 'Account',
	components: {
	},
	data() {
		return {
			new_password: null,
			repeat_password: null,
			updating_password: false,
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		user_id() {
			return this.$store.state.user.data.account.data.id
		},
		current_user() {
			return this.$store.state.current_user
		},
		val_new_password() {
			return this.new_password == null ? null : this.new_password.length >= 5 ? true : false
		},
		val_repeat_password() {
			return this.new_password == null || !this.val_new_password ? null : this.val_same ? true : false
		},
		val_same() {
			return this.new_password === this.repeat_password ? true : false
		},
		val_full() {
			return this.val_new_password && this.val_repeat_password ? true : false
		},
	},
	watch: {
		user_id(id) {
			if (id != null) this.load()
		}
	},
	created() {
		if (this.user_id != null) this.load()
	},
	methods: {
		load() {
			this.$store.dispatch('get_current_user', this.user_id)
		},
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'success' : 'danger',
				autoHideDelay: delay,
			})
		},
		update_password() {
			this.updating_password = true
			this.$store.dispatch('update_password', { user_id: this.user_id, password: this.new_password })
			.then(() => {
				this.updating_password = false
				this.new_password = null
				this.repeat_password = null
				this.toast(true, 'Su nueva contraseña fue guardada correctamente.', 10000)
			})
			.catch(error => {
				this.updating_password = false
				this.toast(false, error.response.data.message, 5000)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
	}
}
</script>