<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-vehicle"
			:title="'Registrar Nuevo ' + type_name"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="12" v-if="type === 2">
						<b-form-group label="Empresa Contratista">
							<b-overlay :show="contractors.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="vehicle.contractor_id" :state="val_contractor" :disabled="contractors.loading" v-if="!contractors.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Contratista...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in contractors.data" :key="index" :value="item.id">{{item.full_name.toUpperCase()}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12" v-else-if="type === 3">
						<b-form-group label="Empresa Subcontratista">
							<b-overlay :show="subcontractors.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="vehicle.subcontractor_id" :state="val_subcontractor" :disabled="subcontractors.loading" v-if="!subcontractors.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Subcontratista...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in subcontractors.data" :key="index" :value="item.id">{{item.full_name.toUpperCase()}} // {{item.contractor.short_name.toUpperCase()}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Placa Patente">
							<b-input v-model="vehicle.identifier" :state="val_identifier"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Tipo de Vehículo">
							<b-overlay :show="vehicle_types.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="vehicle.type_id" :state="val_type_id" :disabled="vehicle_types.loading">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Tipo...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in vehicle_types.data" :key="index" :value="item.id">{{item.name}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Marca">
							<b-input v-model="vehicle.manufacturer" :state="val_manufacturer"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Modelo">
							<b-input v-model="vehicle.model" :state="val_model"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Año">
							<b-input v-model="vehicle.year" :state="val_year" type="number"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="N° de Serie Motor">
							<b-input v-model="vehicle.engine" :state="val_engine"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón REGISTRAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="!val_full || loading">REGISTRAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'CreateVehicle',
	components: {
	},
	data() {
		return {
			loading: false,
			vehicle: {
				contractor_id: null,
				subcontractor_id: null,
				identifier: '',
				type_id: null,
				manufacturer: '',
				model: '',
				year: '',
				engine: ''
			}
		}
	},
	props: {
		type: Number
	},
	computed: {
		contractors() {
			return this.$store.state.contractors
		},
		subcontractors() {
			return this.$store.state.subcontractors
		},
		vehicle_types() {
			return this.$store.state.vehicle_types
		},
		val_full() {
			return ( (this.type == 1 ) || (this.type == 2 && this.val_contractor) || (this.type == 3 && this.val_subcontractor) ) &&
					this.val_identifier &&
					this.val_type_id &&
					this.val_manufacturer &&
					this.val_model &&
					this.val_year &&
					this.val_engine ? true : false
		},
		val_contractor() {
			return this.vehicle.contractor_id != null ? true : false
		},
		val_subcontractor() {
			return this.vehicle.subcontractor_id != null ? true : false
		},
		val_identifier() {
			return this.vehicle.identifier.length > 0 ? true : false
		},
		val_type_id() {
			return this.vehicle.type_id != null ? true : false
		},
		val_manufacturer() {
			return this.vehicle.manufacturer.length > 0 ? true : false
		},
		val_model() {
			return this.vehicle.model.length > 0 ? true : false
		},
		val_year() {
			return this.vehicle.year.length > 0 ? true : false
		},
		val_engine() {
			return this.vehicle.engine.length > 0 ? true : false
		},
		type_name() {
			if (this.type) {
				switch (this.type) {
					case 1:
						return 'Vehículo HMC'
					case 2:
						return 'Vehículo Contratista'
					case 3:
						return 'Vehículo Subcontratista'
					default:
						return 'Vehículo'
				}
			} else {
				return 'Vehículo'
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-vehicle') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.vehicle = {
				contractor_id: null,
				subcontractor_id: null,
				identifier: '',
				type_id: null,
				manufacturer: '',
				model: '',
				year: '',
				engine: ''
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.vehicle.type = this.type
			this.$api.post('vehicles', this.vehicle)
			.then(response => {
				this.loading = false
				this.$emit('update')
				this.$bvModal.hide('create-vehicle')
				this.toast(true, 'Se registró el vehículo Placa Patente "'+response.data.identifier+'" en el sistema correctamente.', 10000)
				this.reset()
			})
			.catch(error => {
				this.loading = false
				if (error.response.status == 409) this.vehicle.identifier = '' // duplicated
				this.toast(false, error.response.data.message, 10000)
			})
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		}
	}
}
</script>

<style scoped>
</style>
