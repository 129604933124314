export default {

	save_session(state, session) {
		state.user.data.session = session
		localStorage.setItem('token_type', session.token_type)
		localStorage.setItem('expires_in', session.expires_in)
		localStorage.setItem('access_token', session.access_token)
		localStorage.setItem('refresh_token', session.refresh_token)
	},

	set_account(state, account) {
		state.user.data.account = account
	},

	destroy_session(state) {
		state.user.data.session = {
			token_type: null,
			expires_in: null,
			access_token: null,
			refresh_token: null
		}
		localStorage.removeItem('token_type')
		localStorage.removeItem('expires_in')
		localStorage.removeItem('access_token')
		localStorage.removeItem('refresh_token')
	},

	/**
	* 
	* @param {*} state 
	* @param {*} session 
	*/
	set_session(state, session) {
		state.user.data.account = {
			loading: false,
			data: session.account
		}
		state.user.data.privileges = {
			loading: false,
			data: session.privileges
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} privileges 
	*/
	set_privileges(state, privileges) {
		state.privileges = {
			loading: false,
			data: privileges
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} privilege_list 
	*/
	set_privilege_list(state, privilege_list) {
		state.privilege_list = {
			loading: false,
			data: privilege_list
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} users 
	*/
	set_users(state, users) {
		state.users = {
			loading: false,
			data: users
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} current_user 
	*/
	set_current_user(state, current_user) {
		state.current_user = {
			loading: false,
			data: current_user
		}
	},
	/**
	* 
	* @param {*} state 
	* @param {*} contractors 
	*/
	set_contractors(state, contractors) {
		state.contractors = {
			loading: false,
			data: contractors
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} contractor
	*/
	set_contractor(state, contractor) {
		state.contractor = {
			loading: false,
			data: contractor
		}
	},

	/**
	* 
	* @param {*} state 
	*/
	reset_contractor(state) {
		state.contractor = {
			loading: false,
			data: {
				identifier: '',
				full_name: '',
				short_name: '',
				phone: '',
				email: '',
				commune_id: null,
				address: ''
			}
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} subcontractors 
	*/
	set_subcontractors(state, subcontractors) {
		state.subcontractors = {
			loading: false,
			data: subcontractors
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} subcontractor
	*/
	set_subcontractor(state, subcontractor) {
		state.subcontractor = {
			loading: false,
			data: subcontractor
		}
	},

	/**
	* 
	* @param {*} state 
	*/
	reset_subcontractor(state) {
		state.subcontractor = {
			loading: false,
			data: {
				contractor_id: null,
				identifier: '',
				full_name: '',
				short_name: '',
			}
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} services 
	*/
	set_services(state, services) {
		state.services = {
			loading: false,
			data: services
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} service
	*/
	set_service(state, service) {
		state.service = {
			loading: false,
			data: service
		}
	},

	/**
	* 
	* @param {*} state 
	*/
	reset_service(state) {
		state.service = {
			loading: false,
			data: {
				contractor_id: null,
				subcontractor_id: null,
				company_id: null,
				comments: '',
				start_date: '',
				end_date: '',
				cod: '',
				workers_quantity: '',
				service_administrator: null,
				shifts: [],
				shift_ids: []
			}
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} workers 
	*/
	set_workers(state, workers) {
		state.workers = {
			loading: false,
			data: workers
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} worker
	*/
	set_worker(state, worker) {
		state.worker = {
			loading: false,
			data: worker
		}
	},

	/**
	* 
	* @param {*} state 
	*/
	reset_worker(state) {
		state.worker = {
			loading: false,
			data: {
				contractor_id: null,
				subcontractor_id: null,
				identifier: '',
				names: '',
				primary_last_name: '',
				second_last_name: '',
				area: '',
				position: '',
				contract_type: null,
				contract_start: '',
				contract_end: '',
				terminated: null,
				termination_date: '',
				driver: false
			}
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} vehicles 
	*/
	set_vehicles(state, vehicles) {
		state.vehicles = {
			loading: false,
			data: vehicles
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} vehicle
	*/
	set_vehicle(state, vehicle) {
		state.vehicle = {
			loading: false,
			data: vehicle
		}
	},

	/**
	* 
	* @param {*} state 
	*/
	reset_vehicle(state) {
		state.vehicle = {
			loading: false,
			data: {
				contractor_id: null,
				subcontractor_id: null,
				identifier: '',
				type_id: null,
				manufacturer: '',
				model: '',
				year: 0,
				engine: ''
			}
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} contractor_requirements
	*/
	set_contractor_requirements(state, contractor_requirements) {
		state.contractor_requirements = {
			loading: false,
			data: contractor_requirements
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} contractor_workers
	*/
	set_contractor_workers(state, contractor_workers) {
		state.contractor_workers = {
			loading: false,
			data: contractor_workers
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} contractor_workers
	*/
	reset_contractor_workers(state) {
		state.contractor_workers = {
			loading: false,
			data: []
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} subcontractor_requirements
	*/
	set_subcontractor_requirements(state, subcontractor_requirements) {
		state.subcontractor_requirements = {
			loading: false,
			data: subcontractor_requirements
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} subcontractor_workers
	*/
	set_subcontractor_workers(state, subcontractor_workers) {
		state.subcontractor_workers = {
			loading: false,
			data: subcontractor_workers
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} subcontractor_workers
	*/
	reset_subcontractor_workers(state) {
		state.subcontractor_workers = {
			loading: false,
			data: []
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} service_requirements
	*/
	set_service_requirements(state, service_requirements) {
		state.service_requirements = {
			loading: false,
			data: service_requirements
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} service_extensions
	*/
	set_service_extensions(state, service_extensions) {
		state.service_extensions = {
			loading: false,
			data: service_extensions
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} service_workers
	*/
	set_service_workers(state, service_workers) {
		state.service_workers = {
			loading: false,
			data: service_workers
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} extension_start
	*/
	set_extension_start(state, extension_start) {
		state.extension_start = {
			loading: false,
			data: extension_start
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} worker_requirements
	*/
	set_worker_requirements(state, worker_requirements) {
		state.worker_requirements = {
			loading: false,
			data: worker_requirements
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} driver_requirements
	*/
	set_driver_requirements(state, driver_requirements) {
		state.driver_requirements.loading = false;
		state.driver_requirements.data = driver_requirements;
	},

	/**
	* 
	* @param {*} state
	* @param {*} current_driver
	*/
	current_driver(state, current_driver) {
		state.current_driver = current_driver
	},

	/**
	* 
	* @param {*} state
	* @param {*} emergency_requirements
	*/
	set_emergency_requirements(state, emergency_requirements) {
		state.emergency_requirements.loading = false;
		state.emergency_requirements.data = emergency_requirements;
	},

	/**
	* 
	* @param {*} state
	* @param {*} current_emergency
	*/
	current_emergency(state, current_emergency) {
		state.current_emergency = current_emergency
	},

	/**
	* 
	* @param {*} state
	* @param {*} data
	*/
	set_expirations(state, data) {
		state.expirations.loading = false;
		state.expirations.data = data;
	},

	/**
	* 
	* @param {*} state
	* @param {*} data
	*/
	save_driving_license(state, data) {
		state.driving_license.loading = false;
		state.driving_license.data = data;
	},

	/**
	* 
	* @param {*} state
	* @param {*} worker_restrictions
	*/
	set_worker_restrictions(state, worker_restrictions) {
		state.worker_restrictions = {
			loading: false,
			data: worker_restrictions
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} worker_restriction_types
	*/
	set_worker_restriction_types(state, worker_restriction_types) {
		state.worker_restriction_types = {
			loading: false,
			data: worker_restriction_types
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} vehicle_requirements
	*/
	set_vehicle_requirements(state, vehicle_requirements) {
		state.vehicle_requirements = {
			loading: false,
			data: vehicle_requirements
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} regions
	*/
	set_regions(state, regions) {
		state.regions = {
			loading: false,
			data: regions
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} communes
	*/
	set_communes(state, communes) {
		state.communes = {
			loading: false,
			data: communes
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} communes
	*/
	set_shifts(state, shifts) {
		state.shifts = {
			loading: false,
			data: shifts
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} vehicle_types
	*/
	set_vehicle_types(state, vehicle_types) {
		state.vehicle_types = {
			loading: false,
			data: vehicle_types
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} service_administrators
	*/
	set_service_administrators(state, service_administrators) {
		state.service_administrators = {
			loading: false,
			data: service_administrators
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} passes
	*/
	set_passes(state, passes) {
		state.passes = {
			loading: false,
			data: passes
		}
	},

	/**
	* 
	* @param {*} state
	* @param {*} cargo_passes
	*/
	set_cargo_passes(state, cargo_passes) {
		state.cargo_passes = {
			loading: false,
			data: cargo_passes
		}
	},


	set_current_date_expirations(state, expirations) {
		state.current_date_expirations.loading = false
		state.current_date_expirations.data = expirations
	},

	/**
	* 
	* @param {*} state 
	* @param {*} user_logs 
	*/
	set_user_logs(state, user_logs) {
		state.user_logs = {
			loading: false,
			data: user_logs
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} requirements_report_options 
	*/
	set_requirements_report_options(state, requirements_report_options) {
		state.requirements_report_options = {
			loading: false,
			data: requirements_report_options
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} controls 
	*/
	set_controls(state, controls) {
		state.controls = {
			loading: false,
			data: controls
		}
	},
	/**
	* 
	* @param {*} state 
	* @param {*} grids 
	*/
	set_grids(state, grids) {
		state.grids = {
			loading: false,
			data: grids
		}
	},
	/**
	* 
	* @param {*} state 
	* @param {*} approvers 
	*/
	set_approvers(state, approvers) {
		state.approvers = {
			loading: false,
			data: approvers
		}
	},

	set_global_parameters(state, global_parameters) {
		state.global_parameters = {
			loading: false,
			data: global_parameters
		}
	},
	set_corporate_passes(state, corporate_passes) {
		state.corporate_passes = {
			loading: false,
			data: corporate_passes
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} countries 
	*/
	countries(state, items) {
		state.countries = {
			loading: false,
			data: items
		}
	},

	/**
	* 
	* @param {*} state 
	* @param {*} migration_statuses 
	*/
	migration_statuses(state, items) {
		state.migration_statuses = {
			loading: false,
			data: items
		}
	},

}