<template>
	<b-modal
		visible
		hide-footer
		hide-header
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		size="sm"
		body-class="p-5 text-center"
	>
		<b-spinner variant="secondary" type="grow"></b-spinner>
		<p class="text-secondary mt-3 mb-0">Cerrando Sesión</p>
	</b-modal>
</template>

<script>
export default {
	name: 'Logout',
	created() {
		this.$store.dispatch('logout')
		.then(() => {
			this.$router.push({ name: 'Login' })
		})
		.catch(() => {
			this.$router.push({ name: 'Login' })
		})
	}
}
</script>