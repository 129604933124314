<template>
	<Unauthorized v-if="!getters.prv_system_shifts_view"></Unauthorized>
	<div v-else>
		<Create></Create>
		<b-row>
			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('System')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Sistema">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar un nuevo turno"
					v-if="getters.prv_system_shifts_manage"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				<b-overlay
					:show="workers.loading"
					rounded
					opacity="0.6"
					class="d-inline-block mr-2"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de trabajadores">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<b-button @click="showShifts()" variant="primary" v-b-tooltip.hover.bottomright title="Ver listado de turnos registrados">
					<span>Ver Turnos</span>
				</b-button>
			</b-col>
			<b-col sm="auto">
				<b-input v-model="filter" placeholder="Escriba nombre..." debounce="500"></b-input>
			</b-col>
			<!-- /ACTIONS -->
		</b-row>
		
		<!-- CONTENT -->
		<b-card no-body>

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="workers.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="workers.data"
					:fields="fields.workers"
					:filter="filter"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
				>
					<template v-slot:cell(person.identifier)="data">
						<div class="pl-2">
							<span>{{parseIdentifier(data.value)}}</span>
						</div>
					</template>
					<template v-slot:cell()="data">
						<div>
							<span class="text-uppercase">{{data.value}}</span>
						</div>
					</template>
					<template v-slot:cell(shift)="data">
						<b-row class="m-0">
							<b-col sm="auto" class="pr-0">
								<b-form-select v-model="selected[data.item.id]" :disabled="shifts.loading || (currentTask.id == data.item.id) || !getters.prv_system_shifts_manage" size="sm">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in shifts.data" :key="index" :value="item.id">{{item.name.toUpperCase()}}</b-form-select-option>
								</b-form-select>
							</b-col>
							<b-col sm="auto">
								<b-overlay
									:show="currentTask.id == data.item.id"
									rounded
									opacity="0.6"
									spinner-small
									spinner-variant="primary"
									class="d-inline-block"
								>
									<b-button variant="outline-primary" size="sm" @click="save(data)" :disabled="!getters.prv_system_shifts_manage">
										<span>Guardar</span>
									</b-button>
								</b-overlay>
							</b-col>
						</b-row>
					</template>
				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<b-modal id="shifts" title="Listado de Turnos" size="lg" body-class="p-0">
			<b-table :items="shifts.data" :fields="fields.shifts" small hover>
				<template v-slot:cell()="data">
					<div class="text-center">
						<span class="text-uppercase">{{data.value}}</span>
					</div>
				</template>
				<template v-slot:cell(name)="data">
					<div class="text-left pl-2">
						<span class="text-uppercase">{{data.value}}</span>
					</div>
				</template>
			</b-table>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import Create from '@/components/CreateShift.vue'
export default {
	name: 'Shifts',
	components: {
		Unauthorized,
		Create
	},
	data() {
		return {
			selected: {},
			filter: '',
			currentTask: {
				loading: false,
				id: null
			},
			fields: {
				workers: [
					{ key: 'person.identifier', label: 'RUT', sortable: true },
					{ key: 'person.names', label: 'Nombres', sortable: true },
					{ key: 'person.primary_last_name', label: 'Apellido Paterno', sortable: true },
					{ key: 'person.second_last_name', label: 'Apellido Materno', sortable: true },
					{ key: 'shift', label: 'Turno', sortable: true },
				],
				shifts: [
					{ key: 'name', label: 'Nombre', sortable: true },
					{ key: 'start_date', label: 'Fecha Arranque', sortable: true },
					{ key: 'start_hour', label: 'Hora Inicio', sortable: true },
					{ key: 'end_hour', label: 'Hora Término', sortable: true },
					{ key: 'work_days', label: 'Días Activos', sortable: true },
					{ key: 'rest_days', label: 'Días Descanso', sortable: true },
				]
			}
		}
	},
	mounted() {
		this.load()
	},
	watch: {
		workers(data) {
			if (data.data.length) {
				this.parse()
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		shifts() {
			return this.$store.state.shifts
		},
		workers() {
			return this.$store.state.workers
		},
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		showShifts() {
			this.$bvModal.show('shifts')
		},
		create() {
			this.$bvModal.show('create-shift')
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		load() {
			this.$store.dispatch('get_shifts')
			this.$store.dispatch('get_internal_workers_with_shifts')
		},
		parse() {
			for(let i=0; i < this.workers.data.length; i++) {
				this.selected[this.workers.data[i].id] = this.workers.data[i].shift != null ? this.workers.data[i].shift.id : null
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		save(worker) {
			const index = this.workers.data.findIndex(item => item.id == worker.item.id)
			const shift_index = this.shifts.data.findIndex(item => item.id == this.selected[worker.item.id])
			let full_name = this.workers.data[index].person.names+' '+this.workers.data[index].person.primary_last_name+' '+this.workers.data[index].person.second_last_name
			let shift_id = this.selected[worker.item.id]
			let worker_id = worker.item.id
			if (shift_id == null && this.workers.data[index].shift == null) {
				this.toast(false, 'Por favor, seleccione un turno para asignar.', 10000)
				return false
			}
			if (this.workers.data[index].shift != null && shift_id == this.workers.data[index].shift.id) {
				this.toast(false, 'El turno seleccionado es el turno ya asignado.', 10000)
				return false
			}
			let shift_name = this.shifts.data[shift_index].name.toUpperCase()
			this.$bvModal.msgBoxConfirm('¿Desea asignar el turno "'+shift_name+'" a "'+full_name.toUpperCase()+'"?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Asignar Turno',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.currentTask = {
						loading: true,
						id: worker_id
					}
					this.$api.post('workers/'+worker_id+'/shift', {
						shift_id: shift_id
					})
					.then(response => {
						this.currentTask = {
							loading: false,
							id: null
						}
						this.workers.data[index].shift = response.data
						this.toast(true, 'El turno "'+shift_name+'" fue correctamente asignado a "'+full_name.toUpperCase()+'".', 5000)
					})
					.catch(error => {
						this.currentTask = {
							loading: false,
							id: null
						}
						this.toast(false, error.response.data.message, 10000)
					})
				}
			})
		}
	}
}
</script>

<style scoped>
</style>
