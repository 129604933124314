<template>
	<div>


		<b-modal
			id="mantencion-mayor-trabajadores"
			title="Acreditación de Trabajadores"
			size="xl"
			hide-footer
		>
			<b-list-group>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Orden de Servicio</b-col>
						<b-col>
							<span v-if="service && service.cod">{{ service.cod }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Empresa</b-col>
						<b-col>
							<span v-if="service && service.company" class="text-uppercase">{{ service.company.full_name }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Título</b-col>
						<b-col>
							<span v-if="service && service.comments" class="text-uppercase">{{ service.comments }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Administrador</b-col>
						<b-col>
							<span v-if="service && service.administrator && service.administrator.person">{{ service.administrator.person.primary_last_name }} {{ service.administrator.person.second_last_name }} {{ service.administrator.person.names }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Fecha de inicio</b-col>
						<b-col>
							<span v-if="service && service.start_date">{{ service.start_date }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Fecha de término</b-col>
						<b-col>
							<span v-if="service && service.real_end_date">{{ service.real_end_date }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Dotación máxima</b-col>
						<b-col>
							<span v-if="service && service.workers_quantity">{{ service.workers_quantity }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
			<b-alert show variant="warning" class="mt-3 mb-3">
				<p class="mb-0 text-size-13">Para ver en detalle los requisitos de un trabajador, consulte el RUT en <strong>Módulo Reportes > Búsqueda Rápida</strong>.</p>
			</b-alert>
			<b-card no-body class="mt-3">
				<b-table
					:items="service.workers_new"
					class="mb-0"
					:fields="[
						{ key: 'index', label: 'N°', sortable: false },
						{ key: 'worker.person.identifier', label: 'RUT Trabajador', sortable: false },
						{ key: 'worker.person', label: 'Nombre Trabajador', sortable: false },
						{ key: 'driver_reqs', label: 'Conduce', sortable: false },
						{ key: 'worker_reqs', label: 'Estado de Requisitos', sortable: false },
						{ key: 'service_req', label: 'Estado en Nómina', sortable: false },
					]"
					hover
				>
					<template #head()="data">
						<div class="text-center">
							{{ data.label }}
						</div>
					</template>
					<template #head(worker.person)="data">
						<div class="text-left">
							{{ data.label }}
						</div>
					</template>
					<template #cell(worker.person.identifier)="data">
						<div class="text-center">
							{{ parseIdentifier(data.value) }}
						</div>
					</template>
					<template #cell(index)="data">
						<div class="text-center">
							{{ data.index + 1 }}
						</div>
					</template>
					<template #cell(worker_reqs)="data">
						<div class="text-center">
							<span v-if="(data.item.is_driver === true && data.item.worker.req_done === 15) || (data.item.is_driver === false && data.item.worker.req_done === 9)">
								<i class="fas fa-check-circle text-success"></i>
							</span>
							<span v-else>
								<i class="fas fa-times-circle text-danger"></i>
							</span>
							{{ data.item.worker.req_done }} de {{ data.item.is_driver === true ? '15' : '9' }}
						</div>
					</template>
					<template #cell(driver_reqs)="data">
						<div v-if="data.item.is_driver === true" class="text-center">
							<span class="text-muted">SÍ</span>
						</div>
						<div v-else class="text-center">
							<span class="text-muted">NO</span>
						</div>
					</template>
					<template #cell(service_req)="data">
						<div v-if="data.item.status === 2" class="text-center">
							<i class="fas fa-check-circle text-success mr-1"></i>
							<span>APROBADO</span>
						</div>
						<div v-else class="text-center">
							<i class="fas fa-times-circle text-danger mr-1"></i>
							<span>PENDIENTE</span>
						</div>
					</template>
					<template #cell(worker.person)="data">
						<div>
							{{ data.value.primary_last_name }} {{ data.value.second_last_name }} {{ data.value.names }}
						</div>
					</template>
				</b-table>
			</b-card>
		</b-modal>

		<b-modal
			id="mantencion-mayor-servicio"
			title="Acreditación de Servicio"
			size="lg"
			hide-footer
		>
			<b-list-group>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Orden de Servicio</b-col>
						<b-col>
							<span v-if="service && service.cod">{{ service.cod }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Empresa</b-col>
						<b-col>
							<span v-if="service && service.company" class="text-uppercase">{{ service.company.full_name }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Título</b-col>
						<b-col>
							<span v-if="service && service.comments" class="text-uppercase">{{ service.comments }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Administrador</b-col>
						<b-col>
							<span v-if="service && service.administrator && service.administrator.person">{{ service.administrator.person.primary_last_name }} {{ service.administrator.person.second_last_name }} {{ service.administrator.person.names }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Fecha de inicio</b-col>
						<b-col>
							<span v-if="service && service.start_date">{{ service.start_date }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Fecha de término</b-col>
						<b-col>
							<span v-if="service && service.real_end_date">{{ service.real_end_date }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
				<b-list-group-item>
					<b-row>
						<b-col sm="3" class="fw-bold">Dotación máxima</b-col>
						<b-col>
							<span v-if="service && service.workers_quantity">{{ service.workers_quantity }}</span>
							<b-skeleton v-else></b-skeleton>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
			<b-card no-body class="mt-3">
				<b-table
					:items="service.requirements"
					:fields="[
						{ key: 'document_type.name', label: 'Nombre del Requisito', sortable: false },
						{ key: 'document', label: 'Estado del Requisito', sortable: false },
					]"
					class="mb-0"
					hover
				>
					<template #cell(document)="data">
						<div v-if="data.value">
							<div v-if="data.value.action">
								<div v-if="data.value.status == 1">
									<i class="fas fa-check-circle text-success mr-2"></i>
									<span>APROBADO</span>
								</div>
								<div v-if="data.value.status == 2">
									<i class="fas fa-check-circle text-secondary mr-2"></i>
									<span>NO REQUERIDO</span>
								</div>
							</div>
							<div v-else>
								<i class="fas fa-times-circle text-danger mr-2"></i>
								<span>NO REVISADO</span>
							</div>
						</div>
						<div v-else>
							<i class="fas fa-times-circle text-danger mr-2"></i>
							<span>NO CARGADO</span>
						</div>
					</template>
				</b-table>
			</b-card>
		</b-modal>

		<b-card-group columns>

			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">ABB</p>
				</b-card-header>
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in abb" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.workers_done }} de {{ item.total_workers }}</span>
									</span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body class="mb-3">
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">EMPEMA</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in empema" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">IMT</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in imt" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">CLARO</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in claro" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">ELECMAIN</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in elecmain" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>


			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">ASH</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in ash" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>


			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">ELECTROFRIO</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in electrofrio" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">DUHOMEC</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in duhomec" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">DALEA</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in dalea" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

			<b-card no-body>
				<b-card-header class="px-3 py-2">
					<p class="mb-0 text-size-15 fw-bold">PROANORTE</p>
				</b-card-header>
				
				<b-list-group flush v-if="data.loading">
					<b-list-group-item>
						<b-skeleton></b-skeleton>
					</b-list-group-item>
				</b-list-group>
				<b-list-group flush v-else>
					<b-list-group-item v-for="(item, i) in proanorte" :key="i" no-body class="px-3 py-2">
						<span class="mb-0 text-muted float-right">
							<i class="far fa-calendar mr-2"></i>
							<span>{{ item.start_date }} </span>
							<i class="fas fa-arrow-right mx-1"></i>
							
							<span v-if="item.is_active">
								<span class="text-success">{{ item.real_end_date }}</span>
								<i class="fas fa-check-circle text-success ml-2"></i>
							</span>
							<span v-else v-b-tooltip.hover title="Servicio No Vigente" role="button">
								<span class="text-danger">{{ item.real_end_date }}</span>
								<i class="fas fa-times-circle text-danger ml-2"></i>
							</span>
						</span>
						<p class="mb-0 text-size-12 fw-bold">OS {{ item.cod }}</p>
						<p class="mb-1 text-size-11 text-muted text-uppercase">{{ item.comments }}</p>
						<b-row>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-briefcase text-size-13 mr-2"></i>
									<span class="text-size-13">Acreditación Servicio</span>
								</p>
								<p class="mb-2 text-size-12">
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="serviceDetail(item)">
										<span v-if="item.req_done == 9">
											<i class="fas fa-check-circle text-success mr-2"></i>
										</span>
										<span v-else>
											<i class="fas fa-times-circle text-danger mr-2"></i>
										</span>
										<span>{{ item.req_done }} de 9 requisitos</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<p class="mb-0">
									<i class="fas fa-user-hard-hat text-size-13 mr-2"></i>
									<span class="text-size-13">Trabajadores Acreditados</span>
								</p>
								<p class="mb-2 text-size-12" v-if="item.total_workers == 0" v-b-tooltip.hover title="No se han asociado trabajadores a este servicio" role="button">
									<i class="fas fa-exclamation-triangle text-warning mr-2"></i>
									<span class="text-secondary">Nómina vacía</span>
								</p>
								<p class="mb-2 text-size-12" v-else>
									<span v-b-tooltip.hover title="Presione para ver detalles" role="button" @click="workersDetail(item)">
										<span v-if="item.workers_done == item.total_workers">
										<i class="fas fa-check-circle text-success mr-2"></i>
									</span>
									<span v-else>
										<i class="fas fa-times-circle text-danger mr-2"></i>
									</span>
									<span>{{ item.workers_done }} de {{ item.total_workers }}</span></span>
								</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-card>

		</b-card-group>

	</div>
</template>

<script>
export default {
	name: 'UpgradeDCS',
	components: {
	},
	data() {
		return {
			data: {
				loading: true,
				items: {},
			},
			service: {},
		}
	},
	mounted() {
		this.load()
	},
	computed: {
		empema() {
			return this.data.loading == true ? [] : this.data.items.empema;
		},
		corsen() {
			return this.data.loading == true ? [] : this.data.items.corsen;
		},
		imt() {
			return this.data.loading == true ? [] : this.data.items.imt;
		},
		abb() {
			return this.data.loading == true ? [] : this.data.items.abb;
		},
		claro() {
			return this.data.loading == true ? [] : this.data.items.claro;
		},
		elecmain() {
			return this.data.loading == true ? [] : this.data.items.elecmain;
		},
		ash() {
			return this.data.loading == true ? [] : this.data.items.ash;
		},
		electrofrio() {
			return this.data.loading == true ? [] : this.data.items.electrofrio;
		},
		duhomec() {
			return this.data.loading == true ? [] : this.data.items.duhomec;
		},
		dalea() {
			return this.data.loading == true ? [] : this.data.items.dalea;
		},
		proanorte() {
			return this.data.loading == true ? [] : this.data.items.proanorte;
		},
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		workersDetail(item) {
			this.service = item
			this.$bvModal.show('mantencion-mayor-trabajadores')
		},
		serviceDetail(item) {
			this.service = item
			this.$bvModal.show('mantencion-mayor-servicio')
		},
		load() {
			this.$api.get('upgrade_dcs/data')
			.then(response => {
				console.log(response)
				this.data.items = response.data
				this.data.loading = false
			})
			.catch(error => {
				console.log(error)
				this.data.loading = false
			});
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
	}
}
</script>

<style scoped>
</style>
