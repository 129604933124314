<template>
	<div>

		<!-- VIEW MODAL -->
		<b-modal
			id="driver-requirement-view"
			title="Ver Documento"
			size="lg"
			body-class="p-4"
		>
			<b-row class="mb-2" v-if="requirement.data.document">
				<b-col>
					<p class="mb-1">Nombre del Documento</p>
					<h5>
						<span>{{requirement.data.document_type.name}}</span>
						<!-- VEHICLE TYPE -->
						<span class="text-info" v-if="requirement.data.document_type_id == 66">
							<span>: </span>
							<span v-if="requirement.data.vehicle_type && requirement.data.vehicle_type.name">{{requirement.data.vehicle_type.name}}</span>
							<span v-else class="text-danger">NO ESPECIFICADO</span>
						</span>
						<!-- /VEHICLE TYPE -->
					</h5>
				</b-col>
				<b-col sm="auto">
					<p class="mb-1">Fecha y Hora de Carga</p>
					<h5>{{requirement.data.document.created_at}}</h5>
				</b-col>
				<b-col sm="auto" v-if="requirement.data.document_type.date_validation == 1 || requirement.data.document_type.date_validation == 2">
					<p class="mb-1">Fecha de Emisión</p>
					<h5 v-if="requirement.data.document.creation_date!=null">{{requirement.data.document.creation_date}}</h5>
					<h5 v-else class="text-muted">N/A</h5>
				</b-col>
				<b-col sm="auto" v-if="requirement.data.document_type.date_validation == 2">
					<p class="mb-1">Fecha de Vencimiento</p>
					<h5 v-if="requirement.data.document.expiration_date=='2035-12-31'" class="text-muted">NO VENCE</h5>
					<h5 v-else-if="requirement.data.document.expiration_date!=null">{{requirement.data.document.expiration_date}}</h5>
					<h5 v-else class="text-muted">N/A</h5>
				</b-col>
			</b-row>
			<b-card no-body class="mb-3" v-if="requirement.data.document">
				<iframe :src="api_root + requirement.data.document.url" class="document-viewer"></iframe>
			</b-card>

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /VIEW MODAL -->

	</div>
</template>

<script>
export default {
	name: 'RequirementView',
	components: {
	},
	data() {
		return {
			loading: false
		}
	},
	props: {
		requirement: Object
	},
	computed: {
		api_root() {
			return this.$store.state.api_root
		},
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>
.document-viewer {
	width: 100%;
	height: 400px;
	border: 0px;
}
</style>
