<template>
	<Unauthorized v-if="!getters.prv_passes"></Unauthorized>
	<div v-else>

		<!-- COMPONENTS -->
		<Create @update="load()"></Create>
		<ViewPass :pass="pass"></ViewPass>
		<!-- /COMPONENTS -->

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<!--
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar un nuevo pase"
					v-if="getters.prv_passes_create"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				-->
				<b-overlay
					:show="passes.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de pases">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->
		
			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2">
				<b-button-group>
					<b-button
						:variant="query.active=='any'?'primary':'light'"
						@click="active('any')"
						v-b-tooltip.hover.bottomleft
						title="Todos los pases de visita"
					>Todo</b-button>
					<b-button
						:variant="query.active=='yes'?'primary':'light'"
						@click="active('yes')"
						v-b-tooltip.hover.bottomleft
						title="Pases de visita vigentes"
					>Vigente</b-button>
					<b-button
						:variant="query.active=='no'?'primary':'light'"
						@click="active('no')"
						v-b-tooltip.hover.bottomleft
						title="Pases de visita expirados"
					>No Vigente</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->
		
		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!passes.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="passes.data.total"> {{passes.data.total}} </span>
						<span v-else> 0 </span>
						<span>Pase<span v-if="passes.data.total > 1 || !passes.data.total">s</span> de visita</span>
						<span v-if="query.active == 'yes'"> vigente<span v-if="passes.data.total > 1 || !passes.data.total">s</span></span>
						<span v-else-if="query.active == 'no'"> no vigente<span v-if="passes.data.total > 1 || !passes.data.total">s</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="passes.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="passes.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="passes.data.data"
					:fields="options.fields"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
					class="table-with-dropdowns"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron pases que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(contact_name)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(actions)="data">
						<div class="text-center">
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-if="!getters.prv_passes_read">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-text
									disabled
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<b-button size="sm" variant="outline-primary" @click="view(data)" v-else>
									<i class="fas fa-cog mr-1"></i>
									<span class="text-size-09">GESTIONAR PASE</span>
							</b-button>
						</div>
					</template>
					<template v-slot:cell(status)="data">
						<div class="text-center">
							<b-badge v-if="data.value == 0" variant="dark">ANULADO</b-badge>
							<b-badge v-else-if="data.value == 1" variant="secondary">PENDIENTE</b-badge>
							<b-badge v-else-if="data.value == 2" variant="success">APROBADO</b-badge>
							<b-badge v-else-if="data.value == 3" variant="danger">RECHAZADO</b-badge>
						</div>
					</template>
					<template v-slot:cell(id)="data">
						<div class="text-center">
							<span>{{data.value}}</span>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<!-- HELP -->
		<b-modal id="passes-help" title="Información de Ayuda" size="lg">

			<p class="text-size-13">Esta es una guía rápida para conocer las funciones del módulo Pases de Visita.</p>

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="secondary">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /HELP -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import Create from '@/components/CreatePass.vue'
import ViewPass from '@/components/ViewPass.vue'

export default {
	name: 'Passes',
	components: {
		Unauthorized,
		Create,
		ViewPass
	},
	data() {
		return {
			pass: {},
			filtered: '',
			query: {
				active: 'yes',
				limit: 10,
				page: 1,
				filter: ''
			},
			options: {
				fields: [
					{ key: 'id', label: 'N° Pase', sortable: true },
					{ key: 'status', label: 'Estado' },
					{ key: 'date_start', label: 'Fecha', sortable: true },
					{ key: 'contact_name', label: 'Solicitante', sortable: true },
					{ key: 'created_at', label: 'Solicitado', sortable: true },
					{ key: 'actions', label: 'Acciones' }
				],
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 9999, text: 'Todos' }
				]
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		passes() {
			return this.$store.state.passes
		},
	},
	created() {
		this.load()
	},
	methods: {
		active(type) {
			this.query.active = type
			this.load()
		},
		create() {
			this.$bvModal.show('create-pass')
		},
		update(pass) {
			this.$store.dispatch('get_pass', pass.item.id)
			this.$bvModal.show('update-pass')
		},
		view(pass) {
			this.pass = pass
			this.$bvModal.show('view-pass')
		},
		load() {
			this.$store.dispatch('get_passes', this.query)
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un número de pase o nombre para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.active = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
			this.filtered = ''
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.active = 'yes'
			this.query.filter = ''
			this.query.limit = 9999
			this.filtered = ''
			this.load()
		},
		help() {
			this.$bvModal.show('passes-help')
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
	}
}
</script>

<style>
</style>