<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="delete-subcontractor"
			title="Eliminar Empresa Subcontratista"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- DATA -->
				<b-row v-if="owner.item" class="mb-4">
					<b-col sm="auto" class="mb-3">
						<h6 class=" text-secondary">RUT</h6>
						<p class="text-size-12 mb-0">{{parseIdentifier(owner.item.identifier)}}</p>
					</b-col>
					<b-col>
						<h6 class=" text-secondary">Razón Social</h6>
						<p class="text-size-12 mb-0 text-uppercase">{{owner.item.full_name}}</p>
					</b-col>
					<b-col sm="auto" class="cursor-pointer" v-b-tooltip.hover :title="validation(owner, 'detail')">
						<h6 class=" text-secondary">Acreditada</h6>
						<p class="text-size-12 mb-0 text-uppercase">
							<b-badge variant="success" class="cursor-pointer" v-if="validation(owner, 'validated')">SÍ</b-badge>
							<b-badge variant="danger" class="cursor-pointer" v-else>NO</b-badge>
						</p>
					</b-col>
					<b-col sm="12">
						<h6 class=" text-secondary">Mandante</h6>
						<p class="text-size-12 mb-0 text-uppercase">{{owner.item.contractor.short_name}}</p>
					</b-col>
				</b-row>
				<b-alert show variant="danger" class="text-size-11">
					<p class="mb-0"><strong>¡ADVERTENCIA!</strong></p>
					<p>Al eliminar una empresa subcontratista, sus servicios, trabajadores y vehículos también serán eliminados del sistema.</p>
					<p class="mb-0">Si desea continuar, marque la casilla de confirmación y luego presione el botón ELIMINAR.</p>
				</b-alert>
				<!-- /DATA -->

				<!-- CONFIRMATION -->
				<b-form-group>
					<b-form-checkbox v-model="confirmation" size="lg" class="text-size-11">
						<strong v-if="confirmation" class="text-danger">Confirmo que deseo continuar con la eliminación.</strong>
						<span v-else>Confirmo que deseo continuar con la eliminación.</span>
					</b-form-checkbox>
				</b-form-group>
				<!-- /CONFIRMATION -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="danger" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">Cancelar</b-button>
				<b-button @click="destroy()" variant="danger" :disabled="loading || !confirmation">ELIMINAR</b-button>
			</template>

			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'DeleteSubcontractor',
	components: {
	},
	data() {
		return {
			loading: false,
			confirmation: false
		}
	},
	props: {
		owner: Object
	},
	computed: {
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'delete-subcontractor') { this.confirmation = false }
		})
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		destroy() {
			this.loading = true
			this.$api.delete('subcontractors/'+this.owner.item.id)
			.then(() => {
				this.loading = false
				this.$emit('update')
				this.$bvModal.hide('delete-subcontractor')
				this.toast(true, 'Se eliminó la empresa subcontratista "'+this.owner.item.full_name+'" del mandante "'+this.owner.item.contractor.short_name+'" correctamente.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		validation(owner, type) {
			const done = owner.item.req_done
			const rejected = owner.item.req_rejected
			const waiting = owner.item.req_waiting
			const missing = owner.item.req_missing
			const total = done + rejected + waiting + missing
			const validated = done == total ? true : false
			const completed = Math.ceil(done / total * 100)
			if (type == 'total') return total
			if (type == 'completed') return completed
			if (type == 'validated') return validated
			if (type == 'done') return done
			if (type == 'detail') return 'Cumple con ' + done + ' de ' + total + ' requisitos ('+ completed + '%)'
			return null
		},
	}
}
</script>

<style scoped>
</style>
