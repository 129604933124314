<template>
	<Unauthorized v-if="!getters.prv_system || !getters.prv_system_settings_view"></Unauthorized>
	<div v-else>

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('System')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Sistema">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-overlay
					:show="user_logs.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar registro de actividad">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2" v-if="query.type != 1">
				<b-button-group>
					<b-button
						:variant="query.type=='any'?'primary':'light'"
						@click="type('any')"
						v-b-tooltip.hover.bottomleft
						title="Todos los usuarios"
					>Todo</b-button>
					<b-button
						:variant="query.type=='people'?'primary':'light'"
						@click="type('people')"
						v-b-tooltip.hover.bottomleft
						title="Usuarios de personas"
					>Personas</b-button>
					<b-button
						:variant="query.type=='companies'?'primary':'light'"
						@click="type('companies')"
						v-b-tooltip.hover.bottomleft
						title="Usuarios de empresas"
					>Empresas</b-button>
					<b-button
						:variant="query.type=='admin'?'primary':'light'"
						@click="type('admin')"
						v-b-tooltip.hover.bottomleft
						title="Usuario Administrador"
					>Administrador</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->


		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!user_logs.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="user_logs.data.total"> {{user_logs.data.total}} </span>
						<span v-else> 0 </span>
						<span v-if="query.type == 'any'">Registro<span v-if="user_logs.data.total > 1 || !user_logs.data.total">s</span></span>
						<span v-else-if="query.type == 'people'">Registro<span v-if="user_logs.data.total > 1 || !user_logs.data.total">s</span> de personas</span>
						<span v-else-if="query.type == 'companies'">Registro<span v-if="user_logs.data.total > 1 || !user_logs.data.total">s</span> de empresas</span>
						<span v-else-if="query.type == 'admin'">Registro<span v-if="user_logs.data.total > 1 || !user_logs.data.total">s</span> de administrador</span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="user_logs.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="user_logs.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="user_logs.data.data"
					:fields="fields"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron registros que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(id)="data">
						<div>
							<b-badge variant="primary" class="cursor-pointer" @click="detail(data.item)">{{data.value}}</b-badge>
						</div>
					</template>
					<template v-slot:cell(created_at)="data">
						<div>
							<span style="white-space:pre" class="text-muted">{{data.value}}</span>
						</div>
					</template>
					<template v-slot:cell(user.rut)="data">
						<div>
							<span style="white-space:pre">{{parseIdentifier(data.value)}}</span>
						</div>
					</template>
					<template v-slot:cell(user.name)="data">
						<div>
							<span style="white-space:pre">{{data.value}}</span>
						</div>
					</template>
					<template v-slot:cell(body)="data">
						<div>
							<code>{{data.value}}</code>
						</div>
					</template>
					<template v-slot:cell(path)="data">
						<div>
							<code>{{data.value}}</code>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<b-modal id="user-log-detail" hide-header size="lg">
			<pre class="log">{{current_log}}</pre>
			<template #modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'

export default {
	name: 'UserLogs',
	components: {
		Unauthorized,
	},
    data(){
        return {
			current_log: {},
			owner: {},
			user: {},
			privileges: {},
			filtered: '',
			query: {
				type: 'people',
				limit: 20,
				page: 1,
				filter: ''
			},
			fields: [
				{ key: 'id', label: 'ID', sortable: true },
				{ key: 'created_at', label: 'Fecha/Hora', sortable: true },
				{ key: 'user.rut', label: 'RUT', sortable: true },
				{ key: 'user.name', label: 'Nombre', sortable: true },
				{ key: 'method', label: 'Método', sortable: true },
				{ key: 'path', label: 'Ruta', sortable: true },
				{ key: 'body', label: 'Solicitud', sortable: true },
			],
			options: {
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 250, text: '250 por página' },
					{ value: 500, text: '500 por página' },
					{ value: 1000, text: '1000 por página' }
				]
			}
        }
    },
	computed: {
		getters() {
			return this.$store.getters
		},
        user_logs() {
            return this.$store.state.user_logs
        }
	},
    created() {
		this.load()
    },
    methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        refresh() {
            this.$store.dispatch('get_user_logs')
        },
		load() {
			this.$store.dispatch('get_user_logs', this.query)
		},
		type(type) {
			this.query.type = type
			this.load()
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un RUT, nombre o e-mail para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.type = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
				this.filtered = ''
				this.query.type = 'any'
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.type = 'any'
			this.query.filter = ''
			this.query.limit = 10
			this.filtered = ''
			this.load()
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
		detail(log) {
			this.current_log = log
			this.$bvModal.show('user-log-detail')
		},
        shortName(name) {
            return name ? name.length > 40 ? name.substring(0,35) + '...' : name : ''
        },
    }
}
</script>

<style scoped>
.log {
	white-space: pre-wrap;
	background: #000;
	color: #fff;
	border-radius: 3px;
	padding: 5px;
	margin-bottom: 0px;
}
</style>