<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-user"
			title="Registrar Nuevo Usuario"
			size="lg"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="6">
						<b-form-group label="Trabajador">
							<b-overlay :show="workers.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="user.worker_id" :disabled="workers.loading" :state="val_worker">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Trabajador...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in workers.data" :key="index" :value="item.id">{{item.person.primary_last_name}} {{item.person.second_last_name}} {{item.person.names}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback :state="val_worker">Seleccione una comuna</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_worker">OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="E-mail">
							<b-input v-model="user.email" :state="val_email"></b-input>
							<b-form-invalid-feedback>Ingrese un correo electrónico válido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
				
				<b-card no-body v-if="!privileges.loading" class="mt-3 mb-3">
					<b-tabs pills card vertical>
						<b-tab v-for="(item, index) in privileges.data" :key="index" no-body>
							<template #title>
								{{item.name}}
							</template>
							<h4 class="px-3 pt-3 mb-1">{{item.description}}</h4>
							<p class="px-3 text-muted text-size-11">Seleccione los privilegios que desee asignar al nuevo usuario.</p>
							<b-table
								:items="item.privileges"
								:fields="privilege_fields"
								small
								hover
								class="mb-0"
							>
								<template v-slot:head(enabled)="data">
									<div class="text-left ml-2">
										<span>{{data.label}}</span>
									</div>
								</template>
								<template v-slot:cell(enabled)="data">
									<div class="text-left ml-2">
										<b-form-checkbox v-model="user.privileges" :value="data.item.id">
											<span>{{data.item.description}}</span>
										</b-form-checkbox>
									</div>
								</template>
							</b-table>
						</b-tab>
					</b-tabs>
				</b-card>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón REGISTRAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="!val_full || loading">REGISTRAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'CreateUser',
	components: {
	},
	data() {
		return {
			loading: false,
			user: {
				worker_id: null,
				email: '',
				privileges: []
			},
            privilege_fields: [
                { key: 'enabled', label: 'Privilegio', sortable: true }
            ],
		}
	},
	computed: {
		workers() {
			return this.$store.state.workers
		},
		privileges() {
			return this.$store.state.privilege_list
		},
		val_full() {
			return this.val_email &&
					this.val_worker ? true : false
		},
		val_email() {
			var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return this.user.email.length > 0 ? regex.test(this.user.email) ? true : false : false
		},
		val_worker() {
			return this.user.worker_id != null ? true : false
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-user') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.user = {
				worker_id: null,
				email: '',
				privileges: []
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('users', this.user)
			.then(() => {
				this.loading = false
				this.$emit('update')
				this.$bvModal.hide('create-user')
				this.toast(true, 'Se registró la nueva cuenta de usuario y se envió la información de acceso al correo electrónico ingresado.', 10000)
				this.reset()
			})
			.catch(error => {
				this.loading = false
				if (error.response.status == 409) {
					if (error.response.data.type == 'email') this.user.email = ''
					if (error.response.data.type == 'identifier') this.user.worker_id = null
				}
				this.toast(false, error.response.data.message, 10000)
			})
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		}
	}
}
</script>

<style scoped>
</style>
