<template>
	<div id="app">
		<Header v-if="!login"></Header>
		<b-container :fluid="!fluid?'xl':''" class="mt-4">
			<Navigation v-if="session"></Navigation>
			<router-view></router-view>
			<Footer v-if="!logout"></Footer>
		</b-container>
		<SystemUpdate></SystemUpdate>
	</div>
</template>
<script>
import Navigation from '@/components/core/Navigation.vue'
import Footer from '@/components/core/Footer.vue'
import Header from '@/components/core/Header.vue'
import SystemUpdate from '@/components/core/SystemUpdate.vue'

export default {
	name: 'AccessUI',
	components: {
		Header,
		Navigation,
		Footer,
		SystemUpdate
	},
	created() {
		this.$api.get('system/build')
		.then(response => {
			if (response.data > this.$store.state.build) {
				this.$bvModal.show('system-update')
			}
		})
		if (this.session) {
			this.$store.dispatch('get_session_privileges')
		}
	},
	computed: {
		fluid() {
			return this.$route.meta.fluid
		},
		session() {
			return this.$store.getters.session
		},
		login() {
			return this.$route.name === 'Login' || this.$route.name === 'Recover' || this.$route.name === 'Reset'
		},
		logout() {
			return this.$route.name === 'Logout'
		}
	},
}
</script>

<style lang="scss">
</style>
