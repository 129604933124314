<template>
	<Unauthorized
		v-if="(!getters.prv_internal_vehicles && query.type === 1) ||
		(!getters.prv_contractor_vehicles && query.type === 2) ||
		(!getters.prv_subcontractor_vehicles && query.type === 3)"
	></Unauthorized>
	<div v-else>

		<!-- COMPONENTS -->
		<Requirements @refresh="load()" :owner="owner"></Requirements>
		<Create @update="load()" :type="query.type"></Create>
		<Update @update="load()" :type="query.type"></Update>
		<Delete @update="load()" :owner="owner" :type="query.type"></Delete>
		<VehicleQR :vehicle="owner"></VehicleQR>
		<!-- /COMPONENTS -->

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar un nuevo vehículo"
					v-if="(getters.prv_internal_vehicles_create && query.type === 1) ||
					(getters.prv_contractor_vehicles_create && query.type === 2) ||
					(getters.prv_subcontractor_vehicles_create && query.type === 3)"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				<b-overlay
					:show="vehicles.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de vehículos">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2" v-if="query.type != 1">
				<b-button-group>
					<b-button
						:variant="query.active=='any'?'primary':'light'"
						@click="active('any')"
						v-b-tooltip.hover.bottomleft
						title="Todos los vehículos"
					>Todo</b-button>
					<b-button
						:variant="query.active=='yes'?'primary':'light'"
						@click="active('yes')"
						v-b-tooltip.hover.bottomleft
						title="Vehículos de empresas vigentes"
					>Vigente</b-button>
					<b-button
						:variant="query.active=='no'?'primary':'light'"
						@click="active('no')"
						v-b-tooltip.hover.bottomleft
						title="Vehículos de empresas no vigentes"
					>No Vigente</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->

		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!vehicles.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="vehicles.data.total"> {{vehicles.data.total}} </span>
						<span v-else> 0 </span>
						<span v-if="query.type == 1">Vehículo<span v-if="vehicles.data.total > 1 || !vehicles.data.total">s</span> HMC</span>
						<span v-else-if="query.type == 2">Vehículo<span v-if="vehicles.data.total > 1 || !vehicles.data.total">s</span> contratista<span v-if="vehicles.data.total > 1 || !vehicles.data.total">s</span></span>
						<span v-else-if="query.type == 3">Vehículo<span v-if="vehicles.data.total > 1 || !vehicles.data.total">s</span> subcontratista<span v-if="vehicles.data.total > 1 || !vehicles.data.total">s</span></span>
						<span v-if="query.active == 'yes'"> vigente<span v-if="vehicles.data.total > 1 || !vehicles.data.total">s</span></span>
						<span v-else-if="query.active == 'no'"> no vigente<span v-if="vehicles.data.total > 1 || !vehicles.data.total">s</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="vehicles.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="vehicles.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="vehicles.data.data"
					:fields="query.type == 1 ? options.fields.simple : options.fields.advanced"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
					class="table-with-dropdowns"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron vehículos que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(identifier)="data">
						<div class="pl-2">
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(manufacturer)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(model)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(company.short_name)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(requirements)="data">
						<div class="text-center">

							<!-- INTERNAL VEHICLES -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-if="query.type === 1">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_internal_vehicles_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Vehículo</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="qrcode(data)" v-if="getters.prv_internal_vehicles_read">
									<i class="fas fa-qrcode text-muted mr-2"></i>
									<span>Ver Código QR</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_internal_vehicles_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_internal_vehicles_update && !getters.prv_internal_vehicles_delete"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /INTERNAL VEHICLES -->

							<!-- CONTRACTOR VEHICLES -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-else-if="query.type === 2">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_contractor_vehicles_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_contractor_vehicles_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Vehículo</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="qrcode(data)" v-if="getters.prv_contractor_vehicles_read">
									<i class="fas fa-qrcode text-muted mr-2"></i>
									<span>Ver Código QR</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_contractor_vehicles_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_contractor_vehicles_read && !getters.prv_contractor_vehicles_update && !getters.prv_contractor_vehicles_delete"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /CONTRACTOR VEHICLES -->

							<!-- SUBCONTRACTOR VEHICLES -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-else-if="query.type === 3">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_subcontractor_vehicles_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_subcontractor_vehicles_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Vehículo</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="qrcode(data)" v-if="getters.prv_subcontractor_vehicles_read">
									<i class="fas fa-qrcode text-muted mr-2"></i>
									<span>Ver Código QR</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_subcontractor_vehicles_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_subcontractor_vehicles_read && !getters.prv_subcontractor_vehicles_update && !getters.prv_subcontractor_vehicles_delete"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /SUBCONTRACTOR VEHICLES -->

						</div>
					</template>
					<template v-slot:cell(req_done)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="success">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_rejected)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_waiting)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_missing)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_pending)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<!-- HELP -->
		<b-modal id="vehicles-help" title="Información de Ayuda" size="lg">

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="secondary">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /HELP -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import Requirements from '@/components/Requirements.vue'
import Create from '@/components/CreateVehicle.vue'
import Update from '@/components/UpdateVehicle.vue'
import Delete from '@/components/DeleteVehicle.vue'
import VehicleQR from '@/components/VehicleQR.vue'

export default {
	name: 'Vehicles',
	components: {
		Unauthorized,
		Create,
		Update,
		Delete,
		Requirements,
		VehicleQR
	},
	data() {
		return {
			owner: {},
			filtered: '',
			query: {
				type: 0,
				active: 'any',
				limit: 10,
				page: 1,
				filter: ''
			},
			options: {
				fields: {
					simple: [
						{ key: 'identifier', label: 'Patente', sortable: true },
						{ key: 'manufacturer', label: 'Marca', sortable: true },
						{ key: 'model', label: 'Modelo', sortable: true },
						{ key: 'year', label: 'Año', sortable: true },
						{ key: 'vehicle_type.name', label: 'Tipo', sortable: true },
						{ key: 'company.short_name', label: 'Empresa', sortable: true },
						{ key: 'requirements', label: 'Acciones' }
					],
					advanced: [
						{ key: 'identifier', label: 'Patente', sortable: true },
						{ key: 'manufacturer', label: 'Marca', sortable: true },
						{ key: 'model', label: 'Modelo', sortable: true },
						{ key: 'year', label: 'Año', sortable: true },
						{ key: 'vehicle_type.name', label: 'Tipo', sortable: true },
						{ key: 'company.short_name', label: 'Empresa', sortable: true },
						{ key: 'requirements', label: 'Acciones' },
						{ key: 'req_done', label: 'Aprobados', sortable: true },
						{ key: 'req_pending', label: 'Pendientes', sortable: true }
					]
				},
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 250, text: '250 por página' },
					{ value: 500, text: '500 por página' },
					{ value: 1000, text: '1000 por página' },
					{ value: 9999, text: 'Todos' }
				]
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		vehicles() {
			return this.$store.state.vehicles
		},
		vehicle_type() {
			return this.$store.state.subroutes.vehicles
		}
	},
	created() {
		this.query.type = this.vehicle_type != 0 ? this.vehicle_type : this.$route.meta.type
		this.load()
	},
	watch: {
		vehicle_type(type) {
			this.type(type)
		}
	},
	methods: {
		active(type) {
			this.query.active = type
			this.load()
		},
		create() {
			if (this.query.type == 3) {
				this.$store.dispatch('get_subcontractors')
			} else if (this.query.type == 2) {
				this.$store.dispatch('get_contractors')
			}
			this.$store.dispatch('get_vehicle_types')
			this.$bvModal.show('create-vehicle')
		},
		update(vehicle) {
			this.$store.dispatch('get_vehicle_types')
			this.$store.dispatch('get_vehicle', vehicle.item.id)
			this.$bvModal.show('update-vehicle')
		},
		destroy(vehicle) {
			this.owner = vehicle
			this.$bvModal.show('delete-vehicle')
		},
		qrcode(vehicle) {
			this.owner = vehicle
			this.$bvModal.show('vehicle-qr')
		},
		type(type) {
			this.query.type = type
			this.query.active = 'any'
			this.query.filter = ''
			this.filtered = ''
			this.$bvModal.hide('vehicle-selector')
			this.load()
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba una marca, patente o empresa para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.active = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
				this.filtered = ''
				this.query.active = 'any'
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.active = 'any'
			this.query.filter = ''
			this.query.limit = 10
			this.filtered = ''
			this.load()
		},
		load() {
			this.$store.dispatch('get_vehicles', this.query)
		},
		help() {
			this.$bvModal.show('vehicles-help')
		},
		requirements(vehicle) {
			this.$store.dispatch('get_vehicle_requirements', vehicle.item.id)
			this.owner = vehicle
			this.$bvModal.show('requirements')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
	}
}
</script>

<style>
.text-size-08 {
	font-size: 0.8em;
}
.text-size-085 {
	font-size: 0.85em;
}
.text-size-12 {
	font-size: 1.2em;
}
.text-size-14 {
	font-size: 1.4em;
}
</style>