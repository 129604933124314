<template>
	<div>
		<b-container class="p-0">
			<b-navbar id="navigation" toggleable="lg" type="dark" variant="dark" class="mb-3" v-if="!session">
				<b-navbar-brand>
					<b-img src="/img/logo_hmc_bordered.svg" class="logo"></b-img>
					<span class="text-white">Reporte Diario</span>
				</b-navbar-brand>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav class="ml-auto">
						<router-link to="/ingresar" custom v-slot="{ navigate }">
							<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
								<i class="fas fa-lock mr-2"></i>
								<span>Iniciar Sesión</span>
							</b-nav-item>
						</router-link>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>
			<b-overlay :show="loading" rounded="sm">
				<b-card no-body>
					<b-card-body v-if="checking">
						<div class="text-center pt-3 pb-2">
							<b-spinner type="grow" variant="primary"></b-spinner>
							<h3 class="mt-3">Cargando Reporte</h3>
							<p class="text-size-15 mb-0 text-muted">Por favor, espere un momento.</p>
						</div>
					</b-card-body>
					<b-card-body v-if="invalid">
						<div class="text-center pt-2 pb-2">
							<i class="fas fa-minus-circle text-size-3 text-danger"></i>
							<h3 class="mt-3">Enlace Inválido</h3>
							<p class="text-size-15 mb-0 text-muted">{{error_message}}</p>
						</div>
					</b-card-body>
					<div v-else-if="!checking && !invalid">
						<b-card-header>
							<b-row>
								<b-col>
									<h4>{{data.meta.day}} de {{getMonthName(data.meta.month)}} de {{data.meta.year}}</h4>
									<h6 class="mb-0 text-muted">Desde: 00:00:00 &ndash; Hasta: {{data.meta.time}}</h6>
								</b-col>
								<b-col>
									<p v-if="updating" class="float-right text-danger">
										<i class="fas fa-circle mr-2"></i>
										<strong>ACTUALIZANDO</strong>
									</p>
								</b-col>
							</b-row>
						</b-card-header>
						<b-tabs card>
							<b-tab title="Resumen de Ingresos" active>
								<p class="text-size-11 text-center text-muted">Desplace el cursor sobre el gráfico para destacar información.</p>
								<apexchart :options="chart.options.donut" :series="data.donut" height="600"></apexchart>
							</b-tab>
							<b-tab title="Ingresos No Acreditados">
								<p class="text-size-11 text-center text-muted">Desplace el cursor sobre el gráfico para destacar información.</p>
								<apexchart :options="chart.options.tree" :series="data.tree" height="600"></apexchart>
							</b-tab>
							<b-tab title="Detalle por Empresa">
								<p class="text-size-11 text-center text-muted">Presione las cabeceras de las columnas para reordenar la información.</p>
								<b-card no-body>
									<b-table
										:items="data.table"
										:fields="fields"
										:sort-by.sync="sortBy"
										:sort-desc.sync="sortDesc"
										hover
										class="mb-0"
										thead-class="thead-light"
									>
										<template #cell(count)="data">
											<div class="text-center">
												<span>{{data.value}}</span>
											</div>
										</template>
										<template #bottom-row>
											<b-th colspan="2" class="text-right" variant="info">
												<span class="text-size-12">TOTAL</span>
											</b-th>
											<b-td class="text-center" variant="info">
												<span class="text-size-12">{{tableTotal}}</span>
											</b-td>
										</template>
									</b-table>
								</b-card>
							</b-tab>
						</b-tabs>
					</div>
				</b-card>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->
			</b-overlay>
			
		</b-container>

	</div>
</template>

<script>
export default {
	name: 'PublicDailyReport',
	components: {
	},
	data() {
		return {
			checking: true,
			updating: false,
			invalid: false,
			loading: false,
			error_message: 'El enlace de reporte utilizado no es válido.',
			sortBy: 'type',
			sortDesc: false,
			data: {
				meta: {
					start: '',
					until: '',
					day: ''
				},
				counters: {
					global: {
						total: 0,
						in: 0,
						out: 0
					},
					validated: {
						total: 0,
						person: 0,
						vehicle: 0
					},
					unknown: 0,
					pass_approved: 0,
					pass_rejected: 0,
					internal: {
						total: 0,
						person: 0,
						vehicle: 0,
						hmc: {
							person: 0,
							vehicle: 0
						},
						transparent: {
							person: 0,
							vehicle: 0
						}
					},
					a1: {
						total: 0,
						person: 0,
						vehicle: 0
					},
					a2: {
						total: 0,
						person: 0,
						vehicle: 0
					},
					a3: {
						total: 0,
						person: 0,
						vehicle: 0
					}
				},
				donut: [],
				tree: [],
				table: []
			},
			fields: [
				{ key: 'name', label: 'EMPRESA', sortable: true },
				{ key: 'type', label: 'RESULTADO', sortable: true },
				{ key: 'count', label: 'CANTIDAD', sortable: true }
			],
			chart: {
				options: {
					tree: {
						chart: {
							height: 350,
							type: 'treemap',
							toolbar: {
								show: false
							}
						}
					},
					donut: {
						chart: {
							type: 'donut',
						},
						stroke: {
							show: false
						},
						tooltip: {
							enabled: true
						},
						labels: [
							'Trabajador HMC',
							'Vehículo HMC',
							'Modo Transparente (Trabajador)',
							'Modo Transparente (Vehículo)',
							'Trabajador Acreditado',
							'Vehículo Acreditado',
							'Empresa No Acreditada',
							'Servicio No Acreditado',
							'Trabajador No Acreditado',
							'Vehículo No Acreditado',
							'Pase Aprobado',
							'Pase Rechazado',
							'Desconocido',
						],
						plotOptions: {
							pie: {
								donut: {
									labels: {
										show: true,
										name: {
											show: true,
											fontWeight: 600
										},
										total: {
											show: true
										}
									}
								}
							}
						},
						fill: {
							opacity: 1
						},
						legend: {
							show: true,
							formatter: function(seriesName, opts) {
								return [seriesName, " (", opts.w.globals.series[opts.seriesIndex], ")"]
							}
						},
					}
				}
			}
		}
	},
	mounted() {
		this.load()
	},
	created() {
		this.interval = setInterval(() => this.load(), 30000);
	},
	computed: {
		session() {
			return this.$store.getters.session
		},
		token() {
			return this.$route.params.token
		},
		tableTotal() {
			if (this.data.table.length) {
				let total = 0
				for (let i = 0; i < this.data.table.length; i++) {
					total = total + this.data.table[i].count
				}
				return total
			} else {
				return 0
			}
		}
	},
	methods: {
		getMonthName(month) {
			if (month == '01') return 'enero'
			else if (month == '02') return 'febrero'
			else if (month == '03') return 'marzo'
			else if (month == '04') return 'abril'
			else if (month == '05') return 'mayo'
			else if (month == '06') return 'junio'
			else if (month == '07') return 'julio'
			else if (month == '08') return 'agosto'
			else if (month == '09') return 'septiembre'
			else if (month == '10') return 'octubre'
			else if (month == '11') return 'noviembre'
			else if (month == '12') return 'diciembre'
			else { return '' }
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay,
				toaster: 'b-toaster-top-center'
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		load() {
			this.updating = true
			this.$api.get('stats/daily/public/'+this.token)
			.then(response => {
				this.updating = false
				this.checking = false
				this.data = response.data
			})
			.catch(error => {
				this.updating = false
				this.checking = false
				this.invalid = true
				if (error.response.data.message) this.error_message = error.response.data.message
			})
		}
	}
}
</script>

<style scoped>
</style>
