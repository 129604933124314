<template>
	<div>

		<!-- FORM MODAL -->
		<b-modal
			id="driver-requirement-form"
			title="Solicitud de Licencia Interna"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">
				<div class="card">

					<div class="card-body bg-light">
						<p class="mb-0 fw-bold text-primary text-uppercase text-size-09">RUT</p>
						<p class="mb-2 text-size-13">{{ current && current.person && current.person.identifier ? parseIdentifier(current.person.identifier) : null }}</p>
						<p class="mb-0 fw-bold text-primary text-uppercase text-size-09">Nombre Completo</p>
						<p class="mb-2 text-size-13">
							<span>{{ current && current.person && current.person.names ? current.person.names : null }}</span>
							<span class="mx-1">{{ current && current.person && current.person.primary_last_name ? current.person.primary_last_name : null }}</span>
							<span>{{ current && current.person && current.person.second_last_name ? current.person.second_last_name : null }}</span><br>
						</p>
						<p class="mb-0 fw-bold text-primary text-uppercase text-size-09">Empresa</p>
						<p class="mb-0 text-size-13">{{ current ? current.company.full_name : null }}</p>
					</div>
					<hr class="my-0">
					<div class="card-body">
						<div class="alert alert-primary py-1 px-2 mb-2">
							<i class="fas fa-ban"></i>
							<span class="ml-2 fw-bold text-uppercase">Complete Restricciones:</span>
						</div>
						<div class="row text-size-11">
							<div class="col-4">
								<p class="mb-0">¿Lentes?</p>
								<div class="form-check form-check-inline">
									<input v-model="driving_license.restrictions_glasses" class="form-check-input" type="radio" name="restrictions_glasses" id="restrictions_glasses1" :value="true">
									<label class="form-check-label" for="restrictions_glasses1">Sí</label>
								</div>
								<div class="form-check form-check-inline ml-3">
									<input v-model="driving_license.restrictions_glasses" class="form-check-input" type="radio" name="restrictions_glasses" id="restrictions_glasses2" :value="false">
									<label class="form-check-label" for="restrictions_glasses2">No</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.restrictions_other" class="form-check-input" type="checkbox" id="restrictions_other" @input="driving_license.restrictions_other_detail = null">
									<label class="form-check-label" for="restrictions_other">Otras:</label>
								</div>
								<input v-model="driving_license.restrictions_other_detail" type="text" class="form-control form-control-sm" :disabled="driving_license.restrictions_other === false">
							</div>
						</div>
					</div>
					<hr class="my-0">
					<div class="card-body">
						<div class="alert alert-primary py-1 px-2">
							<i class="fas fa-id-card"></i>
							<span class="ml-2 fw-bold text-uppercase">Seleccione Clases de Licencia Municipal:</span>
						</div>
						<div class="row mb-2 text-size-11">
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="1" id="driving_license_1">
									<label class="form-check-label" for="driving_license_1">Clase A1</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="2" id="driving_license_2">
									<label class="form-check-label" for="driving_license_2">Clase A2</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="3" id="driving_license_3">
									<label class="form-check-label" for="driving_license_3">Clase A3</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="4" id="driving_license_4">
									<label class="form-check-label" for="driving_license_4">Clase A4</label>
								</div>
							</div>
						</div>
						<div class="row text-size-11 mb-2">
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="5" id="driving_license_5">
									<label class="form-check-label" for="driving_license_5">Clase A5</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="6" id="driving_license_6">
									<label class="form-check-label" for="driving_license_6">Clase B</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="7" id="driving_license_7">
									<label class="form-check-label" for="driving_license_7">Clase C</label>
								</div>
							</div>
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="8" id="driving_license_8">
									<label class="form-check-label" for="driving_license_8">Clase D</label>
								</div>
							</div>
						</div>
						<div class="row text-size-11">
							<div class="col">
								<div class="form-check">
									<input v-model="driving_license.classes" class="form-check-input" type="checkbox" :value="9" id="driving_license_9">
									<label class="form-check-label" for="driving_license_9">Clase F</label>
								</div>
							</div>
							<div class="col"></div>
							<div class="col"></div>
							<div class="col"></div>
						</div>
					</div>
					<hr class="my-0">
					<div class="card-body">
						<div class="alert alert-primary py-1 px-2">
							<i class="fas fa-truck"></i>
							<span class="ml-2 fw-bold text-uppercase">Seleccione Equipos:</span>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="1" id="driving_equipment_1">
							<label class="form-check-label" for="driving_equipment_1">Ambulancias (A2/A3)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="2" id="driving_equipment_2">
							<label class="form-check-label" for="driving_equipment_2">Buses (A3) - Ley Antigua (A1/A2)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="3" id="driving_equipment_3">
							<label class="form-check-label" for="driving_equipment_3">Camionetas (B)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="4" id="driving_equipment_4">
							<label class="form-check-label" for="driving_equipment_4">Carry All (A1/A2/A3)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="5" id="driving_equipment_5">
							<label class="form-check-label" for="driving_equipment_5">Camiones sobre 3.500 kgs. (A3)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="6" id="driving_equipment_6">
							<label class="form-check-label" for="driving_equipment_6">Camiones Pluma / Grúa (A5/D)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="7" id="driving_equipment_7">
							<label class="form-check-label" for="driving_equipment_7">Minibuses (A2/A3)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="8" id="driving_equipment_8">
							<label class="form-check-label" for="driving_equipment_8">Taxis (A1/A2/A3)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.equipments" class="form-check-input" type="checkbox" :value="9" id="driving_equipment_9" @input="driving_license.class_d_other = null">
							<label class="form-check-label" for="driving_equipment_9">Otros Equipos Licencia Clase D:</label>
						</div>
						<input v-model="driving_license.class_d_other" type="text" class="form-control form-control-sm" :disabled="!driving_license.equipments.find(e => e == 9)">
					</div>
					<hr class="my-0">
					<div class="card-body">
						<div class="alert alert-primary py-1 px-2">
							<i class="fas fa-map-marker-alt"></i>
							<span class="ml-2 fw-bold text-uppercase">Seleccione Áreas para conducir / operar:</span>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.areas" class="form-check-input" type="checkbox" :value="1" id="driving_areas_1">
							<label class="form-check-label" for="driving_areas_1">Área Administrativa y Caminos Principales</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.areas" class="form-check-input" type="checkbox" :value="2" id="driving_areas_2">
							<label class="form-check-label" for="driving_areas_2">Área Planta (SX-EW, Lixiviación, Chancado, Ore Sorting)</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.areas" class="form-check-input" type="checkbox" :value="3" id="driving_areas_3">
							<label class="form-check-label" for="driving_areas_3">Área Operaciones Mina</label>
						</div>
						<div class="form-check text-size-11 mb-1">
							<input v-model="driving_license.areas" class="form-check-input" type="checkbox" :value="4" id="driving_areas_4">
							<label class="form-check-label" for="driving_areas_4">Cuesta Michilla</label>
						</div>
					</div>

					<!-- UPLOAD FORM -->
					<div v-if="0">

						<!-- DOCUMENT INFO -->
						<p class="mb-1">
							<span v-if="is_new() == true">Nombre del Documento</span>
							<span v-else>Nombre del Documento</span>
						</p>
						<h5>
							<span>Solicitud Bblabla</span>
						</h5>
						<!-- TEMPLATE -->
						<b-row class="mb-3">
							<b-col>
								<b-button
									variant="outline-secondary"
									size="sm"
									:href="api_path +'files/solicitud_licencia_hmc.pdf'"
								>
									<i class="fas fa-download mr-1"></i>
									<span>Descargar Plantilla</span>
								</b-button>
							</b-col>
						</b-row>
						<!-- /DOCUMENT INFO -->

						<!-- DOCUMENT FILE -->
						<b-form-group :label="is_new() == true ? 'Archivo a cargar' : 'Nuevo documento a cargar'">
							<b-form-file
								v-model="file"
								size="lg"
								placeholder="Presione aquí para seleccionar un archivo"
							>
							</b-form-file>
							<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						</b-form-group>
						<!-- /DOCUMENT FILE -->

						<!-- CREATION DATE -->
						<b-form-group label="Fecha de Emisión">
							<b-form-datepicker
								v-model="creation"
								locale="es"
								placeholder="Seleccionar fecha..."
							></b-form-datepicker>
							<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
						</b-form-group>
						<!-- /CREATION DATE -->

						<!-- EXPIRATION DATE -->
						<b-form-group label="¿El documento tiene fecha de vencimiento?">
							<b-form-radio-group
								stacked :options="options"
								@input="toggleExpiration()"
							></b-form-radio-group>
						</b-form-group>
						<b-form-group label="Fecha de Vencimiento">
							<b-form-datepicker
								v-model="expiration"
								locale="es"
								placeholder="Seleccionar fecha..."
							></b-form-datepicker>
							<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
						</b-form-group>
						<!-- /EXPIRATION DATE -->

						<!-- WARNINGS -->
						<b-alert show variant="info">
							<span v-if="is_new() == true">
								Por favor, complete los campos requeridos para continuar con la carga del documento.
							</span>
							<span v-else>
								Por favor, complete los campos requeridos y asegúrese de que el nuevo documento rectifica lo indicado en el motivo de rechazo.
							</span>
						</b-alert>
						<!-- /WARNINGS -->

					</div>
					<!-- /UPLOAD FORM -->
					
				</div>

				<!-- UPLOAD PROGRESS -->
				<template #overlay>
					<div class="text-center" style="width: 300px">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
						<b-progress :max="progress.total" height="5px" animated>
							<b-progress-bar :value="progress.current"></b-progress-bar>
						</b-progress>
					</div>
				</template>
				<!-- /UPLOAD PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2" :disabled="loading">Cancelar</b-button>
				<b-button @click="submitForm()" variant="success" :disabled="!val_full_alt||loading">Confirmar Solicitud</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /UPLOAD MODAL -->

	</div>
</template>

<script>
export default {
	name: 'DriverRequirementForm',
	components: {
	},
	data() {
		return {
			loading: false,
			file: null,
			expiration: '',
			creation: '',
			expires: true,
			progress: {
				current: 0,
				total: 0
			},
			options: [
				{ text: 'Sí, el documento vence.', value: true },
				{ text: 'No, el documento no vence (es indefinido).', value: false }
			],
			driving_license: {
				restrictions_glasses: false,
				restrictions_other: false,
				restrictions_other_detail: null,
				class_d_other: null,
				classes: [],
				equipments: [],
				areas: [],
			},
		}
	},
	props: {
		requirement: Object
	},
	computed: {
		api_root() {
			return this.$store.state.api_root
		},
		api_path() {
			return this.$store.state.api_path
		},
		requirements() {
			return this.$store.state.driver_requirements
		},
		owners() {
			return this.$store.state.workers
		},
		current() {
			return this.$store.getters.current_driver
		},
		/*
		store_index() {
			if (this.requirement.data.owner_id) {
				const index = this.owners.data.data.findIndex(owner => owner.id === this.requirement.data.owner_id)
				return index
			} else {
				return null
			}
		},
		*/
		val_creation() {
			if (this.creation.length > 0) {
				return true
			} else {
				return false
			}
		},
		val_file() {
			return this.file ? true : false // Boolean(this.file)
		},
		val_expiration() {
			if (this.expiration.length > 0) {
				return true
			} else {
				return false
			}
		},
		val_full() {
			return this.val_creation && this.val_expiration && this.val_file ? true : false
		},
		val_full_alt() {
			return this.driving_license.classes.length > 0 && this.driving_license.equipments.length > 0 && this.driving_license.areas.length > 0 ? true : false;
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'driver-requirement-form') { this.reset() }
		})
	},
	methods: {
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		reset() {
			this.file = null
			this.creation = ''
			this.expiration = ''
			this.expires = true
			this.progress = { total: 0, current: 0 }
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				autoHideDelay: delay
			})
		},
		submitForm() {
			this.loading = true;
			this.driving_license.identifier = this.current.person.identifier;
			this.driving_license.full_name = this.current.person.names +' '+ this.current.person.primary_last_name +' '+ this.current.person.second_last_name;
			this.driving_license.company_name = this.current.company.full_name;
			this.$api.post('driving_licenses', this.driving_license)
			.then(response => {
				this.loading = false;
				this.$store.commit('save_driving_license', response.data);
				this.$bvModal.hide('driver-requirement-form');
			})
		},
		upload() {
			var self = this
			this.progress = { total: 0, current: 0 }
			this.$emit('run_task', this.requirement.index)
			this.loading = true
			const config = {
				onUploadProgress: function(progressEvent) {
					self.progress.total = progressEvent.total
					self.progress.current = progressEvent.loaded
				},
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			let data = new FormData();
			data.append('file', this.file)
			data.append('owner_id', this.requirement.data.owner_id)
			data.append('type_id', this.requirement.data.document_type_id)
			data.append('class', this.requirement.data.class)
			if (this.creation.length > 0) data.append('creation', this.creation)
			if (this.expiration.length > 0) data.append('expiration', this.expiration)
			if (this.is_new() === false) data.append('document_id', this.requirement.data.document.id)

			this.$api.post('driver_documents', data, config)
			.then(response => {
				this.$emit('clear_task')
				this.loading = false
				this.$bvModal.hide('driver-requirement-form')
				/*******************************************************
				 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
				 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
				 *******************************************************/
				if (this.requirement.data.document_type_id != 82 && this.requirement.data.document_type_id != 83) {
					if (this.is_new() === false) {
						//this.owners.data.data[this.store_index].req_rejected--
						//this.owners.data.data[this.store_index].req_waiting++
					} else {
						//this.owners.data.data[this.store_index].req_missing--
						//this.owners.data.data[this.store_index].req_waiting++
					}
				}
				/*******************************************************/
				this.requirements.data[this.requirement.index].document = response.data
				this.toast(true, 'El requisito "'+this.requirement.data.document_type.name+'" fue cargado.', 10000)
			})
			.catch(error => {
				this.$emit('clear_task')
				this.loading = false
				this.toast(false, error.response.data.message, 5000)
			})
		},
		toggleExpiration() {
			if (!this.expires) this.expiration = ''
		},
		is_new() {
			return true
		},
	}
}
</script>

<style scoped>
</style>
