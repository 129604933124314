export default {

	api_path: process.env.VUE_APP_API_PATH,
	api_root: process.env.VUE_APP_API_ROOT,
	
	build: 46, // FIX RECHAZO DE REQUISITOS POR MODAL DUPLICADO (now in drivers)
		
	user: {
		loading: false,
		data: {
			account: {
				loading: false,
				data: {
					id: null,
					identifier: null,
					internal: null,
					owner_id: null,
				}
			},
			session: {
				token_type: localStorage.getItem('token_type') || null,
				expires_in: localStorage.getItem('expires_in') || null,
				access_token: localStorage.getItem('access_token') || null,
				refresh_token: localStorage.getItem('refresh_token') || null
			},
			privileges: {
				loading: false,
				data: []
			}
		}
	},
	expirations: {
		range: null,
		loading: false,
		data: []
	},
	driving_license: {
		loading: false,
		data: {
			id: null,
			name: null,
			status_id: null,
			identifier: null,
			full_name: null,
			company_name: null,
			restrictions_glasses: null,
			restrictions_other: null,
			lass_d_other: null,
			classes: [],
			equipments: [],
			areas: [],
			approved_by: null,
			approved_at: null,
			expiration_date: null,
			comment: null,
			created_by: null,
			archived: null,
			archived_at: null,
			enabled: null,
			disabled_at: null,
		}
	},
	current_driver: null,
	driver_requirements: {
		loading: false,
		current: {
			worker: {
				id: null,
				company_id: null,
				person_id: null,
			},
		},
		new: {
			creation: null,
			expiration: null,
		},
		data: []
	},
	emergency_requirements: {
		loading: false,
		current: {
			worker: {
				id: null,
				company_id: null,
				person_id: null,
			},
		},
		new: {
			creation: null,
			expiration: null,
		},
		data: []
	},

	current_user: {
		loading: false,
		data: []
	},

	global_parameters: {
		loading: false,
		data: []
	},

	subroutes: {
		companies: 0,
		services: 0,
		workers: 0,
		vehicles: 0
	},

	privileges: {
		loading: false,
		data: {
			user: [],
			available: [],
			enabled: []
		}
	},

	privilege_list: {
		loading: false,
		data: []
	},

	users: {
		loading: false,
		data: []
	},

	contractors: {
		loading: false,
		data: []
	},

	corporate_passes: {
		loading: false,
		data: []
	},

	contractor: {
		loading: false,
		data: {
			identifier: '',
			full_name: '',
			short_name: '',
			phone: '',
			email: '',
			commune_id: null,
			address: ''
		}
	},

	contractor_requirements: {
		loading: false,
		data: []
	},

	subcontractors: {
		loading: false,
		data: []
	},

	subcontractor: {
		loading: false,
		data: {
			contractor_id: null,
			identifier: '',
			full_name: '',
			short_name: '',
		}
	},

	subcontractor_requirements: {
		loading: false,
		data: []
	},

	services: {
		loading: false,
		data: []
	},

	service: {
		loading: false,
		data: {
			contractor_id: null,
			subcontractor_id: null,
			company_id: null,
			comments: '',
			start_date: '',
			end_date: '',
			cod: '',
			workers_quantity: '',
			service_administrator: null,
			shifts: [],
			shift_ids: []
		}
	},

	service_requirements: {
		loading: false,
		data: []
	},

	service_extensions: {
		loading: false,
		data: []
	},

	extension_start: {
		loading: false,
		data: ''
	},

	service_workers: {
		loading: false,
		data: []
	},

	service_administrators: {
		loading: false,
		data: []
	},

	workers: {
		loading: false,
		data: []
	},

	contractor_workers: {
		loading: false,
		data: []
	},

	subcontractor_workers: {
		loading: false,
		data: []
	},

	worker: {
		loading: false,
		data: {
			contractor_id: null,
			subcontractor_id: null,
			identifier: '',
			names: '',
			primary_last_name: '',
			second_last_name: '',
			area: '',
			position: '',
			contract_type: null,
			contract_start: '',
			contract_end: '',
			terminated: null,
			termination_date: '',
			driver: false
		}
	},

	worker_requirements: {
		loading: false,
		data: []
	},

	worker_restrictions: {
		loading: false,
		data: []
	},

	worker_restriction_types: {
		loading: false,
		data: []
	},

	vehicles: {
		loading: false,
		data: []
	},

	vehicle: {
		loading: false,
		data: {
			contractor_id: null,
			subcontractor_id: null,
			identifier: '',
			type_id: null,
			manufacturer: '',
			model: '',
			year: 0,
			engine: ''
		}
	},

	vehicle_requirements: {
		loading: false,
		data: []
	},

	vehicle_types: {
		loading: false,
		data: []
	},

	regions: {
		loading: false,
		data: []
	},

	communes: {
		loading: false,
		data: []
	},

	shifts: {
		loading: false,
		data: []
	},

	passes: {
		loading: false,
		data: []
	},

	cargo_passes: {
		loading: false,
		data: []
	},
	
	current_date_expirations: {
		loading: false,
		data: [],
		date: null
	},

	user_logs: {
		loading: false,
		data: []
	},

	requirements_report_options: {
		loading: false,
		data: []
	},

	controls: {
		loading: false,
		data: []
	},

	grids: {
		loading: false,
		data: []
	},

	approvers: {
		loading: false,
		data: []
	},

	countries: {
		loading: false,
		data: []
	},

	migration_statuses: {
		loading: false,
		data: []
	},

}