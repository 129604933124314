<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="update-vehicle"
			:title="'Actualizar ' + type_name"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading || updating" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="6">
						<b-form-group label="Placa Patente">
							<b-input v-model="vehicle.identifier" :state="val_identifier" disabled></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Tipo de Vehículo">
							<b-overlay :show="vehicle_types.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="vehicle.type_id" :state="val_type_id" :disabled="vehicle_types.loading">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Tipo...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in vehicle_types.data" :key="index" :value="item.id">{{item.name}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Marca">
							<b-input v-model="vehicle.manufacturer" :state="val_manufacturer"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Modelo">
							<b-input v-model="vehicle.model" :state="val_model"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Año">
							<b-input v-model="vehicle.year" :state="val_year" type="number"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="N° de Serie Motor">
							<b-input v-model="vehicle.engine" :state="val_engine"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón ACTUALIZAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading || updating">Cancelar</b-button>
				<b-button @click="update()" variant="success" :disabled="!val_full || loading || updating">ACTUALIZAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'UpdateVehicle',
	components: {
	},
	data() {
		return {
			updating: false
		}
	},
	props: {
		type: Number
	},
	computed: {
		vehicle() {
			return this.$store.state.vehicle.data
		},
		loading() {
			return this.$store.state.vehicle.loading
		},
		vehicle_types() {
			return this.$store.state.vehicle_types
		},
		val_full() {
			return this.val_type_id &&
					this.val_manufacturer &&
					this.val_model &&
					this.val_year &&
					this.val_engine ? true : false
		},
		val_identifier() {
			return this.vehicle.identifier && this.vehicle.identifier.length > 0 ? true : false
		},
		val_type_id() {
			return this.vehicle.type_id != null ? true : false
		},
		val_manufacturer() {
			return this.vehicle.manufacturer && this.vehicle.manufacturer.length > 0 ? true : false
		},
		val_model() {
			return this.vehicle.model && this.vehicle.model.length > 0 ? true : false
		},
		val_year() {
			return this.vehicle.year > 1900 ? true : false
		},
		val_engine() {
			return this.vehicle.engine && this.vehicle.engine.length > 0 ? true : false
		},
		type_name() {
			if (this.type) {
				switch (this.type) {
					case 1:
						return 'Vehículo HMC'
					case 2:
						return 'Vehículo Contratista'
					case 3:
						return 'Vehículo Subcontratista'
					default:
						return 'Vehículo'
				}
			} else {
				return 'Vehículo'
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-vehicle') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.$store.commit('reset_vehicle')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		update() {
			this.updating = true
			this.vehicle.type = this.type
			this.$api.put('vehicles/'+this.vehicle.id, this.vehicle)
			.then(response => {
				this.updating = false
				this.$emit('update')
				this.$bvModal.hide('update-vehicle')
				this.toast(true, 'Se guardaron los cambios del vehículo Placa Patente "'+response.data.identifier+'" correctamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		}
	}
}
</script>

<style scoped>
</style>
