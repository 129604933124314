<template>
	<div>
		<b-card no-body>
			<b-card-header>
				<b-row>
					<b-col class="card-title-middle">
						<strong>ACTIVIDAD DE APROBADORES</strong>
					</b-col>
					<b-col class="text-right">
						<b-button to="/reportes/estadisticas" variant="outline-primary" size="sm" class="mr-2" v-b-tooltip.hover title="Volver a Estadísticas">
							<span class="fas fa-arrow-left"></span>
						</b-button>
						<b-button @click="refresh()" variant="outline-primary" size="sm" v-b-tooltip.hover title="Actualizar la información">
							<span class="fas fa-sync"></span>
						</b-button>
					</b-col>
				</b-row>
			</b-card-header>
			<b-card-body class="p-3">
				<b-card class="mb-4">
					<b-card-body class="p-0">
						<p class="inner-card-title">ACTIVIDAD GENERAL</p>
						<b-overlay :show="summary.loading">
							<apexchart :options="summary.chart.options" :series="summary.chart.series" height="300"></apexchart>
						</b-overlay>
					</b-card-body>
				</b-card>
				<b-card class="mb-4">
					<b-card-body class="p-0">
						<p class="inner-card-title">ELIZABETH WALLBERG</p>
						<b-overlay :show="wallberg.loading">
							<apexchart :options="wallberg.chart.options" :series="wallberg.chart.series" height="300"></apexchart>
						</b-overlay>
					</b-card-body>
				</b-card>
				<b-card class="mb-4">
					<b-card-body class="p-0">
						<p class="inner-card-title">RODRIGO MONZONCILLO</p>
						<b-overlay :show="monzoncillo.loading">
							<apexchart :options="monzoncillo.chart.options" :series="monzoncillo.chart.series" height="300"></apexchart>
						</b-overlay>
					</b-card-body>
				</b-card>
				<b-card>
					<b-card-body class="p-0">
						<p class="inner-card-title">MAX OLIVARES</p>
						<b-overlay :show="olivares.loading">
							<apexchart :options="olivares.chart.options" :series="olivares.chart.series" height="300"></apexchart>
						</b-overlay>
					</b-card-body>
				</b-card>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'StatsAction',
	data() {
		return {
			summary: {
				loading: true,
				chart: {
					series: [],
					options: {
						stroke: {
							width: 2
						},
						chart: {
							type: 'area',
							stacked: true,
							toolbar: {
								show: false
							}
						},
						colors: [ '#00E396', '#FF4560' ],
						dataLabels: {
							enabled: true,
						},
					}
				}
			},
			wallberg: {
				loading: true,
				chart: {
					series: [],
					options: {
						stroke: {
							width: 2
						},
						chart: {
							type: 'area',
							stacked: true,
							toolbar: {
								show: false
							}
						},
						colors: [ '#00E396', '#FF4560' ],
						dataLabels: {
							enabled: true,
						},
					}
				}
			},
			monzoncillo: {
				loading: true,
				chart: {
					series: [],
					options: {
						stroke: {
							width: 2
						},
						chart: {
							type: 'area',
							stacked: true,
							toolbar: {
								show: false
							}
						},
						colors: [ '#00E396', '#FF4560' ],
						dataLabels: {
							enabled: true,
						},
					}
				}
			},
			olivares: {
				loading: true,
				chart: {
					series: [],
					options: {
						stroke: {
							width: 2
						},
						chart: {
							type: 'area',
							stacked: true,
							toolbar: {
								show: false
							}
						},
						colors: [ '#00E396', '#FF4560' ],
						dataLabels: {
							enabled: true,
						},
					}
				}
			}
		}
	},
	created() {
		this.getSummary()
		this.getWallberg()
		this.getMonzoncillo()
		this.getOlivares()
	},
	methods: {
		refresh() {
			this.reset()
			this.getSummary()
			this.getWallberg()
			this.getMonzoncillo()
			this.getOlivares()
		},
		reset() {
			this.summary.chart.series = []
			this.wallberg.chart.series = []
			this.monzoncillo.chart.series = []
			this.olivares.chart.series = []
		},
		getSummary() {
			this.summary.loading = true
			this.$api.post('stats/action/summary')
			.then(response => {
				this.summary.loading = false
				this.summary.chart.series = response.data.series
			})
		},
		getWallberg() {
			this.wallberg.loading = true
			this.$api.post('stats/action/wallberg')
			.then(response => {
				this.wallberg.loading = false
				this.wallberg.chart.series = response.data.series
			})
		},
		getMonzoncillo() {
			this.monzoncillo.loading = true
			this.$api.post('stats/action/monzoncillo')
			.then(response => {
				this.monzoncillo.loading = false
				this.monzoncillo.chart.series = response.data.series
			})
		},
		getOlivares() {
			this.olivares.loading = true
			this.$api.post('stats/action/olivares')
			.then(response => {
				this.olivares.loading = false
				this.olivares.chart.series = response.data.series
			})
		}
	},
}
</script>

<style scoped>
.card-title-middle {
	padding-top: 3px;
}
.inner-card-title {
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.15em;
	margin: 0;
}
.inner-card-legend {
	margin: 0;
}
.legend-primary {
	color: #008FFB;
}
.legend-success {
	color: #00E396;
}
.legend-danger {
	color: #FF4560;
}
.legend-warning {
	color: #FEB019;
}
.legend-secondary {
	color: #AAAAAA;
}
</style>