<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="service-extensions"
			:title="'Extensiones ' + title"
			size="lg"
			body-class="p-0"
		>
			<!-- CONTENT -->
			<b-overlay :show="extensions.loading || creating" rounded="sm" class="p-3">
				
				<!-- DATA -->
				<p class="mb-2 text-size-12 text-muted">Extensiones Creadas</p>
				<b-table
					:items="extensions.data"
					:fields="fields"
					:busy="extensions.loading"
					show-empty
					bordered
					hover
				>
					<template v-slot:empty>
						<div class="text-center">
							<span class=" text-transform-none">{{type_name_human(1)}} no registra Extensiones.</span>
						</div>
					</template>
					<template v-slot:cell()="data">
						<div class="text-center">
							<span>{{data.value}}</span>
						</div>
					</template>
					<template v-slot:cell(sap_code)="data">
						<b-overlay :show="loaders.sap_code.index == data.index && loaders.sap_code.active">
							<template #overlay>
								<div class="d-flex align-items-center">
								<b-spinner type="grow" variant="secondary" small></b-spinner>
								</div>
							</template>
							<code v-if="data.value != null && data.value != ''">{{ data.value }}</code>
							<span v-else>
								<span class="text-muted">Sin Registro</span>
							</span>
							<span class="float-right">
								<div v-b-tooltip.hover.right title="Modificar" class="d-inline-block">
									<b-dropdown
										size="sm"
										variant="light"
										no-caret
										@show="copyValue(data.value)"
									>
										<template #button-content>
											<i class="fas fa-pen text-size-08 text-silenced"></i>
										</template>
										<template v-slot:default="{ hide }">
											<div class="px-2 m-0">
												<b-input
													v-model="loaders.sap_code.new"
													style="width:300px;"
													v-on:keyup.enter="updateValue(data); hide()"
												></b-input>
												<b-button
													:variant="loaders.sap_code.new == data.value ? 'secondary' : 'success'"
													:disabled="loaders.sap_code.new == data.value"
													block
													class="mt-2"
													@click="updateValue(data); hide()"
												>Guardar Cambios</b-button>
											</div>
										</template>
									</b-dropdown>
								</div>
							</span>
						</b-overlay>
					</template>
					<template v-slot:cell(end_date)="data">
						<b-overlay :show="loaders.end_date.index == data.index && loaders.end_date.active">
							<template #overlay>
								<div class="d-flex align-items-center">
								<b-spinner type="grow" variant="secondary" small></b-spinner>
								</div>
							</template>
							<div class="text-center">
								<span v-if="data.value != null && data.value != ''">{{ data.value }}</span>
								<span class="float-right" v-if="data.index == extensions.data.length - 1">
									<div v-b-tooltip.hover.right title="Modificar" class="d-inline-block">
										<b-dropdown
											size="sm"
											variant="light"
											no-caret
											right
										>
											<template #button-content>
												<i class="fas fa-pen text-size-08 text-silenced"></i>
											</template>
											<template v-slot:default="{ hide }">
												<div class="px-2 m-0">
													<b-form-group label="Nueva Fecha de Término">
														<b-calendar
															v-model="loaders.end_date.new"
															:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
															:min="data.item.start_date"
															placeholder="Seleccione una fecha"
															:initial-date="data.item.end_date"
														></b-calendar>
														<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
														<b-form-valid-feedback>OK</b-form-valid-feedback>
													</b-form-group>
													<b-button
														:variant="loaders.end_date.new == data.value ? 'secondary' : 'success'"
														:disabled="loaders.end_date.new == data.value"
														block
														class="mt-2"
														@click="updateEndDate(data); hide()"
													>Guardar Cambios</b-button>
												</div>
											</template>
										</b-dropdown>
									</div>
								</span>
							</div>
						</b-overlay>
					</template>
					<template v-slot:cell(code)="data">
						<div class="text-center">
							<span>{{data.value}}</span>
						</div>
					</template>
				</b-table>

				<div v-if="getters.prv_service_orders_extensions_create">
					<hr class="mt-4">
					<p class="mb-2 text-size-12 text-primary">Nueva Extensión</p>
					<b-row>
						<b-col sm="4">
							<b-form-group label="Fecha de Inicio">
								<b-overlay :show="extension_start.loading" class="p-0">
									<template #overlay>
										<div class="d-flex align-items-center">
										<b-spinner type="grow" variant="secondary" small></b-spinner>
										</div>
									</template>
									<b-form-datepicker
										v-model="extension_start.data"
										:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
										:state="val_start"
										placeholder="Seleccione una fecha"
										disabled
									></b-form-datepicker>
								</b-overlay>
								<b-form-invalid-feedback :state="val_start">Requerida</b-form-invalid-feedback>
								<b-form-valid-feedback :state="val_start">OK</b-form-valid-feedback>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group label="Fecha de Término">
								<b-form-datepicker
									v-model="end_date"
									:date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
									:min="extension_start.data"
									:state="val_end"
									:disabled="!val_start"
									placeholder="Seleccione una fecha"
								></b-form-datepicker>
								<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
								<b-form-valid-feedback>OK</b-form-valid-feedback>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group label="Folio SAP">
								<b-form-input
									v-model="sap_code"
									:state="val_sap_code"
									:disabled="!val_start"
									placeholder=""
								></b-form-input>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>Correcto</b-form-valid-feedback>
							</b-form-group>
						</b-col>
					</b-row>

					<b-button variant="success" size="lg" block :disabled="!val_full || creating" @click="create()">
						<span>Guardar Extensión</span>
					</b-button>
				</div>
				<!-- /DATA -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="extensions.loading || creating">Cerrar</b-button>
			</template>

			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'ServiceExtensions',
	components: {
	},
	data() {
		return {
			loaders: {
				sap_code: {
					new: '',
					active: false,
					index: null
				},
				end_date: {
					new: '',
					active: false,
					index: null
				},
			},
			creating: false,
			end_date: '',
			sap_code: '',
			fields: [
				{ key: 'sap_code', label: 'Folio SAP', sortable: true },
				{ key: 'code', label: 'Código', sortable: true },
				{ key: 'start_date', label: 'Fecha de Inicio', sortable: true },
				{ key: 'end_date', label: 'Fecha de Término', sortable: true }
			]
		}
	},
	props: {
		owner: Object
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		extensions() {
			return this.$store.state.service_extensions
		},
		extension_start() {
			return this.$store.state.extension_start
		},
		val_full() {
			return this.val_start && this.val_end && this.val_sap_code ? true : false
		},
		val_start() {
			return this.extension_start.data.length > 0 ? true : false
		},
		val_end() {
			return this.end_date.length > 0 ? true : false
		},
		val_sap_code() {
			return this.sap_code.length > 0 ? true : false
		},
		type_name() {
			if (this.owner.item) {
				switch (this.owner.item.type) {
					case 1:
						return 'Orden de Servicio'
					case 2:
						return 'Contrato'
					case 3:
						return 'Garantía'
					case 4:
						return 'Subcontrato'
					default:
						return 'Servicio'
				}
			} else {
				return ''
			}
		},
		title() {
			if (this.type_name !== '') {
				return this.type_name + ' ' + this.owner.item.cod
			} else {
				return null
			}
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'service-extensions') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.end_date = ''
			this.sap_code = ''
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.creating = true
			this.$api.post('services/'+this.owner.item.id+'/extensions', {
				start_date: this.extension_start.data,
				end_date: this.end_date,
				sap_code: this.sap_code,
			})
			.then(() => {
				this.creating = false
				this.$emit('update')
				this.reset()
				this.$store.dispatch('get_service_extensions', this.owner.item.id)
				this.$store.dispatch('get_extension_start', this.owner.item.id)
				this.toast(true, 'Se extendió '+this.type_name_human(2)+' correctamente.', 10000)
			})
			.catch(error => {
				this.creating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		type_name_human(type) {
			if (this.owner.item) {
				switch (this.owner.item.type) {
					case 1:
						return type === 1 ? 'Esta Orden de Servicio' : 'la Orden de Servicio'
					case 2:
						return type === 1 ? 'Este Contrato' : 'el Contrato'
					case 3:
						return type === 1 ? 'Esta Garantía' : 'la Garantía'
					case 4:
						return type === 1 ? 'Este Subcontrato' : 'el Subcontrato'
					default:
						return type === 1 ? 'Este Servicio' : 'el Servicio'
				}
			} else {
				return type === 1 ? 'Este Servicio' : 'el Servicio'
			}
		},
		validation(owner, type) {
			const done = owner.item.req_done
			const rejected = owner.item.req_rejected
			const waiting = owner.item.req_waiting
			const missing = owner.item.req_missing
			const total = done + rejected + waiting + missing
			const validated = done == total ? true : false
			const completed = Math.ceil(done / total * 100)
			if (type == 'total') return total
			if (type == 'completed') return completed
			if (type == 'validated') return validated
			if (type == 'done') return done
			if (type == 'detail') return 'Cumple con ' + done + ' de ' + total + ' requisitos ('+ completed + '%)'
			return null
		},
		copyValue(value) {
			this.loaders.sap_code.new = value
		},
		updateValue(data) {
			this.loaders.sap_code.active = true
			this.loaders.sap_code.index = data.index
			this.$api.put('services/'+this.owner.item.id+'/extensions/'+data.item.id+'/sap_code', {
				sap_code: this.loaders.sap_code.new
			})
			.then(response => {
				for (let i = 0; i < this.extensions.data.length; i++) {
					if (this.extensions.data[i].id == data.item.id) {
						this.extensions.data[i].sap_code = response.data.sap_code
					}
				}
				this.toast(true, 'Se guardó el nuevo Folio SAP.')
				this.loaders.sap_code.new = ''
				this.loaders.sap_code.active = false
				this.loaders.sap_code.index = null
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
				this.loaders.sap_code.new = ''
				this.loaders.sap_code.active = false
				this.loaders.sap_code.index = null
			})
		},
		copyEndDate(value) {
			this.loaders.end_date.new = value
		},
		updateEndDate(data) {
			this.loaders.end_date.active = true
			this.loaders.end_date.index = data.index
			this.$api.put('services/'+this.owner.item.id+'/extensions/'+data.item.id+'/end_date', {
				end_date: this.loaders.end_date.new
			})
			.then(response => {
				for (let i = 0; i < this.extensions.data.length; i++) {
					if (this.extensions.data[i].id == data.item.id) {
						this.extensions.data[i].end_date = response.data.end_date
					}
				}
				this.toast(true, 'Se actualizó la fecha de término.')
				this.loaders.end_date.new = ''
				this.loaders.end_date.active = false
				this.loaders.end_date.index = null
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
				this.loaders.end_date.new = ''
				this.loaders.end_date.active = false
				this.loaders.end_date.index = null
			})
		},
	}
}
</script>

<style scoped>
</style>
