<template>
	<Unauthorized v-if="!getters.prv_system"></Unauthorized>
	<div v-else>
		<b-row>
			<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_system_users_view">
				<b-card no-body>
					<b-card-body>
						<h5>USUARIOS</h5>
						<p class="text-size-12 text-muted mb-0">Administre usuarios y sus privilegios de acceso.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('Users')">
							<strong>Administrar Usuarios</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_system_shifts_view">
				<b-card no-body>
					<b-card-body>
						<h5>TURNOS</h5>
						<p class="text-size-12 text-muted mb-0">Administre turnos y asignelos a trabajadores HMC.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('Shifts')">
							<strong>Administrar Turnos</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_system_settings_view">
				<b-card no-body>
					<b-card-body>
						<h5>ACTIVIDAD</h5>
						<p class="text-size-12 text-muted mb-0">Consulte el registro de actividad de usuarios.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('UserLogs')">
							<strong>Registro de Actividad</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_system_settings_view && 1==0">
				<b-card no-body>
					<b-card-body>
						<h5>PARÁMETROS</h5>
						<p class="text-size-12 text-muted mb-0">Administre parámetros globales del sistema.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('Settings')">
							<strong>Administrar Parámetros</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_system_control_fixer_view">
				<b-card no-body>
					<b-card-body>
						<h5>CORRECTOR DE CONTROLES</h5>
						<p class="text-size-12 text-muted mb-0">Utilidad para corregir controles realizados incorrectamente.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('ControlFixer')">
							<strong>Abrir Corrector de Controles</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_system_requirements_manager">
				<b-card no-body>
					<b-card-body>
						<h5>ADMINISTRACIÓN DE REQUISITOS</h5>
						<p class="text-size-12 text-muted mb-0">Modifique nombres, descripciones y plantillas de requisitos.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('RequirementsManager')">
							<strong>Abrir Administración de Requisitos</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
			<b-col lg="4" md="6" sm="12" class="mb-3" v-if="getters.prv_system_global_parameters">
				<b-card no-body>
					<b-card-body>
						<h5>PARÁMETROS GLOBALES</h5>
						<p class="text-size-12 text-muted mb-0">Variables disponibles a lo largo de todos los módulos.</p>
					</b-card-body>
					<b-list-group flush>
						<b-list-group-item variant="primary" button @click="goTo('GlobalParameters')">
							<strong>Abrir Parámetros Globales</strong>
							<i class="fas fa-arrow-right float-right mt-1"></i>
						</b-list-group-item>
					</b-list-group>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
export default {
	name: 'System',
	components: {
		Unauthorized
	},
	data() {
		return {
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
	},
	watch: {
	},
	created() {
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'success' : 'danger',
				autoHideDelay: delay,
			})
		},
	}
}
</script>