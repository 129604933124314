<template>
	<Unauthorized v-if="!getters.prv_system || !getters.prv_system_users_view"></Unauthorized>
	<div v-else>

		<CreateUser @update="load()"></CreateUser>
		<ManageUser :user="user"></ManageUser>

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('System')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Sistema">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar un nuevo usuario"
					v-if="getters.prv_system_users_manage"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				<b-overlay
					:show="users.loading"
					rounded
					opacity="0.6"
					class="d-inline-block mr-2"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de usuarios">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<b-button variant="light" @click="goTo('Approvers')" v-b-tooltip.hover.bottomright title="Ver usuarios que revisan documentación">
					<i class="fad fa-users"></i>
					<span> Aprobadores</span>
				</b-button>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2" v-if="query.type != 1">
				<b-button-group>
					<b-button
						:variant="query.type=='any'?'primary':'light'"
						@click="type('any')"
						v-b-tooltip.hover.bottomleft
						title="Todos los usuarios"
					>Todo</b-button>
					<b-button
						:variant="query.type=='internal'?'primary':'light'"
						@click="type('internal')"
						v-b-tooltip.hover.bottomleft
						title="Usuarios HMC"
					>HMC</b-button>
					<b-button
						:variant="query.type=='external'?'primary':'light'"
						@click="type('external')"
						v-b-tooltip.hover.bottomleft
						title="Usuarios externos"
					>Externo</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->


		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!users.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="users.data.total"> {{users.data.total}} </span>
						<span v-else> 0 </span>
						<span v-if="query.type == 'any'">Usuario<span v-if="users.data.total > 1 || !users.data.total">s</span></span>
						<span v-else-if="query.type == 'internal'">Usuario<span v-if="users.data.total > 1 || !users.data.total">s</span> HMC</span>
						<span v-else-if="query.type == 'external'">Usuario<span v-if="users.data.total > 1 || !users.data.total">s</span> externo<span v-if="users.data.total > 1 || !users.data.total">s</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="users.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="users.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="users.data.data"
					:fields="fields"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
					class="table-with-dropdowns"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron usuarios que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(super)="data">
						<div class="ml-2">
							<span v-if="data.value">HMC</span>
							<span v-else>EXTERNO</span>
						</div>
					</template>
					<template v-slot:cell(rut)="data">
						<div>
							<span>{{parseIdentifier(data.value)}}</span>
						</div>
					</template>
					<template v-slot:cell(name)="data">
						<div>
							<span v-b-tooltip.hover.right :title="data.item.owner.full_name.toUpperCase()" v-if="data.item.owner.full_name">
								<span>{{shortName(data.value).toUpperCase()}}</span>
							</span>
							<span v-else>{{data.value.toUpperCase()}}</span>
						</div>
					</template>
					<template v-slot:cell(email)="data">
						<div>
							<span>{{data.value.toLowerCase()}}</span>
						</div>
					</template>
					<template v-slot:cell(actions)="data">
						<div class="text-center">
							<b-dropdown size="sm" variant="outline-primary" right no-caret>
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="manage(data)">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Administrar Privilegios</span>
								</b-dropdown-item-button>
							</b-dropdown>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import CreateUser from '@/components/CreateUser.vue'
import ManageUser from '@/components/ManageUser.vue'

export default {
	name: 'SystemUsers',
	components: {
		Unauthorized,
		CreateUser,
		ManageUser
	},
    data(){
        return {
			owner: {},
			user: {},
			privileges: {},
			filtered: '',
			query: {
				type: 'internal',
				limit: 20,
				page: 1,
				filter: ''
			},
			fields: [
				{ key: 'super', label: 'Tipo', sortable: true },
				{ key: 'rut', label: 'RUT', sortable: true },
				{ key: 'name', label: 'Nombre / Razón Social', sortable: true },
				{ key: 'email', label: 'Correo electrónico', sortable: true },
				{ key: 'actions', label: 'Acciones', sortable: true },
			],
			options: {
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 9999, text: 'Todos' }
				]
			}
        }
    },
	computed: {
		getters() {
			return this.$store.getters
		},
        users() {
            return this.$store.state.users
        }
	},
    created() {
		this.load()
    },
    methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        refresh() {
            this.$store.dispatch('get_users')
        },
		load() {
			this.$store.dispatch('get_users', this.query)
		},
		type(type) {
			this.query.type = type
			this.load()
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un RUT, nombre o e-mail para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.type = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
				this.filtered = ''
				this.query.type = 'any'
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.type = 'any'
			this.query.filter = ''
			this.query.limit = 10
			this.filtered = ''
			this.load()
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
        manageUserPrivilegesModal(credential_id) {
            this.$store.dispatch('get_current_user_privileges', credential_id)
            this.$bvModal.show('manage-user-privileges')
        },
        shortName(name) {
            return name ? name.length > 40 ? name.substring(0,35) + '...' : name : ''
        },
		manage(user) {
			this.$bvModal.show('manage-user')
			this.$store.dispatch('get_privileges', user.item.id)
		},
		create() {
			this.$bvModal.show('create-user')
			this.$store.dispatch('get_internal_workers')
			this.$store.dispatch('get_privilege_list')
		},
    }
}
</script>