<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="vehicle-qr"
			title="Código QR de Placa Patente"
			size="md"
			body-class="p-0"
		>
			<div v-if="vehicle.item">
				<div class="text-center" style="width: 200px; margin:0 auto">
					<qr-code :text="'ISIDF_'+vehicle.item.identifier" :size="200" class="my-4"></qr-code>
				</div>

				<table class="table hover">
					<tr>
						<th>Placa Patente</th>
						<td class="text-uppercase">{{vehicle.item.identifier}}</td>
					</tr>
					<tr>
						<th>Empresa</th>
						<td class="text-uppercase">{{vehicle.item.company.full_name}}</td>
					</tr>
					<tr>
						<th>Tipo</th>
						<td class="text-uppercase">{{vehicle.item.manufacturer}} {{vehicle.item.model}} {{vehicle.item.year}}</td>
					</tr>
				</table>
			</div>

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'VehicleQR',
	components: {
	},
	data() {
		return {
		}
	},
	props: {
		vehicle: Object
	},
	computed: {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>
</style>
