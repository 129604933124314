<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="smart-search"
			title="Búsqueda Rápida"
			size="lg"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- WORKER -->
				<div v-if="search.data.class == 'worker'">
					<b-card no-body>
						<table class="table table-hover">
							<thead class="thead-light">
								<tr>
									<th colspan="2">TRABAJADOR</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Nombre</th>
									<td class="text-uppercase">{{search.data.names}} {{search.data.primary_last_name}} {{search.data.second_last_name}}</td>
								</tr>
								<tr>
									<th>RUT</th>
									<td class="text-uppercase">{{parseIdentifier(search.data.identifier)}}</td>
								</tr>
								<tr>
									<th>Empresa</th>
									<td class="text-uppercase">{{search.data.worker.company.full_name}}</td>
								</tr>
								<tr>
									<th>RUT Empresa</th>
									<td class="text-uppercase">{{parseIdentifier(search.data.worker.company.identifier)}}</td>
								</tr>
								<tr>
									<th>Fono Empresa</th>
									<td class="text-uppercase" v-if="search.data.worker.company.phone">{{search.data.worker.company.phone}}</td>
									<td class="text-uppercase text-muted" v-else>Empresa no registra fono</td>
								</tr>
							</tbody>
						</table>
					</b-card>

					<b-row class="mt-4" v-if="!search.data.worker.company.owner">
						<b-col>
							<b-button block :variant="search.data.company_isDone ? 'success' : 'danger'" @click="companyRequirements()">
								<div class="text-center py-2">
									<p class="mb-1 text-size-19">
										<strong>Empresa</strong>
									</p>
									<div>
										<i v-if="search.data.company_isDone" class="fas fa-check text-size-2 mb-1"></i>
										<i v-else class="fas fa-times text-size-2 mb-1"></i>
									</div>
									<p class="mb-0 text-size-16">
										<span v-if="search.data.company_isDone">Acreditada</span>
										<span v-else>No Acreditada</span>
									</p>
								</div>
							</b-button>
							<b-button size="lg" block :variant="search.data.company_isDone ? 'outline-success' : 'outline-danger'" @click="companyRequirements()">Ver Detalles</b-button>
						</b-col>
						<b-col>
							<b-button block :variant="search.data.service_isDone ? 'success' : 'danger'" @click="serviceRequirements()">
								<div class="text-center py-2">
									<p class="mb-1 text-size-19">
										<strong>Servicio</strong>
									</p>
									<div>
										<i v-if="search.data.service_isDone" class="fas fa-check text-size-2 mb-1"></i>
										<i v-else class="fas fa-times text-size-2 mb-1"></i>
									</div>
									<p class="mb-0 text-size-16">
										<span v-if="search.data.service_isDone">Acreditado</span>
										<span v-else>No Acreditado</span>
									</p>
								</div>
							</b-button>
							<b-button size="lg" block :variant="search.data.service_isDone ? 'outline-success' : 'outline-danger'" @click="serviceRequirements()">Ver Detalles</b-button>
						</b-col>
						<b-col>
							<b-button block :variant="search.data.worker_isDone ? 'success' : 'danger'" @click="workerRequirements()">
								<div class="text-center py-2">
									<p class="mb-1 text-size-19">
										<strong>Trabajador</strong>
									</p>
									<div>
										<i v-if="search.data.worker_isDone" class="fas fa-check text-size-2 mb-1"></i>
										<i v-else class="fas fa-times text-size-2 mb-1"></i>
									</div>
									<p class="mb-0 text-size-16">
										<span v-if="search.data.worker_isDone">Acreditado</span>
										<span v-else>No Acreditado</span>
									</p>
								</div>
							</b-button>
							<b-button size="lg" block :variant="search.data.worker_isDone ? 'outline-success' : 'outline-danger'" @click="workerRequirements()">Ver Detalles</b-button>
						</b-col>
					</b-row>
				</div>
				<!-- /WORKER -->

				<!-- VEHICLE -->
				<div v-if="search.data.class == 'vehicle'">
					<b-card no-body>
						<table class="table table-hover">
							<thead class="thead-light">
								<tr>
									<th colspan="2">VEHÍCULO</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>Tipo</th>
									<td class="text-uppercase">{{search.data.manufacturer}} {{search.data.model}} {{search.data.year}}</td>
								</tr>
								<tr>
									<th>Placa Patente</th>
									<td class="text-uppercase">{{search.data.identifier}}</td>
								</tr>
								<tr>
									<th>Empresa</th>
									<td class="text-uppercase">{{search.data.company.full_name}}</td>
								</tr>
								<tr>
									<th>RUT Empresa</th>
									<td class="text-uppercase">{{parseIdentifier(search.data.company.identifier)}}</td>
								</tr>
								<tr>
									<th>Fono Empresa</th>
									<td class="text-uppercase" v-if="search.data.company.phone">{{search.data.company.phone}}</td>
									<td class="text-uppercase text-muted" v-else>Empresa no registra fono</td>
								</tr>
							</tbody>
						</table>
					</b-card>

					<b-row class="mt-4" v-if="!search.data.company.owner">
						<b-col>
							<b-button block :variant="search.data.company_isDone ? 'success' : 'danger'" @click="companyRequirements()">
								<div class="text-center py-2">
									<p class="mb-1 text-size-19">
										<strong>Empresa</strong>
									</p>
									<div>
										<i v-if="search.data.company_isDone" class="fas fa-check text-size-2 mb-1"></i>
										<i v-else class="fas fa-times text-size-2 mb-1"></i>
									</div>
									<p class="mb-0 text-size-16">
										<span v-if="search.data.company_isDone">Acreditada</span>
										<span v-else>No Acreditada</span>
									</p>
								</div>
							</b-button>
							<b-button size="lg" block :variant="search.data.company_isDone ? 'outline-success' : 'outline-danger'" @click="companyRequirements()">Ver Detalles</b-button>
						</b-col>
						<b-col>
							<b-button block :variant="search.data.vehicle_isDone ? 'success' : 'danger'" @click="vehicleRequirements()">
								<div class="text-center py-2">
									<p class="mb-1 text-size-19">
										<strong>Vehículo</strong>
									</p>
									<div>
										<i v-if="search.data.vehicle_isDone" class="fas fa-check text-size-2 mb-1"></i>
										<i v-else class="fas fa-times text-size-2 mb-1"></i>
									</div>
									<p class="mb-0 text-size-16">
										<span v-if="search.data.vehicle_isDone">Acreditado</span>
										<span v-else>No Acreditado</span>
									</p>
								</div>
							</b-button>
							<b-button size="lg" block :variant="search.data.vehicle_isDone ? 'outline-success' : 'outline-danger'" @click="vehicleRequirements()">Ver Detalles</b-button>
						</b-col>
					</b-row>
				</div>
				<!-- /VEHICLE -->

				<!-- PASS -->
				<div v-if="search.data.class == 'pass'">
					
					<b-card bg-variant="warning" class="mb-4" v-if="search.data.status === 1">
						<p class="mb-0 text-size-12">
							<i class="fas fa-clock mr-2"></i>
							<strong>PASE EN ESPERA DE APROBACION</strong>
						</p>
					</b-card>
					<b-card bg-variant="success" class="mb-4" v-if="search.data.status === 2">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-check mr-2"></i>
							<strong>PASE APROBADO</strong>
						</p>
					</b-card>
					<b-card bg-variant="danger" class="mb-4" v-if="search.data.status === 3">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-times mr-2"></i>
							<strong>PASE RECHAZADO</strong>
						</p>
					</b-card>
					<b-card bg-variant="dark" class="mb-4" v-if="search.data.status === 0">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-trash mr-2"></i>
							<strong>PASE ANULADO</strong>
						</p>
					</b-card>

					<b-card no-body class="mt-4">
						<table class="table table-hover">
							<thead class="thead-light">
								<tr>
									<th colspan="2">PASE DE VISITA</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>N° de Pase</th>
									<td class="text-uppercase">{{search.data.id}}</td>
								</tr>
								<tr>
									<th>Fecha de Solicitud</th>
									<td class="text-uppercase">{{search.data.created_at}}</td>
								</tr>
								<tr>
									<th>Fecha de Visita</th>
									<td class="text-uppercase">{{search.data.date_end}}</td>
								</tr>
								<tr>
									<th>Motivo de Visita</th>
									<td class="text-uppercase">{{search.data.motive}}</td>
								</tr>
								<tr>
									<th>Solicitante</th>
									<td class="text-uppercase">{{search.data.contact_name}}</td>
								</tr>
								<tr>
									<th>Contacto Solicitante</th>
									<td class="text-lowercase">{{search.data.contact_phone}}<span class="text-muted px-2">&ndash;</span>{{search.data.contact_email}}</td>
								</tr>
							</tbody>
						</table>
					</b-card>
					<b-card no-body class="mt-4">
						<b-table :items="search.data.members" :fields="fields.guests" hover>
							<template v-slot:thead-top>
								<tr class="thead-light">
									<th colspan="4">NÓMINA DE PASE</th>
								</tr>
							</template>
							<template v-slot:cell(identifier)="data">
								<div>
									<span>{{parseIdentifier(data.value)}}</span>
								</div>
							</template>
						</b-table>
					</b-card>
				</div>
				<!-- /PASS -->

				<!-- CARGO PASS -->
				<div v-if="search.data.class == 'cargo_pass'">
					
					<b-card bg-variant="warning" class="mb-4" v-if="search.data.status_id === 1">
						<p class="mb-0 text-size-12">
							<i class="fas fa-clock mr-2"></i>
							<strong>PASE EN ESPERA DE APROBACION</strong>
						</p>
					</b-card>
					<b-card bg-variant="success" class="mb-4" v-if="search.data.status_id === 2">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-check mr-2"></i>
							<strong>PASE APROBADO</strong>
						</p>
					</b-card>
					<b-card bg-variant="danger" class="mb-4" v-if="search.data.status_id === 3">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-times mr-2"></i>
							<strong>PASE RECHAZADO</strong>
						</p>
					</b-card>
					<b-card bg-variant="dark" class="mb-4" v-if="search.data.status_id === 0">
						<p class="mb-0 text-size-12 text-white">
							<i class="fas fa-trash mr-2"></i>
							<strong>PASE ANULADO</strong>
						</p>
					</b-card>

					<b-card no-body class="mt-4">
						<table class="table table-hover">
							<thead class="thead-light">
								<tr>
									<th colspan="2">PASE DE TRANSPORTISTA</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th>N° de Pase</th>
									<td class="text-uppercase">{{search.data.id}}</td>
								</tr>
								<tr>
									<th>Fecha de Solicitud</th>
									<td class="text-uppercase">{{search.data.created_at}}</td>
								</tr>
								<tr>
									<th>Fecha de Inicio</th>
									<td class="text-uppercase">{{search.data.date_start}}</td>
								</tr>
								<tr>
									<th>Fecha de Término</th>
									<td class="text-uppercase">{{search.data.date_end}}</td>
								</tr>
								<tr>
									<th>Motivo o Carga</th>
									<td class="text-uppercase">{{search.data.motive}}</td>
								</tr>
								<tr>
									<th>Solicitante</th>
									<td class="text-uppercase">{{search.data.contact_name}}</td>
								</tr>
								<tr>
									<th>Contacto Solicitante</th>
									<td class="text-lowercase">{{search.data.contact_phone}}<span class="text-muted px-2">&ndash;</span>{{search.data.contact_email}}</td>
								</tr>
							</tbody>
						</table>
					</b-card>
					<b-card no-body class="mt-4">
						<b-table :items="search.data.members" :fields="fields.guests" hover>
							<template v-slot:thead-top>
								<tr class="thead-light">
									<th colspan="4">NÓMINA DE PASE</th>
								</tr>
							</template>
							<template v-slot:cell(identifier)="data">
								<div>
									<span>{{parseIdentifier(data.value)}}</span>
								</div>
							</template>
						</b-table>
					</b-card>
				</div>
				<!-- /PASS -->

				<!-- UNKNOWN -->
				<div v-if="search.data.class == 'unknown'">
					<b-alert show variant="danger">
						<p class="mb-0 text-size-12">Este RUT o Patente <kbd>{{search.data.identifier.toUpperCase()}}</kbd> no se encuentra registrado en el sistema, pero sí presenta los registros de control en faena listados a continuación.</p>
					</b-alert>
				</div>
				<!-- /UNKNOWN -->
				
				<!-- CONTROLS -->
				<div v-if="search.data.class == 'worker' || search.data.class == 'vehicle' || search.data.class == 'unknown'" class="mt-4">
					
					<b-card no-body>

					<p class="text-size-11 text-muted mb-0 text-left p-2">
						<i class="fas fa-arrow-down"></i> <strong>Últimos 10 Controles</strong>
					</p>
						<b-table
							:items="search.data.controls"
							:fields="fields.controls"
							hover
							small
							head-variant="light"
							show-empty
						>
							<template v-slot:head()="data">
								<div class="py-1">
									<span class="ml-1">{{data.label}}</span>
								</div>
							</template>
							<template v-slot:cell(enter)="data">
								<div>
									<b-badge v-if="data.value" variant="success">ENTRADA</b-badge>
									<b-badge v-else variant="secondary">SALIDA</b-badge>
								</div>
							</template>
							<template v-slot:cell()="data">
								<div class="px-2">
									<span>{{data.value}}</span>
								</div>
							</template>
							<template v-slot:empty>
								<div class="py-3 text-center text-muted text-size-12">
									<span>No registra controles.</span>
								</div>
							</template>
						</b-table>
					</b-card>
				</div>
				<!-- /CONTROLS -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
			<!-- /FOOTER -->
		</b-modal>
		<!-- /MODAL -->

		<b-modal id="smart-search-company-requirements" title="Acreditación de Empresa" body-class="p-0">
			<b-alert show v-if="!search.data.company_isDone" variant="danger" class="m-3" style="border-radius: 0px;">
				<p class="m-0 text-size-13">El <span v-if="search.data.class=='worker'">trabajador</span><span v-else-if="search.data.class=='vehicle'">vehículo</span> pertenece a una empresa que no cumple con su acreditación, por lo tanto el ingreso no está autorizado.</p>
			</b-alert>
			<b-table :items="search.data.company_requirements" :fields="fields.requirements" small hover>
				<template v-slot:cell(document.action)="data">
					<div class="text-center">
						<span v-if="data.value && data.value.result == 1">
							<i class="fas fa-check text-success"></i>
						</span>
						<span v-else-if="data.value && data.value.result == -2">
							<i class="fas fa-times text-danger"></i>
						</span>
						<span v-else>
							<i class="fas fa-times text-danger"></i>
						</span>
					</div>
				</template>
			</b-table>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
		
		<b-modal id="smart-search-service-requirements" title="Acreditación de Servicio" body-class="p-0">
			<b-alert show v-if="search.data.service_requirements == null" variant="danger" class="m-3" style="border-radius: 0px;">
				<p class="m-0 text-size-13">El trabajador no se encuentra asociado a un servicio, por lo tanto su ingreso no está autorizado.</p>
			</b-alert>
			<b-alert show v-else-if="!search.data.service_isDone && search.data.service_worker.status == 2" variant="danger" class="m-3" style="border-radius: 0px;">
				<p class="m-0 text-size-13">El trabajador está asociado a un servicio que no cumple con su acreditación, por lo tanto el ingreso no está autorizado.</p>
			</b-alert>
			<b-alert show v-else-if="!search.data.service_isDone && search.data.service_worker.status != 2" variant="danger" class="m-3">
				<p class="m-0 text-size-13">El trabajador está en la nómina de un servicio pero no ha sido aprobado aún por HMC, por lo tanto el ingreso no está autorizado.</p>
			</b-alert>
			<b-table v-if="search.data.service_requirements" :items="search.data.service_requirements" :fields="fields.requirements" small hover>
				<template v-slot:top-row>
					<td class="text-center">
						<span v-if="search.data.service_worker.status == 2">
							<i class="fas fa-check text-success"></i>
						</span>
						<span v-else>
							<i class="fas fa-times text-danger"></i>
						</span>
					</td>
					<td>Aprobado en Nómina del Servicio</td>
				</template>
				<template v-slot:cell(document.action)="data">
					<div class="text-center">
						<span v-if="data.value && data.value.result == 1">
							<i class="fas fa-check text-success"></i>
						</span>
						<span v-else-if="data.value && data.value.result == -2">
							<i class="fas fa-times text-danger"></i>
						</span>
						<span v-else>
							<i class="fas fa-times text-danger"></i>
						</span>
					</div>
				</template>
			</b-table>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
		
		<b-modal id="smart-search-worker-requirements" title="Acreditación de Trabajador" body-class="p-0">
			<b-alert show v-if="!search.data.worker_isDone" variant="danger" class="m-3" style="border-radius: 0px;">
				<p class="m-0 text-size-13">El trabajador no cumple con su acreditación, por lo tanto el ingreso no está autorizado.</p>
			</b-alert>
			<b-table :items="search.data.worker_requirements" :fields="fields.requirements" small hover>
				<template v-slot:cell(document.action)="data">
					<div class="text-center">
						<span v-if="data.value && data.value.result == 1">
							<i class="fas fa-check text-success"></i>
						</span>
						<span v-else-if="data.value && data.value.result == -2">
							<i class="fas fa-times text-danger"></i>
						</span>
						<span v-else>
							<i class="fas fa-times text-danger"></i>
						</span>
					</div>
				</template>
			</b-table>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
		
		<b-modal id="smart-search-vehicle-requirements" title="Acreditación de Vehículo" body-class="p-0">
			<b-alert show v-if="!search.data.vehicle_isDone" variant="danger" class="m-3" style="border-radius: 0px;">
				<p class="m-0 text-size-13">El vehículo no cumple con su acreditación, por lo tanto el ingreso no está autorizado.</p>
			</b-alert>
			<b-table :items="search.data.vehicle_requirements" :fields="fields.requirements" small hover>
				<template v-slot:cell(document.action)="data">
					<div class="text-center">
						<span v-if="data.value && data.value.result == 1">
							<i class="fas fa-check text-success"></i>
						</span>
						<span v-else-if="data.value && data.value.result == -2">
							<i class="fas fa-times text-danger"></i>
						</span>
						<span v-else>
							<i class="fas fa-times text-danger"></i>
						</span>
					</div>
				</template>
			</b-table>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
		</b-modal>
			
	</div>
</template>

<script>
export default {
	name: 'SmartSearch',
	components: {
	},
	data() {
		return {
			loading: false,
			fields: {
				requirements: [
					{ key: 'document.action', label: 'Cumple' },
					{ key: 'document_type.name', label: 'Documento' }
				],
				guests: [
					{ key: 'identifier', label: 'RUT', sortable: true },
					{ key: 'names', label: 'Nombre', sortable: true },
					{ key: 'first_lastname', label: 'Apellido Paterno', sortable: true },
					{ key: 'second_lastname', label: 'Apellido Materno', sortable: true },
				],
				controls: [
					{ key: 'timestamp', label: 'Fecha/Hora' },
					{ key: 'enter', label: 'Control' },
					{ key: 'message', label: 'Resultado' },
				]
			},
			multiple: true
		}
	},
	props: {
		search: Object
	},
	computed: {
	},
	created() {
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'smart-search') {
				this.reset()
			}
		})
	},
	methods: {
		reset() {
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		companyRequirements() {
			this.$bvModal.show('smart-search-company-requirements')
		},
		serviceRequirements() {
			this.$bvModal.show('smart-search-service-requirements')
		},
		workerRequirements() {
			this.$bvModal.show('smart-search-worker-requirements')
		},
		vehicleRequirements() {
			this.$bvModal.show('smart-search-vehicle-requirements')
		},

	}
}
</script>

<style scoped>
</style>
