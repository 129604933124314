<template>

	<!-- CONTENT -->
	<b-overlay :show="global_parameters.loading" rounded="sm" class="p-0">

		<!-- MODULE CONTAINER -->
		<b-card>

			<!-- MODULE TITLE -->
			<p class="mb-3 text-size-16">
				<span>Parámetros</span>
			</p>

			<b-alert show variant="danger" class="mb-4">
				<p class="mb-0 text-size-11">Estos parámetros permiten personalizar configuraciones globales. Antes de realizar cambios, por favor lea detenidamente el Manual de Administración para entender los impactos que puedan tener en el funcionamiento del sistema.</p>
			</b-alert>

			<b-card v-for="(setting, index) in global_parameters.data" :key="index" class="mt-3" :bg-variant="setting.locked ? 'light' : ''">
				<b-overlay :show="loaders.setting.index == index && loaders.setting.active">
					<b-row>
						<b-col sm="4">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Parámetro</p>
							<p class="mb-0 text-success text-strong">{{ setting.name }}</p>
						</b-col>
						<b-col sm="4">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Valor</p>
							<p class="mb-0 text-primary text-strong">
								<code :class="setting.locked ? 'text-primary' : ''">{{ setting.value }}</code>
								<span class="pl-2" v-if="!setting.locked">
									<div v-b-tooltip.hover.right title="Editar Valor" class="d-inline-block">
										<b-overlay
											:show="loaders.setting.active && loaders.setting.id == setting.id"
											rounded
											opacity="0.6"
											spinner-small
											spinner-variant="primary"
											class="d-inline-block ml-2"
										>
											<b-dropdown
												size="sm"
												variant="light"
												no-caret
												@show="copyValue(setting)"
											>
												<template #button-content>
													<i class="fas fa-pen text-size-08 text-silenced"></i>
												</template>
												<template v-slot:default="{ hide }">
													<div class="px-2 m-0">
														<b-input
															v-model="loaders.setting.new"
															style="width:300px;"
															v-on:keyup.enter="updateValue(setting); hide()"
														></b-input>
														<b-button
															:variant="loaders.setting.new == setting.value ? 'secondary' : 'success'"
															:disabled="loaders.setting.new == setting.value"
															block
															class="mt-2"
															@click="updateValue(setting); hide()"
														>Guardar Cambios</b-button>
													</div>
												</template>
											</b-dropdown>
										</b-overlay>
									</div>
								</span>
							</p>
						</b-col>
						<b-col sm="4" v-if="!setting.locked">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Última Modificación</p>
							<p class="mb-0">{{ setting.updated }}</p>
						</b-col>
						<b-col sm="12" class="mt-2">
							<p class="mb-0 text-uppercase text-size-07 text-dark">Descripción</p>
							<p class="mb-0">{{ setting.description }}</p>
						</b-col>
					</b-row>
				</b-overlay>
			</b-card>
		</b-card>

		<!-- LOADING PROGRESS -->
		<template #overlay>
			<div class="text-center">
				<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
			</div>
		</template>
		<!-- /LOADING PROGRESS -->

	</b-overlay>
	<!-- /CONTENT -->

</template>

<script>
export default {
	name: 'GlobalParameters',
	components: {
	},
	data() {
		return {
			loading: false,
			current_values: [],
			setting: [],
			loaders: {
				setting: {
					new: '',
					active: false,
					index: null
				},
            }
		}
	},
	computed: {
		global_parameters() {
			return this.$store.state.global_parameters
		},
	},
	created() {
		this.load()
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'global-parameters') { this.reset() }
		})
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		load() {
			
			this.$store.dispatch('get_global_parameters').then(response => {
				this.current_global_parameters = response.data
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
			})
		},
		reset() {
		},
		copyValue(resource) {
			this.loaders.setting.new = resource.value
		},
		updateValue(resource) {
			this.loaders.setting.active = true
			this.loaders.setting.id = resource.id
			this.$api.put('global_parameters/'+ resource.id, {
				value: this.loaders.setting.new
			})
			.then(response => {
				for (let i = 0; i < this.global_parameters.data.length; i++) {
					if (this.global_parameters.data[i].id == resource.id) {
						this.global_parameters.data[i].value = response.data.value
						this.global_parameters.data[i].revision = response.data.revision
						this.global_parameters.data[i].updated = response.data.updated
					}
				}
				this.toast(true, 'Se actualizó el valor del parámetro "'+ response.data.name +'".')
				this.loaders.setting = {
					new: '',
					active: false,
					id: 0,
				}
			})
			.catch(error => {
				this.toast(false, error.response.data.message, 10000)
				this.loaders.setting = {
					new: '',
					active: false,
					id: 0,
				}
			})
		},
		close() {
			this.$bvModal.hide('global_parameters-manager')
			//this.reset()
		},
	}
}
</script>

<style scoped>
</style>
