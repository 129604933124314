<template>
	<div>
		<b-row>
			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('Reports')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Reportes">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-overlay
					:show="loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar controles realizados">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
            <b-col sm="auto" class="text-right pt-2">
                <b-button :variant="online ? 'success' : 'danger'" style="padding: 3px 8px 2px 8px; font-size:0.85em">
                    <i class="fas fa-circle mr-1"></i>
                    <span v-if="online">En Línea</span>
                    <span v-else>Fuera de Línea</span>
                </b-button>
            </b-col>
			<!-- /ACTIONS -->
		</b-row>
        <b-card no-body>
            <b-table
                :items="controls"
                :busy="loading"
                :fields="fields"
                responsive
                hover
                show-empty
                small
                class="m-0 p-0"
                style="height:700px"
            >
                <template v-slot:empty>
                    <div class="text-center p-4">
                        <span class="text-muted">Cargando Controles</span>
                    </div>
                </template>
                <template v-slot:cell(created_at)="data">
                    <div class="text-secondary">
                        <strong>{{data.value}}</strong>
                    </div>
                </template>
                <template v-slot:cell(message)="data">
                    <div v-if="data.item.unknown">
                        RUT: {{parseIdentifier(data.item.identifier)}}
                    </div>
                    <div v-else>
                        {{data.value}}
                    </div>
                </template>
                <template v-slot:cell(name)="data">
                    <div class="text-uppercase">
                        {{data.value}}
                    </div>
                </template>
                <template v-slot:cell(company)="data">
                    <div class="text-uppercase">
                        {{data.value}}
                    </div>
                </template>
                <template v-slot:cell(enter)="data">
                    <div v-if="data.value">
                        <span :class="data.item.success && data.item.code == 2050 ? 'text-warning' : data.item.success && data.item.code != 2050 ? 'text-success':'text-danger'">
                            <span class="fas fa-arrow-right mr-1"></span>
                        </span>
                        <b-badge :variant="data.item.success && data.item.code == 2050 ? 'warning' : data.item.success && data.item.code != 2050 ? 'success':'danger'">INGRESO</b-badge>
                    </div>
                    <div v-else>
                        <span class="fas fa-arrow-left text-secondary mr-1"></span>
                        <b-badge variant="secondary">SALIDA</b-badge>
                    </div>
                </template>
            </b-table>
        </b-card>
	</div>
</template>

<script>
import io from 'socket.io-client';
export default {
	name: 'Monitor',
	components: {
	},
	data() {
		return {
            online: false,
            loading: true,
            controls: [],
            fields: [
                {
                    key: 'timestamp',
                    label: 'Fecha/Hora',
                    sortable: true
                },
                {
                    key: 'enter',
                    label: 'Control',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Trabajador/Vehículo',
                    sortable: true
                },
                {
                    key: 'company',
                    label: 'Empresa',
                    sortable: true
                },
                {
                    key: 'message',
                    label: 'Detalle',
                    sortable: true
                }
            ]
		}
	},
	mounted() {
		this.load()
        var socket = io(process.env.VUE_APP_WSS_PATH)

        /* link status */
        socket.on('connect', () => { this.online = true })
        socket.on('disconnect', () => { this.online = false })

        /* monitor data */
        socket.on('monitor_new', data => {
            this.controls.unshift(data)
        })
	},
	created() {
        this.load()
	},
	computed: {
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
        load() {
            this.loading = true
            this.$api.get('controls/get_2day_full')
            .then(res => {
                this.loading = false
                if (res.data.count > 0) {
                    this.controls = res.data.controls
                }
            })
        }
	}
}
</script>

<style scoped>
</style>
