<template>
	<Unauthorized v-if="!getters.prv_contractors"></Unauthorized>
	<div v-else>

		<!-- COMPONENTS -->
		<Requirements @refresh="load()" :owner="owner"></Requirements>
		<Create @update="load()"></Create>
		<Update @update="load()"></Update>
		<Delete @update="load()" :owner="owner"></Delete>
		<!-- /COMPONENTS -->

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar una nueva empresa"
					v-if="getters.prv_contractors_create"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				<b-overlay
					:show="contractors.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de empresas">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2">
				<b-button-group>
					<b-button
						:variant="query.active=='any'?'primary':'light'"
						@click="active('any')"
						v-b-tooltip.hover.bottomleft
						title="Todas las empresas"
					>Todo</b-button>
					<b-button
						:variant="query.active=='yes'?'primary':'light'"
						@click="active('yes')"
						v-b-tooltip.hover.bottomleft
						title="Empresas con servicios vigentes"
					>Vigente</b-button>
					<b-button
						:variant="query.active=='no'?'primary':'light'"
						@click="active('no')"
						v-b-tooltip.hover.bottomleft
						title="Empresas sin servicios vigentes"
					>No Vigente</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->
		
		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!contractors.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="contractors.data.total"> {{contractors.data.total}} </span>
						<span v-else> 0 </span>
						<span>Empresa<span v-if="contractors.data.total > 1 || !contractors.data.total">s</span> contratista<span v-if="contractors.data.total > 1 || !contractors.data.total">s</span></span>
						<span v-if="query.active == 'yes'"> vigente<span v-if="contractors.data.total > 1 || !contractors.data.total">s</span></span>
						<span v-else-if="query.active == 'no'"> no vigente<span v-if="contractors.data.total > 1 || !contractors.data.total">s</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="contractors.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="contractors.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="contractors.data.data"
					:fields="options.fields"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
					class="table-with-dropdowns"
					
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron empresas que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(identifier)="data">
						<div class="ml-2">
							{{parseIdentifier(data.value)}}
						</div>
					</template>
					<template v-slot:cell(short_name)="data">
						<div>
							<span v-b-tooltip.hover.right :title="data.item.full_name.toUpperCase()" class="cursor-pointer">{{data.value.toUpperCase()}}</span>
						</div>
					</template>
					<template v-slot:cell(requirements)="data">
						<div class="text-center">
							<b-dropdown size="sm" variant="outline-primary" right no-caret>
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_contractors_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_contractors_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Empresa</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_contractors_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_contractors_read && !getters.prv_contractors_update && !getters.prv_contractors_delete"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
						</div>
					</template>
					<template v-slot:cell(req_done)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="success">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_rejected)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_waiting)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_missing)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<!-- HELP -->
		<b-modal id="contractors-help" title="Información de Ayuda" size="lg">

			<p class="text-size-13">Esta es una guía rápida para conocer las funciones del módulo Empresas Contratistas.</p>

			<h4>Opciones Básicas <small class="text-muted">(parte superior izquierda)</small></h4>
			<table class="table table-bordered">
				<tr>
					<td class="text-center bg-secondary">
						<b-button variant="light">
							<i class="fas fa-plus mr-1"></i>
							<span>Nueva Empresa</span>
						</b-button>
					</td>
					<td>
						<p class="text-transform-none mb-0">Abre el formulario para registrar nuevas empresas contratistas en el sistema. Al completar el formulario, la empresa recibirá una notificación por correo electrónico con información para ingresar al sistema y acreditarse.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center bg-secondary">
						<b-button variant="light">
							<i class="fas fa-sync"></i> Actualizar
						</b-button>
					</td>
					<td>
						<p class="text-transform-none mb-0">Recarga el listado de empresas contratistas y los contadores de requisitos. Puede resultar útil si deja el sistema abierto por mucho tiempo y quiere comprobar si existen cambios en la acreditación.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center bg-secondary">
						<b-button variant="info">
							<i class="fas fa-question"></i> Ayuda
						</b-button>
					</td>
					<td>
						<p class="text-transform-none mb-0">Muestra esta ventana de ayuda que está leyendo.</p>
					</td>
				</tr>
			</table>

			<h4 class="mt-4">Opciones Avanzadas <small class="text-muted">(parte superior derecha)</small></h4>
			<table class="table table-bordered">
				<tr>
					<td class="text-center bg-secondary">
						<b-button-group>
							<b-button
								:variant="query.active=='any'?'primary':'light'"
							>Todo</b-button>
							<b-button
								:variant="query.active=='yes'?'primary':'light'"
							>Vigentes</b-button>
							<b-button
								:variant="query.active=='no'?'primary':'light'"
							>No Vigentes</b-button>
						</b-button-group>
					</td>
					<td>
						<p class="text-transform-none mb-0">Filtra las empresas de acuerdo a si tienen o no servicios vigentes. Los servicios vigentes son aquellos que aún no alcanzan su fecha de vencimiento. Los servicios no vigentes son aquellos que ya alcanzaron su fecha de vencimiento y por lo tanto expiraron. La opción Todo muestra las empresas sin importar si tienen o no servicios vigentes.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center bg-secondary">
						<b-input-group prepend="Buscar">
							<b-input v-model="example.filter"></b-input>
							<b-input-group-append>
								<b-button :variant="example.filter.length > 0 ? 'primary' : 'light'">
									<i class="fas fa-arrow-right"></i>
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</td>
					<td>
						<p class="text-transform-none mb-0">Filtra las empresas que contengan la palabra o RUT que usted escriba. Al escribir, presione la flecha <i class="fas fa-arrow-right"></i> o la tecla <kbd>Enter</kbd> para iniciar la búsqueda.</p>
					</td>
				</tr>
			</table>

			<h4 class="mt-4">Tabla de Contenido <small class="text-muted">(parte inferior)</small></h4>
			
			<table class="table table-bordered">
				<tr>
					<td class="text-center">
						<strong>RUT</strong>
						<br>
						<span>90.000.000-K</span>
					</td>
					<td>
						<p class="text-transform-none mb-0">Muestra el RUT de la empresa contratista.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center">
						<strong>Empresa</strong>
						<br>
						<span v-b-tooltip.hover title="EMPRESA CONTRATISTA LIMITADA" class="cursor-pointer">EECC LTDA</span>
					</td>
					<td>
						<p class="text-transform-none mb-0">Muestra el nombre abreviado de la empresa contratista. Si posa el puntero del mouse sobre el nombre, verá la razón social completa.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center">
						<strong>Acciones</strong>
						<br>
						<b-dropdown size="sm" variant="outline-secondary" right no-caret>
							<template v-slot:button-content>
								<i class="fas fa-cog"></i>
								<span class="text-size-09"> GESTIONAR</span>
							</template>
							<b-dropdown-item-button>
								<i class="fas fa-edit text-muted mr-2"></i>
								<span>Ficha de Empresa</span>
							</b-dropdown-item-button>
							<b-dropdown-item-button>
								<i class="fas fa-check text-muted mr-2"></i>
								<span>Requisitos de Acreditación</span>
							</b-dropdown-item-button>
						</b-dropdown>
					</td>
					<td>
						<p class="text-transform-none mb-0">Abre el menú de opciones para gestionar la empresa contratista, que incluye:</p>
						<ul class="text-transform-none">
							<li>Ficha de Empresa: Muestra la información detallada de la empresa contratista, como datos de contacto o dirección.</li>
							<li>Requisitos de Acreditación: Abre el gestor de requisitos donde se pueden visualizar los documentos cargados por la empresa, para aprobarlos o rechazarlos.</li>
						</ul>
					</td>
				</tr>
				<tr>
					<td class="text-center">
						<strong>Aprobados</strong>
						<br>
						<b-badge variant="success">1</b-badge>
					</td>
					<td>
						<p class="text-transform-none mb-0">Cantidad de documentos de acreditación que fueron cargados por la empresa y aprobados por HMC.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center">
						<strong>Cargados</strong>
						<br>
						<b-badge variant="danger">1</b-badge>
					</td>
					<td>
						<p class="text-transform-none mb-0">Cantidad de documentos de acreditación que fueron cargados por la empresa y aún no han sido revisados por HMC.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center">
						<strong>Rechazados</strong>
						<br>
						<b-badge variant="danger">1</b-badge>
					</td>
					<td>
						<p class="text-transform-none mb-0">Cantidad de documentos de acreditación que fueron cargados por la empresa y rechazados por HMC.</p>
					</td>
				</tr>
				<tr>
					<td class="text-center">
						<strong>Pendientes</strong>
						<br>
						<b-badge variant="danger">1</b-badge>
					</td>
					<td>
						<p class="text-transform-none mb-0">Cantidad de documentos de acreditación que aún no han sido cargados por la empresa.</p>
					</td>
				</tr>
			</table>

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="secondary">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /HELP -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import Requirements from '@/components/Requirements.vue'
import Create from '@/components/CreateContractor.vue'
import Update from '@/components/UpdateContractor.vue'
import Delete from '@/components/DeleteContractor.vue'

export default {
	name: 'Contractors',
	components: {
		Unauthorized,
		Create,
		Update,
		Delete,
		Requirements
	},
	data() {
		return {
			owner: {},
			filtered: '',
			query: {
				active: 'yes',
				limit: 9999,
				page: 1,
				filter: ''
			},
			example: {
				filter: '',
				table: [
					{
						identifier: '90000000K',
						short_name: 'EECC LTDA',
						full_name: 'EMPRESA CONTRATISTA LIMITADA',
						req_done: 4,
						req_waiting: 3,
						req_rejected: 2,
						req_missing: 1
					}
				]
			},
			options: {
				fields: [
					{ key: 'identifier', label: 'RUT', sortable: true },
					{ key: 'short_name', label: 'Empresa', sortable: true },
					{ key: 'requirements', label: 'Acciones' },
					{ key: 'req_done', label: 'Aprobados', sortable: true },
					{ key: 'req_waiting', label: 'Cargados', sortable: true },
					{ key: 'req_rejected', label: 'Rechazados', sortable: true },
					{ key: 'req_missing', label: 'Pendientes', sortable: true }
				],
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 9999, text: 'Todas' }
				]
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		contractors() {
			return this.$store.state.contractors
		},
	},
	created() {
		this.load()
	},
	methods: {
		active(type) {
			this.query.active = type
			this.load()
		},
		create() {
			this.$store.dispatch('get_communes')
			this.$bvModal.show('create-contractor')
		},
		update(contractor) {
			this.$store.dispatch('get_communes')
			this.$store.dispatch('get_contractor', contractor.item.id)
			this.$bvModal.show('update-contractor')
		},
		destroy(contractor) {
			this.owner = contractor
			this.$bvModal.show('delete-contractor')
		},
		load() {
			this.$store.dispatch('get_contractors', this.query)
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un nombre de empresa o RUT para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.active = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
			this.filtered = ''
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.active = 'yes'
			this.query.filter = ''
			this.query.limit = 9999
			this.filtered = ''
			this.load()
		},
		help() {
			this.$bvModal.show('contractors-help')
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		requirements(contractor) {
			this.$store.dispatch('get_contractor_requirements', contractor.item.id)
			this.owner = contractor
			this.$bvModal.show('requirements')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
	}
}
</script>

<style>
</style>