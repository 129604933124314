<template>
	<div>
		<div class="p-0">

			<!-- NAVBAR -->
			<b-navbar id="navigation" toggleable="lg" type="dark" variant="dark" class="mb-3" v-if="!session">
				<b-navbar-brand>
					<b-img src="/img/logo_hmc_bordered.svg" class="logo"></b-img>
					<span class="text-white">Acreditación</span>
				</b-navbar-brand>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav class="ml-auto">
						<router-link to="/ingresar" custom v-slot="{ navigate }">
							<b-nav-item @click="navigate" @keypress.enter="navigate" role="link">
								<i class="fas fa-lock mr-2"></i>
								<span>Iniciar Sesión</span>
							</b-nav-item>
						</router-link>
					</b-navbar-nav>
				</b-collapse>
			</b-navbar>
			<!-- /NAVBAR -->

			<!-- BODY -->
			<b-card no-body>

				<b-card-body v-if="checking">
					<div class="text-center pt-3 pb-2">
						<b-spinner type="grow" variant="primary"></b-spinner>
						<h3 class="mt-3">Cargando Reporte</h3>
						<p class="text-size-15 mb-0 text-muted">Por favor, espere un momento.</p>
					</div>
				</b-card-body>
				<b-card-body v-if="invalid">
					<div class="text-center pt-2 pb-2">
						<i class="fas fa-minus-circle text-size-3 text-danger"></i>
						<h3 class="mt-3">Enlace Inválido</h3>
						<p class="text-size-15 mb-0 text-muted">{{error_message}}</p>
					</div>
				</b-card-body>

				<!-- REPORTS -->
				<div v-else-if="!checking && !invalid">

					<!-- TAB BUTTONS -->
					<b-card-header>
						<b-row>
							<b-col>
								<p class="mb-1 text-primary text-size-11">
									<i class="fas fa-arrow-down text-size-09 mr-1"></i>
									<span>Presione los botones para cambiar de sección</span>
								</p>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col md="3" sm="6" class="mb-2">
								<b-button
									block
									:variant="tab === 'companies' ? 'primary' : 'secondary'"
									size="lg"
									@click="goToTab('companies')"
									title="Presione para ver Acreditación de Empresas"
								>Empresas</b-button>
							</b-col>
							<b-col md="3" sm="6" class="mb-2">
								<b-button
									block
									:variant="tab === 'services' ? 'primary' : 'secondary'"
									size="lg"
									@click="goToTab('services')"
									title="Presione para ver Acreditación de Servicios"
								>Servicios</b-button>
							</b-col>
							<b-col md="3" sm="6" class="mb-2">
								<b-button
									block
									:variant="tab === 'workers' ? 'primary' : 'secondary'"
									size="lg" @click="goToTab('workers')"
									title="Presione para ver Acreditación de Trabajadores"
								>Trabajadores</b-button>
							</b-col>
							<b-col md="3" sm="6" class="mb-2">
								<b-button
									block
									:variant="tab === 'vehicles' ? 'primary' : 'secondary'"
									size="lg"
									@click="goToTab('vehicles')"
									title="Presione para ver Acreditación de Vehículos"
								>Vehículos</b-button>
							</b-col>
						</b-row>
					</b-card-header>
					<!-- /TAB BUTTONS -->

					<!-- COMPANIES -->
					<b-row v-if="tab === 'companies'" class="px-4 mt-3 mb-3">
						<b-col sm="12">
							<b-form-group label="Mostrar Acreditación de:" label-size="lg" class="mb-1">
								<b-overlay :show="requirements_report_options.loading" rounded class="p-0">
									<template #overlay>
										<div class="d-flex align-items-center">
										<b-spinner type="grow" variant="secondary" small></b-spinner>
										</div>
									</template>
									<b-form-select
										v-model="companies_company_id"
										:disabled="requirements_report_options.loading"
										size="lg"
										@change="select()"
									>
										<b-form-select-option value="all_contractors">TODAS LAS EMPRESAS CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="all_subcontractors">TODAS LAS EMPRESAS SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.contractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}}
										</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.subcontractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}} // SUBCONTRATO DE {{item.contractor.short_name.toUpperCase()}}
										</b-form-select-option>
									</b-form-select>
								</b-overlay>
							</b-form-group>
							<p class="mb-4 text-muted">
								<i class="fas fa-arrow-up text-size-09 mr-1"></i>
								<span>Presione para filtrar por empresa</span>
							</p>
						</b-col>
						<b-col sm="12">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title">
										<span v-if="!is_single_companies">Acreditación de Empresas</span>
										<span v-else>Acreditación de Empresa</span>
									</p>
									<div v-if="is_single_companies">
										<div v-if="data.companies.status.loading">
											<b-alert show variant="light" class="mt-3">
												<p class="text-size-15 mb-0 py-5 text-center">
													<b-spinner type="grow" variant="primary"></b-spinner>
												</p>
											</b-alert>
										</div>
										<div v-else-if="data.companies.status.series[0].data[0] == 1 || data.companies.status.series[0].data[1] == 1">
											<b-alert show variant="success" class="mt-3">
												<p class="text-size-15 mb-0 py-5 text-center">
													<i class="fas fa-check mr-2 text-size-2"></i><br>
													<span>{{current_companies_company}} se encuentra Acreditada y <span v-if="data.companies.documents.total.active > 0">tiene Servicios Vigentes</span><span v-else>no tiene Servicios Vigentes</span>.</span>
												</p>
											</b-alert>
										</div>
										<div v-else-if="data.companies.status.series[1].data[0] == 1 || data.companies.status.series[1].data[1] == 1">
											<b-alert show variant="danger" class="mt-3">
												<p class="text-size-15 mb-0 py-5 text-center">
													<i class="fas fa-times mr-2 text-size-2"></i><br>
													<span>{{current_companies_company}} no se encuentra Acreditada y <span v-if="data.companies.documents.total.active > 0">tiene Servicios Vigentes</span><span v-else>no tiene Servicios Vigentes</span>.</span>
												</p>
											</b-alert>
										</div>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-0" v-if="!data.companies.status.loading">{{data.companies.status.total}} {{current_companies_company}} en Sistema</p>
										<b-overlay :show="data.companies.status.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.status" :series="data.companies.status.series" height="auto"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="12" sm="12" v-if="is_single_companies || (is_single_companies && !data.companies.documents.loading && data.companies.documents.total.active > 0)">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">
										<span v-if="!is_single_companies">Documentos de Empresas Vigentes</span>
										<span v-else>Documentos de Empresa</span>
									</p>
									<div>
										<p class="text-muted text-size-12 mb-2" v-if="!data.companies.documents.loading">
											{{current_companies_company}} <span v-if="data.companies.documents.total.active > 0">tiene Servicios Vigentes</span><span v-else>no tiene Servicios Vigentes</span>
										</p>
										<b-overlay :show="data.companies.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.companies.documents.total.active > 0 ? data.companies.documents.series.active : data.companies.documents.series.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="!is_single_companies">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">
										<span v-if="!is_single_companies">Documentos de Empresas Vigentes</span>
										<span v-else>Documentos de Empresa</span>
									</p>
									<div>
										<p class="text-muted text-size-12 mb-2" v-if="!data.companies.documents.loading"><span v-if="!is_single_companies">{{data.companies.status.series[0].data[0] + data.companies.status.series[1].data[0]}} </span>{{current_companies_company}} <span v-if="is_single_companies">tiene Servicios Vigentes</span><span v-else>con Servicios Vigentes</span></p>
										<b-overlay :show="data.companies.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.companies.documents.series.active" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="!is_single_companies">
							<b-card>
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">
										<span v-if="!is_single_companies">Documentos de Empresas No Vigentes</span>
										<span v-else>Documentos de Empresa</span>
									</p>
									<div>
										<p class="text-muted text-size-12 mb-2" v-if="!data.companies.documents.loading"><span v-if="!is_single_companies">{{data.companies.status.series[0].data[1] + data.companies.status.series[1].data[1]}} </span>{{current_companies_company}} <span v-if="is_single_companies">no tiene Servicios Vigentes</span><span v-else>sin Servicios Vigentes</span></p>
										<b-overlay :show="data.companies.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.companies.documents.series.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
					<!-- /COMPANIES -->

					<!-- SERVICES -->
					<b-row v-else-if="tab === 'services'" class="px-4 mt-3 mb-3">
						<b-col sm="12">
							<b-form-group label="Mostrar Servicios de:" label-size="lg" class="mb-1">
								<b-overlay :show="requirements_report_options.loading" rounded class="p-0">
									<template #overlay>
										<div class="d-flex align-items-center">
										<b-spinner type="grow" variant="secondary" small></b-spinner>
										</div>
									</template>
									<b-form-select
										v-model="services_company_id"
										:disabled="requirements_report_options.loading"
										size="lg"
										@change="select()"
									>
										<b-form-select-option value="all_contractors">TODAS LAS EMPRESAS CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="all_subcontractors">TODAS LAS EMPRESAS SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.contractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}}
										</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.subcontractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}} // SUBCONTRATO DE {{item.contractor.short_name.toUpperCase()}}
										</b-form-select-option>
									</b-form-select>
								</b-overlay>
							</b-form-group>
							<p class="mb-4 text-muted">
								<i class="fas fa-arrow-up text-size-09 mr-1"></i>
								<span>Presione para filtrar por empresa</span>
							</p>
						</b-col>
						<b-col sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title">Acreditación de Órdenes de Servicio</p>
									<div v-if="!data.services.status.loading && data.services.status.total.orders == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Órdenes de Servicio en Sistema.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-0" v-if="!data.services.status.loading">{{data.services.status.total.orders}} <span v-if="data.services.status.total.orders > 1">Órdenes</span><span v-else>Orden</span> de Servicio <span v-if="is_single_services">de {{current_services_company}}</span><span v-else>en Sistema</span></p>
										<b-overlay :show="data.services.status.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.status" :series="data.services.status.series.orders" height="auto"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor && data.services.status.total.orders > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Órdenes de Servicio Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.orders.active == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Órdenes de Servicio Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_active_orders_count}} <span v-if="services_active_orders_count > 1">Órdenes</span><span v-else>Orden</span> de Servicio Vigente<span v-if="services_active_orders_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.orders.active" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor && data.services.status.total.orders > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Órdenes de Servicio No Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.orders.inactive == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Órdenes de Servicio No Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_inactive_orders_count}} <span v-if="services_inactive_orders_count > 1">Órdenes</span><span v-else>Orden</span> de Servicio No Vigente<span v-if="services_inactive_orders_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.orders.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title">Acreditación de Contratos</p>
									<div v-if="!data.services.status.loading && data.services.status.total.contracts == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Contratos en Sistema.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-0" v-if="!data.services.status.loading">{{data.services.status.total.contracts}} Contrato<span v-if="data.services.status.total.contracts > 1">s</span> <span v-if="is_single_services">de {{current_services_company}}</span><span v-else>en Sistema</span></p>
										<b-overlay :show="data.services.status.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.status" :series="data.services.status.series.contracts" height="auto"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor && data.services.status.total.contracts > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Contratos Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.contracts.active == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Contratos Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_active_contracts_count}} Contrato<span v-if="services_active_contracts_count > 1">s</span> Vigente<span v-if="services_active_contracts_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.contracts.active" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor && data.services.status.total.contracts > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Contratos No Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.contracts.inactive == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Contratos No Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_inactive_contracts_count}} Contrato<span v-if="services_inactive_contracts_count > 1">s</span> No Vigente<span v-if="services_inactive_contracts_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.contracts.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title">Acreditación de Garantías</p>
									<div v-if="!data.services.status.loading && data.services.status.total.warranties == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Garantías en Sistema.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-0" v-if="!data.services.status.loading">{{data.services.status.total.warranties}} Garantía<span v-if="data.services.status.total.warranties > 1">s</span> <span v-if="is_single_services">de {{current_services_company}}</span><span v-else>en Sistema</span></p>
										<b-overlay :show="data.services.status.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.status" :series="data.services.status.series.warranties" height="auto"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor && data.services.status.total.warranties > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Garantías Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.warranties.active == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Garantías Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_active_warranties_count}} Garantía<span v-if="services_active_warranties_count > 1">s</span> Vigente<span v-if="services_active_warranties_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.warranties.active" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="services_company_id != 'all_subcontractors' && !is_subcontractor && data.services.status.total.warranties > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Garantías No Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.warranties.inactive == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Garantías No Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_inactive_warranties_count}} Garantía<span v-if="services_inactive_warranties_count > 1">s</span> No Vigente<span v-if="services_inactive_warranties_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.warranties.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col sm="12">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title">Acreditación de Subcontratos</p>
									<div v-if="!data.services.status.loading && data.services.status.total.subcontracts == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Subcontratos en Sistema.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-0" v-if="!data.services.status.loading">{{data.services.status.total.subcontracts}} Subcontrato<span v-if="data.services.status.total.subcontracts > 1">s</span> <span v-if="is_single_services">de {{current_services_company}}</span><span v-else>en Sistema</span></p>
										<b-overlay :show="data.services.status.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.status" :series="data.services.status.series.subcontracts" height="auto"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="data.services.status.total.subcontracts > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Subcontratos Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.subcontracts.active == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Subcontratos Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_active_subcontracts_count}} Subcontrato<span v-if="services_active_subcontracts_count > 1">s</span> Vigente<span v-if="services_active_subcontracts_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.subcontracts.active" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="data.services.status.total.subcontracts > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Subcontratos No Vigentes</p>
									<div v-if="!data.services.documents.loading && data.services.documents.total.subcontracts.inactive == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_services_company}} no registra Subcontratos No Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2">{{services_inactive_subcontracts_count}} Subcontrato<span v-if="services_inactive_subcontracts_count > 1">s</span> No Vigente<span v-if="services_inactive_subcontracts_count > 1">s</span><span v-if="is_single_services"> de {{current_services_company}}</span></p>
										<b-overlay :show="data.services.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.services.documents.series.subcontracts.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
					<!-- /SERVICES -->
					
					<!-- WORKERS -->
					<b-row v-else-if="tab === 'workers'" class="px-4 mt-3 mb-3">
						<b-col sm="12">
							<b-form-group label="Mostrar Trabajadores de:" label-size="lg" class="mb-1">
								<b-overlay :show="requirements_report_options.loading" rounded class="p-0">
									<template #overlay>
										<div class="d-flex align-items-center">
										<b-spinner type="grow" variant="secondary" small></b-spinner>
										</div>
									</template>
									<b-form-select
										v-model="workers_company_id"
										:disabled="requirements_report_options.loading"
										size="lg"
										@change="select()"
									>
										<b-form-select-option value="all_contractors">TODAS LAS EMPRESAS CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="all_subcontractors">TODAS LAS EMPRESAS SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.contractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}}
										</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.subcontractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}} // SUBCONTRATO DE {{item.contractor.short_name.toUpperCase()}}
										</b-form-select-option>
									</b-form-select>
								</b-overlay>
							</b-form-group>
							<p class="mb-4 text-muted">
								<i class="fas fa-arrow-up text-size-09 mr-1"></i>
								<span>Presione para filtrar por empresa</span>
							</p>
						</b-col>
						<b-col sm="12">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title">Acreditación de Trabajadores</p>
									<div v-if="!data.workers.status.loading && data.workers.status.total == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_workers_company}} no registra Trabajadores en Sistema.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-0" v-if="!data.workers.status.loading">{{data.workers.status.total}} Trabajador<span v-if="data.workers.status.total > 1">es</span> <span v-if="is_single_workers">de {{current_workers_company}}</span><span v-else>en Sistema</span></p>
										<b-overlay :show="data.workers.status.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.status" :series="data.workers.status.series" height="auto"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="data.workers.status.total > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Trabajadores Vigentes</p>
									<div v-if="!data.workers.documents.loading && data.workers.documents.total.active == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_workers_company}} no registra Trabajadores Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2" v-if="!data.workers.documents.loading">{{workers_active_count}} Trabajador<span v-if="workers_active_count > 1">es</span> Vigente<span v-if="workers_active_count > 1">s</span><span v-if="is_single_workers"> de {{current_workers_company}}</span></p>
										<b-overlay :show="data.workers.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.workers.documents.series.active" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="data.workers.status.total > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Trabajadores No Vigentes</p>
									<div v-if="!data.workers.documents.loading && data.workers.documents.total.inactive == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_workers_company}} no registra Trabajadores No Vigentes.</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2" v-if="!data.workers.documents.loading">{{workers_inactive_count}} Trabajador<span v-if="workers_inactive_count > 1">es</span> No Vigente<span v-if="workers_inactive_count > 1">s</span><span v-if="is_single_workers"> de {{current_workers_company}}</span></p>
										<b-overlay :show="data.workers.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.workers.documents.series.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
					<!-- /WORKERS -->
					
					<!-- VEHICLES -->
					<b-row v-else-if="tab === 'vehicles'" class="px-4 mt-3 mb-3">
						<b-col sm="12">
							<b-form-group label="Mostrar Vehículos de:" label-size="lg" class="mb-1">
								<b-overlay :show="requirements_report_options.loading" rounded class="p-0">
									<template #overlay>
										<div class="d-flex align-items-center">
										<b-spinner type="grow" variant="secondary" small></b-spinner>
										</div>
									</template>
									<b-form-select
										v-model="vehicles_company_id"
										:disabled="requirements_report_options.loading"
										size="lg"
										@change="select()"
									>
										<b-form-select-option value="all_contractors">TODAS LAS EMPRESAS CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="all_subcontractors">TODAS LAS EMPRESAS SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>CONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.contractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}}
										</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option value="" disabled>SUBCONTRATISTAS</b-form-select-option>
										<b-form-select-option value="" disabled>-----------------------</b-form-select-option>
										<b-form-select-option v-for="item in requirements_report_options.data.subcontractors" :key="item.id" :value="item.id">
											{{item.short_name.toUpperCase()}} // SUBCONTRATO DE {{item.contractor.short_name.toUpperCase()}}
										</b-form-select-option>
									</b-form-select>
								</b-overlay>
							</b-form-group>
							<p class="mb-4 text-muted">
								<i class="fas fa-arrow-up text-size-09 mr-1"></i>
								<span>Presione para filtrar por empresa</span>
							</p>
						</b-col>
						<b-col sm="12">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title">Acreditación de Vehículos</p>
									<div v-if="!data.vehicles.status.loading && data.vehicles.status.total == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0"><span v-if="vehicles_company_id == 'all_subcontractors'">Ninguna Empresa Subcontratista registra Vehículos en Sistema</span><span v-else>{{current_vehicles_company}} no registra Vehículos en Sistema</span></b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-0" v-if="!data.vehicles.status.loading">{{data.vehicles.status.total}} Vehículo<span v-if="data.vehicles.status.total > 1">s</span> <span v-if="is_single_vehicles">de {{current_vehicles_company}}</span><span v-else>en Sistema</span></p>
										<b-overlay :show="data.vehicles.status.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.status" :series="data.vehicles.status.series" height="300"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="data.vehicles.status.total > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Vehículos Vigentes</p>
									<div v-if="!data.vehicles.documents.loading && data.vehicles.documents.total.active == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_vehicles_company}} no registra Vehículos Vigentes</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2" v-if="!data.vehicles.documents.loading">{{vehicles_active_count}} Vehículo<span v-if="vehicles_active_count > 1">s</span> Vigente<span v-if="vehicles_active_count > 1">s</span><span v-if="is_single_vehicles"> de {{current_vehicles_company}}</span></p>
										<b-overlay :show="data.vehicles.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.vehicles.documents.series.active" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col md="6" sm="12" v-if="data.vehicles.status.total > 0">
							<b-card class="mb-4">
								<b-card-body class="p-0">
									<p class="inner-card-title mb-1">Documentos de Vehículos No Vigentes</p>
									<div v-if="!data.vehicles.documents.loading && data.vehicles.documents.total.inactive == 0">
										<b-alert show variant="secondary" class="text-size-13 py-4 text-center mt-3 mb-0">{{current_vehicles_company}} no registra Vehículos No Vigentes</b-alert>
									</div>
									<div v-else>
										<p class="text-muted text-size-12 mb-2" v-if="!data.vehicles.documents.loading">{{vehicles_inactive_count}} Vehículo<span v-if="vehicles_inactive_count > 1">s</span> No Vigente<span v-if="vehicles_inactive_count > 1">s</span><span v-if="is_single_vehicles"> de {{current_vehicles_company}}</span></p>
										<b-overlay :show="data.vehicles.documents.loading" rounded spinner-type="grow" spinner-variant="primary">
											<apexchart :options="charts.documents" :series="data.vehicles.documents.series.inactive" height="400"></apexchart>
										</b-overlay>
									</div>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
					<!-- /VEHICLES -->
					
				</div>
				<!-- /REPORTS -->

			</b-card>
			<!-- /BODY -->
			
		</div>

	</div>
</template>

<script>
export default {
	name: 'RequirementsReport',
	components: {
	},
	data() {
		return {
			checking: true,
			invalid: false,
			loading: false,
			error_message: 'El sistema no pudo encontrar un reporte asociado a este enlace.',
			tab: 'companies',
			companies_company_id: 'all_contractors',
			services_company_id: 'all_contractors',
			workers_company_id: 'all_contractors',
			vehicles_company_id: 'all_contractors',
			data: {
				companies: {
					status: {
						loading: true,
						series: [],
						total: 0
					},
					documents: {
						loading: true,
						series: { general: [], active: [], inactive: [] },
						total: { active: 0, inactive: 0 },
					}
				},
				services: {
					status: {
						loading: true,
						series: {
							orders: [],
							contracts: [],
							warranties: [],
							subcontracts: []
						},
						total: {
							orders: 0,
							contracts: 0,
							warranties: 0,
							subcontracts: 0
						}
					},
					documents: {
						loading: true,
						series: {
							orders: { general: [], active: [], inactive: [] },
							contracts: { general: [], active: [], inactive: [] },
							warranties: { general: [], active: [], inactive: [] },
							subcontracts: { general: [], active: [], inactive: [] },
						},
						total: {
							orders: { active: 0, inactive: 0 },
							contracts: { active: 0, inactive: 0 },
							warranties: { active: 0, inactive: 0 },
							subcontracts: { active: 0, inactive: 0 }
						},
					}
				},
				workers: {
					status: {
						loading: true,
						series: [],
						total: 0
					},
					documents: {
						loading: true,
						series: { general: [], active: [], inactive: [] },
						total: { active: 0, inactive: 0 },
					}
				},
				vehicles: {
					status: {
						loading: true,
						series: [],
						total: 0
					},
					documents: {
						loading: true,
						series: { general: [], active: [], inactive: [] },
						total: { active: 0, inactive: 0 },
					}
				}
			},
			charts: {
				status: {
					dataLabels: {
						formatter: function(val, opt) {
							if (val == 0) return ''
							return Math.round(val) + '% ('+opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]+')'
						}
					},
					chart: {
						type: 'bar',
						stacked: true,
						stackType: '100%',
						toolbar: {
							show: false
						},
						height: 200
					},
					plotOptions: {
						bar: {
							horizontal: true,
							barHeight: '60%',
						}
					},
					colors: [ '#008FFB', '#FF4560' ],
					fill: {
						opacity: 1
					},
					legend: {
						show: true
					},
					responsive: [
						{
							breakpoint: 600,
							options: {
								plotOptions: {
									bar: {
										horizontal: false,
									}
								},
								chart: {
									height: 300
								},
								xaxis: {
									position: 'top',
									categories: [ 'Vigentes', 'No Vigentes' ],
									labels: {
										show: true
									},
									axisTicks: {
										show: false
									}
								},
								yaxis: {
									labels: {
										show: false
									},
									axisTicks: {
										show: false
									}
								},
							},
						},
						{
							breakpoint: 9000,
							options: {
								xaxis: {
									categories: [ 'Vigentes', 'No Vigentes' ],
									labels: {
										show: false
									},
									axisTicks: {
										show: false
									}
								},
								yaxis: {
									labels: {
										show: true
									},
									axisTicks: {
										show: false
									}
								},
								chart: {
									type: 'bar',
									stacked: true,
									stackType: '100%',
									toolbar: {
										show: false
									},
									height: 200
								},
								plotOptions: {
									bar: {
										horizontal: true,
										barHeight: '60%',
									}
								},
								colors: [ '#008FFB', '#FF4560' ],
								fill: {
									opacity: 1
								},
								legend: {
									show: true
								},
							},
						}
					],
				},
				documents: {
					stroke: {
						show: false
					},
					chart: {
						type: 'donut',
					},
					labels: ['Aprobados', 'Rechazados', 'Esperando Revisión', 'Pendientes'],
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									name: {
										show: true,
										fontWeight: 600
									},
									total: {
										show: true
									}
								}
							}
						}
					},
					colors: [ '#00E396', '#FF4560', '#FEB019', '#AAAAAA' ],
					fill: {
						opacity: 1
					},
					legend: {
						show: true,
						position: 'bottom',
						formatter: function(seriesName, opts) {
							return [seriesName, " (", opts.w.globals.series[opts.seriesIndex], ")"]
						}
					},
					dataLabels: {
						minAngleToShowLabel: 20,
						style: {
							colors: [ '#333333', '#333333', '#333333', '#333333' ]
						},
						dropShadow: {
							enabled: false
						},
						background: {
							enabled: true
						}
					}
				}
			},
		}
	},
	mounted() {
	},
	created() {
		this.$store.dispatch('get_requirements_report_options', this.token)
		.then(() => {
			this.checking = false
			this.loadCompaniesStatus()
			this.loadCompaniesDocuments()
			this.loadServicesStatus()
			this.loadServicesDocuments()
			this.loadWorkersStatus()
			this.loadWorkersDocuments()
			this.loadVehiclesStatus()
			this.loadVehiclesDocuments()
		})
		.catch(error => {
			this.checking = false
			this.invalid = true
			if (error.response.data.message) this.error_message = error.response.data.message
		})
	},
	computed: {
		token() {
			return this.$route.params.token
		},
		session() {
			return this.$store.getters.session
		},
		requirements_report_options() {
			return this.$store.state.requirements_report_options
		},
		services_active_orders_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.orders[0].data[0] + this.data.services.status.series.orders[1].data[0]
			} else {
				return 0
			}
		},
		services_inactive_orders_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.orders[0].data[1] + this.data.services.status.series.orders[1].data[1]
			} else {
				return 0
			}
		},
		services_active_contracts_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.contracts[0].data[0] + this.data.services.status.series.contracts[1].data[0]
			} else {
				return 0
			}
		},
		services_inactive_contracts_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.contracts[0].data[1] + this.data.services.status.series.contracts[1].data[1]
			} else {
				return 0
			}
		},
		services_active_warranties_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.warranties[0].data[0] + this.data.services.status.series.warranties[1].data[0]
			} else {
				return 0
			}
		},
		services_inactive_warranties_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.warranties[0].data[1] + this.data.services.status.series.warranties[1].data[1]
			} else {
				return 0
			}
		},
		services_active_subcontracts_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.subcontracts[0].data[0] + this.data.services.status.series.subcontracts[1].data[0]
			} else {
				return 0
			}
		},
		services_inactive_subcontracts_count() {
			if (!this.data.services.status.loading) {
				return this.data.services.status.series.subcontracts[0].data[1] + this.data.services.status.series.subcontracts[1].data[1]
			} else {
				return 0
			}
		},
		workers_active_count() {
			if (!this.data.workers.status.loading) {
				return this.data.workers.status.series[0].data[0] + this.data.workers.status.series[1].data[0]
			} else {
				return 0
			}
		},
		workers_inactive_count() {
			if (!this.data.workers.status.loading) {
				return this.data.workers.status.series[0].data[1] + this.data.workers.status.series[1].data[1]
			} else {
				return 0
			}
		},
		vehicles_active_count() {
			if (!this.data.vehicles.status.loading) {
				return this.data.vehicles.status.series[0].data[0] + this.data.vehicles.status.series[1].data[0]
			} else {
				return 0
			}
		},
		vehicles_inactive_count() {
			if (!this.data.vehicles.status.loading) {
				return this.data.vehicles.status.series[0].data[1] + this.data.vehicles.status.series[1].data[1]
			} else {
				return 0
			}
		},
		is_single_companies() {
			if (this.companies_company_id == 'all_contractors' || this.companies_company_id == 'all_subcontractors') {
				return false
			} else {
				return true
			}
		},
		is_single_services() {
			if (this.services_company_id == 'all_contractors' || this.services_company_id == 'all_subcontractors') {
				return false
			} else {
				return true
			}
		},
		is_single_workers() {
			if (this.workers_company_id == 'all_contractors' || this.workers_company_id == 'all_subcontractors') {
				return false
			} else {
				return true
			}
		},
		is_single_vehicles() {
			if (this.vehicles_company_id == 'all_contractors' || this.vehicles_company_id == 'all_subcontractors') {
				return false
			} else {
				return true
			}
		},
		is_subcontractor() {
			if (!this.requirements_report_options.loading && this.requirements_report_options.data.subcontractors) {
				const index = this.requirements_report_options.data.subcontractors.findIndex(item => item.id === this.services_company_id)
				if (index != -1) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		current_companies_company() {
			if (this.companies_company_id == 'all_contractors') {
				return 'Empresas Contratistas'
			}
			else if (this.companies_company_id == 'all_subcontractors') {
				return 'Empresas Subcontratistas'
			}
			else if (this.requirements_report_options.data.contractors && this.requirements_report_options.data.subcontractors) {
				const contractor = this.requirements_report_options.data.contractors.findIndex(item => item.id === this.companies_company_id)
				const subcontractor = this.requirements_report_options.data.subcontractors.findIndex(item => item.id === this.companies_company_id)
				if (contractor != -1) {
					return this.requirements_report_options.data.contractors[contractor].short_name.toUpperCase()
				}
				else if (subcontractor != -1) {
					return this.requirements_report_options.data.subcontractors[subcontractor].short_name.toUpperCase() +
					' como Subcontratista de ' +
					this.requirements_report_options.data.subcontractors[subcontractor].contractor.short_name.toUpperCase()
				}
				else {
					return ''
				}
			}
			else {
				return ''
			}
		},
		current_services_company() {
			if (this.services_company_id == 'all_contractors') {
				return 'todas las contratistas'
			}
			else if (this.services_company_id == 'all_subcontractors') {
				return 'todas las subcontratistas'
			}
			else if (this.requirements_report_options.data.contractors && this.requirements_report_options.data.subcontractors) {
				const contractor = this.requirements_report_options.data.contractors.findIndex(item => item.id === this.services_company_id)
				const subcontractor = this.requirements_report_options.data.subcontractors.findIndex(item => item.id === this.services_company_id)
				if (contractor != -1) {
					return this.requirements_report_options.data.contractors[contractor].short_name.toUpperCase()
				}
				else if (subcontractor != -1) {
					return this.requirements_report_options.data.subcontractors[subcontractor].short_name.toUpperCase() +
					' como subcontratista de ' +
					this.requirements_report_options.data.subcontractors[subcontractor].contractor.short_name.toUpperCase()
				}
				else {
					return ''
				}
			}
			else {
				return ''
			}
		},
		current_workers_company() {
			if (this.workers_company_id == 'all_contractors') {
				return 'todas las empresas contratistas'
			}
			else if (this.workers_company_id == 'all_subcontractors') {
				return 'todas las empresas subcontratistas'
			}
			else if (this.requirements_report_options.data.contractors && this.requirements_report_options.data.subcontractors) {
				const contractor = this.requirements_report_options.data.contractors.findIndex(item => item.id === this.workers_company_id)
				const subcontractor = this.requirements_report_options.data.subcontractors.findIndex(item => item.id === this.workers_company_id)
				if (contractor != -1) {
					return this.requirements_report_options.data.contractors[contractor].short_name.toUpperCase()
				}
				else if (subcontractor != -1) {
					return this.requirements_report_options.data.subcontractors[subcontractor].short_name.toUpperCase() +
					' como subcontratista de ' +
					this.requirements_report_options.data.subcontractors[subcontractor].contractor.short_name.toUpperCase()
				}
				else {
					return ''
				}
			}
			else {
				return ''
			}
		},
		current_vehicles_company() {
			if (this.vehicles_company_id == 'all_contractors') {
				return 'todas las empresas contratistas'
			}
			else if (this.vehicles_company_id == 'all_subcontractors') {
				return 'todas las empresas subcontratistas'
			}
			else if (this.requirements_report_options.data.contractors && this.requirements_report_options.data.subcontractors) {
				const contractor = this.requirements_report_options.data.contractors.findIndex(item => item.id === this.vehicles_company_id)
				const subcontractor = this.requirements_report_options.data.subcontractors.findIndex(item => item.id === this.vehicles_company_id)
				if (contractor != -1) {
					return this.requirements_report_options.data.contractors[contractor].short_name.toUpperCase()
				}
				else if (subcontractor != -1) {
					return this.requirements_report_options.data.subcontractors[subcontractor].short_name.toUpperCase() +
					' como subcontratista de ' +
					this.requirements_report_options.data.subcontractors[subcontractor].contractor.short_name.toUpperCase()
				}
				else {
					return ''
				}
			}
			else {
				return ''
			}
		},
	},
	methods: {
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		goToTab(tab) {
			this.tab = tab
		},
		select() {
			if (this.tab === 'companies') {
				this.reset()
				this.loadCompaniesStatus()
				this.loadCompaniesDocuments()
			}
			else if (this.tab === 'services') {
				this.reset()
				this.loadServicesStatus()
				this.loadServicesDocuments()
			}
			else if (this.tab === 'workers') {
				this.reset()
				this.loadWorkersStatus()
				this.loadWorkersDocuments()
			}
			else if (this.tab === 'vehicles') {
				this.reset()
				this.loadVehiclesStatus()
				this.loadVehiclesDocuments()
			}
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		loadCompaniesStatus() {
			this.data.companies.status.loading = true
			this.$api.post('stats/requirements/companies/status', { token: this.token, company_id: this.companies_company_id })
			.then(response => {
				this.data.companies.status.loading = false
				this.data.companies.status.series = response.data.series
				this.data.companies.status.total = response.data.total
			})
		},
		loadCompaniesDocuments() {
			this.data.companies.documents.loading = true
			this.$api.post('stats/requirements/companies/documents', { token: this.token, company_id: this.companies_company_id })
			.then(response => {
				this.data.companies.documents.loading = false
				this.data.companies.documents.series = response.data.series
				this.data.companies.documents.total = response.data.total
			})
		},
		loadServicesStatus() {
			this.data.services.status.loading = true
			this.$api.post('stats/requirements/services/status', { token: this.token, company_id: this.services_company_id })
			.then(response => {
				this.data.services.status.loading = false
				this.data.services.status.series = response.data.series
				this.data.services.status.total = response.data.total
			})
		},
		loadServicesDocuments() {
			this.data.services.documents.loading = true
			this.$api.post('stats/requirements/services/documents', { token: this.token, company_id: this.services_company_id })
			.then(response => {
				this.data.services.documents.loading = false
				this.data.services.documents.series = response.data.series
				this.data.services.documents.total = response.data.total
			})
		},
		loadWorkersStatus() {
			this.data.workers.status.loading = true
			this.$api.post('stats/requirements/workers/status', { token: this.token, company_id: this.workers_company_id })
			.then(response => {
				this.data.workers.status.loading = false
				this.data.workers.status.series = response.data.series
				this.data.workers.status.total = response.data.total
			})
		},
		loadWorkersDocuments() {
			this.data.workers.documents.loading = true
			this.$api.post('stats/requirements/workers/documents', { token: this.token, company_id: this.workers_company_id })
			.then(response => {
				this.data.workers.documents.loading = false
				this.data.workers.documents.series = response.data.series
				this.data.workers.documents.total = response.data.total
			})
		},
		loadVehiclesStatus() {
			this.data.vehicles.status.loading = true
			this.$api.post('stats/requirements/vehicles/status', { token: this.token, company_id: this.vehicles_company_id })
			.then(response => {
				this.data.vehicles.status.loading = false
				this.data.vehicles.status.series = response.data.series
				this.data.vehicles.status.total = response.data.total
			})
		},
		loadVehiclesDocuments() {
			this.data.vehicles.documents.loading = true
			this.$api.post('stats/requirements/vehicles/documents', { token: this.token, company_id: this.vehicles_company_id })
			.then(response => {
				this.data.vehicles.documents.loading = false
				this.data.vehicles.documents.series = response.data.series
				this.data.vehicles.documents.total = response.data.total
			})
		},
		reset() {
			if (this.tab === 'companies') {
				let clean = {
					status: {
						loading: true,
						series: [],
						total: 0
					},
					documents: {
						loading: true,
						series: { general: [], active: [], inactive: [] },
						total: { active: 0, inactive: 0 },
					}
				}
				this.data.companies = clean
			}
			else if (this.tab === 'services') {
				let clean = {
					status: {
						loading: true,
						series: {
							orders: [],
							contracts: [],
							warranties: [],
							subcontracts: []
						},
						total: {
							orders: 0,
							contracts: 0,
							warranties: 0,
							subcontracts: 0
						}
					},
					documents: {
						loading: true,
						series: {
							orders: { general: [], active: [], inactive: [] },
							contracts: { general: [], active: [], inactive: [] },
							warranties: { general: [], active: [], inactive: [] },
							subcontracts: { general: [], active: [], inactive: [] },
						},
						total: {
							orders: { active: 0, inactive: 0 },
							contracts: { active: 0, inactive: 0 },
							warranties: { active: 0, inactive: 0 },
							subcontracts: { active: 0, inactive: 0 }
						},
					}
				}
				this.data.services = clean
			}
			else if (this.tab === 'workers') {
				let clean = {
					status: {
						loading: true,
						series: [],
						total: 0
					},
					documents: {
						loading: true,
						series: { general: [], active: [], inactive: [] },
						total: { active: 0, inactive: 0 },
					}
				}
				this.data.workers = clean
			}
			else if (this.tab === 'vehicles') {
				let clean = {
					status: {
						loading: true,
						series: [],
						total: 0
					},
					documents: {
						loading: true,
						series: { general: [], active: [], inactive: [] },
						total: { active: 0, inactive: 0 },
					}
				}
				this.data.vehicles = clean
			}
		},
	}
}
</script>

<style scoped>
.inner-card-title {
	text-align: left;
	font-weight: 500;
	font-size: 1.5em;
	margin: 0;
}
</style>
