<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="worker-internal-license"
			title="Licencia Interna"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="updating" rounded="sm" class="p-3">

				<b-card class="mb-4">
					<p class="mb-0"><strong class="text-size-09">CARTA DE COMPROMISO</strong></p>
					<p class="mb-2 text-size-14">Conductores y Operadores</p>
					<b-button
						@click="compromiso()"
						variant="primary"
					>
						<i class="fad fa-download mr-2"></i>
						<span>Generar Documento</span>
					</b-button>
				</b-card>
				
				<b-card class="mb-4">
					<p class="mb-0"><strong class="text-size-09">CONSTANCIA</strong></p>
					<p class="mb-2 text-size-14">Autorización de Operación de Vehículos</p>
					<b-form-group label="Nombre de Jefatura del Trabajador">
						<b-input
							v-model="data.signer_name"
							:state="val_signer_name"
							placeholder="Ejemplo: Pedro Pérez Pereira"
						></b-input>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback>Correcto</b-form-valid-feedback>
					</b-form-group>
					<b-form-group label="RUT de Jefatura del Trabajador">
						<b-input
							v-model="data.signer_vatin"
							:state="val_signer_vatin"
							placeholder="Ejemplo: 12.345.678-9"
						></b-input>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback>Correcto</b-form-valid-feedback>
					</b-form-group>
					<b-form-group label="Cargo de Jefatura del Trabajador">
						<b-input
							v-model="data.signer_position"
							:state="val_signer_position"
							placeholder="Ejemplo: Jefe de Operaciones"
						></b-input>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback>Correcto</b-form-valid-feedback>
					</b-form-group>
					<b-button
						@click="constancia()"
						:variant="val_request ? 'primary' : 'danger'"
						:disabled="!val_request"
					>
						<i class="fad fa-download mr-2"></i>
						<span>Generar Documento</span>
					</b-button>
				</b-card>

				<b-card>
					<p class="mb-0"><strong class="text-size-09">SOLICITUD</strong></p>
					<p class="mb-2 text-size-14">Licencia Interna de Conducción HMC Faena Michilla - EECC</p>
					<b-button
						@click="licencia()"
						variant="primary"
					>
						<i class="fad fa-download mr-2"></i>
						<span>Generar Documento</span>
					</b-button>
				</b-card>

				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

		<b-modal
			id="worker-internal-license-download"
			size="sm"
			title="¡Documento Generado!"
		>
			<b-button
				variant="primary"
				:href="download_link"
				@click="closeDownload()"
				block
			>
				<i class="fad fa-download mr-2"></i>
				<span>Descargar Documento</span>
			</b-button>
			<template v-slot:modal-footer>
				<b-button @click="closeDownload()">Cerrar</b-button>
			</template>
		</b-modal>

	</div>
</template>

<script>
export default {
	name: 'UpdateWorker',
	components: {
	},
	data() {
		return {
			updating: false,
			loading: false,
			uuid: '',
			data: {
				signer_name: '',
				signer_vatin: '',
				signer_position: ''
			},
			options: [
				{ value: 1, text: 'Días Licencia Médica Efectivos (LM)' },
				{ value: 2, text: 'Días Vacaciones Efectivos (V)' },
				{ value: 3, text: 'Reposo Mutual Efectivos (RM)' },
				{ value: 4, text: 'Permiso Legal Efectivos (PL)' },
				{ value: 5, text: 'Turno Gerencia (TG)' },
				{ value: 6, text: 'Permiso Con Goce de Sueldo (PG)' },
				{ value: 7, text: 'Permiso Sin Goce de Sueldo (PS)' },
				{ value: 8, text: 'TeleTrabajo (TT)' }
			],
			fields: [
				{ key: 'type.description', label: 'Tipo', sortable: true },
				{ key: 'start_date', label: 'Fecha de Inicio', sortable: true },
				{ key: 'end_date', label: 'Fecha de Término', sortable: true }
			]
		}
	},
	props: {
		owner: Object
	},
	computed: {
		api_path() {
			return this.$store.state.api_path
		},
		download_link() {
			if (this.uuid.length > 0) {
				return this.api_path+'documents/'+this.uuid
			} else {
				return ''
			}
		},
		getters() {
			return this.$store.getters
		},
		val_signer_name() {
			return this.data.signer_name.length > 0 ? true : false
		},
		val_signer_vatin() {
			return this.data.signer_vatin.length > 0 ? true : false
		},
		val_signer_position() {
			return this.data.signer_position.length > 0 ? true : false
		},
		val_request() {
			return this.val_signer_name && this.val_signer_vatin && this.val_signer_position ? true : false
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'worker-internal-license') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.data = {
				signer_name: '',
				signer_vatin: '',
				signer_position: ''
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		closeDownload() {
			this.$bvModal.hide('worker-internal-license-download')
			this.uuid = ''
		},
		constancia() {
			this.updating = true
			this.$api.post('workers/'+this.owner.item.id+'/constancia', this.data)
			.then(response => {
				this.updating = false
				this.uuid = response.data.uuid
				this.$bvModal.show('worker-internal-license-download')
				this.reset()
				//this.$store.dispatch('get_worker_restrictions', this.owner.item.id)
				//this.toast(true, 'Se registró la restricción correctamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		compromiso() {
			this.updating = true
			this.$api.post('workers/'+this.owner.item.id+'/compromiso')
			.then(response => {
				this.updating = false
				this.uuid = response.data.uuid
				this.$bvModal.show('worker-internal-license-download')
				//this.$store.dispatch('get_worker_restrictions', this.owner.item.id)
				//this.toast(true, 'Se registró la restricción correctamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		licencia() {
			this.updating = true
			this.$api.post('workers/'+this.owner.item.id+'/licencia')
			.then(response => {
				this.updating = false
				this.uuid = response.data.uuid
				this.$bvModal.show('worker-internal-license-download')
				//this.reset()
				//this.$store.dispatch('get_worker_restrictions', this.owner.item.id)
				//this.toast(true, 'Se registró la restricción correctamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
	}
}
</script>

<style scoped>
</style>
