<template>
	<b-modal
		id="system-update"
		size="lg"
		body-class="p-0 text-center"
		hide-header
		no-close-on-backdrop
		no-close-on-esc
		hide-footer
	>
		<div class="p-4">
			<i class="fad fa-layer-plus text-size-3 mb-2 text-success"></i>
			<h3 class="mb-3 text-dark">Actualización Disponible</h3>
			<p class="text-size-15 mb-0">Para actualizar a la última versión del sistema, recargue esta página en su navegador web.</p>
		</div>

		<hr class="m-0">

		<div class="p-4">
			<p class="text-dark text-size-12 mb-3">Si al recargar la página sigue viendo este mensaje, intente los siguientes pasos:</p>
			<b-card-group deck class="mb-3">
				<b-card>
					<p class="mb-1 text-size-12">
						<span>Desde</span>
						<i class="fab fa-windows mr-1 ml-2 windows-logo"></i>
						<span class="windows-logo">Windows</span>
					</p>
					<p class="mb-2 text-dark text-size-1">
						<span>Presione al mismo tiempo las teclas</span>
					</p>
					<p class="mb-0">
						<kbd>CTRL</kbd>
						<i class="fal fa-plus mx-1"></i>
						<kbd>F5</kbd>
					</p>
				</b-card>
				<b-card>
					<p class="mb-1 text-size-12">
						<span>Desde</span>
						<i class="fab fa-apple mr-1 ml-2 text-secondary"></i>
						<span class="text-secondary">macOS</span>
					</p>
					<p class="mb-2 text-dark text-size-1">
						<span>Presione al mismo tiempo las teclas</span>
					</p>
					<p class="mb-0">
						<kbd>⌘</kbd>
						<i class="fal fa-plus mx-1"></i>
						<kbd>⌥</kbd>
						<i class="fal fa-plus mx-1"></i>
						<kbd>R</kbd>
					</p>
				</b-card>
			</b-card-group>

			<p class="text-dark text-size-12 pt-3 mb-3">Si el problema persiste, consulte el manual de su navegador web:</p>
			<b-row>
				<b-col sm="4" class="mb-3">
					<b-button
						variant="light"
						block
						class="text-center"
						href="https://support.google.com/accounts/answer/32050?hl=es-419"
						target="_blank"
					>
						<b-img src="/img/logo_browser_chrome.svg" class="browser_logo mr-1"></b-img>
						<span class="mb-0 text-size-11 text-dark">Google Chrome</span>
					</b-button>
				</b-col>
				<b-col sm="4" class="mb-3">
					<b-button
						variant="light"
						block
						class="text-center"
						href="https://support.mozilla.org/es/kb/limpia-la-cache-y-elimina-los-archivos-temporales-"
						target="_blank"
					>
						<b-img src="/img/logo_browser_firefox.svg" class="browser_logo mr-1"></b-img>
						<span class="mb-0 text-size-11 text-dark">Mozilla Firefox</span>
					</b-button>
				</b-col>
				<b-col sm="4" class="mb-3">
					<b-button
						variant="light"
						block
						class="text-center"
						href="https://support.microsoft.com/es-cl/microsoft-edge/00cf7943-a9e1-975a-a33d-ac10ce454ca4"
						target="_blank"
					>
						<b-img src="/img/logo_browser_edge.svg" class="browser_logo mr-1"></b-img>
						<span class="mb-0 text-size-11 text-dark">Microsoft Edge</span>
					</b-button>
				</b-col>
				<b-col sm="4" class="mb-3">
					<b-button
						variant="light"
						block
						class="text-center"
						href="https://support.apple.com/es-es/HT201265"
						target="_blank"
					>
						<b-img src="/img/logo_browser_safari.svg" class="browser_logo mr-1"></b-img>
						<span class="mb-0 text-size-11 text-dark">Apple Safari</span>
					</b-button>
				</b-col>
				<b-col sm="4" class="mb-3">
					<b-button
						variant="light"
						block
						class="text-center"
						href="https://blogs.opera.com/la-spain/2016/04/como-borrar-el-historial-de-navegacion/"
						target="_blank"
					>
						<b-img src="/img/logo_browser_opera.svg" class="browser_logo mr-1"></b-img>
						<span class="mb-0 text-size-11 text-dark">Opera Browser</span>
					</b-button>
				</b-col>
			</b-row>
		</div>

	</b-modal>
</template>

<script>
export default {
	name: 'SystemUpdate',
	components: {
	},
}
</script>

<style>
#system-update .browser_logo {
	width: 20px;
	margin-top: -2px;
}
</style>