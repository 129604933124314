<template>
	<div>

		<!-- COMPONENTS -->
		<RequirementView :requirement="requirement"></RequirementView>
		<RequirementReview :requirement="requirement" @run_task="runTask" @clear_task="clearTask"></RequirementReview>
		<RequirementUpload :requirement="requirement" @run_task="runTask" @clear_task="clearTask"></RequirementUpload>
		<!-- /COMPONENTS -->

		<!-- MODAL -->
		<b-modal
			id="requirements"
			:title="title"
			size="lg"
			body-class="p-0 requirements"
			hide-footer
		>
			<!-- CONTENT -->
			<b-skeleton-wrapper :loading="requirements.loading" class="p-4">

				<!-- LOADING -->
				<template #loading>
					<b-row class="mb-3">
						<b-col>
							<b-card>
								<b-skeleton width="85%"></b-skeleton>
								<b-skeleton width="55%"></b-skeleton>
							</b-card>
						</b-col>
						<b-col>
							<b-card>
								<b-skeleton width="85%"></b-skeleton>
								<b-skeleton width="55%"></b-skeleton>
							</b-card>
						</b-col>
					</b-row>
					<b-skeleton-table
						animation="fade"
						:rows="14"
						:columns="1"
						:table-props="{ small: true, bordered: true }"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- OWNER INFO -->
				<div v-if="owner.item">
				
					<!-- CONTRACTOR INFO -->
					<b-card class="mx-4 mt-3 mb-3" v-if="owner.item && owner.item.class == 'contractor'">
						<b-row>
							<b-col sm="auto">
								<h6 class=" text-secondary">RUT</h6>
								<p class="text-size-12 mb-0">{{parseIdentifier(owner.item.identifier)}}</p>
							</b-col>
							<b-col>
								<h6 class=" text-secondary">Razón Social</h6>
								<p class="text-size-12 mb-0 text-uppercase">{{owner.item.full_name}}</p>
							</b-col>
							<b-col sm="auto text-center" class="cursor-pointer" v-b-tooltip.hover :title="validation(owner, 'detail')">
								<h6 class=" text-secondary">Acreditada</h6>
								<p class="text-size-12 mb-0 text-uppercase">
									<b-badge variant="success" class="cursor-pointer" v-if="validation(owner, 'validated')">SÍ</b-badge>
									<b-badge variant="danger" class="cursor-pointer" v-else>NO</b-badge>
								</p>
							</b-col>
						</b-row>
					</b-card>
					<!-- /CONTRACTOR INFO -->

					<!-- SUBCONTRACTOR INFO -->
					<b-card class="mx-4 mt-3 mb-3" v-else-if="owner.item && owner.item.class == 'subcontractor'">
						<b-row>
							<b-col sm="auto">
								<h6 class=" text-secondary">RUT</h6>
								<p class="text-size-12 mb-0">{{parseIdentifier(owner.item.identifier)}}</p>
							</b-col>
							<b-col sm="auto">
								<h6 class=" text-secondary">Razón Social</h6>
								<p class="text-size-12 mb-0 text-uppercase">{{owner.item.full_name}}</p>
							</b-col>
							<b-col>
								<h6 class=" text-secondary">Mandante</h6>
								<p class="text-size-12 mb-0 text-uppercase">{{owner.item.contractor.short_name}}</p>
							</b-col>
							<b-col sm="auto text-center" class="cursor-pointer" v-b-tooltip.hover :title="validation(owner, 'detail')">
								<h6 class=" text-secondary">Acreditada</h6>
								<p class="text-size-12 mb-0 text-uppercase">
									<b-badge variant="success" class="cursor-pointer" v-if="validation(owner, 'validated')">SÍ</b-badge>
									<b-badge variant="danger" class="cursor-pointer" v-else>NO</b-badge>
								</p>
							</b-col>
						</b-row>
					</b-card>
					<!-- /SUBCONTRACTOR INFO -->

					<!-- SERVICE INFO -->
					<b-card class="mx-4 mt-3 mb-3" v-else-if="owner.item && owner.item.class == 'service'">
						<b-row>
							<b-col sm="auto">
								<h6 class=" text-secondary">
									<span v-if="owner.item.type == 1">Orden de Servicio</span>
									<span v-if="owner.item.type == 2">Contrato</span>
									<span v-if="owner.item.type == 3">Garantía</span>
									<span v-if="owner.item.type == 4">Subcontrato</span>
								</h6>
								<p class="text-size-12 mb-0">{{owner.item.cod}}</p>
							</b-col>
							<b-col>
								<h6 class=" text-secondary">Título</h6>
								<p class="text-size-12 mb-0 text-uppercase">{{owner.item.comments}}</p>
							</b-col>
							<b-col sm="auto text-center" class="cursor-pointer" v-b-tooltip.hover :title="validation(owner, 'detail')">
								<h6 class=" text-secondary">Acreditado</h6>
								<p class="text-size-12 mb-0 text-uppercase">
									<b-badge variant="success" class="cursor-pointer" v-if="validation(owner, 'validated')">SÍ</b-badge>
									<b-badge variant="danger" class="cursor-pointer" v-else>NO</b-badge>
								</p>
							</b-col>
						</b-row>
					</b-card>
					<!-- /SERVICE INFO -->

					<!-- WORKER INFO -->
					<b-card class="mx-4 mt-3 mb-3" v-else-if="owner.item && owner.item.class == 'worker'">
						<b-row>
							<b-col sm="auto">
								<h6 class="text-secondary mb-0">RUT</h6>
								<p class="text-size-12 mb-2">{{parseIdentifier(owner.item.person.identifier)}}</p>
								<h6 class="text-secondary mb-0">Edad</h6>
								<p class="text-size-12 mb-0">
									<span v-if="owner.item.age != null">{{ owner.item.age }}</span>
									<span v-else class="text-danger text-size-09">
										<i class="fas fa-exclamation-triangle mr-1"></i>
										<span>No Informada</span>
									</span>
								</p>
							</b-col>
							<b-col>
								<h6 class="text-secondary mb-0">Nombre Completo</h6>
								<p class="text-size-12 mb-2 text-uppercase">{{owner.item.person.names}} {{owner.item.person.primary_last_name}} {{owner.item.person.second_last_name}}</p>
								<h6 class="text-secondary mb-0">Nacionalidad</h6>
								<p class="text-size-12 mb-0">
									<span v-if="owner.item.country != null && owner.item.country.nationality != null">{{ owner.item.country.nationality.toUpperCase() }}</span>
									<span v-else class="text-danger text-size-09">
										<i class="fas fa-exclamation-triangle mr-1"></i>
										<span>No Informada</span>
									</span>
								</p>
							</b-col>
							<b-col sm="auto text-center" class="cursor-pointer" v-b-tooltip.hover :title="validation(owner, 'detail')">
								<h6 class="text-secondary mb-0">Acreditado</h6>
								<p class="text-size-12 mb-0 text-uppercase">
									<b-badge variant="success" class="cursor-pointer" v-if="validation(owner, 'validated')">SÍ</b-badge>
									<b-badge variant="danger" class="cursor-pointer" v-else>NO</b-badge>
								</p>
							</b-col>
						</b-row>
					</b-card>
					<!-- /WORKER INFO -->

					<!-- VEHICLE INFO -->
					<b-card class="mx-4 mt-3 mb-3" v-else-if="owner.item && owner.item.class == 'vehicle'">
						<b-row>
							<b-col sm="auto">
								<h6 class=" text-secondary">Patente</h6>
								<p class="text-size-12 mb-0">{{owner.item.identifier}}</p>
							</b-col>
							<b-col>
								<h6 class=" text-secondary">Marca/Modelo</h6>
								<p class="text-size-12 mb-0 text-uppercase">{{owner.item.manufacturer}} {{owner.item.model}} {{owner.item.year}}</p>
							</b-col>
							<b-col sm="auto text-center" class="cursor-pointer" v-b-tooltip.hover :title="validation(owner, 'detail')">
								<h6 class=" text-secondary">Acreditado</h6>
								<p class="text-size-12 mb-0 text-uppercase">
									<b-badge variant="success" class="cursor-pointer" v-if="validation(owner, 'validated')">SÍ</b-badge>
									<b-badge variant="danger" class="cursor-pointer" v-else>NO</b-badge>
								</p>
							</b-col>
						</b-row>
					</b-card>
					<!-- /VEHICLE INFO -->

				</div>
				<!-- /OWNER INFO -->

				<!-- PHYSICAL FOLDER -->
				<div v-if="owner.item && owner.item.class == 'service' && validation(owner, 'validated')" class="mx-4 mb-3">
					<b-card no-body class="py-2 px-3">
						<b-row>
							<b-col sm="2">
								<div v-if="owner.item.physical_folder" class="text-success">
									<i class="fas fa-check mr-2"></i>
									<strong>Entregada</strong>
								</div>
								<div v-else class="text-danger">
									<i class="fas fa-times mr-2"></i>
									<strong>Pendiente</strong>
								</div>
							</b-col>
							<b-col>
								Carpeta Física
							</b-col>
							<b-col class="text-right" sm="2" v-if="folder_task_running">
								<b-skeleton variant="primary" width="100%"></b-skeleton>
							</b-col>
							<b-col class="text-right" sm="auto" v-else>
								<div v-if="owner.item.physical_folder">
									<b-button
										variant="outline-danger"
										size="sm"
										@click="disapprovePhysicalFolder(owner.item.id)"
										v-if="(owner.item.type === 1 && getters.prv_service_orders_physical_folder_manage) ||
										(owner.item.type === 2 && getters.prv_contracts_physical_folder_manage) ||
										(owner.item.type === 3 && getters.prv_warranties_physical_folder_manage) ||
										(owner.item.type === 4 && getters.prv_subcontracts_physical_folder_manage)"
									>
										<span>Anular Recepción</span>
									</b-button>
								</div>
								<div v-else>
									<b-button
										variant="outline-success"
										size="sm"
										@click="approvePhysicalFolder(owner.item.id)"
										v-if="(owner.item.type === 1 && getters.prv_service_orders_physical_folder_manage) ||
										(owner.item.type === 2 && getters.prv_contracts_physical_folder_manage) ||
										(owner.item.type === 3 && getters.prv_warranties_physical_folder_manage) ||
										(owner.item.type === 4 && getters.prv_subcontracts_physical_folder_manage)"
									>
										<span>Confirmar Recepción</span>
									</b-button>
								</div>
							</b-col>
						</b-row>
					</b-card>
				</div>
				<!-- /PHYSICAL FOLDER -->

				<!-- REQUIREMENTS INFO -->
				<b-list-group class="mx-4 mb-4">
					<b-list-group-item v-for="(item, index) in requirements.data" :key="index" class="xs-lg">
						<b-row>

							<!-- STATUS -->
							<b-col sm="2">
								
								<!-- MISSING REQUIREMENT -->
								<div v-if="status(item) == 'missing'">
									<span class="status-dot secondary"></span> <span class="text-muted">Pendiente</span>
								</div>
								<!-- /MISSING REQUIREMENT -->
								
								<!-- DISABLED REQUIREMENT -->
								<div v-else-if="status(item) == 'disabled'">
									<span class="status-dot light"></span> <span class="text-muted">No Aplica</span>
								</div>
								<!-- /DISABLED REQUIREMENT -->
								
								<!-- DONE REQUIREMENT -->
								<div v-else-if="status(item) == 'done'">
									<span class="status-dot success"></span> <span class="text-muted">Aprobado</span>
								</div>
								<!-- /DONE REQUIREMENT -->
								
								<!-- REJECTED REQUIREMENT -->
								<div v-else-if="status(item) == 'rejected'">
									<span class="status-dot danger"></span> <span class="text-muted">Rechazado</span>
								</div>
								<!-- /REJECTED REQUIREMENT -->
								
								<!-- WAITING REQUIREMENT -->
								<div v-else-if="status(item) == 'waiting'">
									<span class="status-dot warning"></span> <span class="text-muted">Enviado</span>
								</div>
								<!-- /WAITING REQUIREMENT -->

							</b-col>
							<!-- /STATUS -->

							<!-- INFORMATION -->
							<b-col>
								<p class="m-0">
									<span>{{item.document_type.name}}</span>
									<span
										v-if="item.document_type.id == 82 || item.document_type.id == 83"
										class="text-primary ml-2"
										v-b-tooltip.hover
										title="Requerimiento exigido por Copper Mark"
									>
										<i class="fas fa-circle-info"></i>
									</span>
								</p>
								<p class="m-0 text-danger text-uppercase" v-if="status(item) == 'rejected'">
									<strong>{{item.document.observation}}</strong>
								</p>
							</b-col>
							<!-- /INFORMATION -->

							<!-- ACTIONS -->
							<b-col sm="2" class="text-right pt-1" v-if="current_task == index">
								<b-skeleton variant="primary" width="100%"></b-skeleton>
							</b-col>
							<b-col sm="auto" class="text-right" v-else>
								
								<!-- MISSING REQUIREMENT -->
								<div v-if="status(item) == 'missing'">
									<b-button
										variant="outline-secondary xs-btn"
										@click="upload(item, index)"
										v-if="checkPrivilege(item, 'upload')"
									>Cargar Documento</b-button>
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="disable(item, index)"
										v-if="checkPrivilege(item, 'disable')"
									>Desactivar Requisito</b-button>
								</div>
								<!-- /MISSING REQUIREMENT -->

								<!-- WAITING REQUIREMENT -->
								<div v-if="status(item) == 'waiting'">
									<b-button
										variant="outline-secondary xs-btn"
										@click="review(item, index)"
										v-if="checkPrivilege(item, 'review')"
									>Revisar Documento</b-button>
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="destroy(item, index)"
										v-if="checkPrivilege(item, 'destroy')"
									>Eliminar Carga</b-button>
								</div>
								<!-- /WAITING REQUIREMENT -->

								<!-- DONE REQUIREMENT -->
								<div v-if="status(item) == 'done'">
									<b-button
										variant="danger xs-btn" 
										@click="reupload(item, index)"
										v-if="checkPrivilege(item, 'upload') && ((item.document_type.id == 69 || item.document_type.id == 70) && (item.document.expiration_date == null || softExpired(item.document.expiration_date)))"
									>
										<i class="fas fa-exclamation-triangle mr-1"></i>
										<span>Reemplazar Documento</span>
									</b-button>
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="view(item, index)"
										v-if="checkPrivilege(item, 'view')"
									>Ver Documento</b-button>
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="unapprove(item, index)"
										v-if="checkPrivilege(item, 'unapprove')"
									>Anular Aprobación</b-button>
								</div>
								<!-- /DONE REQUIREMENT -->

								<!-- REJECTED REQUIREMENT -->
								<div v-if="status(item) == 'rejected'">
									<b-button
										variant="outline-secondary xs-btn"
										@click="view(item, index)"
										v-if="checkPrivilege(item, 'view')"
									>Ver Documento</b-button>
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="unreject(item, index)"
										v-if="checkPrivilege(item, 'unreject')"
									>Anular Rechazo</b-button>
									<b-button
										variant="outline-secondary xs-btn" class="ml-1"
										@click="reupload(item, index)"
										v-if="checkPrivilege(item, 'reupload')"
									>Reemplazar Documento</b-button>
								</div>
								<!-- /REJECTED REQUIREMENT -->

								<!-- DISABLED REQUIREMENT -->
								<div v-if="status(item) == 'disabled'">
									<b-button
										variant="outline-secondary xs-btn"
										@click="enable(item, index)"
										v-if="checkPrivilege(item, 'enable')"
									>Reactivar Requisito</b-button>
								</div>
								<!-- /DISABLED REQUIREMENT -->

							</b-col>
							<!-- /ACTIONS -->

						</b-row>
					</b-list-group-item>
				</b-list-group>
				<!-- REQUIREMENTS INFO -->
				<!-- /DATA -->

				<b-alert :show="owner.item && owner.item.class == 'worker'" class="mx-4 mb-4" variant="primary">
					<p class="mb-0">
						<i class="fas fa-circle-info text-primary mr-2"></i>
						<span>Alerta de cumplimiento Copper Mark, póngase en contacto de manera urgente con el Depto. de RRHH si tiene consultas para realizar la carga.</span>
					</p>
				</b-alert>

			</b-skeleton-wrapper>
			<!-- CONTENT -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
import RequirementReview from '@/components/RequirementReview.vue'
import RequirementView from '@/components/RequirementView.vue'
import RequirementUpload from '@/components/RequirementUpload.vue'

export default {
	name: 'Requirements',
	components: {
		RequirementReview,
		RequirementView,
		RequirementUpload
	},
	data() {
		return {
			requirement: {
				index: null,
				data: {}
			},
			current_task: null,
			folder_task_running: false
		}
	},
	props: {
		owner: Object
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		api_root() {
			return this.$store.state.api_root
		},
		title() {
			if (this.owner.item) {
				switch (this.owner.item.class) {
					case 'contractor':
						return 'Requisitos de Empresa Contratista'
					case 'subcontractor':
						return 'Requisitos de Empresa Subcontratista'
					case 'service':
						return 'Requisitos de Servicio'
					case 'worker':
						return 'Requisitos de Trabajador'
					case 'vehicle':
						return 'Requisitos de Vehículo'
					default:
						return 'Requisitos'
				}
			} else {
				return null
			}
		},
		requirements() {
			if (this.owner.item) {
				switch (this.owner.item.class) {
					case 'contractor':
						return this.$store.state.contractor_requirements
					case 'subcontractor':
						return this.$store.state.subcontractor_requirements
					case 'service':
						return this.$store.state.service_requirements
					case 'worker':
						return this.$store.state.worker_requirements
					case 'vehicle':
						return this.$store.state.vehicle_requirements
					default:
						return { loading: false, data: [] }
				}
			} else {
				return { loading: false, data: [] }
			}
		},
		owners() {
			if (this.owner.item) {
				switch (this.owner.item.class) {
					case 'contractor':
						return this.$store.state.contractors
					case 'subcontractor':
						return this.$store.state.subcontractors
					case 'service':
						return this.$store.state.services
					case 'worker':
						return this.$store.state.workers
					case 'vehicle':
						return this.$store.state.vehicles
					default:
						return { loading: false, data: [] }
				}
			} else {
				return { loading: false, data: [] }
			}
		},
		store_index() {
			if (this.owner.item) {
				const index = this.owners.data.data.findIndex(owner => owner.id === this.owner.item.id)
				return index
			} else {
				return null
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'requirements') {
				//console.log(modalId)
			}
		})
	},
	methods: {
		checkPrivilege(privilege, action) {
			let requirements = {
				contractors: [ 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ],
				contractors_risk: [6, 12, 13, 14, 15, 16, 17],
				subcontractors: [ 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ],
				subcontractors_risk: [6, 12, 13, 14, 15, 16, 17],
				service_orders: [30, 31, 32, 33, 34, 35, 36, 29, 37],
				contracts: [30, 31, 32, 33, 34, 35, 36, 29, 37],
				warranties: [63, 64, 65],
				subcontracts: [72, 73, 74, 75, 76, 77, 78, 79, 80],
				contractor_workers: [46, 48, 49, 50, 51, 53, 54, 55, 56],
				contractor_workers_driver: [59, 60, 62, 57, 58, 61],
				contractor_workers_coppermark: [82, 83],
				subcontractor_workers: [46, 48, 49, 50, 51, 53, 54, 55, 56],
				subcontractor_workers_driver: [59, 60, 62, 57, 58, 61],
				subcontractor_workers_coppermark: [82, 83],
				contractor_vehicles: [66, 67, 68, 69, 70, 71],
				subcontractor_vehicles: [66, 67, 68, 69, 70, 71]
			}
			let current_class = privilege.class
			let current_requirement = privilege.document_type_id
			switch (current_class) {

				case 'contractor':
					if (requirements.contractors.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractors_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractors_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractors_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_contractors_requirements_upload || this.getters.prv_contractors_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_contractors_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractors_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractors_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractors_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractors_requirements_disable) return true
						else { return false }
					}
					else if (requirements.contractors_risk.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractors_risk_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractors_risk_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractors_risk_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_contractors_risk_requirements_upload || this.getters.prv_contractors_risk_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_contractors_risk_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractors_risk_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractors_risk_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractors_risk_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractors_risk_requirements_disable) return true
						else { return false }
					}
					break

				case 'subcontractor':
					if (requirements.subcontractors.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractors_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractors_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractors_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_subcontractors_requirements_upload || this.getters.prv_subcontractors_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_subcontractors_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractors_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractors_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractors_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractors_requirements_disable) return true
						else { return false }
					}
					else if (requirements.subcontractors_risk.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractors_risk_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractors_risk_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractors_risk_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_subcontractors_risk_requirements_upload || this.getters.prv_subcontractors_risk_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_subcontractors_risk_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractors_risk_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractors_risk_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractors_risk_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractors_risk_requirements_disable) return true
						else { return false }
					}
					break
				
				case 'service':
					if (requirements.service_orders.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_service_orders_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_service_orders_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_service_orders_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_service_orders_requirements_upload || this.getters.prv_service_orders_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_service_orders_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_service_orders_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_service_orders_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_service_orders_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_service_orders_requirements_disable) return true
						else { return false }
					}
					else if (requirements.contracts.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contracts_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contracts_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contracts_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_contracts_requirements_upload || this.getters.prv_contracts_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_contracts_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contracts_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contracts_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contracts_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contracts_requirements_disable) return true
						else { return false }
					}
					else if (requirements.warranties.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_warranties_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_warranties_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_warranties_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_warranties_requirements_upload || this.getters.prv_warranties_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_warranties_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_warranties_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_warranties_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_warranties_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_warranties_requirements_disable) return true
						else { return false }
					}
					else if (requirements.subcontracts.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontracts_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontracts_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontracts_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_subcontracts_requirements_upload || this.getters.prv_subcontracts_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_subcontracts_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontracts_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontracts_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontracts_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontracts_requirements_disable) return true
						else { return false }
					}
					break
				
				case 'worker':
					if (requirements.contractor_workers.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractor_workers_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractor_workers_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractor_workers_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_contractor_workers_requirements_upload || this.getters.prv_contractor_workers_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_contractor_workers_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractor_workers_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractor_workers_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractor_workers_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractor_workers_requirements_disable) return true
						else { return false }
					}
					else if (requirements.contractor_workers_driver.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractor_workers_driver_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractor_workers_driver_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractor_workers_driver_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_contractor_workers_driver_requirements_upload || this.getters.prv_contractor_workers_driver_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_contractor_workers_driver_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractor_workers_driver_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractor_workers_driver_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractor_workers_driver_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractor_workers_driver_requirements_disable) return true
						else { return false }
					}
					else if (requirements.contractor_workers_coppermark.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractor_workers_coppermark_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractor_workers_coppermark_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractor_workers_coppermark_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_contractor_workers_coppermark_requirements_upload || this.getters.prv_contractor_workers_coppermark_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_contractor_workers_coppermark_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractor_workers_coppermark_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractor_workers_coppermark_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractor_workers_coppermark_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractor_workers_coppermark_requirements_disable) return true
						else { return false }
					}
					if (requirements.subcontractor_workers.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractor_workers_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractor_workers_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractor_workers_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_subcontractor_workers_requirements_upload || this.getters.prv_subcontractor_workers_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_subcontractor_workers_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractor_workers_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractor_workers_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractor_workers_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractor_workers_requirements_disable) return true
						else { return false }
					}
					else if (requirements.subcontractor_workers_driver.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractor_workers_driver_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractor_workers_driver_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractor_workers_driver_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_subcontractor_workers_driver_requirements_upload || this.getters.prv_subcontractor_workers_driver_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_subcontractor_workers_driver_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractor_workers_driver_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractor_workers_driver_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractor_workers_driver_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractor_workers_driver_requirements_disable) return true
						else { return false }
					}
					else if (requirements.subcontractor_workers_coppermark.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractor_workers_coppermark_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractor_workers_coppermark_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractor_workers_coppermark_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_subcontractor_workers_coppermark_requirements_upload || this.getters.prv_subcontractor_workers_coppermark_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_subcontractor_workers_coppermark_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractor_workers_coppermark_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractor_workers_coppermark_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractor_workers_coppermark_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractor_workers_coppermark_requirements_disable) return true
						else { return false }
					}
					break
				
				case 'vehicle':
					if (requirements.contractor_vehicles.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_contractor_vehicles_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_contractor_vehicles_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_contractor_vehicles_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_contractor_vehicles_requirements_upload || this.getters.prv_contractor_vehicles_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_contractor_vehicles_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_contractor_vehicles_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_contractor_vehicles_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_contractor_vehicles_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_contractor_vehicles_requirements_disable) return true
						else { return false }
					}
					if (requirements.subcontractor_vehicles.includes(current_requirement)) {
						if (action == 'view' && this.getters.prv_subcontractor_vehicles_requirements_read) return true
						else if (action == 'upload' && this.getters.prv_subcontractor_vehicles_requirements_upload) return true
						else if (action == 'reupload' && this.getters.prv_subcontractor_vehicles_requirements_upload) return true
						else if (action == 'destroy' && (this.getters.prv_subcontractor_vehicles_requirements_upload || this.getters.prv_subcontractor_vehicles_requirements_review)) return true
						else if (action == 'review' && this.getters.prv_subcontractor_vehicles_requirements_review) return true
						else if (action == 'unapprove' && this.getters.prv_subcontractor_vehicles_requirements_review) return true
						else if (action == 'unreject' && this.getters.prv_subcontractor_vehicles_requirements_review) return true
						else if (action == 'disable' && this.getters.prv_subcontractor_vehicles_requirements_disable) return true
						else if (action == 'enable' && this.getters.prv_subcontractor_vehicles_requirements_disable) return true
						else { return false }
					}
					break
			}
		},
		validation(owner, type) {
			const done = owner.item.req_done
			const rejected = owner.item.req_rejected
			const waiting = owner.item.req_waiting
			const missing = owner.item.req_missing
			const total = done + rejected + waiting + missing
			const validated = done == total ? true : false
			const completed = Math.ceil(done / total * 100)
			if (type == 'total') return total
			if (type == 'completed') return completed
			if (type == 'validated') return validated
			if (type == 'done') return done
			if (type == 'detail') return 'Cumple con ' + done + ' de ' + total + ' requisitos ('+ completed + '%)'
			return null
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		clearTask() {
			this.current_task = null
		},
		runTask(index) {
			this.current_task = index
		},
		checkTask() {
			if (this.current_task !== null) {
				this.toast(false, 'Por favor, espere un momento mientras se procesa su última solicitud.', 5000)
				return true
			} else {
				return false
			}
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		status(requirement) {
			if (requirement) {
				if (requirement.document == null) {
					return 'missing'
				} else if (requirement.document != null && requirement.document.action != null && requirement.document.action.result == 1 && requirement.document.status == 2) {
					return 'disabled'
				} else if (requirement.document != null && requirement.document.action != null && requirement.document.action.result == 1 && requirement.document.status == 1) {
					return 'done'
				} else if (requirement.document != null && requirement.document.action != null && requirement.document.action.result == -2) {
					return 'rejected'
				} else if (requirement.document != null && requirement.document.action == null) {
					return 'waiting'
				}
			} else {
				return null
			}
		},
		upload(requirement, index) {
			this.requirement = { index: index, data: requirement }
			this.$bvModal.show('requirement-upload')
		},
		reupload(requirement, index) {
			this.requirement = { index: index, data: requirement }
			this.$bvModal.show('requirement-upload')
		},
		view(requirement, index) {
			this.requirement = { index: index, data: requirement }
			this.$bvModal.show('requirement-view')
		},
		review(requirement, index) {
			this.requirement = { index: index, data: requirement }
			this.$bvModal.show('requirement-review')
		},
		disable(requirement, index) {
			if (this.checkTask()) return
			this.$bvModal.msgBoxConfirm('¿Desea desactivar el requisito "'+requirement.document_type.name+'"?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Desactivar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(index)
					this.$api.post('requirements/disable', {
						class: requirement.class,
						document_type_id: requirement.document_type_id,
						owner_id: requirement.owner_id
					})
					.then(response => {
						this.requirements.data[index].document = response.data
						/*******************************************************
						 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
						 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
						 *******************************************************/
						if (requirement.document_type.id != 82 && requirement.document_type.id != 83) {
							this.owners.data.data[this.store_index].req_missing--
							if (this.owners.data.data[this.store_index].req_pending) this.owners.data.data[this.store_index].req_pending--
							this.owners.data.data[this.store_index].req_done++
						}
						/*******************************************************/
						this.clearTask()
						this.toast(true, 'El requisito "'+requirement.document_type.name+'" fue desactivado y la empresa ya no debe cargar el documento.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.clearTask()
					})
				}
			})
		},
		enable(requirement, index) {
			if (this.checkTask()) return
			this.$bvModal.msgBoxConfirm('¿Desea volver a activar el requisito "'+requirement.document_type.name+'"?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Activar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(index)
					this.$api.post('requirements/enable', {
						class: requirement.class,
						document_id: requirement.document.id,
						owner_id: requirement.owner_id
					})
					.then(() => {
						this.requirements.data[index].document = null
						/*******************************************************
						 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
						 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
						 *******************************************************/
						if (requirement.document_type.id != 82 && requirement.document_type.id != 83) {
							this.owners.data.data[this.store_index].req_done--
							if (this.owners.data.data[this.store_index].req_pending) this.owners.data.data[this.store_index].req_pending++
							this.owners.data.data[this.store_index].req_missing++
						}
						/*******************************************************/
						this.clearTask()
						this.toast(true, 'El requisito "'+requirement.document_type.name+'" fue reactivado y la empresa debe cargar el documento.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.clearTask()
					})
				}
			})
		},
		unapprove(requirement, index) {
			if (this.checkTask()) return
			this.$bvModal.msgBoxConfirm('¿Desea anular la aprobación del documento "'+requirement.document_type.name+'"?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Anular',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(index)
					this.$api.post('requirements/unapprove', {
						class: requirement.class,
						document_id: requirement.document.id,
						owner_id: requirement.owner_id
					})
					.then(response => {
						this.requirements.data[index].document = response.data
						/*******************************************************
						 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
						 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
						 *******************************************************/
						if (requirement.document_type.id != 82 && requirement.document_type.id != 83) {
							this.owners.data.data[this.store_index].req_done--
							if (this.owners.data.data[this.store_index].req_pending) this.owners.data.data[this.store_index].req_pending++
							this.owners.data.data[this.store_index].req_waiting++
						}
						/*******************************************************/
						this.clearTask()
						this.toast(true, 'La aprobación del requisito "'+requirement.document_type.name+'" fue anulada y debe ser revisado nuevamente.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.clearTask()
					})
				}
			})
		},
		unreject(requirement, index) {
			if (this.checkTask()) return
			this.$bvModal.msgBoxConfirm('¿Desea anular el rechazo del documento "'+requirement.document_type.name+'"?', {
				title: 'Confirmar Acción',
				size: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Anular',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(index)
					this.$api.post('requirements/unreject', {
						class: requirement.class,
						document_id: requirement.document.id,
						owner_id: requirement.owner_id
					})
					.then(response => {
						this.requirements.data[index].document = response.data
						/*******************************************************
						 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
						 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
						 *******************************************************/
						if (requirement.document_type.id != 82 && requirement.document_type.id != 83) {
							this.owners.data.data[this.store_index].req_rejected--
							this.owners.data.data[this.store_index].req_waiting++
						}
						/*******************************************************/
						this.clearTask()
						this.toast(true, 'El rechazo del requisito "'+requirement.document_type.name+'" fue anulado y debe ser revisado nuevamente.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.clearTask()
					})
				}
			})
		},
		destroy(requirement, index) {
			if (this.checkTask()) return
			this.$bvModal.msgBoxConfirm('¿Desea eliminar el documento "'+requirement.document_type.name+'" enviado por la empresa?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Eliminar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.runTask(index)
					this.$api.post('requirements/destroy', {
						class: requirement.class,
						document_type_id: requirement.document_type_id,
						owner_id: requirement.owner_id
					})
					.then(() => {
						this.requirements.data[index].document = null
						/*******************************************************
						 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
						 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
						 *******************************************************/
						if (requirement.document_type.id != 82 && requirement.document_type.id != 83) {
							this.owners.data.data[this.store_index].req_waiting--
							this.owners.data.data[this.store_index].req_missing++
						}
						/*******************************************************/
						this.clearTask()
						this.toast(true, 'El documento "'+requirement.document_type.name+'" fue eliminado del sistema y debe ser cargado nuevamente.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.clearTask()
					})
				}
			})
		},
		approvePhysicalFolder(service_id) {
			if (this.folder_task_running) return
			this.$bvModal.msgBoxConfirm('¿Desea confirmar la recepción de la Carpeta Física de este servicio?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'success',
				okTitle: 'Sí, Confirmar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.folder_task_running = true
					this.$api.post('services/'+service_id+'/physical_folder')
					.then(() => {
						this.owners.data.data[this.store_index].physical_folder = true
						this.folder_task_running = false
						this.toast(true, 'La recepción de la Carpeta Física fue confirmada correctamente.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.folder_task_running = false
					})
				}
			})
		},
		disapprovePhysicalFolder(service_id) {
			if (this.folder_task_running) return
			this.$bvModal.msgBoxConfirm('¿Desea anular la recepción de la Carpeta Física de este servicio?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'danger',
				okTitle: 'Sí, Anular',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.folder_task_running = true
					this.$api.delete('services/'+service_id+'/physical_folder')
					.then(() => {
						this.owners.data.data[this.store_index].physical_folder = false
						this.folder_task_running = false
						this.toast(true, 'La recepción de la Carpeta Física fue anulada correctamente.', 10000)
					})
					.catch(error => {
						this.toast(false, error.response.data.message, 5000)
						this.folder_task_running = false
					})
				}
			})
		},
		softExpired(expiration_date) {
			if (expiration_date == 'undefined' || expiration_date == null) return true
			var date = expiration_date.split('-')
			if ((date[0] < 2022) || (date[0] == 2022 && date[1] < 12) || (date[0] == 2022 && date[1] == 12 && date[2] < 6)) return true
			return false
		},
	}
}
</script>

<style scoped>
.requirements .xs-lg {
	padding: 5px 15px;
}
.requirements .xs-btn {
	padding: 3px 5px;
	margin: 0px;
	font-size: 0.85em;
}
.requirements .status-dot {
	display: inline-block;
	height: 8px;
	width: 8px;
	border-radius: 10px;
	margin-right: 15px;
}
.requirements .status-dot.success {
	background-color: #28A745;
}
.requirements .status-dot.danger {
	background-color:#dc3545;
}
.requirements .status-dot.warning {
	background-color:#fd7e14;
}
.requirements .status-dot.secondary {
	background-color: #343a40;
}
.requirements .status-dot.light {
	background-color:#999999;
}
</style>
