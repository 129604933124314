<template>
	<div>
		<!-- ACTIONS -->
		<div class="row mb-2 no-gutters">
			<div class="col-auto">
				<b-button variant="outline-warning" @click="goTo('Reports')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Reportes">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-button variant="light" @click="load()">
					<i class="fas fa-redo mr-1"></i>
					<span>Refrescar Información</span>
				</b-button>
			</div>
			<div class="col-auto pl-2">
				<div class="input-group" style="width: 140px;">
					<div class="input-group-prepend">
						<button class="btn btn-light px-1" @click="minus()">
							<i class="fa-fw fas fa-minus"></i>
						</button>
					</div>
					<input type="text" class="form-control text-center" :value="rows + (rows != 1 ? ' meses' : ' mes')" readonly>
					<div class="input-group-append">
						<button class="btn btn-light px-1" @click="plus()">
							<i class="fa-fw fas fa-plus"></i>
						</button>
					</div>
				</div>
			</div>
			<div class="col-auto pl-2">
				<select v-model="type_id" class="form-control" @change="load()">
					<option :value="null">TODOS LOS TIPOS DE DOCUMENTOS</option>
					<option value="Carta de Compromiso de Conductores y Operadores">Carta de Compromiso de Conductores y Operadores</option>
					<option value="Cédula de Identidad">Cédula de Identidad</option>
					<option value="Certificado de Competencias Conducción">Certificado de Competencias Conducción</option>
					<option value="Certificado de Competencias Trabajador">Certificado de Competencias Trabajador</option>
					<option value="Certificado de Nacimiento">Certificado de Nacimiento</option>
					<option value="Certificado de Revisión Técnica">Certificado de Revisión Técnica</option>
					<option value="Constancia de Autorización de Operación de Vehículos">Constancia de Autorización de Operación de Vehículos</option>
					<option value="Curso de Conducción a la Defensiva">Curso de Conducción a la Defensiva</option>
					<option value="Entrega de Elementos de Protección Personal (EPP)">Entrega de Elementos de Protección Personal (EPP)</option>
					<option value="Entrega de RIOHS">Entrega de RIOHS</option>
					<option value="Examen de Alcohol y Drogas">Examen de Alcohol y Drogas</option>
					<option value="Examen Pre-Ocupacional">Examen Pre-Ocupacional</option>
					<option value="Examen Psicosensotécnico">Examen Psicosensotécnico</option>
					<option value="Fotocopia Notarial del Título Profesional APR">Fotocopia Notarial del Título Profesional APR</option>
					<option value="Fotografía con Nombre y RUT">Fotografía con Nombre y RUT</option>
					<option value="Hoja de Vida del Conductor ">Hoja de Vida del Conductor </option>
					<option value="Licencia de Conducir Municipal">Licencia de Conducir Municipal</option>
					<option value="Obligación De Informar (ODI)">Obligación De Informar (ODI)</option>
					<option value="Padrón o Contrato del Vehículo">Padrón o Contrato del Vehículo</option>
					<option value="Permiso de Circulación">Permiso de Circulación</option>
					<option value="Registro Fotográfico">Registro Fotográfico</option>
					<option value="Seguro Obligatorio">Seguro Obligatorio</option>
					<option value="Solicitud de Licencia Interna para Conducción">Solicitud de Licencia Interna para Conducción</option>
					<option value="Tipo de Equipo y/o Vehículo">Tipo de Equipo y/o Vehículo</option>
					<option value="Visa y Pasaporte (Extranjeros)">Visa y Pasaporte (Extranjeros)</option>
				</select>
			</div>
		</div>
		<!-- /ACTIONS -->
		<ViewDateExpirations></ViewDateExpirations>
		<b-overlay :show="expirations.loading">
			<v-calendar
				:rows="rows"
				:masks="masks"
				:attributes="expirations.data.object"
				disable-page-swipe
				@update:from-page="updatePage"
				is-expanded
			>
				<template v-slot:day-content="{ day, attributes }">
					<div :id="day.id">
						<span class="text-secondary">{{ day.day }}</span>
						<div @click="openDateModal(day.id)" style="cursor:pointer;font-size:0.9em">
							<p
								v-for="attr in attributes"
								:key="attr.key"
								class="rounded-sm text-center"
								:class="attr.customData.class"
								style="padding: 2px 4px"
							>
								{{ attr.customData.count }} <span v-if="attr.customData.count > 1">documentos</span><span v-else>documento</span>
							</p>
						</div>
					</div>
				</template>
			</v-calendar>
		</b-overlay>
	</div>
</template>

<script>
import ViewDateExpirations from '@/components/ViewDateExpirations'
export default {
	name: 'Expirations',
	components: {
		ViewDateExpirations
	},
	data() {
		return {
			loading: true,
			type_id: null,
			year: null,
			month: null,
			rows: 1,
			masks: {
				weekdays: 'WWW',
			},
			types: [
				"Acreditación de Cuentas Corrientes",
				"Acuerdo de Garantías de Partes u Orden de Compra Entre Partes",
				"Carpeta Tributaria Electrónica",
				"Carta de Compromiso de Conductores y Operadores",
				"Cédula de Identidad",
				"Certificado de Accidentabilidad",
				"Certificado de Adherencia a Mutualidad",
				"Certificado de Competencias Conducción",
				"Certificado de Competencias Trabajador",
				"Certificado de Deuda de la Tesoreria General de la República",
				"Certificado de Inicio de Faenas SERNAGEOMIN",
				"CERTIFICADO DE INICIO DE FAENAS SERNAGEOMIN",
				"Certificado de Nacimiento",
				"Certificado de Revisión Técnica",
				"Certificado de Vigencia de Sociedad",
				"Constancia de Autorización de Operación de Vehículos",
				"Contrato de Trabajo",
				"Copia de Constitucion de Empresa y Modificaciones Sociales",
				"Currículum Vitae de APR",
				"CURRÍCULUM VITAE DE APR",
				"Curso de Conducción a la Defensiva",
				"Declaración de Sustancias Peligrosas",
				"DECLARACIÓN DE SUSTANCIAS PELIGROSAS",
				"Declaración Jurada Ley 20.393",
				"Entrega de Elementos de Protección Personal (EPP)",
				"Entrega de RIOHS",
				"Examen de Alcohol y Drogas",
				"Examen Pre-Ocupacional",
				"Examen Psicosensotécnico",
				"F-30 del Mes Anterior",
				"F-30 DEL MES ANTERIOR",
				"Ficha de Validación de Documentos",
				"Formulario 22: Impuestos a la Renta",
				"Formulario 29: Declaraciones Mensuales de Impuestos",
				"Fotocopia Notarial del Título Profesional APR",
				"Fotocopia Registro APR (S.N.S y/o SERNAGEOMIN)",
				"FOTOCOPIA REGISTRO APR (S.N.S Y/O SERNAGEOMIN)",
				"Fotografía con Nombre y RUT",
				"Hoja de Vida del Conductor ",
				"Inicio de Actividades ante el SII",
				"Inventario IPER (Matriz de Identificación de Peligros y Evaluación de Riesgos)",
				"Jornada de APR",
				"JORNADA DE APR",
				"Jornada Excepcional",
				"JORNADA EXCEPCIONAL",
				"Licencia de Conducir Municipal",
				"Matriz de Riesgos",
				"MATRIZ DE RIESGOS",
				"Obligación De Informar (ODI)",
				"Padrón o Contrato del Vehículo",
				"Permiso de Circulación",
				"Procedimientos de Trabajo Seguro de las Actividades a Ejecutar",
				"Procedimientos, Reglamentos e Instructivos de Trabajo Seguro",
				"PROCEDIMIENTOS, REGLAMENTOS E INSTRUCTIVOS DE TRABAJO SEGURO",
				"Programa de Prevención de Riesgos",
				"Registro Fotográfico",
				"Reglamento Interno de Orden, Higiene y Seguridad (RIOHS)",
				"Respaldo Presentación a la Dirección del Trabajo del RIOHS",
				"Respaldo Presentación a la SEREMI de Salud del RIOHS",
				"RUT Sociedad",
				"Seguro Obligatorio",
				"Solicitud de Licencia Interna para Conducción",
				"Tipo de Equipo y/o Vehículo",
				"Visa y Pasaporte (Extranjeros)",
			]
		}
	},
	mounted() {
	},
	computed: {
		expirations() { return this.$store.getters.expirations }
	},
	methods: {
		plus() {
			if (this.rows == 4) return false;
			this.rows++;
			this.load();
		},
		minus() {
			if (this.rows == 1) return false;
			this.rows--;
			this.load();
		},
		updatePage(data) {
			this.year = data.year;
			this.month = data.month;
			this.load();
		},
		load() {
			this.$store.dispatch('get_expirations', { month: this.month, year: this.year, rows: this.rows, type_id: this.type_id })
		},
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
		openDateModal(date) {
			this.$bvModal.show('view-date-expirations')
			this.$store.dispatch('get_date_expirations', date)
		}
	}
}
</script>

<style>
.vc-container {
	border: none !important;
}
.vc-day {
	padding: 0 5px 3px 5px;
	height: 70px;
	border: 1px solid #ddd;
	border-right: none;
	border-bottom: none;
	background-color: #f8fafc;
}
.vc-day.on-right {
	border-right: 1px solid #ddd;
}
.vc-day.on-bottom {
	border-bottom: 1px solid #ddd;
}
.weekday-7, .weekday-1 {
	background-color: #eff8ff;
}
</style>
