<template>
	<div>

		<!-- UPLOAD MODAL -->
		<b-modal
			id="requirement-upload-contractor"
			:title="is_new() == true ? 'Cargar Nuevo Documento' : 'Reemplazar Documento Rechazado'"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- UPLOAD FORM -->
				<div v-if="requirement.data.id">

					<!-- DOCUMENT INFO -->
					<p class="mb-1">
						<span v-if="is_new() == true">Nombre del Documento</span>
						<span v-else>Nombre del Documento Rechazado</span>
					</p>
					<h5>{{requirement.data.document_type.name}}</h5>
					<!-- TEMPLATE -->
					<b-row class="mb-3" v-if="requirement.data.document_type.template && requirement.data.document_type.template.uuid">
						<b-col>
							<b-button
								variant="outline-secondary"
								size="sm"
								:href="api_path +'files/'+requirement.data.document_type.template.uuid"
							>
								<i class="fas fa-download mr-1"></i>
								<span>Descargar Plantilla</span>
							</b-button>
						</b-col>
					</b-row>
					<div v-if="is_new() == false" class="mt-3">
						<p class="mb-0" >Motivo del Rechazo</p>
						<p class="text-uppercase text-danger">
							<strong>{{requirement.data.document.observation}}</strong>
						</p>
						<hr>
					</div>
					<!-- /DOCUMENT INFO -->
					
					<!-- HELPER TEXT -->
					<div v-if="requirement.data.document_type.helper_text != null && requirement.data.document_type.helper_text.length">
						<p class="mb-1 mt-3 text-size-07 text-muted text-uppercase fw-bold">Información Adicional</p>
						<b-alert
							variant="secondary"
							class="mb-3 px-3 py-2"
							show
						>
							<p class="mb-0 text-muted text-size-11">
								<i>{{ requirement.data.document_type.helper_text }}</i>
							</p>
						</b-alert>
					</div>
					<!-- /HELPER TEXT -->

					<!-- DOCUMENT FILE -->
					<b-form-group :label="is_new() == true ? 'Archivo a cargar' : 'Nuevo documento a cargar'">
						<b-form-file
							v-model="file"
							:state="val_file"
							size="lg"
							placeholder="Presione aquí para seleccionar un archivo"
						>
						</b-form-file>
						<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
					</b-form-group>
					<!-- /DOCUMENT FILE -->

					<!-- CREATION DATE -->
					<b-form-group label="Fecha de Emisión" v-if="requirement.data.document_type.date_validation > 0">
						<b-form-datepicker
							v-model="creation"
							locale="es"
							placeholder="Seleccionar fecha..."
							:state="val_creation"
						></b-form-datepicker>
						<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
						<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
					</b-form-group>
					<!-- /CREATION DATE -->

					<!-- EXPIRATION DATE -->
					<b-form-group label="¿El documento tiene fecha de vencimiento?" v-if="requirement.data.document_type.date_validation == 2">
						<b-form-radio-group
							v-model="expires"
							stacked :options="options"
							@input="toggleExpiration()"
						></b-form-radio-group>
					</b-form-group>
					<b-form-group label="Fecha de Vencimiento" v-if="requirement.data.document_type.date_validation == 2 && expires">
						<b-form-datepicker
							v-model="expiration"
							locale="es"
							placeholder="Seleccionar fecha..."
							:state="val_expiration"
						></b-form-datepicker>
						<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
						<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
					</b-form-group>
					<!-- /EXPIRATION DATE -->

					<!-- WARNINGS -->
					<b-alert :show="!val_full" variant="info">
						<span v-if="is_new() == true">
							Por favor, complete los campos requeridos para continuar con la carga del documento.
						</span>
						<span v-else>
							Por favor, complete los campos requeridos y asegúrese de que el nuevo documento rectifica lo indicado en el motivo de rechazo.
						</span>
					</b-alert>
					<!-- /WARNINGS -->

				</div>
				<!-- /UPLOAD FORM -->

				<!-- UPLOAD PROGRESS -->
				<template #overlay>
					<div class="text-center" style="width: 300px">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
						<b-progress :max="progress.total" height="5px" animated>
							<b-progress-bar :value="progress.current"></b-progress-bar>
						</b-progress>
					</div>
				</template>
				<!-- /UPLOAD PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2" :disabled="loading">Cancelar</b-button>
				<b-button @click="upload()" variant="success" :disabled="!val_full||loading">Cargar Documento</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /UPLOAD MODAL -->

	</div>
</template>

<script>
export default {
	name: 'RequirementUploadContractor',
	components: {
	},
	data() {
		return {
			loading: false,
			file: null,
			expiration: '',
			creation: '',
			expires: true,
			progress: {
				current: 0,
				total: 0
			},
			options: [
				{ text: 'Sí, el documento vence.', value: true },
				{ text: 'No, el documento no vence (es indefinido).', value: false }
			]
		}
	},
	props: {
		requirement: Object
	},
	computed: {
		api_path() {
			return this.$store.state.api_path
		},
		api_root() {
			return this.$store.state.api_root
		},
		requirements() {
			return this.$store.state.contractor_requirements
		},
		val_creation() {
			if (this.requirement.data.document_type.date_validation > 0) {
				if (this.creation.length > 0) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		},
		val_file() {
			return this.file ? true : false // Boolean(this.file)
		},
		val_expiration() {
			if (this.requirement.data.document_type.date_validation == 2 && this.expires) {
				if (this.expiration.length > 0) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		},
		val_full() {
			return this.val_creation && this.val_expiration && this.val_file ? true : false
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'requirement-upload-contractor') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.file = null
			this.creation = ''
			this.expiration = ''
			this.expires = true
			this.progress = { total: 0, current: 0 }
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				autoHideDelay: delay
			})
		},
		upload() {
			var self = this
			this.progress = { total: 0, current: 0 }
			this.$emit('run_task', this.requirement.index)
			this.loading = true
			const config = {
				onUploadProgress: function(progressEvent) {
					self.progress.total = progressEvent.total
					self.progress.current = progressEvent.loaded
				},
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			let data = new FormData();
			data.append('file', this.file)
			data.append('owner_id', this.requirement.data.owner_id)
			data.append('type_id', this.requirement.data.document_type_id)
			data.append('class', this.requirement.data.class)
			if (this.creation.length > 0) data.append('creation', this.creation)
			if (this.expiration.length > 0) data.append('expiration', this.expiration)
			if (this.is_new() === false) data.append('document_id', this.requirement.data.document.id)

			this.$api.post('documents', data, config)
			.then(response => {
				this.$emit('clear_task')
				this.loading = false
				this.$bvModal.hide('requirement-upload-contractor')
				this.requirements.data[this.requirement.index].document = response.data
				this.toast(true, 'El requisito "'+this.requirement.data.document_type.name+'" fue cargado.', 10000)
			})
			.catch(error => {
				this.$emit('clear_task')
				this.loading = false
				this.toast(false, error.response.data.message, 5000)
			})
		},
		toggleExpiration() {
			if (!this.expires) this.expiration = ''
		},
		is_new() {
			if (this.requirement.data) {
				if (this.requirement.data.document == null) {
					return true
				} else {
					return false
				}
			} else {
				return null
			}
		},
	}
}
</script>

<style scoped>
</style>
