<template>
	<div>

		<!-- VIEW MODAL -->
		<b-modal
			id="emergency-requirement-view"
			title="Ver Información de Capacitación"
			size="lg"
			body-class="p-4"
		>
			<b-row class="mb-2" v-if="requirement.data.document">
				<b-col sm="12" class="mb-3">
					<p class="mb-1">Nombre de la Capacitación</p>
					<h5 style="font-size:2em;">
						<span>{{requirement.data.document_type.name}}</span>
					</h5>
				</b-col>
				<b-col sm="4" class="mb-3">
					<p class="mb-1">Información Ingresada Por</p>
					<h5 v-if="requirement.data && requirement.data.document && requirement.data.document.creator && requirement.data.document.creator.name">{{requirement.data.document.creator.name }}</h5>
				</b-col>
				<b-col sm="4" class="mb-3">
					<p class="mb-1">Fecha y Hora</p>
					<h5>{{requirement.data.document.created_at}}</h5>
				</b-col>
				<b-col sm="4"></b-col>
				<b-col sm="4" v-if="requirement.data.document_type.date_validation == 1 || requirement.data.document_type.date_validation == 2">
					<p style="font-size:1.5em;" class="mb-1">Fecha de Realización</p>
					<h5 style="font-size:1.5em;" v-if="requirement.data.document.creation_date!=null">{{requirement.data.document.creation_date}}</h5>
					<h5 style="font-size:1.5em;" v-else class="text-muted">N/A</h5>
				</b-col>
				<b-col sm="4" v-if="requirement.data.document_type.date_validation == 2">
					<p style="font-size:1.5em;" class="mb-1">Fecha de Vencimiento</p>
					<h5 style="font-size:1.5em;" v-if="requirement.data.document.expiration_date=='2035-12-31'" class="text-muted">NO VENCE</h5>
					<h5 style="font-size:1.5em;" v-else-if="requirement.data.document.expiration_date!=null">{{requirement.data.document.expiration_date}}</h5>
					<h5 style="font-size:1.5em;" v-else class="text-muted">N/A</h5>
				</b-col>
			</b-row>

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /VIEW MODAL -->

	</div>
</template>

<script>
export default {
	name: 'RequirementView',
	components: {
	},
	data() {
		return {
			loading: false
		}
	},
	props: {
		requirement: Object
	},
	computed: {
		api_root() {
			return this.$store.state.api_root
		},
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>
.document-viewer {
	width: 100%;
	height: 400px;
	border: 0px;
}
</style>
