<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-corporate-pass"
			:title="confirmation.status ? 'Pase Corporativo Solicitado' : 'Solicitar Pase Corporativo'"
			:size="confirmation.status ? 'lg' : 'xl'"
			body-class="p-0"
		>
			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">
				
				<!-- CONFIRMATION -->
				<div v-if="confirmation.status">
					
					<b-alert show variant="success" class="text-size-12 mb-4">
						<p class="mb-2">El Pase Corporativo fue generado correctamente y no requiere aprobación.</p>
						<p class="mb-0">Se envió un mensaje confirmando su solicitud al correo electrónico de contacto que ingresó en el formulario con toda la información aquí desplegada.</p>
					</b-alert>

					<b-list-group class="mb-4">
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>N° de Pase</strong>
								</b-col>
								<b-col>
									{{confirmation.data.id}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Estado de Solicitud</strong>
								</b-col>
								<b-col>
									<b-badge variant="success">APROBADO</b-badge>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Fecha de Inicio</strong>
								</b-col>
								<b-col>
									{{confirmation.data.date_start}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Fecha de Término</strong>
								</b-col>
								<b-col>
									{{confirmation.data.date_end}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Motivo / Carga</strong>
								</b-col>
								<b-col>
									{{confirmation.data.motive}}
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>E-mail de Anfitrión</strong>
								</b-col>
								<b-col>
									<span class="text-megamuted mr-1"><i class="fas fa-envelope"></i></span>
									<span>{{confirmation.data.host_email}}</span>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>
										<span v-if="confirmation.data.on_behalf">Empresa Solicitante</span>
										<span v-else>Empresa</span>
									</strong>
								</b-col>
								<b-col>
									<span>
										<span>{{confirmation.data.contact_name}}</span>
										<span class="text-muted mx-2">&ndash;</span>
										<span class="text-muted mr-1">RUT</span>
										<span>{{parseIdentifier(confirmation.data.contact_identifier)}}</span>
									</span>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item v-if="confirmation.data.on_behalf">
							<b-row>
								<b-col sm="3">
									<strong>Empresa Representada</strong>
								</b-col>
								<b-col>
									<span>
										<span>{{confirmation.data.on_behalf_name}}</span>
										<span class="text-muted mx-2">&ndash;</span>
										<span class="text-muted mr-1">RUT</span>
										<span>{{parseIdentifier(confirmation.data.on_behalf_identifier)}}</span>
									</span>
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							<b-row>
								<b-col sm="3">
									<strong>Contacto</strong>
								</b-col>
								<b-col>
									<span>
										<span class="text-megamuted mr-1"><i class="fas fa-phone-alt"></i></span>
										<span class="mr-3">{{confirmation.data.contact_phone}}</span>
										<span class="text-megamuted mr-1"><i class="fas fa-envelope"></i></span>
										<span>{{confirmation.data.contact_email}}</span>
									</span>
								</b-col>
							</b-row>
						</b-list-group-item>
					</b-list-group>
					
					<b-card no-body>
						<b-card-header>
							<strong class="mr-2">Nómina de Personal</strong>
							<span class="text-muted">({{confirmation.data.members.length}} persona<span v-if="confirmation.data.members.length >1">s</span>)</span>
						</b-card-header>
						<b-table
							:items="confirmation.data.members"
							:fields="fields_confirmed"
							hover
						>
							<template v-slot:cell(identifier)="data">
								<div class="text-left">
									{{parseIdentifier(data.value)}}
								</div>
							</template>
						</b-table>
					</b-card>
				</div>
				<!-- /CONFIRMATION -->

				<!-- FORM -->
				<div v-else>

					<!-- SECTION 1 -->
					<h4>1. Ingreso<small class="ml-2 text-muted">Detalle el motivo o carga, anfitrión y período.</small></h4>
					<b-row>
						<b-col xl="4" lg="12" md="12" sm="12">
							<b-form-group label="Motivo / Carga">
								<b-input v-model="pass.motive" :state="val_motive"></b-input>
								<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback>OK</b-form-valid-feedback>
							</b-form-group>
						</b-col>
						<b-col xl="4" lg="6" md="12" sm="12">
							<b-form-group label="E-mail de Anfitrión">
								<b-input-group append="@minerahmc.cl">
									<b-input v-model="pass.host_email" :state="val_host_email"></b-input>
								</b-input-group>
								<b-form-invalid-feedback :state="val_host_email">Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback :state="val_host_email">OK</b-form-valid-feedback>
							</b-form-group>
						</b-col>
						<b-col xl="2" lg="3" md="12" sm="12">
							<b-form-group label="Fecha de Inicio">
								<b-form-datepicker
									v-model="pass.date_start"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
									:state="val_date_start"
									:min="min_date"
									:max="pass.date_end"
									placeholder="Seleccione"
								></b-form-datepicker>
								<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
								<b-form-valid-feedback>OK</b-form-valid-feedback>
							</b-form-group>
						</b-col>
						<b-col xl="2" lg="3" md="12" sm="12">
							<b-form-group label="Fecha de Término">
								<b-form-datepicker
									v-model="pass.date_end"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
									:state="val_date_end"
									:max="max_date"
									:min="pass.date_start"
									:disabled="!val_date_start"
									placeholder="Seleccione"
								></b-form-datepicker>
								<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
								<b-form-valid-feedback>OK</b-form-valid-feedback>
							</b-form-group>
						</b-col>
					</b-row>
					<!-- /SECTION 1 -->
					
					<!-- SECTION 2 -->
					<b-overlay :show="!val_section1" opacity="1" rounded="sm">
						<template #overlay>
							<div class="text-megamuted text-size-15 mb-0 text-center">
								<p class="mb-0"><i class="fas fa-arrow-up"></i></p>
								<p>
									<span>Complete la Sección </span>
									<span v-if="!val_section1">1 (Ingreso)</span>
									<span v-else-if="!val_section2">2 (Solicitante)</span>
									<span v-else-if="!val_section3">3 (Nómina)</span>
								</p>
							</div>
						</template>
						<h4 class="mt-2">2. Solicitante<small class="ml-2 text-muted">Ingrese su información de contacto.</small></h4>
						<b-form-group label="">
							<b-form-radio-group v-model="pass.on_behalf" :options="on_behalf_options" :state="val_on_behalf" @change="onBehalfChange()">
								<b-form-invalid-feedback :state="val_on_behalf">Requerido</b-form-invalid-feedback>
								<b-form-valid-feedback :state="val_on_behalf">OK</b-form-valid-feedback>
							</b-form-radio-group>
						</b-form-group>
						<b-row>
							<b-col xl="4" lg="6"  md="6" sm="12">
								<b-form-group :label="pass.on_behalf ? 'Razón Social Empresa Solicitante' : 'Razón Social'">
									<b-input v-model="pass.contact_name" :state="val_contact_name" :disabled="!val_section1"></b-input>
									<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
									<b-form-valid-feedback>OK</b-form-valid-feedback>
								</b-form-group>
							</b-col>
							<b-col xl="2" lg="6" md="6" sm="12">
								<b-form-group :label="pass.on_behalf ? 'RUT Empresa Solicitante' : 'RUT'">
									<b-input v-model="pass.contact_identifier" :state="val_contact_identifier" :formatter="cleanIdentifier" :maxlength="9" :disabled="!val_section1"></b-input>
									<b-form-invalid-feedback>Ingrese un RUT válido</b-form-invalid-feedback>
									<b-form-valid-feedback>OK</b-form-valid-feedback>
								</b-form-group>
							</b-col>
							<b-col xl="2" lg="6"  md="6" sm="12">
								<b-form-group label="Teléfono">
									<b-input v-model="pass.contact_phone" :state="val_contact_phone" :disabled="!val_section1"></b-input>
									<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
									<b-form-valid-feedback>OK</b-form-valid-feedback>
								</b-form-group>
							</b-col>
							<b-col xl="4" lg="6"  md="6" sm="12">
								<b-form-group label="Correo electrónico">
									<b-input v-model="pass.contact_email" :state="val_contact_email" :disabled="!val_section1"></b-input>
									<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
									<b-form-valid-feedback>OK</b-form-valid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row v-if="pass.on_behalf">
							<b-col xl="4" lg="6"  md="6" sm="12">
								<b-form-group label="Razón Social Empresa Representada">
									<b-input v-model="pass.on_behalf_name" :state="val_on_behalf_name" :disabled="!val_section1"></b-input>
									<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
									<b-form-valid-feedback>OK</b-form-valid-feedback>
								</b-form-group>
							</b-col>
							<b-col xl="2" lg="6"  md="6" sm="12">
								<b-form-group label="RUT Empresa Representada">
									<b-input v-model="pass.on_behalf_identifier" :state="val_on_behalf_identifier" :formatter="cleanIdentifier" :maxlength="9" :disabled="!val_section1"></b-input>
									<b-form-invalid-feedback>Ingrese un RUT válido</b-form-invalid-feedback>
									<b-form-valid-feedback>OK</b-form-valid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
					</b-overlay>
					<!-- /SECTION 2 -->

					<!-- SECTION 3 -->
					<b-overlay :show="!val_section1 || !val_section2" opacity="1" rounded="sm" v-if="val_section1">
						<template #overlay>
							<div class="text-megamuted text-size-15 mb-0 text-center">
								<p class="mb-0"><i class="fas fa-arrow-up"></i></p>
								<p>
									<span>Complete la Sección </span>
									<span v-if="!val_section1">1 (Ingreso)</span>
									<span v-else-if="!val_section2">2 (Solicitante)</span>
									<span v-else-if="!val_section3">3 (Nómina)</span>
									<span v-else-if="!val_section4">4 (Documentos)</span>
								</p>
							</div>
						</template>
						<h4 class="mt-2">3. Nómina<small class="ml-2 text-muted">Especifique las personas que ingresarán a faena.</small></h4>
						<b-button @click="new_member()" :disabled="member_form || !val_section2" variant="outline-primary" size="sm" class="mb-2">
							<i class="fas fa-plus mr-1"></i>
							<span v-if="!val_members">Agregar Persona</span>
							<span v-else>Agregar Otra Persona</span>
						</b-button>
						<b-card v-if="member_form" body-class="p-2" body-bg-variant="light">
							<b-row>
								<b-col xl="2" md="6" sm="12">
									<b-form-group label="RUT">
										<b-overlay :show="member_check">
											<template #overlay>
												<div class="d-flex align-items-center">
												<b-spinner type="grow" variant="secondary" small></b-spinner>
												</div>
											</template>
											<b-input v-model="member.identifier" :state="val_member_identifier" :formatter="cleanIdentifier" :maxlength="9" :disabled="!val_section2"></b-input>
										</b-overlay>
										<b-form-invalid-feedback :state="val_member_identifier">Ingrese un RUT válido</b-form-invalid-feedback>
										<b-form-valid-feedback :state="val_member_identifier">OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col xl="3" md="6" sm="12">
									<b-form-group label="Nombres">
										<b-overlay :show="member_check">
											<template #overlay>
												<div class="d-flex align-items-center">
												<b-spinner type="grow" variant="secondary" small></b-spinner>
												</div>
											</template>
											<b-input v-model="member.names" :state="val_member_names" :disabled="!val_section2"></b-input>
										</b-overlay>
										<b-form-invalid-feedback :state="val_member_names">Requerido</b-form-invalid-feedback>
										<b-form-valid-feedback :state="val_member_names">OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col xl="2" md="6" sm="12">
									<b-form-group label="1er Apellido">
										<b-overlay :show="member_check">
											<template #overlay>
												<div class="d-flex align-items-center">
												<b-spinner type="grow" variant="secondary" small></b-spinner>
												</div>
											</template>
											<b-input v-model="member.first_lastname" :state="val_member_first_lastname" :disabled="!val_section2"></b-input>
										</b-overlay>
										<b-form-invalid-feedback :state="val_member_first_lastname">Requerido</b-form-invalid-feedback>
										<b-form-valid-feedback :state="val_member_first_lastname">OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col xl="2" md="6" sm="12">
									<b-form-group label="2do Apellido">
										<b-overlay :show="member_check">
											<template #overlay>
												<div class="d-flex align-items-center">
												<b-spinner type="grow" variant="secondary" small></b-spinner>
												</div>
											</template>
											<b-input v-model="member.second_lastname" :state="val_member_second_lastname" :disabled="!val_section2"></b-input>
										</b-overlay>
										<b-form-invalid-feedback :state="val_member_second_lastname">Requerido</b-form-invalid-feedback>
										<b-form-valid-feedback :state="val_member_second_lastname">OK</b-form-valid-feedback>
									</b-form-group>
								</b-col>
								<b-col xl="3" md="6" sm="12">
									<b-form-group label="Confirmar">
										<b-row>
											<b-col>
												<b-button
													variant="success"
													block
													@click="add_member()"
													:disabled="!val_member || member_check"
													v-b-tooltip
													title="Agregar a la nómina"
												>
													<i class="fas fa-check"></i>
												</b-button>
											</b-col>
											<b-col>
												<b-button
													variant="secondary"
													block
													@click="cancel_member()"
													:disabled="member_check"
													v-b-tooltip
													title="Cancelar"
												>
													<i class="fas fa-times"></i>
												</b-button>
											</b-col>
										</b-row>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card>
						<b-table
							:items="pass.members"
							:fields="fields"
							bordered
							hover
							small
							show-empty
							responsive
							class="mt-2"
						>
							<template v-slot:empty>
								<div class="text-left text-transform-none text-size-12 p-3 text-danger">
									<strong>Se requiere al menos 1 persona en la nómina.</strong>
								</div>
							</template>
							<template v-slot:cell(identifier)="data">
								<div>
									<span>{{parseIdentifier(data.value)}}</span>
								</div>
							</template>
							<template v-slot:cell(actions)="data">
								<div class="text-center pl-2">
									<b-button @click="delete_member(data.index)" size="sm" variant="secondary" block>
										<i class="fas fa-times"></i> Descartar
									</b-button>
								</div>
							</template>
						</b-table>
					</b-overlay>
					<!-- /SECTION 3 -->
					
					<!-- SECTION 4 -->
					<b-overlay :show="!val_section1 || !val_section2 || !val_section3" opacity="1" rounded="sm" v-if="val_section1 && val_section2">
						<template #overlay>
							<div class="text-megamuted text-size-15 mb-0 text-center">
								<p class="mb-0"><i class="fas fa-arrow-up"></i></p>
								<p>
									<span>Complete la Sección </span>
									<span v-if="!val_section1">1 (Ingreso)</span>
									<span v-else-if="!val_section2">2 (Solicitante)</span>
									<span v-else-if="!val_section3">3 (Nómina)</span>
									<span v-else-if="!val_section4">4 (Documentos)</span>
								</p>
							</div>
						</template>
						<h4 class="mt-4">4. Documentos<small class="ml-2 text-muted">Cargue los documentos necesarios para acreditar su ingreso.</small></h4>
						<b-row class="mb-3">
							<b-col sm="6">
								<b-overlay
									:show="file.loading"
									rounded
									spinner-type="grow"
									spinner-small
									opacity="0.6"
								>
									<b-form-file v-model="file.data" @input="fileLoaded()" placeholder="Presione aquí para buscar un documento en su equipo..." browse-text="Examinar"></b-form-file>
								</b-overlay>
							</b-col>
							<b-col>
								<b-input v-model="file.description" placeholder="Escriba aquí el Tipo de Documento..."></b-input>
							</b-col>
							<b-col sm="auto">
								<b-overlay
									:show="file.loading"
									rounded
									spinner-type="grow"
									spinner-small
									opacity="0.6"
								>
									<b-button
										:variant="val_file_full ? 'success' : 'secondary'"
										:disabled="val_file_full ? false : true"
										@click="uploadFile()"
									>Agregar</b-button>
								</b-overlay>
							</b-col>
						</b-row>
						<b-progress :max="file.progress.total" height="5px" animated class="mb-3" v-if="val_file_full && file.loading">
							<b-progress-bar :value="file.progress.current" variant="success"></b-progress-bar>
						</b-progress>
						<b-alert :show="val_file && (!val_file_size || !val_file_extension)" variant="danger">
							<p class="mb-0" v-if="!val_file_size && !val_file_extension">Sólo se permite agregar documentos PDF de hasta 10 MB.</p>
							<p class="mb-0" v-else-if="!val_file_size">Sólo se permite agregar documentos de hasta 10 MB.</p>
							<p class="mb-0" v-else-if="!val_file_extension">Sólo se permite agregar documentos PDF.</p>
						</b-alert>
						<b-card no-body class="mb-4">
							<b-table :items="pass.documents" :fields="files_fields" show-empty small hover class="mb-0">
								<template #empty>
									<div class="text-center">
										<p class="mb-0 py-3 text-secondary">0 documentos agregados</p>
									</div>
								</template>
								<template #table-colgroup="scope">
									<col
										v-for="field in scope.fields"
										:key="field.key"
										:style="{ width: field.key === 'name' ? '70%' : 'auto' }"
									>
								</template>
								<template #head(name)="data">
									<div class="text-left ml-2">
										<span>{{data.label}}</span>
									</div>
								</template>
								<template #head(size)="data">
									<div class="text-center">
										<span>{{data.label}}</span>
									</div>
								</template>
								<template #head(discard)="data">
									<div class="text-center">
										<span>{{data.label}}</span>
									</div>
								</template>
								<template #cell(name)="data">
									<div class="text-left ml-2">
										<span>{{data.value}}</span>
									</div>
								</template>
								<template #cell(size)="data">
									<div class="text-center">
										<span>{{humanBytes(data.value)}}</span>
									</div>
								</template>
								<template #cell(discard)="data">
									<div class="text-center" v-if="task.active && task.index == data.index">
										<b-skeleton variant="danger" width="100%" style="margin-top: 3px"></b-skeleton>
									</div>
									<div class="text-center" v-else>
										<b-button @click="discardFile(data)" style="padding: 0px 5px !important;" v-b-tooltip.hover title="Presione para descartar">
											<i class="fas fa-trash-alt"></i>
										</b-button>
									</div>
								</template>
							</b-table>
						</b-card>
					</b-overlay>
					<!-- /SECTION 4 -->
					
					<!-- SECTION 5 -->
					<b-overlay :show="!val_section1 || !val_section2 || !val_section3 || !val_section4" opacity="1" rounded="sm" v-if="val_section1 && val_section2 && val_section3">
						<template #overlay>
							<div class="text-megamuted text-size-15 mb-0 text-center">
								<p class="mb-0"><i class="fas fa-arrow-up"></i></p>
								<p>
									<span>Complete la Sección </span>
									<span v-if="!val_section1">1 (Ingreso)</span>
									<span v-else-if="!val_section2">2 (Solicitante)</span>
									<span v-else-if="!val_section3">3 (Nómina)</span>
									<span v-else-if="!val_section4">4 (Documentos)</span>
								</p>
							</div>
						</template>
						<h4 class="mt-4">5. Condiciones<small class="ml-2 text-muted">Lea las exigencias de Seguridad y confirme su cumplimiento.</small></h4>
						<b-textarea :value="terms.text" rows="3" :state="val_terms" readonly class="bg-white" :disabled="!val_section4"></b-textarea>
						<b-form-checkbox v-model="terms.compliance" :state="val_terms" :disabled="!val_section4" class="mt-1">Declaro cumplimiento de las condiciones anteriormente descritas.</b-form-checkbox>
					</b-overlay>
					<!-- /SECTION 5 -->

					<!-- /FORM -->

					<!-- MESSAGE -->
					<b-alert :show="val_full || (val_section1 && val_section2 && val_section3 && val_section4)" :variant="val_full?'success':'danger'" class="text-size-11 mt-4">
						<span v-if="val_full">¡Todo bien! Para terminar, presione el botón GENERAR PASE.</span>
						<span v-else-if="member_form">Los campos para agregar otra persona a la nómina se encuentran activados. Complete la información o descarte la acción presionando el botón CANCELAR.</span>
						<span v-else>Por favor, complete todos los campos requeridos (marcados en color rojo).</span>
					</b-alert>
					<!-- /MESSAGE -->
				</div>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">
					<span v-if="!confirmation.status">Cancelar</span>
					<span v-else>Cerrar</span>
				</b-button>
				<b-button @click="create()" variant="success" :disabled="!val_full || loading" v-if="!confirmation.status">GENERAR PASE</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'CreatePass',
	components: {
	},
	data() {
		return {
			loading: false,
			terms: {
				compliance: false,
				text: ''
			},
			on_behalf_options: [
				{ value: false, text: 'Estoy solicitando un pase para mi empresa' },
				{ value: true, text: 'Estoy solicitando un pase en nombre de otra empresa' }
			],
			member_form: false,
			member_check: false,
			min_date: null,
			pass: {
				motive: '',
				date_start: '',
				date_end: '',
				host_email: '',
				contact_identifier: '',
				contact_name: '',
				contact_phone: '',
				contact_email: '',
				members: [],
				on_behalf: false,
				on_behalf_identifier: '',
				on_behalf_name: '',
				documents: []
			},
			member: {
				identifier: '',
				names: '',
				first_lastname: '',
				second_lastname: ''
			},
			confirmation: {
				status: false,
				data: []
			},
			fields_confirmed: [
				{ key: 'identifier', label: 'RUT', sortable: true },
				{ key: 'names', label: 'Nombres', sortable: true },
				{ key: 'first_lastname', label: '1er Apellido', sortable: true },
				{ key: 'second_lastname', label: '2do Apellido', sortable: true }
			],
			fields: [
				{ key: 'identifier', label: 'RUT', sortable: true },
				{ key: 'names', label: 'Nombres', sortable: true },
				{ key: 'first_lastname', label: '1er Apellido', sortable: true },
				{ key: 'second_lastname', label: '2do Apellido', sortable: true },
				{ key: 'actions', label: 'Descartar', sortable: false }
			],
			file: {
				loading: false,
				progress: {
					current: 0,
					total: 0
				},
				data: null,
				allowed_extensions: ['pdf', 'PDF'],
				max_size: 10,
				description: '',
			},
			files_fields: [
				{ key: 'name', label: 'Nombre', sortable: false },
				{ key: 'description', label: 'Tipo', sortable: false },
				{ key: 'size', label: 'Tamaño', sortable: false },
				{ key: 'discard', label: 'Descartar', sortable: false },
			],
			task: {
				active: false,
				index: null
			}
		}
	},
	computed: {
		val_full() {
			return this.val_motive &&
					this.val_date_start &&
					this.val_date_end &&
					this.val_host_email &&
					this.val_contact_identifier &&
					this.val_contact_name &&
					this.val_contact_phone &&
					this.val_contact_email &&
					this.val_members &&
					this.val_terms &&
					this.val_section1 &&
					this.val_section2 &&
					this.val_section3 &&
					!this.member_form ? true : false
		},
		val_section1() {
			return true
			//return this.val_motive && this.val_host_email && this.val_date_start && this.val_date_end ? true : false
		},
		val_section2() {
			return true
			/*
			return this.val_contact_identifier &&
					this.val_contact_name &&
					this.val_contact_phone &&
					this.val_contact_email &&
					this.val_on_behalf &&
					(!this.pass.on_behalf || (this.pass.on_behalf && this.val_on_behalf_identifier && this.val_on_behalf_name)) ? true : false
			*/
		},
		val_section3() {
			return true
			//return this.val_members ? true : false
		},
		val_section4() {
			return true
			//return this.val_files ? true : false
		},
		val_section5() {
			return this.val_terms ? true : false
		},
		val_on_behalf() {
			return this.pass.on_behalf != null ? true : false
		},
		val_contact_identifier() {
			if (this.pass.contact_identifier.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.pass.contact_identifier)) return false
			var dv = this.pass.contact_identifier.slice(-1)
			var rut = this.pass.contact_identifier.length == 9 ? this.pass.contact_identifier.slice(0,8) : this.pass.contact_identifier.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
		val_motive() {
			return this.pass.motive.length > 0 ? true : false
		},
		val_host_email() {
			var regex = /^[0-9a-zA-Z._]{2,30}$/
			return this.pass.host_email.length > 0 ? regex.test(this.pass.host_email) ? true : false : false
		},
		val_date_start() {
			return this.pass.date_start.length > 0 ? true : false
		},
		val_date_end() {
			return this.pass.date_end.length > 0 ? true : false
		},
		val_contact_name() {
			return this.pass.contact_name.length > 0 ? true : false
		},
		val_contact_phone() {
			return this.pass.contact_phone.length > 0 ? true : false
		},
		val_contact_email() {
			var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return this.pass.contact_email.length > 0 ? regex.test(this.pass.contact_email) ? true : false : false
		},
		val_on_behalf_identifier() {
			if (this.pass.on_behalf_identifier.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.pass.on_behalf_identifier)) return false
			var dv = this.pass.on_behalf_identifier.slice(-1)
			var rut = this.pass.on_behalf_identifier.length == 9 ? this.pass.on_behalf_identifier.slice(0,8) : this.pass.on_behalf_identifier.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
		val_on_behalf_name() {
			return this.pass.on_behalf_name.length > 0 ? true : false
		},
		val_terms() {
			return this.terms.compliance === true ? true : false
		},
		val_members() {
			return this.pass.members.length > 0 ? true : false
		},
		val_member() {
			return this.val_member_identifier && this.val_member_names && this.val_member_first_lastname && this.val_member_second_lastname ? true : false
		},
		val_member_identifier() {
			if (this.member.identifier.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.member.identifier)) return false
			var dv = this.member.identifier.slice(-1)
			var rut = this.member.identifier.length == 9 ? this.member.identifier.slice(0,8) : this.member.identifier.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
		val_member_names() {
			return this.member.names.length > 0 ? true : false
		},
		val_member_first_lastname() {
			return this.member.first_lastname.length > 0 ? true : false
		},
		val_member_second_lastname() {
			return this.member.second_lastname.length > 0 ? true : false
		},
		max_date() {
			if (this.val_date_start) {
				let date = new Date(this.pass.date_start + ' 00:00:00')
				date.setDate(date.getDate() + 365);
				return date.toISOString().slice(0, 10)
			} else {
				return null
			}
		},
		val_file() {
			return this.file.data == null ? false : true
		},
		val_file_size() {
			if (this.file.data == null) return false
			let size = this.file.data.size
			if ((size / 1048576) > this.file.max_size) return false
			return true
		},
		val_file_extension() {
			if (this.file.data == null) return false
			let filename = this.file.data.name.split(".")
			let extension = filename[filename.length-1]
			if (this.file.allowed_extensions.indexOf(extension) == -1) return false
			return true
		},
		val_file_description() {
			return this.file.description.length > 0 ? true : false
		},
		val_file_full() {
			return this.val_file && this.val_file_size && this.val_file_extension && this.val_file_description ? true : false
		},
		val_files() {
			return this.pass.documents.length > 0 ? true : false
		},
	},
	created() {
		this.getTerms()
		const now = new Date()
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
		this.min_date = today
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-corporate-pass') {
				this.reset()
				this.resetMember()
				this.resetFile()
			}
		})
	},
	methods: {
		tutorial() {
			this.$bvModal.show('tutorial-pass')
		},
		onBehalfChange() {
			if (this.pass.on_behalf === false) {
				this.pass.on_behalf_identifier = ''
				this.pass.on_behalf_name = ''
			}
			if (this.member_form) {
				this.cancel_member()
			}
		},
		reset() {
			this.pass = {
				motive: '',
				date_start: '',
				date_end: '',
				host_email: '',
				contact_identifier: '',
				contact_name: '',
				contact_phone: '',
				contact_email: '',
				members: [],
				documents: []
			}
			this.terms.compliance = false
			this.member_form = false
			this.confirmation = {
				status: false,
				data: []
			}
		},
		resetMember() {
			this.member = {
				identifier: '',
				names: '',
				first_lastname: '',
				second_lastname: ''
			}
		},
		resetFile() {
			this.file.data = null
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		getTerms() {
			this.$api.get('corporate_passes/terms')
			.then(response => {
				this.terms.text = response.data
			})
		},
		create() {
			this.loading = true
			this.$api.post('corporate_passes', this.pass)
			.then(response => {
				this.loading = false
				this.$emit('update')
				this.confirmation = {
					status: true,
					data: response.data
				}
				this.toast(true, 'Se registró el Pase Corporativo N°'+ response.data.id +'.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response ? error.response.data.message : 'Ocurrió un problema inesperado al completar la solicitud. Por favor, inténtelo nuevamente.', 10000)
			})
		},
		new_member() {
			this.member_form = true
		},
		add_member() {
			this.member_check = true
			this.$api.post('corporate_passes/member_check', { identifier: this.member.identifier })
			.then(() => {
				this.member_check = false
				this.member_form = false
				this.pass.members.push(this.member)
				this.resetMember()
			})
			.catch(error => {
				this.member_check = false
				this.resetMember()
				this.toast(false, error.response ? error.response.data.message : 'Ocurrió un problema inesperado al completar la solicitud. Por favor, inténtelo nuevamente.', 15000)
			})
			
		},
		cancel_member() {
			this.member_form = false
			this.resetMember()
		},
		delete_member(index) {
			this.pass.members.splice(index, 1)
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		fileLoaded() {
			console.log('size:' + this.val_file_size)
			console.log('extn:' + this.val_file_extension)
		},
		uploadFile() {
			var self = this
			this.file.progress = { total: 0, current: 0 }
			this.file.loading = true
			const config = {
				onUploadProgress: function(progressEvent) {
					self.file.progress.total = progressEvent.total
					self.file.progress.current = progressEvent.loaded
				},
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			let data = new FormData();
			data.append('file', this.file.data)
			data.append('description', this.file.description)
			this.$api.post('corporate_passes/files', data, config)
			.then(response => {
				this.file.loading = false
				this.pass.documents.push(response.data)
				this.file.data = null
				this.file.description = ''
				console.log(response.data)
			})
			.catch(error => {
				this.file.loading = false
				console.log(error)
			})
		},
		humanBytes(size) {
			if (size == 0) return 0
			if (size < (1024 * 1024)) return (size / (1024)).toFixed(2) + ' KB'
			return (size / (1024 * 1024)).toFixed(2) + ' MB'
		},
		discardFile(file) {
			this.$bvModal.msgBoxConfirm('¿Desea descartar este documento de su solicitud?', {
				title: 'Descartar Documento',
				size: 'md',
				buttonSize: 'md',
				okVariant: 'danger',
				okTitle: 'SÍ, DESCARTAR',
				cancelTitle: 'No, Cancelar',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: false
			})
			.then(response => {
				if (response) {
					this.task = { active: true, index: file.index }
					this.$api.delete('corporate_passes/files/'+file.item.id)
					.then(() => {
						this.task = { active: false, index: null }
						this.pass.documents.splice(file.index, 1)
					})
					.catch(() => {
						this.task = { active: false, index: null }
					})
				}
			})
			.catch(error => {
				console.log(error)
			})
		}
	}
}
</script>

<style scoped>
</style>
