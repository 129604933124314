<template>
	<div>
		<b-card no-body>
			<b-card-header>
				<b-row>
					<b-col class="card-title-middle">
						<strong>ACREDITACIÓN DE TRABAJADORES SUBCONTRATISTAS</strong>
					</b-col>
					<b-col class="text-right">
						<b-button to="/reportes/estadisticas" variant="outline-primary" size="sm" class="mr-2" v-b-tooltip.hover title="Volver a Estadísticas">
							<span class="fas fa-arrow-left"></span>
						</b-button>
						<b-button @click="refresh()" variant="outline-primary" size="sm" v-b-tooltip.hover title="Actualizar la información">
							<span class="fas fa-sync"></span>
						</b-button>
					</b-col>
				</b-row>
			</b-card-header>
			<b-overlay>
				<b-card-body class="p-3">
					<b-form-group label="Seleccione Empresa" label-size="lg">
						<b-overlay :show="subcontractors.loading" class="p-0">
							<template #overlay>
								<div class="d-flex align-items-center">
								<b-spinner type="grow" variant="secondary" small></b-spinner>
								</div>
							</template>
							<b-form-select v-model="subcontractor_id" :disabled="subcontractors.loading" v-if="!subcontractors.data.data" size="lg" @change="select()">
								<template v-slot:first>
									<b-form-select-option :value="null">Todas las empresas subcontratistas</b-form-select-option>
								</template>
								<b-form-select-option v-for="(item, index) in subcontractors.data" :key="index" :value="item.id">{{item.full_name.toUpperCase()}} // {{item.contractor.short_name.toUpperCase()}}</b-form-select-option>
							</b-form-select>
						</b-overlay>
						<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
						<b-form-valid-feedback>OK</b-form-valid-feedback>
					</b-form-group>
					<b-card>
						<b-card-body class="p-0">
							<p class="inner-card-title">Resumen de Acreditación</p>
							<p class="text-muted text-size-12">Estado de acreditación de trabajadores de {{current_subcontractor}}.</p>
							<b-overlay :show="status.loading">
								<apexchart :options="status.chart.options" :series="status.chart.series" height="300"></apexchart>
							</b-overlay>
						</b-card-body>
					</b-card>
					<!--
					<b-card class="mt-4">
						<b-card-body class="p-0">
							<p class="inner-card-title mb-1">Documentación de Trabajadores General</p>
							<p class="text-muted text-size-12">Documentos de acreditación de trabajadores de empresas contratistas sin considerar su asociación a servicios.</p>
							<b-overlay :show="documents.loading">
								<apexchart :options="documents.chart.options" :series="documents.chart.series.general" height="400"></apexchart>
							</b-overlay>
						</b-card-body>
					</b-card>
					-->
					<b-card class="mt-4">
						<b-card-body class="p-0">
							<p class="inner-card-title mb-1">Documentación de Trabajadores Vigentes</p>
							<p class="text-muted text-size-12">Trabajadores de {{current_subcontractor}} que se encuentran asociados a servicios vigentes.</p>
							<b-overlay :show="documents.loading">
								<apexchart :options="documents.chart.options" :series="documents.chart.series.active" height="400"></apexchart>
							</b-overlay>
						</b-card-body>
					</b-card>
					<b-card class="mt-4">
						<b-card-body class="p-0">
							<p class="inner-card-title mb-1">Documentación de Trabajadores No Vigentes</p>
							<p class="text-muted text-size-12">Trabajadores de {{current_subcontractor}} que no se encuentran asociados a servicios vigentes.</p>
							<b-overlay :show="documents.loading">
								<apexchart :options="documents.chart.options" :series="documents.chart.series.inactive" height="400"></apexchart>
							</b-overlay>
						</b-card-body>
					</b-card>
				</b-card-body>
			</b-overlay>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'StatsSubcontractWorker',
	data() {
		return {
			subcontractor_id: null,
			status: {
				loading: true,
				chart: {
					series: [],
					options: {
						dataLabels: {
							formatter: function(val, opt) {
								if (val == 0) return ''
								return Math.round(val) + '% ('+opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]+')'
							}
						},
						xaxis: {
							categories: [ 'Trabajadores Vigentes', 'Trabajadores No Vigentes' ],
							labels: {
								show: false
							},
							axisTicks: {
								show: false
							}
						},
						chart: {
							type: 'bar',
							stacked: true,
							stackType: '100%',
							toolbar: {
								show: false
							}
						},
						plotOptions: {
							bar: {
								horizontal: true,
								barHeight: '60%',
							}
						},
						colors: [ '#008FFB', '#FF4560' ],
						fill: {
							opacity: 1
						},
						legend: {
							show: true
						},
					}
				}
			},
			documents: {
				loading: true,
				chart: {
					series: {
						general: [],
						active: [],
						inactive: []
					},
					options: {
						stroke: {
							show: false
						},
						chart: {
							type: 'donut',
						},
						labels: ['Documentos Aprobados', 'Documentos Rechazados', 'Documentos En Revisión', 'Documentos Pendientes'],
						plotOptions: {
							pie: {
								donut: {
									labels: {
										show: true,
										name: {
											show: true,
											fontWeight: 600
										},
										total: {
											show: true
										}
									}
								}
							}
						},
						colors: [ '#00E396', '#FF4560', '#FEB019', '#AAAAAA' ],
						fill: {
							opacity: 1
						},
						legend: {
							show: true,
							position: 'left',
							formatter: function(seriesName, opts) {
								return [seriesName, " (", opts.w.globals.series[opts.seriesIndex], ")"]
							}
						},
						dataLabels: {
							minAngleToShowLabel: 20,
							style: {
								colors: [ '#333333', '#333333', '#333333', '#333333' ]
							},
							dropShadow: {
								enabled: false
							},
							background: {
								enabled: true
							}
						}
					}
				}
			}
		}
	},
	created() {
		this.$store.dispatch('get_subcontractors')
		this.getStatus()
		this.getDocuments()
	},
	computed: {
		subcontractors() {
			return this.$store.state.subcontractors
		},
		current_subcontractor() {
			if (this.subcontractor_id != null && this.subcontractors.data.length) {
				const index = this.subcontractors.data.findIndex(item => item.id === this.subcontractor_id)
				return this.subcontractors.data[index].short_name.toUpperCase()+ ' (subcontratados por ' +this.subcontractors.data[index].contractor.short_name.toUpperCase()+ ')'
			} else {
				return 'todas las empresas subcontratistas'
			}
		}
	},
	methods: {
		refresh() {
			this.reset()
			this.getStatus()
			this.getDocuments()
		},
		select() {
			this.getStatus()
			this.getDocuments()
		},
		reset() {
			this.status.chart.series = []
			this.documents.chart.series = { general: [], active: [], inactive: [] }
		},
		getStatus() {
			this.status.loading = true
			this.$api.post('stats/subcontract_worker/status', { subcontractor_id: this.subcontractor_id })
			.then(response => {
				this.status.loading = false
				this.status.chart.series = response.data.series
			})
		},
		getDocuments() {
			this.documents.loading = true
			this.$api.post('stats/subcontract_worker/documents', { subcontractor_id: this.subcontractor_id })
			.then(response => {
				this.documents.loading = false
				this.documents.chart.series = response.data.series
			})
		}
	},
}
</script>

<style scoped>
.card-title-middle {
	padding-top: 3px;
}
.inner-card-title {
	text-align: left;
	font-weight: 500;
	font-size: 1.5em;
	margin: 0;
}
.inner-card-legend {
	margin: 0;
}
.legend-primary {
	color: #008FFB;
}
.legend-success {
	color: #00E396;
}
.legend-danger {
	color: #FF4560;
}
.legend-warning {
	color: #FEB019;
}
.legend-secondary {
	color: #AAAAAA;
}
</style>