<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="worker-restrictions"
			title="Gestión de Restricciones"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="updating" rounded="sm" class="p-3">

				<b-skeleton-wrapper :loading="restrictions.loading">

					<!-- LOADING -->
					<template #loading>
						<b-skeleton-table
							animation="fade"
							:table-props="{ small: true, bordered: true }"
							:rows="3"
							:columns="3"
						></b-skeleton-table>
					</template>
					<!-- /LOADING -->

					<h6 class="text-secondary mb-0">Fecha de registro</h6>
					<p class="text-size-12 mb-3 text-uppercase" v-if="owner && owner.item && owner.item.creation">{{ owner.item.creation }}</p>

					<b-table :items="restrictions.data" :fields="fields" hover small bordered class="mb-0" show-empty>
						<template v-slot:thead-top="{ columns }">
							<tr class="thead-light">
								<th :colspan="columns">Restricciones Registradas</th>
							</tr>
						</template>
						<template v-slot:empty>
							<div class="text-center">
								<p class="mb-0 text-size-12 text-muted text-size-12 py-2">No hay registros.</p>
							</div>
						</template>
						<template v-slot:cell()="data">
							<div class="text-center">
								<span>{{data.value}}</span>
							</div>
						</template>
						<template v-slot:head()="data">
							<div class="text-center">
								<span>{{data.label}}</span>
							</div>
						</template>
						<template v-slot:cell(type.description)="data">
							<div class="text-left pl-2">
								<span>{{data.value}}</span>
							</div>
						</template>
						<template v-slot:head(type.description)="data">
							<div class="text-left pl-2">
								<span>{{data.label}}</span>
							</div>
						</template>
					</b-table>
				</b-skeleton-wrapper>
			
				<h4 class="mt-4" v-if="getters.prv_internal_workers_restrictions_create">Nueva Restricción</h4>

				<!-- FORM -->
				<b-row class="p-0" v-if="getters.prv_internal_workers_restrictions_create">
					<b-col sm="12">
						<b-form-group label="Seleccione Tipo de Restricción">
							<b-overlay :show="restriction_types.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
										<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="restriction.type_id" :state="val_type" :disabled="restriction_types.loading">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Tipo...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in restriction_types.data" :key="index" :value="item.id">{{item.description}} ({{item.name}})</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Fecha de Inicio">
							<b-datepicker v-model="restriction.start_date" :max="restriction.end_date" :state="val_start" placeholder="Presione..."></b-datepicker>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Fecha de Término">
							<b-datepicker v-model="restriction.end_date" :min="restriction.start_date" :state="val_end" placeholder="Presione..."></b-datepicker>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11" v-if="getters.prv_internal_workers_restrictions_create">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón ACTUALIZAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="updating">
					<span v-if="getters.prv_internal_workers_restrictions_create && (val_type || val_start || val_end)">Cancelar</span>
					<span v-else>Cerrar</span>
				</b-button>
				<b-button @click="register()" variant="dark" :disabled="!val_full || updating" v-if="getters.prv_internal_workers_restrictions_create">REGISTRAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'UpdateWorker',
	components: {
	},
	data() {
		return {
			updating: false,
			loading: false,
			restriction: {
				type_id: null,
				start_date: '',
				end_date: ''
			},
			options: [
				{ value: 1, text: 'Días Licencia Médica Efectivos (LM)' },
				{ value: 2, text: 'Días Vacaciones Efectivos (V)' },
				{ value: 3, text: 'Reposo Mutual Efectivos (RM)' },
				{ value: 4, text: 'Permiso Legal Efectivos (PL)' },
				{ value: 5, text: 'Turno Gerencia (TG)' },
				{ value: 6, text: 'Permiso Con Goce de Sueldo (PG)' },
				{ value: 7, text: 'Permiso Sin Goce de Sueldo (PS)' },
				{ value: 8, text: 'TeleTrabajo (TT)' }
			],
			fields: [
				{ key: 'type.description', label: 'Tipo', sortable: true },
				{ key: 'start_date', label: 'Fecha de Inicio', sortable: true },
				{ key: 'end_date', label: 'Fecha de Término', sortable: true }
			]
		}
	},
	props: {
		owner: Object
	},
	computed: {
		restriction_types() {
			return this.$store.state.worker_restriction_types
		},
		getters() {
			return this.$store.getters
		},
		val_type() {
			return this.restriction.type_id != null ? true : false
		},
		val_start() {
			return this.restriction.start_date.length > 0 ? true : false
		},
		val_end() {
			return this.restriction.end_date.length > 0 ? true : false
		},
		val_full() {
			return this.val_type && this.val_start && this.val_end ? true : false
		},
		restrictions() {
			return this.$store.state.worker_restrictions
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'update-worker') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.restriction = {
				type_id: null,
				start_date: '',
				end_date: ''
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		register() {
			this.updating = true
			this.$api.post('workers/'+this.owner.item.id+'/restrictions', this.restriction)
			.then(() => {
				this.updating = false
				this.reset()
				this.$store.dispatch('get_worker_restrictions', this.owner.item.id)
				this.toast(true, 'Se registró la restricción correctamente.', 10000)
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
	}
}
</script>

<style scoped>
</style>
