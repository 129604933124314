<template>
	<Unauthorized
		v-if="(!getters.prv_internal_workers && query.type === 1) ||
		(!getters.prv_contractor_workers && query.type === 2) ||
		(!getters.prv_subcontractor_workers && query.type === 3)"
	></Unauthorized>
	<div v-else>

		<!-- COMPONENTS -->
		<Requirements @refresh="load()" :owner="owner"></Requirements>
		<Create @update="load()" :type="query.type"></Create>
		<Update @update="load()" :type="query.type"></Update>
		<Delete @update="load()" :owner="owner" :type="query.type"></Delete>
		<Restrictions :owner="owner"></Restrictions>
		<InternalLicense :owner="owner"></InternalLicense>
		<DriverRequirements @refresh="load()" :owner="owner"></DriverRequirements>
		<EmergencyRequirements @refresh="load()" :owner="owner"></EmergencyRequirements>
		<!-- /COMPONENTS -->

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar un nuevo trabajador"
					v-if="(getters.prv_internal_workers_create && query.type === 1) ||
					(getters.prv_contractor_workers_create && query.type === 2) ||
					(getters.prv_subcontractor_workers_create && query.type === 3)"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				<b-overlay
					:show="workers.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de trabajadores">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2" v-if="query.type != 1">
				<b-button-group>
					<b-button
						:variant="query.active=='any'?'primary':'light'"
						@click="active('any')"
						v-b-tooltip.hover.bottomleft
						title="Todos los trabajadores"
					>Todo</b-button>
					<b-button
						:variant="query.active=='yes'?'primary':'light'"
						@click="active('yes')"
						v-b-tooltip.hover.bottomleft
						title="Trabajadores en servicios vigentes"
					>Vigente</b-button>
					<b-button
						:variant="query.active=='no'?'primary':'light'"
						@click="active('no')"
						v-b-tooltip.hover.bottomleft
						title="Trabajadores no vigentes"
					>No Vigente</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->

		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!workers.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="workers.data.total"> {{workers.data.total}} </span>
						<span v-else> 0 </span>
						<span v-if="query.type == 1">Trabajador<span v-if="workers.data.total > 1 || !workers.data.total">es</span> HMC</span>
						<span v-else-if="query.type == 2">Trabajador<span v-if="workers.data.total > 1 || !workers.data.total">es</span> contratista<span v-if="workers.data.total > 1 || !workers.data.total">s</span></span>
						<span v-else-if="query.type == 3">Trabajador<span v-if="workers.data.total > 1 || !workers.data.total">es</span> subcontratista<span v-if="workers.data.total > 1 || !workers.data.total">s</span></span>
						<span v-if="query.active == 'yes'"> vigente<span v-if="workers.data.total > 1 || !workers.data.total">s</span></span>
						<span v-else-if="query.active == 'no'"> no vigente<span v-if="workers.data.total > 1 || !workers.data.total">s</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="workers.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="workers.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="workers.data.data"
					:fields="query.type == 1 ? options.fields.simple : options.fields.advanced"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
					class="table-with-dropdowns"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron trabajadores que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(person.identifier)="data">
						<div class="text-center">
							{{parseIdentifier(data.value)}}
						</div>
					</template>
					<template v-slot:cell(person.names)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(person.primary_last_name)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(person.second_last_name)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(company.short_name)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(area)="data">
						<div>
							{{data.value.toUpperCase()}}
						</div>
					</template>
					<template v-slot:cell(requirements)="data">
						<div class="text-center">

							<!-- INTERNAL WORKERS -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-if="query.type === 1">
								<template v-slot:button-content>
									<i class="fas fa-fw fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="driverRequirements(data)" v-if="getters.prv_internal_workers_driver_requirements_read">
									<i class="fas fa-fw fa-steering-wheel text-muted mr-2"></i>
									<span>Gestionar Licencia Interna</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="emergencyRequirements(data)" v-if="getters.prv_internal_workers_emergency_requirements_read">
									<i class="fas fa-fw fa-light-emergency-on text-muted mr-2"></i>
									<span>Capacitaciones Emergencia</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_internal_workers_update">
									<i class="fas fa-fw fa-edit text-muted mr-2"></i>
									<span>Modificar Trabajador</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="restrictions(data)" v-if="getters.prv_internal_workers_restrictions_read">
									<i class="fas fa-fw fa-ban text-muted mr-2"></i>
									<span>Gestionar Restricciones</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_internal_workers_delete">
									<i class="fas fa-fw fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_internal_workers_update && !getters.prv_internal_workers_delete && !getters.prv_internal_workers_driver_requirements_read && !getters.prv_internal_workers_emergency_requirements_read"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /INTERNAL WORKERS -->
							
							<!-- CONTRACTOR WORKERS -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-else-if="query.type === 2">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_contractor_workers_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="internalLicense(data)" v-if="data.item.req_done == 15">
									<i class="fas fa-steering-wheel text-muted mr-2"></i>
									<span>Licencia Interna</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_contractor_workers_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Trabajador</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_contractor_workers_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_contractor_workers_read && !getters.prv_contractor_workers_update && !getters.prv_contractor_workers_delete"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /CONTRACTOR WORKERS -->

							<!-- SUBCONTRACTOR WORKERS -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-else-if="query.type === 3">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_subcontractor_workers_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="internalLicense(data)" v-if="data.item.req_done == 15">
									<i class="fas fa-steering-wheel text-muted mr-2"></i>
									<span>Licencia Interna</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_subcontractor_workers_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Trabajador</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_subcontractor_workers_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_subcontractor_workers_read && !getters.prv_subcontractor_workers_update && !getters.prv_subcontractor_workers_delete"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /SUBCONTRACTOR WORKERS -->

						</div>
					</template>
					<template v-slot:cell(req_done)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="success">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_rejected)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_waiting)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_missing)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_pending)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<!-- HELP -->
		<b-modal id="workers-help" title="Información de Ayuda" size="lg">

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="secondary">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /HELP -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import Requirements from '@/components/Requirements.vue'
import Create from '@/components/CreateWorker.vue'
import Update from '@/components/UpdateWorker.vue'
import Delete from '@/components/DeleteWorker.vue'
import Restrictions from '@/components/WorkerRestrictions.vue'
import InternalLicense from '@/components/WorkerInternalLicense.vue'
import DriverRequirements from '@/components/DriverRequirements.vue'
import EmergencyRequirements from '@/components/EmergencyRequirements.vue'

export default {
	name: 'Workers',
	components: {
		Unauthorized,
		Create,
		Update,
		Delete,
		Requirements,
		Restrictions,
		InternalLicense,
		DriverRequirements,
		EmergencyRequirements,
	},
	data() {
		return {
			owner: {},
			filtered: '',
			query: {
				type: 0,
				active: 'any',
				limit: 10,
				page: 1,
				filter: ''
			},
			options: {
				fields: {
					simple: [
						{ key: 'person.identifier', label: 'RUT', sortable: true },
						{ key: 'person.names', label: 'Nombres', sortable: true },
						{ key: 'person.primary_last_name', label: 'Apellido Paterno', sortable: true },
						{ key: 'person.second_last_name', label: 'Apellido Materno', sortable: true },
						{ key: 'company.short_name', label: 'Empresa', sortable: true },
						{ key: 'area', label: 'Área', sortable: true },
						{ key: 'requirements', label: 'Acciones' }
					],
					advanced: [
						{ key: 'person.identifier', label: 'RUT', sortable: true },
						{ key: 'person.names', label: 'Nombres', sortable: true },
						{ key: 'person.primary_last_name', label: 'Apellido Paterno', sortable: true },
						{ key: 'person.second_last_name', label: 'Apellido Materno', sortable: true },
						{ key: 'company.short_name', label: 'Empresa', sortable: true },
						{ key: 'requirements', label: 'Acciones' },
						{ key: 'req_done', label: 'Aprobados', sortable: true },
						{ key: 'req_pending', label: 'Pendientes', sortable: true }
					]
				},
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 250, text: '250 por página' },
					{ value: 500, text: '500 por página' },
					{ value: 1000, text: '1000 por página' },
					{ value: 9999, text: 'Todos' }
				]
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		workers() {
			return this.$store.state.workers
		},
		worker_type() {
			return this.$store.state.subroutes.workers
		}
	},
	created() {
		this.query.type = this.worker_type != 0 ? this.worker_type : this.$route.meta.type
		this.load()
	},
	watch: {
		worker_type(type) {
			this.type(type)
		}
	},
	methods: {
		shortPersonName(names, lastname) {
			const name = names.split(' ')
			return name[0] + ' ' + lastname
		},
		create() {
			this.$store.dispatch('countries')
			this.$store.dispatch('migration_statuses')
			if (this.query.type == 3) {
				this.$store.dispatch('get_subcontractors')
			} else if (this.query.type == 2) {
				this.$store.dispatch('get_contractors')
			}
			this.$bvModal.show('create-worker')
		},
		update(worker) {
			this.$store.dispatch('get_worker', worker.item.id)
			this.$store.dispatch('countries')
			this.$store.dispatch('migration_statuses')
			this.$bvModal.show('update-worker')
		},
		active(type) {
			this.query.active = type
			this.load()
		},
		destroy(worker) {
			this.owner = worker
			this.$bvModal.show('delete-worker')
		},
		type(type) {
			this.query.type = type
			this.query.active = 'any'
			this.query.filter = ''
			this.filtered = ''
			this.load()
		},
		load() {
			this.$store.dispatch('get_workers', this.query)
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un nombre, RUT o empresa para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.active = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
				this.filtered = ''
				this.query.active = 'any'
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.active = 'any'
			this.query.filter = ''
			this.query.limit = 10
			this.filtered = ''
			this.load()
		},
		help() {
			this.$bvModal.show('workers-help')
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		},
		requirements(worker) {
			this.$store.dispatch('get_worker_requirements', worker.item.id)
			this.owner = worker
			this.$bvModal.show('requirements')
		},
		driverRequirements(worker) {
			console.log(worker)
			this.$store.dispatch('get_driver_requirements', worker.item.id)
			this.$store.dispatch('get_driving_license', worker.item.person.identifier)
			this.$store.commit('current_driver', worker.item)
			this.owner = worker
			this.$bvModal.show('driver-requirements')
		},
		emergencyRequirements(worker) {
			console.log(worker)
			this.$store.dispatch('get_emergency_requirements', worker.item.id)
			this.$store.commit('current_emergency', worker.item)
			this.owner = worker
			this.$bvModal.show('emergency-requirements')
		},
		restrictions(worker) {
			this.$store.dispatch('get_worker_restrictions', worker.item.id)
			this.$store.dispatch('get_worker_restriction_types')
			this.owner = worker
			this.$bvModal.show('worker-restrictions')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		internalLicense(worker) {
			this.owner = worker
			this.$bvModal.show('worker-internal-license')
		}
	}
}
</script>

<style>
</style>