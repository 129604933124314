<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-shift"
			title="Registrar Nuevo Turno"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="12">
						<b-form-group label="Nombre del Turno">
							<b-input v-model="shift.name" :state="val_name" size="lg"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Fecha de Arranque">
							<b-datepicker v-model="shift.start_date" :state="val_start_date" size="lg" placeholder="Presione..."></b-datepicker>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Días Activos">
							<b-form-spinbutton v-model="shift.work_days" :state="val_work_days" size="lg"></b-form-spinbutton>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Días Descanso">
							<b-form-spinbutton v-model="shift.rest_days" :state="val_rest_days" size="lg"></b-form-spinbutton>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Hora Inicio">
							<b-form-timepicker v-model="shift.end_hour" :state="val_end_hour" size="lg" placeholder="Presione..."></b-form-timepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Hora Término">
							<b-form-timepicker v-model="shift.start_hour" :state="val_start_hour" size="lg" placeholder="Presione..."></b-form-timepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón REGISTRAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="!val_full || loading">REGISTRAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'CreateShift',
	components: {
	},
	data() {
		return {
			loading: false,
			shift: {
				name: '',
				start_hour: '',
				end_hour: '',
				start_date: '',
				work_days: 0,
				rest_days: 0
			}
		}
	},
	computed: {
		val_name() {
			return this.shift.name.length > 0 ? true : false
		},
		val_start_hour() {
			return this.shift.start_hour.length > 0 ? true : false
		},
		val_end_hour() {
			return this.shift.end_hour.length > 0 ? true : false
		},
		val_start_date() {
			return this.shift.start_date.length > 0 ? true : false
		},
		val_work_days() {
			return this.shift.work_days > 0 ? true : false
		},
		val_rest_days() {
			return this.shift.rest_days > 0 ? true : false
		},
		val_full() {
			return this.val_name && this.val_start_hour && this.val_end_hour && this.val_start_date && this.val_work_days && this.val_rest_days ? true : false
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-shift') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.shift = {
				name: '',
				start_hour: '',
				end_hour: '',
				start_date: '',
				work_days: 0,
				rest_days: 0
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('shifts', this.shift)
			.then(response => {
				this.loading = false
				this.$bvModal.hide('create-shift')
				this.$store.dispatch('get_shifts')
				this.toast(true, 'Se registró el turno "'+response.data.name+'" en el sistema correctamente.', 10000)
				this.reset()
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 10000)
			})
		}
	}
}
</script>

<style scoped>
</style>
