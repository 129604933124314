<template>
	<Unauthorized v-if="!getters.prv_system || !getters.prv_system_control_fixer_view"></Unauthorized>
	<div v-else>

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('System')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Sistema">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-overlay
					:show="controls.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar registro de actividad">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<!--
			<b-col sm="auto" class="px-1 mb-2" v-if="query.type != 1">
				<b-button-group>
					<b-button
						:variant="query.type=='any'?'primary':'light'"
						@click="type('any')"
						v-b-tooltip.hover.bottomleft
						title="Todos los usuarios"
					>Todo</b-button>
				</b-button-group>
			</b-col>
			-->
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba un RUT"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->


		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!controls.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="controls.data.total"> {{controls.data.total}} </span>
						<span v-else> 0 </span>
						<span v-if="query.type == 'any'">Control<span v-if="controls.data.total > 1 || !controls.data.total">es</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="controls.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="controls.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="controls.data.data"
					:fields="fields"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron registros que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(enter)="data">
						<div>
							<b-dropdown :text="data.value ? 'ENTRADA' : 'SALIDA'" size="sm" :variant="data.value ? 'success' : 'secondary'">
								<b-dropdown-text v-if="!getters.prv_system_control_fixer_manage">Su cuenta de usuario no posee los privilegios necesarios para corregir controles.</b-dropdown-text>
								<b-dropdown-item @click="confirmation(data)" v-else>
									<span :class="data.value ? 'text-secondary' : 'text-success'">
										<i class="fas fa-circle text-size-09 mr-2"></i>
									</span>
									<span v-if="data.value">Cambiar por Salida</span>
									<span v-else>Cambiar por Entrada</span>
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
					<template v-slot:cell(identifier)="data">
						<div>
							<span style="white-space:pre">{{parseIdentifier(data.value)}}</span>
						</div>
					</template>
					<template v-slot:cell(person)="data">
						<div class="text-uppercase">
							<span style="white-space:pre">{{data.value.names}} {{data.value.primary_last_name}} {{data.value.second_last_name}}</span>
						</div>
					</template>
					<template v-slot:cell(person.worker.company.short_name)="data">
						<div class="text-uppercase">
							<span>{{data.value}}</span>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<b-modal id="control-fixer-confirmation" size="md" :title="modalTitle" body-class="p-0">
			
			<b-overlay :show="updating" rounded="sm" class="p-3">
				<b-row>
					<b-col sm="6">
						<h6 class="mb-1">FECHA/HORA</h6>
						<p class="text-size-12">{{current_control.timestamp}}</p>
					</b-col>
					<b-col sm="6">
						<h6 class="mb-1">RUT</h6>
						<p class="text-size-12">{{parseIdentifier(current_control.person.identifier)}}</p>
					</b-col>
					<b-col sm="12">
						<h6 class="mb-1">TRABAJADOR</h6>
						<p class="text-size-12">{{current_control.person.names}} {{current_control.person.primary_last_name}} {{current_control.person.second_last_name}}</p>
					</b-col>
					<b-col sm="12">
						<h6 class="mb-1">EMPRESA</h6>
						<p class="text-size-12 mb-0">{{current_control.person.worker.company.full_name}}</p>
					</b-col>
				</b-row>
				<hr>
				<b-row>
					<b-col>
						<div class="text-center">
							<p class="mb-2 text-muted">
								<span :class="current_control.enter ? 'text-success' : 'text-secondary'">Actual</span>
							</p>
						</div>
						<b-card :bg-variant="current_control.enter ? 'success' : 'secondary'" text-variant="white" body-class="px-0 py-2 text-center text-size-12">
							<span v-if="current_control.enter">CONTROL ENTRADA</span>
							<span v-else>CONTROL SALIDA</span>
						</b-card>
					</b-col>
					<b-col sm="auto" class="text-center">
						<i class="fas fa-chevron-right text-size-2 mt-3"></i>
					</b-col>
					<b-col>
						<div class="text-center">
							<p class="mb-2 text-muted">
								<strong :class="!current_control.enter ? 'text-success' : 'text-secondary'">Cambiar Por</strong>
							</p>
						</div>
						<b-card :bg-variant="!current_control.enter ? 'success' : 'secondary'" text-variant="white" body-class="px-0 py-2 text-center text-size-12">
							<strong v-if="!current_control.enter">CONTROL ENTRADA</strong>
							<strong v-else>CONTROL SALIDA</strong>
						</b-card>
					</b-col>
				</b-row>
				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->
			</b-overlay>

			<template #modal-footer="{ close }">
				<b-button variant="danger" @click="close()" :disabled="updating">Cancelar</b-button>
				<b-button variant="primary" @click="save(current_control.id)" :disabled="updating">CONFIRMAR CAMBIO</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'

export default {
	name: 'ControlFixer',
	components: {
		Unauthorized,
	},
    data(){
        return {
			updating: false,
			current_control: {
				id: '',
				timestamp: '',
				enter: null,
				person: {
					identifier: '',
					names: '',
					primary_last_name: '',
					second_last_name: '',
					worker: {
						company: {
							full_name: '',
							short_name: ''
						}
					}
				}
			},
			filtered: '',
			query: {
				type: 'any',
				limit: 100,
				page: 1,
				filter: ''
			},
			fields: [
				//{ key: 'id', label: 'ID', sortable: true },
				{ key: 'timestamp', label: 'Fecha/Hora', sortable: true },
				{ key: 'enter', label: 'Tipo', sortable: true },
				{ key: 'identifier', label: 'RUT', sortable: true },
				{ key: 'person', label: 'Trabajador', sortable: true },
				{ key: 'person.worker.company.short_name', label: 'Empresa', sortable: true },
				//{ key: 'message', label: 'Resultado', sortable: true },
			],
			options: {
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 250, text: '250 por página' },
					{ value: 500, text: '500 por página' },
					{ value: 1000, text: '1000 por página' }
				]
			}
        }
    },
	computed: {
		getters() {
			return this.$store.getters
		},
        controls() {
            return this.$store.state.controls
        },
		modalTitle() {
			if (this.current_control.id) {
				return 'Control N°'+this.current_control.id
			} else {
				return 'Cargando...'
			}
		}
	},
    created() {
		this.load()
    },
    methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        refresh() {
            this.$store.dispatch('get_controls')
        },
		load() {
			this.$store.dispatch('get_controls', this.query)
		},
		type(type) {
			this.query.type = type
			this.load()
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un RUT, nombre o e-mail para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.type = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
				this.filtered = ''
				this.query.type = 'any'
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.type = 'any'
			this.query.filter = ''
			this.query.limit = 10
			this.filtered = ''
			this.load()
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
		confirmation(control) {
			this.current_control = control.item
			this.$bvModal.show('control-fixer-confirmation')
		},
        shortName(name) {
            return name ? name.length > 40 ? name.substring(0,35) + '...' : name : ''
        },
		save(control_id) {
			this.updating = true
			this.$api.put('controls/'+control_id+'/fix')
			.then(response => {
				this.updating = false
				const index = this.controls.data.data.findIndex(control => control.id === control_id)
				this.controls.data.data[index].enter = response.data.enter
				var fix_type = response.data.enter ? 'Salida a Entrada' : 'Entrada a Salida'
				this.toast(true, 'Control N°'+control_id+' corregido de '+ fix_type +'.', 5000)
				this.$bvModal.hide('control-fixer-confirmation')
			})
			.catch(error => {
				this.updating = false
				this.toast(false, error.response.data.message, 10000)
			})
		}
    }
}
</script>

<style scoped>
</style>