<template>
	<div>

		<b-row class="mb-2">
			<b-col>
				<b-button
					@click="reload()"
					:disabled="reloading"
					variant="light"
				>
					<i class="fas fa-sync mr-2"></i>
					<span>Actualizar</span>
				</b-button>
			</b-col>
		</b-row>

		<code v-if="0">{{files}}</code>
		
		<b-list-group v-if="loading && !data.items.length">
			<b-list-group-item class="p-3">
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
			</b-list-group-item>
			<b-list-group-item class="p-3">
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
			</b-list-group-item>
			<b-list-group-item class="p-3">
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
			</b-list-group-item>
			<b-list-group-item class="p-3">
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
			</b-list-group-item>
			<b-list-group-item class="p-3">
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
				<b-skeleton></b-skeleton>
			</b-list-group-item>
		</b-list-group>
		<b-card v-for="(item, index) in data.items" :key="index" class="mb-2" no-body>
			<b-card-header class="py-2">
				<b-row no-gutters>
					<b-col sm="auto" class="pr-2">
						<span class="mr-1 text-muted fw-bold">CATEGORÍA</span>
						<i class="fas fa-chevron-right text-muted"></i>
					</b-col>
					<b-col>
						<strong class="text-primary">{{ item.class.description.toUpperCase() }}</strong>
					</b-col>
				</b-row>
			</b-card-header>
			<b-card-body class="px-3 py-3">
				<b-row>
					<b-col>
						<b-form-group label="Nombre del Requisito" label-class="fw-bold">
							<b-form-input
								v-model="names[index]"
								class="mb-1"
								:disabled="reloading || (active.name && active.index == index)"
							></b-form-input>
							<b-button
								size="sm"
								variant="outline-secondary"
								@click="saveName(index, item.document_type.id)"
								:disabled="reloading || (active.name && active.index == index)"
							>
								<i class="fas fa-floppy-disk mr-1"></i>
								<span>Modificar</span>
							</b-button>
						</b-form-group>
						<b-form-group label="Texto Explicativo" label-class="fw-bold" class="mb-0">
							<b-textarea
								v-model="descriptions[index]"
								class="mb-1"
								:disabled="reloading || (active.description && active.index == index)"
							></b-textarea>
							<b-button
								size="sm"
								variant="outline-secondary"
								:disabled="reloading || (active.description && active.index == index)"
								@click="saveDescription(index, item.document_type.id)"
							>
								<i class="fas fa-floppy-disk mr-1"></i>
								<span>Modificar</span>
							</b-button>
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group label="Plantilla Actual" label-class="fw-bold">
							<b-form-input
								v-model="templates[index]"
								disabled
								class="mb-1"
							></b-form-input>
							<b-button
								size="sm"
								variant="outline-secondary"
								:href="api_path + 'files/'+ uuids[index]"
								:disabled="reloading"
							>
								<i class="fas fa-download mr-1"></i>
								<span>Descargar</span>
							</b-button>
						</b-form-group>
						<b-form-group label="Reemplazar Plantilla" label-class="fw-bold">
							<b-form-file
								v-model="files[index]"
								class="mb-1"
								placeholder=""
								browse-text="Examinar"
								:disabled="reloading || file.loading"
							></b-form-file>
							<b-progress :max="file.progress.total" height="5px" animated class="mb-1" v-if="files[index] != null">
								<b-progress-bar :value="file.progress.current" variant="success"></b-progress-bar>
							</b-progress>
							<b-button
								size="sm"
								variant="outline-secondary"
								@click="saveTemplate(index, item.document_type.id)"
								:disabled="reloading || files[index] == null || file.loading"
							>
								<i class="fas fa-upload mr-1"></i>
								<span>Cargar</span>
							</b-button>
						</b-form-group>
					</b-col>
				</b-row>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'RequirementsManager',
	components: {
	},
	data() {
		return {
			loading: false,
			reloading: false,
			data: {
				items: [],
			},
			fields: [
				{ key: 'class.description', label: 'Acredita' },
				{ key: 'document_type.name', label: 'Requisito' },
				{ key: 'template', label: 'Plantilla' },
			],
			active: {
				index: null,
				name: false,
				description: false,
			},
			files: [],
			file: {
				loading: false,
				progress: {
					current: 0,
					total: 0
				},
				data: null,
				allowed_extensions: ['pdf', 'PDF'],
				max_size: 9,
			},
		}
	},
	mounted() {
		this.load()
	},
	computed: {
		api_path() {
			return this.$store.state.api_path
		},
		names() {
			if (!this.loading && this.data.items.length) {
				let items = []
				this.data.items.forEach(item => items.push(item.document_type.name))
				return items
			} else {
				return []
			}
		},
		descriptions() {
			if (!this.loading && this.data.items.length) {
				let descriptions = []
				this.data.items.forEach(item => descriptions.push(item.document_type.helper_text))
				return descriptions
			} else {
				return []
			}
		},
		templates() {
			if (!this.loading && this.data.items.length) {
				let templates = []
				this.data.items.forEach(item => {
					if (item.document_type.template && item.document_type.template.name) {
						templates.push(item.document_type.template.name)
					} else {
						templates.push(null)
					}
				})
				return templates
			} else {
				return []
			}
		},
		uuids() {
			if (!this.loading && this.data.items.length) {
				let uuids = []
				this.data.items.forEach(item => {
					if (item.document_type.template && item.document_type.template.uuid) {
						uuids.push(item.document_type.template.uuid)
					} else {
						uuids.push(null)
					}
				})
				return uuids
			} else {
				return []
			}
		},
	},
	methods: {
		load() {
			this.loading = true
			this.$api.get('requirements')
			.then(response => {
				this.data.items = response.data
				this.loading = false
				this.reloading = false
			})
			.catch(() => {
				this.loading = false
				this.reloading = false
			})
		},
		reload() {
			this.reloading = true
			this.load()
		},
		saveDescription(index, document_type_id) {
			this.active.description = true
			this.active.index = index
			this.$api.put('document_types/'+document_type_id+'/description', { description: this.descriptions[index]})
			.then(() => {
				this.active.description = false
				this.active.index = null
			})
			.catch(() => {
				this.active.description = false
				this.active.index = null
			})
		},
		saveName(index, document_type_id) {
			this.active.name = true
			this.active.index = index
			this.$api.put('document_types/'+document_type_id+'/name', { name: this.names[index]})
			.then(() => {
				this.active.name = false
				this.active.index = null
			})
			.catch(() => {
				this.active.name = false
				this.active.index = null
			})
		},
		saveTemplate(index, document_type_id) {
			var self = this
			this.file.progress = { total: 0, current: 0 }
			this.file.loading = true
			const config = {
				onUploadProgress: function(progressEvent) {
					self.file.progress.total = progressEvent.total
					self.file.progress.current = progressEvent.loaded
				},
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
			let data = new FormData();
			data.append('file', this.files[index])
			this.$api.post('document_types/'+document_type_id+'/template', data, config)
			.then(() => {
				this.file.loading = false
				this.files[index] = null
				this.file.progress = { total: 0, current: 0 }
				this.reload()
			})
			.catch(() => {
				this.file.loading = false
				this.files[index] = null
				this.file.progress = { total: 0, current: 0 }
			})
		},
	}
}
</script>

<style>
.fw-bold {
	font-weight: bold !important;
}
</style>
