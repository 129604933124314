<template>
	<div>
	</div>
</template>

<script>
export default {
	name: 'Support',
	components: {
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	computed: {
	},
	methods: {
	}
}
</script>

<style scoped>
</style>
