<template>
	<div>
		<div class="login-box">
			<div class="text-center mb-3">
				<router-link to="/ingresar" custom v-slot="{ navigate }">
					<a href="#" @click="navigate" @keypress.enter="navigate" role="link" class="text-muted">
						<i class="fas fa-arrow-left mr-2"></i>
						<span>Regresar al Inicio de Sesión</span>
					</a>
				</router-link>
			</div>
			<b-overlay :show="loading" rounded="sm">
				<b-card no-body>
					<b-card-body>
						<div v-if="checking" class="text-center pt-3 pb-2">
							<b-spinner type="grow" variant="primary"></b-spinner>
							<h3 class="mt-3">Verificando Solicitud</h3>
							<p class="text-size-15 mb-0 text-muted">Por favor, espere un momento.</p>
						</div>
						<div v-else-if="invalid" class="text-center pt-2 pb-2">
							<i class="fas fa-minus-circle text-size-3 text-danger"></i>
							<h3 class="mt-3">Solicitud Inválida</h3>
							<p class="text-size-15 mb-0 text-muted">{{error_message}}</p>
						</div>
						<div v-else-if="success" class="text-center pt-2 pb-2">
							<i class="fas fa-check-circle text-size-3 text-success"></i>
							<h3 class="mt-3">Contraseña Actualizada</h3>
							<p class="text-size-15 mb-0 text-muted">Ya puede ingresar al sistema con su nueva contraseña.</p>
							<router-link to="/ingresar" custom v-slot="{ navigate }">
								<b-button @click="navigate" @keypress.enter="navigate" role="link" variant="outline-success" class="mt-4" size="lg">
									<i class="fas fa-arrow-left mr-2"></i>
									<span>Regresar al Inicio de Sesión</span>
								</b-button>
							</router-link>
						</div>
						<div v-else>
							<div class="text-center mb-4">
								<b-img src="/img/logo_hmc.svg" style="width: 50px" class="mb-3"></b-img>
								<h6 class="mb-0">HMC Michilla</h6>
								<h5 class="mb-0">Activar Acceso</h5>
							</div>
							<b-form action="#" @submit.prevent="reset">
								<b-form-group label="Ingrese su nueva contraseña">
									<b-input type="password" v-model="new_password" size="lg" :state="val_new_password"></b-input>
									<b-form-invalid-feedback>
										<span>Mínimo 5 letras o números</span>
									</b-form-invalid-feedback>
									<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
								</b-form-group>
								<b-form-group label="Repita su nueva contraseña">
									<b-input type="password" v-model="repeat_password" size="lg" :state="val_repeat_password"></b-input>
									<b-form-invalid-feedback>
										<span>La contraseña debe ser idéntica</span>
									</b-form-invalid-feedback>
									<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
								</b-form-group>
								<b-button type="submit" variant="primary" size="lg" block :disabled="!val_full">
									<span>Cambiar Contraseña</span>
								</b-button>
							</b-form>
						</div>
					</b-card-body>
				</b-card>

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->
			</b-overlay>

		</div>
	</div>
</template>

<script>
export default {
	name: 'Reset',
	components: {
	},
	data() {
		return {
			checking: true,
			invalid: false,
			loading: false,
			success: false,
			new_password: null,
			repeat_password: null,
			error_message: 'El enlace de recuperación utilizado no es válido.'
		}
	},
	mounted() {
	},
	created() {
		this.$store.dispatch('check_reset_token', this.token)
		.then(() => {
			this.checking = false
		})
		.catch(error => {
			this.checking = false
			this.invalid = true
			if (error.response.data.message) this.error_message = error.response.data.message
		})
	},
	computed: {
		val_new_password() {
			return this.new_password == null ? null : this.new_password.length >= 5 ? true : false
		},
		val_repeat_password() {
			return this.new_password == null || !this.val_new_password ? null : this.val_same ? true : false
		},
		val_same() {
			return this.new_password === this.repeat_password ? true : false
		},
		val_full() {
			return this.val_new_password && this.val_repeat_password ? true : false
		},
		token() {
			return this.$route.params.token
		},
	},
	methods: {
		reset() {
			this.loading = true
			this.$store.dispatch('reset', { token: this.token, password: this.new_password })
			.then(() => {
				this.loading = false
				this.success = true
			})
			.catch(error => {
				this.loading = false
				this.toast(false, error.response.data.message, 5000)
			})
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay,
				toaster: 'b-toaster-top-center'
			})
		},
	}
}
</script>

<style scoped>
.login-box {
	width: 320px;
	margin: 50px auto 0px;
}
</style>
