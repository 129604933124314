<template>
	<div>
		<b-overlay :show="grids.loading">
			<b-card no-body>
				<b-card-body class="pb-0">
					<b-row>
						<b-col sm="auto">
							<p class="mb-0 text-size-09 text-uppercase text-muted">Acreditación</p>
							<p class="mb-0 text-size-12">
								<b-badge :variant="current.worker.is_owner ? 'success' : current.worker.stage1 && current.worker.stage2 && current.worker.stage3 ? 'success' : 'danger'">
									<span>{{ current.worker.is_owner ? 'HMC PLANTA' : current.worker.stage1 && current.worker.stage2 && current.worker.stage3 ? 'CUMPLE' : 'NO CUMPLE' }}</span>
								</b-badge>
							</p>
						</b-col>
						<b-col sm="auto">
							<p class="mb-0 text-size-09 text-uppercase text-muted">Empresa</p>
							<p class="mb-0 text-size-12">
								{{ current.worker.id ? current.worker.company_name : '' }}
							</p>
						</b-col>
						<b-col sm="auto">
							<p class="mb-0 text-size-09 text-uppercase text-muted">Trabajador</p>
							<p class="mb-0 text-size-12">
								{{ current.worker.id ? current.worker.worker_name +' '+
									current.worker.worker_lastname +' '+
									current.worker.worker_secondary_lastname : '' }}
							</p>
						</b-col>
						<b-col>
							<code></code>
						</b-col>
						<b-col class="text-right">
							<b-button
								variant="light"
								size="sm"
								@click="reload()"
							>
								<i class="fas fa-redo"></i>
							</b-button>
						</b-col>
					</b-row>
				</b-card-body>
				<b-card-body>
					<span v-for="(item, index) in grids.data.all" :key="index" class="mx-1" @mouseenter="changeCurrentWorker(item)">
						<span
							class="grid-item"
							v-bind:class="{
								'active' : item.is_owner || item.active,
								'inactive' : !item.is_owner && !item.active,
								'success' : item.is_owner || (item.stage1 && item.stage2 && item.stage3),
								'danger' : !item.is_owner && (!item.stage1 || !item.stage2 || !item.stage3),
							}"
						>
							<span v-if="item.is_owner" style="width: 2px; height: 2px; border-radius: 5px; display: inline-block; position: fixed; margin-top: 4px; margin-left: 4px;" class="bg-white"></span>
						</span>
					</span>
				</b-card-body>
			</b-card>
		</b-overlay>
	</div>
</template>

<script>
export default {
	name: 'Grids',
	components: {
	},
	data() {
		return {
			size: 'xs',
			reloading: false,
			current: {
				worker: {},
			},
			query: {

			},
		}
	},
	created() {
		this.load()
	},
	mounted() {
	},
	computed: {
		grids() {
			return this.$store.state.grids
		},
	},
	methods: {
		load() {
			this.$store.dispatch('get_grids').then(() => this.reloading = false)
		},
		reload() {
			this.reloading = true
			this.load()
		},
		changeCurrentWorker(worker) {
			this.current.worker = worker
		}
	}
}
</script>

<style>
.grid-item {
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 2px;
}
.grid-item.active.success {
	background-color: #28a745;
}
.grid-item.active.danger {
	background-color: #dc3545;
}
.grid-item.active.secondary {
	background-color: #6c757d;
}
.grid-item.inactive.success {
	border: 1px solid #28a745;
}
.grid-item.inactive.danger {
	border: 1px solid #dc3545;
}
.grid-item.inactive.secondary {
	border: 1px solid #999;
}
</style>
