<template>
	<div>

		<!-- REVIEW MODAL -->
		<b-modal
			id="requirement-review"
			title="Revisar Documento"
			size="lg"
			body-class="p-0"
		>
				<!-- CONTENT -->
				<b-overlay :show="loading" rounded="sm" class="p-4">

					<!-- DATA -->
					<b-row class="mb-2" v-if="requirement.data.document">
						<b-col>
							<p class="mb-1">Nombre del Documento</p>
							<h5>
								<span>{{requirement.data.document_type.name}}</span>
								<!-- VEHICLE TYPE -->
								<span class="text-info" v-if="requirement.data.document_type_id == 66">
									<span>: </span>
									<span v-if="requirement.data.vehicle_type && requirement.data.vehicle_type.name">{{requirement.data.vehicle_type.name}}</span>
									<span v-else class="text-danger">NO ESPECIFICADO</span>
								</span>
								<!-- /VEHICLE TYPE -->
							</h5>
						</b-col>
						<b-col sm="auto">
							<p class="mb-1">Fecha y Hora de Carga</p>
							<h5>{{requirement.data.document.created_at}}</h5>
						</b-col>
						<b-col sm="auto" v-if="requirement.data.document_type.date_validation == 1 || requirement.data.document_type.date_validation == 2">
							<p class="mb-1">Fecha de Emisión</p>
							<h5 v-if="requirement.data.document.creation_date!=null">{{requirement.data.document.creation_date}}</h5>
							<h5 v-else class="text-muted">N/A</h5>
						</b-col>
						<b-col sm="auto" v-if="requirement.data.document_type.date_validation == 2">
							<p class="mb-1">Fecha de Vencimiento</p>
							<h5 v-if="requirement.data.document.expiration_date=='2035-12-31'" class="text-muted">NO VENCE</h5>
							<h5 v-else-if="requirement.data.document.expiration_date!=null">{{requirement.data.document.expiration_date}}</h5>
							<h5 v-else class="text-muted">N/A</h5>
						</b-col>
					</b-row>
					<b-card no-body class="mb-3" v-if="requirement.data.document">
						<iframe :src="api_root + requirement.data.document.url" class="document-viewer"></iframe>
					</b-card>
					<b-row>
						<b-col>
							<b-button block variant="danger" @click="reject_comment()">
								<i class="fas fa-times mr-1"></i>
								<span>RECHAZAR</span>
							</b-button>
						</b-col>
						<b-col>
							<b-button block variant="success" @click="approve(requirement)">
								<i class="fas fa-check mr-1"></i>
								<span>APROBAR</span></b-button>
						</b-col>
					</b-row>
					<!-- /DATA -->

				</b-overlay>
				<!-- /CONTENT -->

				<template v-slot:modal-footer="{ close }">
					<div>
						<b-button @click="close()">Cerrar</b-button>
					</div>
				</template>

		</b-modal>
		<!-- /REVIEW MODAL -->

		<!-- REJECTION MODAL -->
		<b-modal id="reject-requirement" title="Rechazar Documento">
			<div class="modal-body pt-0 pb-0">
				<b-form-group label="Motivo del Rechazo">
					<b-input v-model="comment" :state="val_comment"></b-input>
					<b-form-valid-feedback>¡OK!</b-form-valid-feedback>
					<b-form-invalid-feedback>Debe ingresar un motivo para poder rechazar un documento</b-form-invalid-feedback>
				</b-form-group>
			</div>
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" class="mr-2">Cancelar</b-button>
				<b-button @click="reject(requirement)" variant="danger" :disabled="!val_comment">
					<i class="fas fa-times mr-1"></i>
					<span>Confirmar Rechazo</span>
				</b-button>
			</template>
		</b-modal>
		<!-- REJECTION MODAL -->

	</div>
</template>

<script>
export default {
	name: 'RequirementReview',
	components: {
	},
	data() {
		return {
			loading: false,
			input: '',
			select: null,
			comment: ''
		}
	},
	props: {
		requirement: Object
	},
	computed: {
		api_root() {
			return this.$store.state.api_root
		},
		val_comment() {
			return this.comment.length > 0 ? true : false
		},
		requirements() {
			if (this.requirement.data.class) {
				switch (this.requirement.data.class) {
					case 'contractor':
						return this.$store.state.contractor_requirements
					case 'subcontractor':
						return this.$store.state.subcontractor_requirements
					case 'service':
						return this.$store.state.service_requirements
					case 'worker':
						return this.$store.state.worker_requirements
					case 'vehicle':
						return this.$store.state.vehicle_requirements
					default:
						return null
				}
			} else {
				return null
			}
		},
		owners() {
			if (this.requirement.data.class) {
				switch (this.requirement.data.class) {
					case 'contractor':
						return this.$store.state.contractors
					case 'subcontractor':
						return this.$store.state.subcontractors
					case 'service':
						return this.$store.state.services
					case 'worker':
						return this.$store.state.workers
					case 'vehicle':
						return this.$store.state.vehicles
					default:
						return null
				}
			} else {
				return null
			}
		},
		store_index() {
			if (this.requirement.data.owner_id) {
				const index = this.owners.data.data.findIndex(owner => owner.id === this.requirement.data.owner_id)
				return index
			} else {
				return null
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'requirement-review') { this.reset() }
			if (modalId == 'reject-requirement') { console.log(modalId) }
		})
	},
	methods: {
		reset() {
			this.comment = ''
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				autoHideDelay: delay
			})
		},
		approve(requirement) {
			this.$bvModal.msgBoxConfirm('¿Desea aprobar el documento "'+requirement.data.document_type.name+'"?', {
				title: 'Confirmar Acción',
				requisize: 'md',
				okVariant: 'success',
				okTitle: 'Sí, Aprobar',
				cancelTitle: 'No, Cancelar'
			})
			.then(confirmation => {
				if (confirmation) {
					this.$emit('run_task', requirement.index)
					this.loading = true
					this.$api.post('requirements/approve', {
						class: requirement.data.class,
						document_id: requirement.data.document.id,
						owner_id: requirement.data.owner_id
					})
					.then(response => {
						this.$bvModal.hide('requirement-review')
						this.$emit('clear_task')
						this.loading = false
						this.requirements.data[requirement.index].document = response.data
						/*******************************************************
						 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
						 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
						 *******************************************************/
						if (requirement.data.document_type_id != 82 && requirement.data.document_type_id != 83) {
							this.owners.data.data[this.store_index].req_waiting--
							if (this.owners.data.data[this.store_index].req_pending) this.owners.data.data[this.store_index].req_pending--
							this.owners.data.data[this.store_index].req_done++
						}
						/*******************************************************/
						this.toast(true, 'El requisito "'+requirement.data.document_type.name+'" fue aprobado.', 10000)
					})
					.catch(error => {
						this.$emit('clear_task')
						this.loading = false
						this.toast(false, error.response.data.message, 5000)
					})
				}
			})
		},
		reject_comment() {
			this.$bvModal.show('reject-requirement')
		},
		reject(requirement) {
			this.$emit('run_task', requirement.index)
			this.loading = true
			this.$bvModal.hide('reject-requirement')
			this.$api.post('requirements/reject', {
				class: requirement.data.class,
				document_id: requirement.data.document.id,
				owner_id: requirement.data.owner_id,
				observation: this.comment
			})
			.then(response => {
				this.$bvModal.hide('requirement-review')
				this.$emit('clear_task')
				this.loading = false
				this.requirements.data[requirement.index].document = response.data
				/*******************************************************
				 * REQUISITOS NO CONTABILIZADOS TEMPORALMENTE DEBIDO A
				 * PERIODO DE GRACIA POR ACTIVACION DE RECHAZO AUTOMATICO
				 *******************************************************/
				if (requirement.data.document_type_id != 82 && requirement.data.document_type_id != 83) {
					this.owners.data.data[this.store_index].req_waiting--
					this.owners.data.data[this.store_index].req_rejected++
				}
				/*******************************************************/
				this.toast(true, 'El requisito "'+requirement.data.document_type.name+'" fue rechazado.', 10000)
			})
			.catch(error => {
				this.loading = false
				this.$emit('clear_task')
				this.toast(false, error.response.data.message, 5000)
			})
		}
	}
}
</script>

<style scoped>
.document-viewer {
	width: 100%;
	height: 400px;
	border: 0px;
}
</style>
