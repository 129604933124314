<template>
	<Unauthorized v-if="!getters.prv_system || !getters.prv_system_users_view"></Unauthorized>
	<div v-else>

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('Users')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Usuarios">
					<i class="fas fa-arrow-left"></i>
				</b-button>
				<b-overlay
					:show="approvers.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar registro de actividad">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

		</b-row>
		<!-- /TOP BAR -->

		<!-- CONTENT -->
		<b-card>
			<b-overlay
				:show="approvers.loading"
				rounded
				opacity="0.6"
				:class="{ 'py-5' : approvers.loading && !approvers.data.length }"
			>
				<template #overlay>
					<b-spinner type="grow" class="text-muted"></b-spinner>
				</template>
				<b-card v-for="(requirement_group, index) in approvers.data" :key="index" no-body class="mb-4">
					<b-card-header>
						<p class="text-size-12 mb-0">
							<strong>{{ requirement_group.name }}</strong>
						</p>
					</b-card-header>
					<b-card-body>
						<b-row>
							<b-col>
								<p class="text-success text-size-11 mb-2">
									<i class="fad fa-check-double mr-1"></i>
									<strong>Requisitos</strong>
								</p>
								<ul class="text-size-11">
									<li v-for="(document, d_index) in requirement_group.document_names" :key="d_index">
										{{ document }}
									</li>
								</ul>
							</b-col>
							<b-col>
								<p class="text-primary text-size-11 mb-2">
									<i class="fad fa-users mr-1"></i>
									<strong>Aprobadores de Requisitos</strong>
								</p>
								<b-row>
									<b-col sm="6" v-for="(approver, a_index) in requirement_group.approvers" :key="a_index" class="mb-2">
										<p class="mb-0">
											<strong>{{ capitalizeFirstLetter(approver.name) }} {{ capitalizeFirstLetter(approver.primary_last_name) }}</strong>
										</p>
										<p class="mb-0">{{ capitalizeFirstLetter(approver.position) }}</p>
										<p class="mb-0">{{ capitalizeFirstLetter(approver.area) }}</p>
										<p class="mb-0 text-muted">{{ approver.email }}</p>
									</b-col>
								</b-row>
								<p class="text-primary text-size-11 mb-2 mt-3" v-if="requirement_group.worker_approvers">
									<i class="fad fa-users mr-1"></i>
									<strong>Aprobadores de Nóminas</strong>
								</p>
								<b-row v-if="requirement_group.worker_approvers">
									<b-col sm="6" v-for="(worker_approver, wa_index) in requirement_group.worker_approvers" :key="wa_index" class="mb-2">
										<p class="mb-0">
											<strong>{{ capitalizeFirstLetter(worker_approver.name) }} {{ capitalizeFirstLetter(worker_approver.primary_last_name) }}</strong>
										</p>
										<p class="mb-0">{{ capitalizeFirstLetter(worker_approver.position) }}</p>
										<p class="mb-0">{{ capitalizeFirstLetter(worker_approver.area) }}</p>
										<p class="mb-0 text-muted">{{ worker_approver.email }}</p>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-overlay>

		</b-card>
		<!-- /CONTENT -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'

export default {
	name: 'Approvers',
	components: {
		Unauthorized,
	},
    data(){
        return {
        }
    },
	computed: {
		getters() {
			return this.$store.getters
		},
		approvers() {
			return this.$store.state.approvers
		}
	},
    created() {
		this.load()
    },
    methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
        refresh() {
            this.$store.dispatch('get_approvers')
        },
		load() {
			this.$store.dispatch('get_approvers')
		},
        parseIdentifier(i) {
            return i ? i.length <= 10 ? i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1) : 'INVÁLIDO' : i
        },
        shortName(name) {
            return name ? name.length > 40 ? name.substring(0,35) + '...' : name : ''
        },
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
		}
	}
}
</script>