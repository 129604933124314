<template>
	<div>
		<b-row>
			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button variant="outline-warning" @click="goTo('Reports')" class="mr-2" v-b-tooltip.hover.bottomright title="Volver a Módulo Reportes">
					<i class="fas fa-arrow-left"></i> Volver a Reportes
				</b-button>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->
		</b-row>
		<b-card-group deck>
			<b-card body-class="pb-0">
				<b-card-title>Contratistas</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Empresas Contratistas.</p>
				<template #footer>
					<b-button to="/reportes/estadisticas/contratistas" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Subcontratistas</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Empresas Subcontratistas.</p>
				<template #footer>
					<b-button to="/reportes/estadisticas/subcontratistas" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Órdenes de Servicio</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Órdenes de Servicio.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/ordenes-servicio" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
		</b-card-group>
		<b-card-group deck class="mt-4">
			<b-card body-class="pb-0">
				<b-card-title>Contratos</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Contratos de Servicio.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/contratos" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Garantías</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Servicios de Garantía.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/garantias" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Subcontratos</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Subcontratos de Servicio.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/subcontratos" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
		</b-card-group>
		<b-card-group deck class="mt-4">
			<b-card body-class="pb-0">
				<b-card-title>Trabajadores Contratistas</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Trabajdores Contratistas.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/trabajadores/contratistas" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Trabajadores Subcontratistas</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Trabajadores Subcontratistas.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/trabajadores/subcontratistas" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Vehículos Contratistas</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Vehículos Contratistas.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/vehiculos/contratistas" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
		</b-card-group>
		<b-card-group deck class="mt-4">
			<b-card body-class="pb-0">
				<b-card-title>Vehículos Subcontratistas</b-card-title>
				<p class="text-muted text-size-12 mb-3">Resumen de acreditación y documentos de Vehículos Subcontratistas.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/vehiculos/subcontratistas" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Controles</b-card-title>
				<p class="text-muted text-size-12 mb-3">Consulte los controles de ingreso y salida.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/controles" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<b-card body-class="pb-0">
				<b-card-title>Aprobadores</b-card-title>
				<p class="text-muted text-size-12 mb-3">Consulte la actividad de usuarios aprobadores de requisitos.</p>
				<template #footer>
				<b-button to="/reportes/estadisticas/aprobadores" variant="primary" block size="lg">Ver Estadísticas</b-button>
				</template>
			</b-card>
			<!--
			<b-card bg-variant="light" text-variant="secondary">
				<p class="text-center text-size-3 mb-0"><i class="fas fa-envelope"></i></p>
				<p class="text-center text-size-12 mb-1">Si necesita más estadísticas, envíe su solicitud a:</p>
				<p class="text-center text-size-15 mb-0">soporte@electrofrio.cl</p>
			</b-card>
			-->
		</b-card-group>
	</div>
</template>

<script>
export default {
	name: 'Stats',
	components: {
	},
	data() {
		return {
		}
	},
	mounted() {
	},
	computed: {
	},
	methods: {
		goTo(route_name) {
			this.$router.push({ name: route_name })
		},
	}
}
</script>

<style scoped>
.card-title-middle {
	padding-top: 3px;
}
.inner-card-title {
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.15em;
	margin: 0;
}
.inner-card-legend {
	margin: 0;
}
.legend-primary {
	color: #008FFB;
}
.legend-success {
	color: #00E396;
}
.legend-danger {
	color: #FF4560;
}
.legend-warning {
	color: #FEB019;
}
.legend-secondary {
	color: #AAAAAA;
}
</style>
