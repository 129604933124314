<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-worker"
			:title="'Registrar Nuevo ' + type_name"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="12" v-if="type === 2">
						<b-form-group label="Empresa Contratista">
							<b-overlay :show="contractors.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="worker.contractor_id" :state="val_contractor" :disabled="contractors.loading" v-if="!contractors.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Contratista...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in contractors.data" :key="index" :value="item.id">{{item.full_name.toUpperCase()}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12" v-else-if="type === 3">
						<b-form-group label="Empresa Subcontratista">
							<b-overlay :show="subcontractors.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="worker.subcontractor_id" :state="val_subcontractor" :disabled="subcontractors.loading" v-if="!subcontractors.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Subcontratista...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in subcontractors.data" :key="index" :value="item.id">{{item.full_name.toUpperCase()}} // {{item.contractor.short_name.toUpperCase()}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="RUT">
							<b-input v-model="worker.identifier" :state="val_identifier" :formatter="cleanIdentifier" :maxlength="9"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Nombres">
							<b-input v-model="worker.names" :state="val_names"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Apellido Paterno">
							<b-input v-model="worker.primary_last_name" :state="val_primary_last_name"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Apellido Materno">
							<b-input v-model="worker.second_last_name" :state="val_second_last_name"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12">
						<b-form-group label="Área o Departamento">
							<b-input v-model="worker.area" :state="val_area"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Cargo">
							<b-input v-model="worker.position" :state="val_position"></b-input>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Tipo de Contrato">
							<b-select v-model="worker.contract_type" :options="contract_type_options" :state="val_contract_type" @input="changeContractType()"></b-select>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group :label="worker.contract_type == 3 ? 'Inicio de Práctica' : 'Inicio de Contrato'">
							<b-form-datepicker
								v-model="worker.contract_start"
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
								:state="val_contract_start"
								@input="worker.contract_end = ''"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="worker.contract_type == 1 || worker.contract_type == 3">
						<b-form-group :label="worker.contract_type == 3 ? 'Término de Práctica' : 'Término de Contrato'">
							<b-form-datepicker
								v-model="worker.contract_end"
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
								:state="val_contract_end"
								:min="worker.contract_start"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback>Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback>Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="12" v-if="type != 1 && false">
						<b-form-group label="¿Habilitar como conductor?">
							<b-form-checkbox-group v-model="worker.driver" size="lg" disabled>
								<b-form-checkbox :value="false"  inline>
									<span>No</span>
								</b-form-checkbox>
								<b-form-checkbox :value="true" inline>
									<span v-if="!worker.driver">Sí</span>
									<span v-else class="text-primary">
										<strong>Sí</strong>
									</span>
								</b-form-checkbox>
							</b-form-checkbox-group>
						</b-form-group>
					</b-col>

					<!-- COPPER MARK -->
					<!-- 11/09/2023  -->
					<b-col sm="6">
						<b-form-group label="Fecha de Nacimiento">
							<b-form-datepicker
								v-model="worker.birth_date"
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
								:state="val_birth_date"
								max="2007-12-31"
								label-no-date-selected="Use las flechas para cambiar mes o año"
								placeholder="Seleccione una fecha"
							></b-form-datepicker>
							<b-form-invalid-feedback :state="val_birth_date">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_birth_date">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Nacionalidad">
							<b-overlay :show="countries.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select
									v-model="worker.country_id"
									:state="val_country"
									:disabled="countries.loading"
									v-if="!countries.data.data"
									@change="worker.migration_status_id = null"
								>
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Nacionalidad...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in countries.data" :key="index" :value="item.id">{{ item.nationality.toUpperCase() }}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback :state="val_country">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_country">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="worker.country_id != null && worker.country_id != 1">
						<b-form-group label="Estado Migratorio" :disabled="worker.country_id == null || worker.country_id === 1">
							<b-overlay :show="migration_statuses.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="worker.migration_status_id" :state="val_migration_status" :disabled="migration_statuses.loading" v-if="!migration_statuses.data.data">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Estado...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in migration_statuses.data" :key="index" :value="item.id">{{ item.name.toUpperCase() }}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback :state="val_migration_status">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_migration_status">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="Persona con Discapacidad">
							<b-form-radio-group v-model="worker.has_disabilities" size="lg">
								<b-form-radio :value="true" inline :state="val_disabilities">
									<span>Sí</span>
								</b-form-radio>
								<b-form-radio :value="false" inline :state="val_disabilities">
									<span>No</span>
								</b-form-radio>
							</b-form-radio-group>
							<b-form-invalid-feedback :state="val_disabilities">Requerido</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_disabilities">Correcto</b-form-valid-feedback>
						</b-form-group>
					</b-col>

				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón REGISTRAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="!val_full || loading">REGISTRAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'CreateWorker',
	components: {
	},
	data() {
		return {
			loading: false,
			worker: {
				contractor_id: null,
				subcontractor_id: null,
				identifier: '',
				names: '',
				primary_last_name: '',
				second_last_name: '',
				area: '',
				position: '',
				contract_type: null,
				contract_start: '',
				contract_end: '',
				driver: false,
				birth_date: '',
				country_id: null,
				migration_status_id: null,
				has_disabilities: null,
			},
			contract_type_options: [],
		}
	},
	props: {
		type: Number
	},
	watch: {
		type(worker_type) {
			this.updateContractTypes(worker_type)
		}
	},
	computed: {
		contractors() {
			return this.$store.state.contractors
		},
		subcontractors() {
			return this.$store.state.subcontractors
		},
		migration_statuses() {
			return this.$store.state.migration_statuses
		},
		countries() {
			return this.$store.state.countries
		},
		val_full() {
			return ( (this.type == 1 ) || (this.type == 2 && this.val_contractor) || (this.type == 3 && this.val_subcontractor) ) &&
					this.val_identifier &&
					this.val_names &&
					this.val_primary_last_name &&
					this.val_second_last_name &&
					this.val_area &&
					this.val_position &&
					this.val_contract_type &&
					this.val_contract_start &&
					this.val_contract_end &&
					this.val_birth_date &&
					this.val_country &&
					(this.worker.country_id == 1 || (this.worker.country_id != 1 && this.val_migration_status)) &&
					this.val_disabilities ? true : false
		},
		val_contractor() {
			return this.worker.contractor_id != null ? true : false
		},
		val_subcontractor() {
			return this.worker.subcontractor_id != null ? true : false
		},
		val_names() {
			return this.worker.names.length > 0 ? true : false
		},
		val_primary_last_name() {
			return this.worker.primary_last_name.length > 0 ? true : false
		},
		val_second_last_name() {
			return this.worker.second_last_name.length > 0 ? true : false
		},
		val_area() {
			return this.worker.area.length > 0 ? true : false
		},
		val_position() {
			return this.worker.position.length > 0 ? true : false
		},
		val_contract_type() {
			return this.worker.contract_type != null ? true : false
		},
		val_contract_start() {
			return this.worker.contract_start.length > 0 ? true : false
		},
		val_contract_end() {
			return this.worker.contract_end.length > 0 || this.worker.contract_type === 2 ? true : false
		},
		val_birth_date() {
			return this.worker.birth_date && this.worker.birth_date.length > 0 ? true : false
		},
		val_country() {
			return this.worker.country_id != null ? true : false
		},
		val_migration_status() {
			return this.worker.migration_status_id != null ? true : false
		},
		val_disabilities() {
			return this.worker.has_disabilities != null ? true : false
		},
		val_identifier() {
			if (this.worker.identifier.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.worker.identifier)) return false
			var dv = this.worker.identifier.slice(-1)
			var rut = this.worker.identifier.length == 9 ? this.worker.identifier.slice(0,8) : this.worker.identifier.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
		type_name() {
			if (this.type) {
				switch (this.type) {
					case 1:
						return 'Trabajador HMC'
					case 2:
						return 'Trabajador Contratista'
					case 3:
						return 'Trabajador Subcontratista'
					default:
						return 'Trabajador'
				}
			} else {
				return 'Trabajador'
			}
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-worker') { this.reset() }
		})
		this.updateContractTypes(this.type)
	},
	methods: {
		reset() {
			this.worker = {
				contractor_id: null,
				subcontractor_id: null,
				identifier: '',
				names: '',
				primary_last_name: '',
				second_last_name: '',
				area: '',
				position: '',
				contract_type: null,
				contract_start: '',
				contract_end: '',
				driver: false
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.worker.type = this.type
			this.$api.post('workers', this.worker)
			.then(response => {
				this.loading = false
				this.$emit('update')
				this.$bvModal.hide('create-worker')
				this.toast(true, 'Se registró a "'+response.data.person.names+' '+response.data.person.primary_last_name+' '+response.data.person.second_last_name+'" en el sistema correctamente.', 10000)
				this.reset()
			})
			.catch(error => {
				this.loading = false
				if (error.response.status == 409) this.worker.identifier = '' // duplicated
				this.toast(false, error.response.data.message, 10000)
			})
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
		changeContractType() {
			if (this.worker.contract_type === 2) {
				this.worker.contract_end = ''
			}
		},
		updateContractTypes(worker_type) {
			if (worker_type == 1) {
				this.contract_type_options = [
					{ value: 1, text: 'CONTRATO FIJO' },
					{ value: 2, text: 'CONTRATO INDEFINIDO' },
					{ value: 3, text: 'ALUMNO EN PRÁCTICA' },
				]
			} else {
				this.contract_type_options = [
					{ value: 1, text: 'CONTRATO FIJO' },
					{ value: 2, text: 'CONTRATO INDEFINIDO' },
				]
			}
		}
	}
}
</script>

<style scoped>
</style>
