<template>
	<div>

		<!-- MODAL -->
		<b-modal
			id="create-contractor"
			title="Registrar Nueva Empresa Contratista"
			size="md"
			body-class="p-0"
		>

			<!-- CONTENT -->
			<b-overlay :show="loading" rounded="sm" class="p-3">

				<!-- FORM -->
				<b-row>
					<b-col sm="12">
						<b-form-group label="Razón Social">
							<b-input v-model="contractor.full_name" :state="val_full_name"></b-input>
							<b-form-invalid-feedback>Requerida</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="5">
						<b-form-group label="RUT">
							<b-input v-model="contractor.identifier" :state="val_identifier" :formatter="cleanIdentifier" :maxlength="9"></b-input>
							<b-form-invalid-feedback>Ingrese un RUT válido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="7">
						<b-form-group label="Nombre Fantasía">
							<b-input v-model="contractor.short_name" :state="val_short_name"></b-input>
							<b-form-invalid-feedback>Nombre abreviado para mostrar en sistema</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="5">
						<b-form-group label="Teléfono">
							<b-input v-model="contractor.phone" :state="val_phone"></b-input>
							<b-form-invalid-feedback>Ingrese un teléfono de contacto</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="7">
						<b-form-group label="E-mail">
							<b-input v-model="contractor.email" :state="val_email"></b-input>
							<b-form-invalid-feedback>Ingrese un correo electrónico válido</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="5">
						<b-form-group label="Comuna">
							<b-overlay :show="communes.loading" class="p-0">
								<template #overlay>
									<div class="d-flex align-items-center">
									<b-spinner type="grow" variant="secondary" small></b-spinner>
									</div>
								</template>
								<b-form-select v-model="contractor.commune_id" :disabled="communes.loading" :state="val_commune">
									<template v-slot:first>
										<b-form-select-option :value="null" disabled>Seleccione Comuna...</b-form-select-option>
									</template>
									<b-form-select-option v-for="(item, index) in communes.data" :key="index" :value="item.id">{{item.name}}</b-form-select-option>
								</b-form-select>
							</b-overlay>
							<b-form-invalid-feedback :state="val_commune">Seleccione una comuna</b-form-invalid-feedback>
							<b-form-valid-feedback :state="val_commune">OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
					<b-col sm="7">
						<b-form-group label="Dirección">
							<b-input v-model="contractor.address" :state="val_address"></b-input>
							<b-form-invalid-feedback>Ingrese avenida o calle y número</b-form-invalid-feedback>
							<b-form-valid-feedback>OK</b-form-valid-feedback>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- /FORM -->

				<!-- MESSAGE -->
				<b-alert show :variant="val_full?'success':'danger'" class="text-size-11">
					<span v-if="val_full">¡Todo bien! Para terminar, presione el botón REGISTRAR.</span>
					<span v-else>Por favor, complete todos los campos requeridos.</span>
				</b-alert>
				<!-- /MESSAGE -->

				<!-- LOADING PROGRESS -->
				<template #overlay>
					<div class="text-center">
						<b-spinner type="grow" variant="primary" class="mb-4"></b-spinner>
					</div>
				</template>
				<!-- /LOADING PROGRESS -->

			</b-overlay>
			<!-- /CONTENT -->

			<!-- FOOTER -->
			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" :disabled="loading">Cancelar</b-button>
				<b-button @click="create()" variant="success" :disabled="!val_full || loading">REGISTRAR</b-button>
			</template>
			<!-- /FOOTER -->

		</b-modal>
		<!-- /MODAL -->

	</div>
</template>

<script>
export default {
	name: 'CreateContractor',
	components: {
	},
	data() {
		return {
			loading: false,
			contractor: {
				identifier: '',
				full_name: '',
				short_name: '',
				phone: '',
				email: '',
				commune_id: null,
				address: ''
			},
		}
	},
	computed: {
		val_full() {
			return this.val_full_name &&
					this.val_identifier &&
					this.val_short_name &&
					this.val_phone &&
					this.val_email &&
					this.val_address &&
					this.val_commune ? true : false
		},
		val_full_name() {
			return this.contractor.full_name.length > 0 ? true : false
		},
		val_identifier() {
			if (this.contractor.identifier.length < 8 || !/^[0-9]+[0-9kK]{1}$/.test(this.contractor.identifier)) return false
			var dv = this.contractor.identifier.slice(-1)
			var rut = this.contractor.identifier.length == 9 ? this.contractor.identifier.slice(0,8) : this.contractor.identifier.slice(0,7)
			if ( dv == 'K' ) dv = 'k'
			var M=0,S=1; for(;rut;rut=Math.floor(rut/10)) { S=(S+rut%10*(9-M++%6))%11; }
			var real_dv = S?S-1:'k'
			return (real_dv == dv)
		},
		val_short_name() {
			return this.contractor.short_name.length > 0 ? true : false
		},
		val_phone() {
			return this.contractor.phone.length > 0 ? true : false
		},
		val_email() {
			var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return this.contractor.email.length > 0 ? regex.test(this.contractor.email) ? true : false : false
		},
		val_address() {
			return  this.contractor.address.length > 0 ? true : false
		},
		val_commune() {
			return this.contractor.commune_id != null ? true : false
		},
		communes() {
			return this.$store.state.communes
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
			if (modalId == 'create-contractor') { this.reset() }
		})
	},
	methods: {
		reset() {
			this.contractor = {
				identifier: '',
				full_name: '',
				short_name: '',
				phone: '',
				email: '',
				commune_id: null,
				address: ''
			}
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
		create() {
			this.loading = true
			this.$api.post('contractors', this.contractor)
			.then(response => {
				this.loading = false
				this.$emit('update')
				this.$bvModal.hide('create-contractor')
				this.toast(true, 'Se registró la empresa "'+response.data.full_name+'" con el RUT '+this.parseIdentifier(response.data.identifier)+' correctamente y la información de acceso fue enviada a '+response.data.email+'. Puede encontrar la nueva empresa en el listado de No Vigentes.', 10000)
				this.reset()
			})
			.catch(error => {
				this.loading = false
				if (error.response.status == 409) {
					if (error.response.data.type == 'email') this.contractor.email = ''
					if (error.response.data.type == 'identifier') this.contractor.identifier = ''
				}
				this.toast(false, error.response.data.message, 10000)
			})
		},
		cleanIdentifier(value) {
			return value.replace(/[^0-9kK]/g,'');
		},
		parseIdentifier(i) {
			return i.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".")+'-'+i.substr(i.length-1)
		}
	}
}
</script>

<style scoped>
</style>
