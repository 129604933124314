<template>
	<div>
		<b-card no-body>
			<b-card-header>
				<b-row>
					<b-col class="card-title-middle">
						<strong>ACREDITACIÓN DE ÓRDENES DE SERVICIO</strong>
					</b-col>
					<b-col class="text-right">
						<b-button to="/reportes/estadisticas" variant="outline-primary" size="sm" class="mr-2" v-b-tooltip.hover title="Volver a Estadísticas">
							<span class="fas fa-arrow-left"></span>
						</b-button>
						<b-button @click="refresh()" variant="outline-primary" size="sm" v-b-tooltip.hover title="Actualizar la información">
							<span class="fas fa-sync"></span>
						</b-button>
					</b-col>
				</b-row>
			</b-card-header>
			<b-overlay>
				<b-card-body class="p-3">
					<b-row>
						<b-col md="4" sm="12">
							<b-card>
								<b-card-body class="p-0">
									<p class="inner-card-title">RESUMEN</p>
									<b-overlay :show="status.loading">
										<apexchart :options="status.chart.options" :series="status.chart.series" height="200"></apexchart>
									</b-overlay>
									<p class="inner-card-legend">
										<b-row>
											<b-col class="text-center">
												<span class="fas fa-square legend-primary"></span> Acreditada
											</b-col>
											<b-col class="text-center">
												<span class="fas fa-square legend-danger"></span> No Acreditada
											</b-col>
										</b-row>
									</p>
									<b-row class="mt-4">
										<b-col>
											<b-tabs>
												<b-tab title="General">
													<b-table :items="status.table.items.general" :fields="status.table.fields" show-empty bordered :busy="status.loading">
														<template v-slot:cell(validated)="data">
															<div class="text-center">
																<span v-if="data.value" class="fas fa-square legend-primary"></span>
																<span v-else class="fas fa-square legend-danger"></span>
															</div>
														</template>
														<template v-slot:table-busy>
															<div class="text-center p-3">
																<b-spinner></b-spinner>
															</div>
														</template>
													</b-table>
												</b-tab>
												<b-tab title="Vigentes" active>
													<b-table :items="status.table.items.active" :fields="status.table.fields" show-empty bordered :busy="status.loading">
														<template v-slot:cell(validated)="data">
															<div class="text-center">
																<span v-if="data.value" class="fas fa-square legend-primary"></span>
																<span v-else class="fas fa-square legend-danger"></span>
															</div>
														</template>
														<template v-slot:table-busy>
															<div class="text-center p-3">
																<b-spinner></b-spinner>
															</div>
														</template>
													</b-table>
												</b-tab>
												<b-tab title="No Vigentes">
													<b-table :items="status.table.items.inactive" :fields="status.table.fields" show-empty bordered :busy="status.loading">
														<template v-slot:cell(validated)="data">
															<div class="text-center">
																<span v-if="data.value" class="fas fa-square legend-primary"></span>
																<span v-else class="fas fa-square legend-danger"></span>
															</div>
														</template>
														<template v-slot:table-busy>
															<div class="text-center p-3">
																<b-spinner></b-spinner>
															</div>
														</template>
													</b-table>
												</b-tab>
											</b-tabs>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
						<b-col>
							<b-card>
								<b-card-body class="p-0">
									<p class="inner-card-title">DETALLE DOCUMENTACIÓN</p>
									<b-overlay :show="documents.loading">
										<b-row>
											<b-col md="4" sm="12" class="p-0 text-center">
												<span>General</span>
												<apexchart :options="documents.chart.options" :series="documents.chart.series.general" height="200"></apexchart>
											</b-col>
											<b-col md="4" sm="12" class="p-0 text-center">
												<span>Vigentes</span>
												<apexchart :options="documents.chart.options" :series="documents.chart.series.active" height="200"></apexchart>
											</b-col>
											<b-col md="4" sm="12" class="p-0 text-center">
												<span>No Vigentes</span>
												<apexchart :options="documents.chart.options" :series="documents.chart.series.inactive" height="200"></apexchart>
											</b-col>
										</b-row>
									</b-overlay>
									<p class="inner-card-legend">
										<b-row>
											<b-col class="text-center">
												<span class="fas fa-square legend-success"></span> Aprobado
											</b-col>
											<b-col class="text-center">
												<span class="fas fa-square legend-danger"></span> Rechazado
											</b-col>
											<b-col class="text-center">
												<span class="fas fa-square legend-warning"></span> En Revisión
											</b-col>
											<b-col class="text-center">
												<span class="fas fa-square legend-secondary"></span> Pendiente
											</b-col>
										</b-row>
									</p>
									<b-row class="mt-4">
										<b-col>
											<b-tabs>
												<b-tab title="General">
													<b-table :items="documents.table.items.general" :fields="documents.table.fields" show-empty bordered :busy="documents.loading">
														<template v-slot:cell(done)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(rejected)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(waiting)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(missing)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:table-busy>
															<div class="text-center p-3">
																<b-spinner></b-spinner>
															</div>
														</template>
													</b-table>
												</b-tab>
												<b-tab title="Vigentes" active>
													<b-table :items="documents.table.items.active" :fields="documents.table.fields" show-empty bordered :busy="documents.loading">
														<template v-slot:cell(done)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(rejected)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(waiting)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(missing)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:table-busy>
															<div class="text-center p-3">
																<b-spinner></b-spinner>
															</div>
														</template>
													</b-table>
												</b-tab>
												<b-tab title="No Vigentes">
													<b-table :items="documents.table.items.inactive" :fields="documents.table.fields" show-empty bordered :busy="documents.loading">
														<template v-slot:cell(done)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(rejected)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(waiting)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:cell(missing)="data">
															<div class="text-center">
																{{data.value}}
															</div>
														</template>
														<template v-slot:table-busy>
															<div class="text-center p-3">
																<b-spinner></b-spinner>
															</div>
														</template>
													</b-table>
												</b-tab>
											</b-tabs>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
				</b-card-body>
			</b-overlay>
		</b-card>
	</div>
</template>

<script>
export default {
	name: 'StatsService',
	data() {
		return {
			status: {
				loading: true,
				chart: {
					series: [],
					options: {
						xaxis: {
							categories: [ 'General', 'Vigentes', 'No Vigentes' ],
							labels: {
								show: false
							},
							axisTicks: {
								show: false
							}
						},
						chart: {
							type: 'bar',
							stacked: true,
							stackType: '100%',
							toolbar: {
								show: false
							}
						},
						plotOptions: {
							bar: {
								horizontal: true,
								barHeight: '60%',
							}
						},
						colors: [ '#008FFB', '#FF4560' ],
						fill: {
							opacity: 1
						},
						legend: {
							show: false
						},
					}
				},
				table: {
					items: {
						general: [],
						active: [],
						inactive: []
					},
					fields: [
						{ key: 'service', label: 'OS', sortable: true },
						{ key: 'validated', label: 'Acreditada', sortable: true }
					]
				}
			},
			documents: {
				loading: true,
				chart: {
					series: {
						general: [],
						active: [],
						inactive: []
					},
					options: {
						stroke: {
							show: false
						},
						chart: {
							type: 'pie',
						},
						labels: ['Aprobados', 'Rechazados', 'Revisión', 'Pendientes'],
						plotOptions: {
						},
						colors: [ '#00E396', '#FF4560', '#FEB019', '#AAAAAA' ],
						fill: {
							opacity: 1
						},
						legend: {
							show: false
						},
						dataLabels: {
							minAngleToShowLabel: 20,
							style: {
								colors: [ '#333333', '#333333', '#333333', '#333333' ]
							},
							dropShadow: {
								enabled: false
							},
							background: {
								enabled: true
							}
						}
					}
				},
				table: {
					items: {
						general: [],
						active: [],
						inactive: []
					},
					fields: [
						{ key: 'service', label: 'OS', sortable: true },
						{ key: 'done', label: 'Aprobado', sortable: true },
						{ key: 'rejected', label: 'Rechazado', sortable: true },
						{ key: 'waiting', label: 'Revisión', sortable: true },
						{ key: 'missing', label: 'Pendiente', sortable: true }
					]
				}
			}
		}
	},
	created() {
		this.getStatus()
		this.getDocuments()
	},
	methods: {
		refresh() {
			this.reset()
			this.getStatus()
			this.getDocuments()
		},
		reset() {
			this.status.chart.series = []
			this.status.table.items = { general: [], active: [], inactive: [] }
			this.documents.chart.series = { general: [], active: [], inactive: [] }
			this.documents.table.items = { general: [], active: [], inactive: [] }
		},
		getStatus() {
			this.status.loading = true
			this.$api.post('stats/service/status')
			.then(response => {
				this.status.loading = false
				this.status.chart.series = response.data.series
				this.status.table.items = response.data.table
			})
		},
		getDocuments() {
			this.documents.loading = true
			this.$api.post('stats/service/documents')
			.then(response => {
				this.documents.loading = false
				this.documents.chart.series = response.data.series
				this.documents.table.items = response.data.table
			})
		}
	},
}
</script>

<style scoped>
.card-title-middle {
	padding-top: 3px;
}
.inner-card-title {
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.15em;
	margin: 0;
}
.inner-card-legend {
	margin: 0;
}
.legend-primary {
	color: #008FFB;
}
.legend-success {
	color: #00E396;
}
.legend-danger {
	color: #FF4560;
}
.legend-warning {
	color: #FEB019;
}
.legend-secondary {
	color: #AAAAAA;
}
</style>