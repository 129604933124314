<template>
	<Unauthorized
		v-if="(!getters.prv_service_orders && query.type === 1) ||
		(!getters.prv_contracts && query.type === 2) ||
		(!getters.prv_warranties && query.type === 3) ||
		(!getters.prv_subcontracts && query.type === 4)"
	></Unauthorized>
	<div v-else>

		<!-- COMPONENTS -->
		<Create @update="load()" :type="query.type"></Create>
		<Update @update="load()" :type="query.type"></Update>
		<Delete @update="load()" :owner="owner"></Delete>
		<Requirements @refresh="load()" :owner="owner"></Requirements>
		<ServiceExtensions @update="load()" :owner="owner"></ServiceExtensions>
		<ServiceWorkers :owner="owner"></ServiceWorkers>
		<!-- /COMPONENTS -->

		<!-- TOP BAR -->
		<b-row>

			<!-- ACTIONS -->
			<b-col class="pr-1 mb-2">
				<b-button
					variant="success"
					@click="create()"
					class="mr-2"
					v-b-tooltip.hover.bottomright
					title="Agregar un nuevo servicio"
					v-if="(getters.prv_service_orders_create && query.type === 1) ||
					(getters.prv_contracts_create && query.type === 2) ||
					(getters.prv_warranties_create && query.type === 3) ||
					(getters.prv_subcontracts_create && query.type === 4)"
				>
					<i class="fas fa-plus mr-1"></i>
					<span>Agregar</span>
				</b-button>
				<b-overlay
					:show="services.loading"
					rounded
					opacity="0.6"
					class="d-inline-block"
				>
					<template #overlay>
						<span>&nbsp;</span>
					</template>
					<b-button variant="light" @click="load()" v-b-tooltip.hover.bottomright title="Actualizar listado de servicios">
						<i class="fas fa-redo"></i>
						<span> Actualizar</span>
					</b-button>
				</b-overlay>
				<!--
				<b-button variant="info" @click="help()" v-b-tooltip.hover.bottomright title="Ver información de ayuda" class="ml-2">
					<i class="fas fa-question"></i>
				</b-button>
				-->
			</b-col>
			<!-- /ACTIONS -->

			<!-- FILTERS -->
			<b-col sm="auto" class="px-1 mb-2">
				<b-button-group>
					<b-button
						:variant="query.active=='any'?'primary':'light'"
						@click="active('any')"
						v-b-tooltip.hover.bottomleft
						title="Todos los servicios"
					>Todo</b-button>
					<b-button
						:variant="query.active=='yes'?'primary':'light'"
						@click="active('yes')"
						v-b-tooltip.hover.bottomleft
						title="Servicios vigentes"
					>Vigente</b-button>
					<b-button
						:variant="query.active=='no'?'primary':'light'"
						@click="active('no')"
						v-b-tooltip.hover.bottomleft
						title="Servicios no vigentes"
					>No Vigente</b-button>
				</b-button-group>
			</b-col>
			<!-- /FILTERS -->

			<!-- SEARCH -->
			<b-col sm="auto" class="pl-1 mb-2">
				<b-input-group prepend="Buscar">
					<b-input v-model="query.filter" @keyup.delete="reset()" @keyup.enter="filter()" ref="filter" placeholder="Escriba aquí"></b-input>
					<b-input-group-append>
						<b-button :variant="query.filter.length > 0 ? 'primary' : 'light'" @click="filter()" v-b-tooltip.hover.bottomleft title="Presione para buscar">
							<i class="fas fa-arrow-right"></i>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-col>
			<!-- /SEARCH -->

		</b-row>
		<!-- /TOP BAR -->

		<!-- CONTENT -->
		<b-card no-body>

			<!-- PAGINATION -->
			<b-row class="px-3 pt-3">
				<b-col>
					<p v-if="!services.loading" class="text-size-13 mb-0 text-muted mt-1">
						<span v-if="services.data.total"> {{services.data.total}} </span>
						<span v-else> 0 </span>
						<span v-if="query.type == 1"><span v-if="services.data.total > 1">Ó</span><span v-else>O</span>rden<span v-if="services.data.total > 1 || !services.data.total">es</span> de Servicio</span>
						<span v-else-if="query.type == 2">Contrato<span v-if="services.data.total > 1 || !services.data.total">s</span></span>
						<span v-else-if="query.type == 3">Garantía<span v-if="services.data.total > 1 || !services.data.total">s</span></span>
						<span v-else-if="query.type == 4">Subcontrato<span v-if="services.data.total > 1 || !services.data.total">s</span></span>
						<span v-if="query.active == 'yes'"> vigente<span v-if="services.data.total > 1 || !services.data.total">s</span></span>
						<span v-else-if="query.active == 'no'"> no vigente<span v-if="services.data.total > 1 || !services.data.total">s</span></span>
						<span v-if="filtered.length > 0"> con el filtro "{{filtered}}"</span>
					</p>
					<span v-else>
						<b-spinner type="grow" small variant="primary" class="mt-2"></b-spinner>
					</span>
				</b-col>
				<b-col sm="auto" class="pr-1">
					<b-form-group label="Mostrar" label-cols-sm="3" label-align-sm="left">
					<b-select v-model="query.limit" :options="options.pagination" @change="load()">
						<template v-slot:first>
							<b-form-select-option :value="null" disabled>Seleccione una opción...</b-form-select-option>
						</template>
					</b-select></b-form-group>
				</b-col>
				<b-col sm="auto" class="pl-1">
					<b-pagination
						v-model="query.page"
						:total-rows="services.data.total"
						:per-page="query.limit"
						@input="load()"
						first-number
						last-number
						align="right"
					></b-pagination>
				</b-col>
			</b-row>
			<!-- /PAGINATION -->

			<!-- TABLE -->
			<b-skeleton-wrapper :loading="services.loading">

				<!-- LOADING -->
				<template #loading>
					<b-skeleton-table
						animation="fade"
						:table-props="{ small: true }"
						:rows="10"
						:columns="5"
					></b-skeleton-table>
				</template>
				<!-- /LOADING -->

				<!-- DATA -->
				<b-table
					:items="services.data.data"
					:fields="options.fields"
					show-empty
					small
					responsive
					hover
					sort-icon-left
					head-variant="light"
					class="table-with-dropdowns"
				>

					<!-- TEMPLATES -->
					<template v-slot:empty>
						<div class="text-center">
							<p class="mt-5 mb-4 text-size-14 text-muted text-transform-none">No se encontraron servicios que coincidan con su búsqueda.</p>
							<b-button @click="clear()" variant="outline-primary" class="mb-5" size="lg">Regresar al listado completo</b-button>
						</div>
					</template>
					<template v-slot:head()="data">
						<div class="py-1">
							<span class="ml-1">{{data.label}}</span>
						</div>
					</template>
					<template v-slot:cell(cod)="data">
						<div class="text-center ml-2 text-size-13">
							{{data.value}}
						</div>
					</template>
					<template v-slot:cell(company.short_name)="data">
						<div class="px-2" style="min-width:200px">
							<p class="mt-2 mb-1 text-size-085"><i class="fas fa-building"></i> <span v-b-tooltip.hover.right :title="data.item.company.full_name.toUpperCase()">{{data.value.toUpperCase()}}</span></p>
							<p class="mb-2 text-size-085 text-muted" v-if="data.item.administrator">
								<i v-if="data.item.administrator.short_name" class="fas fa-building mr-1"></i>
								<i v-else-if="data.item.administrator.person" class="fas fa-user mr-1"></i>
								<span v-if="data.item.administrator.short_name" v-b-tooltip.hover.right title="Mandante de subcontrato">{{data.item.administrator.short_name.toUpperCase()}}</span>
								<span v-else-if="data.item.administrator.person" v-b-tooltip.hover.right title="Administrador de contrato">{{shortPersonName(data.item.administrator.person.names, data.item.administrator.person.primary_last_name).toUpperCase()}}</span>
							</p>
						</div>
					</template>
					<template v-slot:cell(comments)="data">
						<div class="px-2">
							<p class="mt-2 mb-1 text-size-085">{{data.value.toUpperCase()}}</p>
							<p class="mb-2 text-size-085 text-muted text-lowercase">
								<span v-b-tooltip.hover.right :title="data.item.extensions.length+' extensiones'">
									<i class="fas fa-calendar mr-1"></i>
									<span>{{data.item.start_date}}</span>
									<span class="mx-1">al</span>
									<span v-if="data.item.extensions.length">{{data.item.extensions[data.item.extensions.length-1].end_date}}</span>
									<span v-else>{{data.item.end_date}}</span>
								</span>
							</p>
						</div>
					</template>
					<template v-slot:cell(requirements)="data">
						<div class="text-center">

							<!-- SERVICE ORDERS -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-if="query.type === 1">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_service_orders_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="workers(data)" v-if="getters.prv_service_orders_workers_read">
									<i class="fas fa-user text-muted mr-2"></i>
									<span>Nómina de Personal</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="extensions(data)" v-if="getters.prv_service_orders_extensions_read">
									<i class="fas fa-calendar-plus text-muted mr-2"></i>
									<span>Extensiones de Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_service_orders_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_service_orders_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_service_orders_read && !getters.prv_service_orders_update && !getters.prv_service_orders_delete &&
									!getters.prv_service_orders_workers_read && !getters.prv_service_orders_extensions_read"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /SERVICE ORDERS -->

							<!-- CONTRACTS -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-else-if="query.type === 2">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_contracts_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="workers(data)" v-if="getters.prv_contracts_workers_read">
									<i class="fas fa-user text-muted mr-2"></i>
									<span>Nómina de Personal</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="extensions(data)" v-if="getters.prv_contracts_extensions_read">
									<i class="fas fa-calendar-plus text-muted mr-2"></i>
									<span>Extensiones de Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_contracts_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_contracts_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_contracts_read && !getters.prv_contracts_update && !getters.prv_contracts_delete &&
									!getters.prv_contracts_workers_read && !getters.prv_contracts_extensions_read"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /CONTRACTS -->

							<!-- WARRANTIES -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-else-if="query.type === 3">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_warranties_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="workers(data)" v-if="getters.prv_warranties_workers_read">
									<i class="fas fa-user text-muted mr-2"></i>
									<span>Nómina de Personal</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="extensions(data)" v-if="getters.prv_warranties_extensions_read">
									<i class="fas fa-calendar-plus text-muted mr-2"></i>
									<span>Extensiones de Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_warranties_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_warranties_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_warranties_read && !getters.prv_warranties_update && !getters.prv_warranties_delete &&
									!getters.prv_warranties_workers_read && !getters.prv_warranties_extensions_read"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /WARRANTIES -->

							<!-- SUBCONTRACTS -->
							<b-dropdown size="sm" variant="outline-primary" right no-caret v-else-if="query.type === 4">
								<template v-slot:button-content>
									<i class="fas fa-cog"></i>
									<span class="text-size-09"> GESTIONAR</span>
								</template>
								<b-dropdown-item-button @click="requirements(data)" v-if="getters.prv_subcontracts_read">
									<i class="fas fa-check text-muted mr-2"></i>
									<span>Requisitos de Acreditación</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="workers(data)" v-if="getters.prv_subcontracts_workers_read">
									<i class="fas fa-user text-muted mr-2"></i>
									<span>Nómina de Personal</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="extensions(data)" v-if="getters.prv_subcontracts_extensions_read">
									<i class="fas fa-calendar-plus text-muted mr-2"></i>
									<span>Extensiones de Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="update(data)" v-if="getters.prv_subcontracts_update">
									<i class="fas fa-edit text-muted mr-2"></i>
									<span>Modificar Servicio</span>
								</b-dropdown-item-button>
								<b-dropdown-item-button @click="destroy(data)" v-if="getters.prv_subcontracts_delete">
									<i class="fas fa-times text-muted mr-2"></i>
									<span>Eliminar del Sistema</span>
								</b-dropdown-item-button>
								<b-dropdown-text
									disabled
									v-if="!getters.prv_subcontracts_read && !getters.prv_subcontracts_update && !getters.prv_subcontracts_delete &&
									!getters.prv_subcontracts_workers_read && !getters.prv_subcontracts_extensions_read"
									class="disabled_actions text-muted"
								>Su cuenta no posee privilegios de gestión.</b-dropdown-text>
							</b-dropdown>
							<!-- /SUBCONTRACTS -->

						</div>
					</template>
					<template v-slot:cell(req_done)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="success">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_rejected)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_waiting)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<template v-slot:cell(req_missing)="data">
						<div class="text-center">
							<b-badge v-if="data.value != 0" variant="danger">{{data.value}}</b-badge>
							<span v-else class="text-secondary">0</span>
						</div>
					</template>
					<!-- /TEMPLATES -->

				</b-table>
				<!-- /DATA -->
			
			</b-skeleton-wrapper>
			<!-- /TABLE -->

		</b-card>
		<!-- /CONTENT -->

		<!-- HELP -->
		<b-modal id="services-help" title="Información de Ayuda" size="lg">

			<template v-slot:modal-footer="{ close }">
				<b-button @click="close()" variant="secondary">Cerrar</b-button>
			</template>

		</b-modal>
		<!-- /HELP -->

	</div>
</template>

<script>
import Unauthorized from '@/components/core/Unauthorized.vue'
import Create from '@/components/CreateService.vue'
import Update from '@/components/UpdateService.vue'
import Delete from '@/components/DeleteService.vue'
import Requirements from '@/components/Requirements.vue'
import ServiceExtensions from '@/components/ServiceExtensions.vue'
import ServiceWorkers from '@/components/ServiceWorkers.vue'

export default {
	name: 'Services',
	components: {
		Unauthorized,
		Create,
		Update,
		Delete,
		Requirements,
		ServiceExtensions,
		ServiceWorkers
	},
	data() {
		return {
			owner: {},
			filtered: '',
			query: {
				type: 0, // default service
				active: 'yes',
				limit: 9999,
				page: 1,
				filter: ''
			},
			options: {
				fields: [
					{ key: 'cod', label: 'Folio', sortable: true },
					{ key: 'company.short_name', label: 'Proveedor', sortable: true },
					{ key: 'comments', label: 'Detalles del Servicio', sortable: true },
					{ key: 'requirements', label: 'Acciones' },
					{ key: 'req_done', label: 'Aprobados', sortable: true },
					{ key: 'req_waiting', label: 'Cargados', sortable: true },
					{ key: 'req_rejected', label: 'Rechazados', sortable: true },
					{ key: 'req_missing', label: 'Pendientes', sortable: true }
				],
				pagination: [
					{ value: 5, text: '5 por página' },
					{ value: 10, text: '10 por página' },
					{ value: 20, text: '20 por página' },
					{ value: 50, text: '50 por página' },
					{ value: 100, text: '100 por página' },
					{ value: 9999, text: 'Todos' }
				]
			}
		}
	},
	computed: {
		getters() {
			return this.$store.getters
		},
		services() {
			return this.$store.state.services
		},
		service_type() {
			return this.$store.state.subroutes.services
		}
	},
	created() {
		this.query.type = this.service_type != 0 ? this.service_type : this.$route.meta.type
		this.load()
	},
	watch: {
		service_type(type) {
			this.type(type)
		}
	},
	methods: {
		shortPersonName(names, lastname) {
			const name = names.split(' ')
			return name[0] + ' ' + lastname
		},
		create() {
			if (this.query.type == 4) {
				this.$store.dispatch('get_subcontractors')
			} else {
				this.$store.dispatch('get_contractors')
				this.$store.dispatch('get_service_administrators')
			}
			this.$store.dispatch('get_shifts')
			this.$bvModal.show('create-service')
		},
		update(service) {
			if (this.query.type == 4) {
				this.$store.dispatch('get_subcontractors')
			} else {
				this.$store.dispatch('get_contractors')
				this.$store.dispatch('get_service_administrators')
			}
			this.$store.dispatch('get_shifts')
			this.$store.dispatch('get_service', service.item.id)
			this.$bvModal.show('update-service')
		},
		extensions(service) {
			this.$store.dispatch('get_service_extensions', service.item.id)
			this.$store.dispatch('get_extension_start', service.item.id)
			this.owner = service
			this.$bvModal.show('service-extensions')
		},
		workers(service) {
			if (this.query.type == 4) {
				this.$store.dispatch('get_subcontractor_workers', service.item.company_id)
			} else {
				this.$store.dispatch('get_contractor_workers', service.item.company_id)
			}
			this.owner = service
			this.$store.dispatch('get_service_workers', service.item.id)
			this.$bvModal.show('service-workers')
		},
		active(type) {
			this.query.active = type
			this.load()
		},
		destroy(service) {
			this.owner = service
			this.$bvModal.show('delete-service')
		},
		type(type) {
			this.query.type = type
			this.query.active = 'yes'
			this.query.filter = ''
			this.filtered = ''
			this.load()
		},
		load() {
			this.$store.dispatch('get_services', this.query)
		},
		filter() {
			if (this.query.filter.length === 0) {
				this.$refs.filter.focus()
				this.toast(false, 'Escriba un folio, palabra o empresa para buscar.', 5000)
			} else {
				this.query.page = 1
				this.query.active = 'any'
				this.filtered = this.query.filter
				this.load()
			}
		},
		reset() {
			if (this.query.filter.length == 0) {
				this.query.page = 1
			this.filtered = ''
				this.load()
			}
		},
		clear() {
			this.query.page = 1
			this.query.active = 'yes'
			this.query.filter = ''
			this.query.limit = 9999
			this.filtered = ''
			this.load()
		},
		help() {
			this.$bvModal.show('services-help')
		},
		requirements(service) {
			this.$store.dispatch('get_service_requirements', service.item.id)
			this.owner = service
			this.$bvModal.show('requirements')
		},
		toast(success, message, delay) {
			this.$bvToast.toast(message, {
				title: success ? 'Acción completada' : 'Acción no completada',
				variant: success ? 'info' : 'danger',
				autoHideDelay: delay
			})
		},
	}
}
</script>

<style>
.text-size-08 {
	font-size: 0.8em;
}
.text-size-085 {
	font-size: 0.85em;
}
.text-size-12 {
	font-size: 1.2em;
}
.text-size-14 {
	font-size: 1.4em;
}
</style>